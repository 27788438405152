import React from "react";
import { Button, TextField, Box, LinearProgress } from "@material-ui/core";

export default function TextInput({
    onChange,
    buttonStyle,
    textFieldStyle,
    style,
    handleSubmit,
    classes,
    value,
    onKeyPress,
    inputRef,
    loading,
}) {
    let containerClass = classes && classes.container ? classes.container : "";
    let buttonClass = classes && classes.button ? classes.button : "";
    let textFieldClass = classes && classes.textField ? classes.textField : "";
    return (
        <Box
            display="flex"
            flexDirection="row"
            className={`white-container TextField-with-border-radius ${containerClass}`}
            position="relative"
            style={style}
        >
            <TextField
                className={textFieldClass}
                style={{ width: "80%", ...textFieldStyle }}
                id="input"
                label="Code"
                type="text"
                value={value}
                onChange={onChange}
                variant="outlined"
                fullWidth
                onKeyPress={(ev) => onKeyPress(ev)}
                inputRef={inputRef}
            />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="20%"
            >
                <Button
                    onClick={handleSubmit}
                    id="btn-scan"
                    variant="outlined"
                    className={buttonClass}
                    style={{ width: '100px', ...buttonStyle}}
                >
                    Scan
                </Button>
            </Box>
            {loading ? (
                <LinearProgress className="linear-progress-bar" />
            ) : null}
        </Box>
    );
}
