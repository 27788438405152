import React from 'react'
import MUIDataTable from "mui-datatables";

export default class CustomDatatable extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.data.length !== nextProps.data.length ? true : false;
    }

    render() {
        return (
            <MUIDataTable
                title={this.props.title}
                data={this.props.data}
                columns={this.props.columns}
                options={this.props.options}
            />
        );
    }
}