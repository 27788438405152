import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
// import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import CustomSnackbar from '../layout/CustomSnackbar';
import CustomDatatable from './elements/CustomDatatable';
import { FormLabel, FormGroup, TextField } from '@material-ui/core';
const moment = require('moment');
moment().format();

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});


const columns = [
    {
        name: "ASIN",
        label: "ASIN",
        options: {
            filter: true,
            sort: true,
            customFilterListRender: v => {
                if (v) {
                    return `ASIN includes ${v}`
                }
                return false;
            },
            customBodyRender: (value, _, __) => {
                return <a target="_blank" rel="noopener noreferrer" href={`https://www.amazon.com/dp/${value}/?th=1&psc=1`}>{value}</a>
            }
        }
    },
    {
        name: "SortDate",
        label: "SortDate",
        options: {
            sort: true,
            display: false,
            sortDirection: 'desc',
        }
    },
    {
        name: "Timestamp",
        label: "Timestamp",
        options: {
            display: true,
            customBodyRender: (value, _, __) => {
                return <p>{value.replace("T", " ").replace("Z", " ").split(".")[0]}</p>
            }
        }
    },
    {
        name: "LowestPrice",
        label: "LowestPrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min LowestPrice: ${v['min']}, Max LowestPrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min LowestPrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max LowestPrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>LowestPrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "BuyBoxPrice",
        label: "BuyBoxPrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min BuyBoxPrice: ${v['min']}, Max BuyBoxPrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min BuyBoxPrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max BuyBoxPrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>BuyBoxPrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "MaxPrice",
        label: "MyPrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min MyPrice: ${v['min']}, Max MyPrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min MyPrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max MyPrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>MyPrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "CompetitorCount",
        label: "CompetitorCount",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min CompetitorCount: ${v['min']}, Max CompetitorCount: ${v['max']}`;
                } else if (v['min']) {
                    return `Min CompetitorCount: ${v['min']}`;
                } else if (v['max']) {
                    return `Max CompetitorCount: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>CompetitorCount</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "BuyBoxOwned",
        label: "BuyBoxOwned",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min BuyBoxOwned: ${v['min']}, Max BuyBoxOwned: ${v['max']}`;
                } else if (v['min']) {
                    return `Min BuyBoxOwned: ${v['min']}`;
                } else if (v['max']) {
                    return `Max BuyBoxOwned: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>BuyBoxOwned</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "BuyBoxWinner",
        label: "BuyBoxWinner",
        options: {
            filter: true,
            sort: false,
            display: true,
        }
    },
    {
        name: "PriceList",
        label: "PriceList",
        options: {
            filter: true,
            sort: false,
            display: true,
        }
    },
    {
        name: "SellerList",
        label: "SellerList",
        options: {
            filter: true,
            sort: false,
            display: true,
        }
    },
]

class NotifBrowser extends Component {
    state = {
        code: '',
        cost: '',
        coupon: '',
        subscription: 'NOT ACTIVE',
        credentials: {
            token: ' ',
            sellerID: ' ',
            keepaKey: ' '
        },
        productData: [],
        errorMsg: '',
        snackbarMsg: '',
        snackbarOpened: false,
        snackbarVariant: 'error',
        isLoading: false,
        loadingMsg: '',
        currColl: '',
        oldColl: '',
        collections: ["B07CNGQ28G", "B07KKCFZYG", "B01AMT0AUI", "B07WHM53HW", "B07DS9YN57", "B07HP7YD35", "B00K2SRBP2", "B07SN5DY9T", "B07LBSNJ33", "B07LBV8H3Z", "B07LBVC3T5"],
        purchasedList: '',
        salesData: [],
        siteColumns: columns,
    }

    constructor(props) {
        super(props)
        this.textInput = React.createRef();
    }


    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;
        const copy = require('clipboard-copy')

        const options = {
            filterType: 'textField',
            selectableRows: 'none',
            print: false,
            onCellClick: (colData, celMeta) => {
                copy(`${colData}`)
            },
            downloadOptions: {
                filename: 'notifBrowserExport.csv',
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                }
            },
            responsive: 'standard',
            fixedHeader: true,
        };

        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container display="flex" maxWidth={false} height="80%">
                <Container width='100%' m={3} >
                    <Box display="flex" flexDirection="row" className={classes.container} justifyContent="center" alignItems="center">
                        <Select
                            value={this.state.currColl}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'currColl',
                                id: 'choose-curr-coll',
                            }}
                        >
                            {this.state.collections.map((name) => {
                                return <MenuItem value={name}>{name}</MenuItem>
                            })}
                        </Select>
                        <Button onClick={() => this.newGetData(this.state.currColl)}>
                            Download
            </Button>
                    </Box>
                </Container>

                <Container width='100%' maxWidth="xl" className={classes.container}>
                    {/* <MuiThemeProvider theme={niceScrollTheme}> */}
                    <CustomDatatable
                        title={"Website data"}
                        data={this.state.productData}
                        columns={this.state.siteColumns}
                        options={options}
                    />
                    {/* </MuiThemeProvider> */}


                </Container>

                <CustomSnackbar variant={this.state.snackbarVariant} duration={2000} onClose={this.onSnackbarClose} className={classes.margin} opened={this.state.snackbarOpened} message={this.state.snackbarMsg} />
            </Container>
        )
    }

    focusTextInput() {
        this.textInput.current.focus();
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    handleSubmit = (e) => {
        console.log('Submitted wtf lol')
    }


    getProjSuiteDataWithSearch = async (token, db, coll, searchKey, searchValue) => {
        const dropletData = await fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=${db}&collection=${coll}&searchKey=${searchKey}&searchValue=${searchValue}`).then((resp) => resp.json());
        return dropletData.result;
    }

    parseData = (jsonObj) => {
        const trigger = jsonObj['Notification']['NotificationPayload']['AnyOfferChangedNotification']['OfferChangeTrigger'];

        const sheetData = {};
        sheetData['ASIN'] = trigger['ASIN'];
        sheetData['ChangeType'] = trigger['OfferChangeType'];
        sheetData['Timestamp'] = jsonObj['Notification']['NotificationMetaData'].PublishTime;

        const summary = jsonObj['Notification']['NotificationPayload']['AnyOfferChangedNotification']['Summary'];

        if (summary !== undefined) {
            if (Array.isArray(summary['NumberOfOffers']['OfferCount'])) {
                sheetData['OfferCount'] = summary['NumberOfOffers']['OfferCount'].map((offerObj) => {
                    if (offerObj['@_condition'] === 'new') {
                        return offerObj['#text'];
                    } else {
                        return 0;
                    }
                }).reduce((a, b) => a + b, 0);
            } else if (summary['NumberOfOffers']['OfferCount'] !== undefined && summary['NumberOfOffers']['OfferCount']['@_condition'] === 'new') {
                sheetData['OfferCount'] = summary['NumberOfOffers']['OfferCount']['#text'];
            } else if (summary['NumberOfOffers']['OfferCount'] !== undefined) {
                // console.log('OfferCount is zero for \'New\' condition')
                sheetData['OfferCount'] = 0;
            }

            if (Array.isArray(summary['LowestPrices']['LowestPrice'])) {
                let currMin = -1;
                currMin = Math.min(...summary['LowestPrices']['LowestPrice'].map((lowestPrice) => {
                    if (lowestPrice['@_condition'] === 'new') {
                        return lowestPrice['LandedPrice']['Amount'];
                    } else {
                        return -1;
                    }
                }).filter((price) => {
                    return price !== -1;
                }));
                sheetData['LowestPrice'] = currMin;
            } else {
                sheetData['LowestPrice'] = summary['LowestPrices']['LowestPrice']['LandedPrice']['Amount'];
            }

            if (summary['BuyBoxPrices'] !== undefined) {
                if (Array.isArray(summary['BuyBoxPrices']['BuyBoxPrice'])) {
                    sheetData['BuyBoxPrice'] = Math.min(...summary['BuyBoxPrices']['BuyBoxPrice'].map((lowestPrice) => {
                        if (lowestPrice['@_condition'] === 'new') {
                            return lowestPrice['LandedPrice']['Amount'];
                        } else {
                            return -1;
                        }
                    }).filter((price) => {
                        return price !== -1;
                    }));
                } else if (summary['BuyBoxPrices']['BuyBoxPrice']['@_condition'] === 'new') {
                    sheetData['BuyBoxPrice'] = summary['BuyBoxPrices']['BuyBoxPrice']['LandedPrice']['Amount'];
                } else {
                    sheetData['BuyBoxPrice'] = -1;
                }
            } else {
                sheetData['BuyBoxPrice'] = -1;
            }
        }

        const offersObj = jsonObj['Notification']['NotificationPayload']['AnyOfferChangedNotification']['Offers'];

        if (offersObj !== undefined && Array.isArray(offersObj['Offer'])) {
            offersObj['Offer'] = offersObj['Offer'].filter((offer) => {
                return offer['SubCondition'] === 'new';
            });

            const sellerList = offersObj['Offer'].map((offer) => {
                return offer['SellerId'];
            }).join(',');

            let temp
            sheetData['MaxPrice'] = (temp = offersObj['Offer'].filter((offer) => offer['SellerId'] === 'A3B3XKFL4HJA0A')[0]) !== undefined ? temp['ListingPrice']['Amount'] : -1;

            const priceList = offersObj['Offer'].map((offer) => {
                return offer['ListingPrice']['Amount'];
            }).join(',');

            const buyboxWinner = offersObj['Offer'].filter((offer) => {
                return offer['IsBuyBoxWinner'];
            }).map((offer) => {
                return offer['SellerId'];
            }).join(',');

            const merchantWinner = offersObj['Offer'].map((offer) => {
                const x = {
                    channel: offer['IsFulfilledByAmazon'],
                    offer: offer['IsBuyBoxWinner'],
                    id: offer['SellerId'],
                };
                return x;
            }).filter((offerNew) => {
                return !offerNew.channel && offerNew.offer;
            }).map((offerNewNew) => {
                return offerNewNew.id;
            }).join(',');

            sheetData['PriceList'] = priceList;
            sheetData['SellerList'] = sellerList;
            sheetData['BuyBoxWinner'] = buyboxWinner;
            sheetData['MerchantWinner'] = merchantWinner;
        } else if (offersObj['Offer'] !== undefined && Object.entries(offersObj['Offer']).length !== 0) {
            sheetData['PriceList'] = offersObj['Offer']['ListingPrice']['Amount'].toString();
            sheetData['SellerList'] = offersObj['Offer']['SellerId'];
            sheetData['MaxPrice'] = offersObj['Offer']['ListingPrice']['Amount'];
            sheetData['BuyBoxWinner'] = offersObj['Offer']['IsBuyBoxWinner'] === true ? offersObj['Offer']['SellerId'] : '';
            sheetData['MerchantWinner'] = offersObj['Offer']['IsBuyBoxWinner'] === true && !offersObj['Offer']['IsFulfilledByAmazon'] ? offersObj['Offer']['SellerId'] : '';
        }

        if (sheetData['BuyBoxPrice'] !== -1 && sheetData['PriceList'] !== '') {
            sheetData['CompetitorCount'] = sheetData.PriceList.split(',').filter((price) => (Math.abs(price - sheetData['BuyBoxPrice'])) < 0.05).length;
        } else {
            sheetData['CompetitorCount'] = -1;
        }

        return sheetData;
    };

    newGetData = async (currColl) => {
        const token = await getToken();
        this.getProjSuiteDataWithSearch(token, 'luOjCSlnssfwomE0Lic6gHzmDcw1', 'notifs', 'ASIN', currColl).then(data => {
            console.log(data);
            if (data.length > 0) {
                const replacementIds = {
                    'A3B3XKFL4HJA0A': 'Max',
                    'A12115QSRA5J4Q': 'Brian',
                    'AB1XQ3DA8GGTV': 'Jeff',
                    'AWJMXP5IJRVY4': 'ShoeCago',
                    'A134OEVQ3I2MCT': 'Nick',
                    'ATVPDKIKX0DER': 'AZ',
                }
                const tableData = data.map(data => this.parseData(data.Notif)).map(data => {
                    data.MomentTimestamp = moment(data.Timestamp)
                    data.Timestamp = new Date(data.Timestamp).toLocaleString("en-US", { timeZone: "America/Winnipeg" })
                    data.PriceList = data.PriceList.split(",").slice(0, 3).join(", ")
                    data.SellerList = data.SellerList.split(",").slice(0, 3).join(", ")
                    data.SortDate = (new Date(data.Timestamp)).getTime()
                    Object.keys(replacementIds).forEach(id => {
                        data.SellerList = data.SellerList.replace(new RegExp(id, "g"), replacementIds[id]);
                        data.BuyBoxWinner = data.BuyBoxWinner.replace(new RegExp(id, "g"), replacementIds[id]);
                    })
                    return data
                })

                for (let i = 2; i < tableData.length; i++) {
                    tableData[i-1].BuyBoxOwned = parseFloat(moment.duration(tableData[i].MomentTimestamp.diff(tableData[i-1].MomentTimestamp)).asMinutes().toFixed(2))
                }
                tableData[0].BuyBoxOwned = moment.duration(0).asMinutes();
                tableData[tableData.length-1].BuyBoxOwned = -1

                console.log(tableData);
                this.setState({ productData: tableData })
            }
        })
    }

    uniq = (a) => {
        var seen = {};
        return a.filter(function (item) {
            return seen.hasOwnProperty(item.ASIN) ? false : (seen[item.ASIN] = true);
        });
    }

    onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpened: false, snackbarMsg: null })
    }

    componentDidMount() {
        this.getCollections()
    }

    getCollections = async () => {
        const token = await getToken();
        fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&collection=asin_list&db=repricer`).then(response => {
            return response.json();
        }).then(jsonResponse => {
            let collections = jsonResponse["result"].map(obj => obj.ASIN)
            this.setState({ collections: collections })
        })
    }
}

NotifBrowser.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(NotifBrowser))