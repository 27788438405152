const basicData = {
    "KeepaKey": "cnddihfl5guphi0qo5nm9vpp1ckgggdfcbopbv21qjb2nili0sfdfeau1lbsh43p",
};

function connect(code, keepaKey, formatParam) {
    var url = 'https://api.keepa.com/product?domain=1&history=0&stats=180&' + formatParam + '=' + code + '&key=' + basicData.KeepaKey;
    return fetch(url).then(response => {
        return response.json();
    }).then(jsonResponse => {
        return JSON.parse(JSON.stringify(jsonResponse));
    })
}

export function getProductForUPC(upc, keepaKey) {
    return connect(upc, keepaKey, 'code').then(response => {
        return response;
    });
}

export function getProductForASIN(asin, keepaKey) {
    return connect(asin, keepaKey, 'asin').then(response => {
        return response;
    })
}

export function extractChartData(result) {
    let threeMonthsAgo = new Date()
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3)
    let priceCSV = result.products[0].csv[0]
    let salesRankCSV = result.products[0].csv[3]
    let priceHistory = []
    let salesRankHistory = []

    for (var i = 0; i < priceCSV.length-1; i += 2) {
        if (priceCSV[i+1] !== -1) {
            // Convert Keepa minutes to date
            let currDate = new Date((priceCSV[i] + 21564000) * 60000)
            if (currDate > threeMonthsAgo) {
                priceHistory.push({
                    date: currDate,
                    amazonPrice: priceCSV[i+1]/100,
                })
            }
        }
    }

    for (i = 0; i < salesRankCSV.length-1; i += 2) {
        if (salesRankCSV[i+1] !== -1) {
            // Convert Keepa minutes to date
            let currDate = new Date((salesRankCSV[i] + 21564000) * 60000)
            if (currDate > threeMonthsAgo && i % 3 === 0) {
                salesRankHistory.push({
                    date: currDate,
                    salesRank: salesRankCSV[i+1],
                })
            }
        }
    }

    let chartData = {
        price: priceHistory,
        salesRank: salesRankHistory
    }
    return chartData
}

export function extractASIN(result) {
    console.log(result)
    return result.products[0].asin
}

export function extractProduct(result, product) {
    if (result.products[0].asin !== undefined) {
        for (let i = 0; i < result.products.length; i++) {
            var keepaProduct = result.products[0]
            var keepaStats = keepaProduct.stats
            // Basic info
            let resultProduct = {
                ASIN: keepaProduct.asin,
                UPC: keepaProduct.upc,
                name: keepaProduct.title,
                brand: keepaProduct.brand,
                parentASIN: keepaProduct.parentAsin,
                size: keepaProduct.size,
                currentAmazonPrice: keepaStats.current[0] !== -1 ? keepaStats.current[0]/100 : -1,
                currentNewPrice: keepaStats.current[1] !== -1 ? keepaStats.current[1]/100 : -1,
                currentSalesRank: keepaStats.current[3],
                currentListPrice: keepaStats.current[4] !== -1 ? keepaStats.current[4]/100 : -1,
                currentNewOfferCount: keepaStats.current[11],
                avgAmazonPrice: keepaStats.avg[0] !== -1 ? keepaStats.avg[0]/100 : -1,
                avgNewPrice: keepaStats.avg[1] !== -1 ? keepaStats.avg[1]/100 : -1,
                avgSalesRank: keepaStats.avg[3],
                avgListPrice  : keepaStats.avg[4] !== -1 ? keepaStats.avg[4]/100 : -1,
                rootCategory: keepaProduct.categoryTree !== null ? keepaProduct.categoryTree[0].name : 'Unknown',
                availabilityAmazon: keepaProduct.availabilityAmazon,
                azLink: 'https://www.amazon.com/gp/product/' + keepaProduct.asin + '/?th=1&psc=1',
                graphURL: 'https://graph.keepa.com/pricehistory.png?domain=com&salesrank=1&asin=' + keepaProduct.asin,
            }
            return resultProduct
        }
    } else {
        return product;
    }
}