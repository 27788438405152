import { FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import KeepaGraph from './KeepaGraph';
import CustomSnackbar from '../layout/CustomSnackbar'
import CustomDatatable from './elements/CustomDatatable'
const dataForge = require('data-forge')
const moment = require('moment');
moment().format();

const styles = theme => ({
  container: {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    marginTop: theme.spacing(4),
    padding: 5,
    margin: 5,
  },
  innerContainer: {

  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const rankSalesCorrelation = [
  {
    'Rank': 500,
    'Sales': 8000,
  },
  {
    'Rank': 1000,
    'Sales': 6000,
  },
  {
    'Rank': 2000,
    'Sales': 4000,
  },
  {
    'Rank': 3000,
    'Sales': 3500,
  },
  {
    'Rank': 3000,
    'Sales': 3000,
  },
  {
    'Rank': 5000,
    'Sales': 2400,
  },
  {
    'Rank': 7500,
    'Sales': 2000,
  },
  {
    'Rank': 10000,
    'Sales': 1600,
  },
  {
    'Rank': 12500,
    'Sales': 1200,
  },
  {
    'Rank': 15000,
    'Sales': 1000,
  },
  {
    'Rank': 17500,
    'Sales': 900,
  },
  {
    'Rank': 20000,
    'Sales': 800,
  },
  {
    'Rank': 25000,
    'Sales': 700,
  },
  {
    'Rank': 30000,
    'Sales': 600,
  },
  {
    'Rank': 35000,
    'Sales': 500,
  },
  {
    'Rank': 40000,
    'Sales': 400,
  },
  {
    'Rank': 45000,
    'Sales': 350,
  },
  {
    'Rank': 50000,
    'Sales': 300,
  },
  {
    'Rank': 55000,
    'Sales': 250,
  },
  {
    'Rank': 60000,
    'Sales': 225,
  },
  {
    'Rank': 65000,
    'Sales': 200,
  },
  {
    'Rank': 70000,
    'Sales': 175,
  },
  {
    'Rank': 75000,
    'Sales': 160,
  },
  {
    'Rank': 80000,
    'Sales': 150,
  },
  {
    'Rank': 85000,
    'Sales': 135,
  },
  {
    'Rank': 90000,
    'Sales': 130,
  },
  {
    'Rank': 95000,
    'Sales': 120,
  },
  {
    'Rank': 100000,
    'Sales': 110,
  },
  {
    'Rank': 110000,
    'Sales': 100,
  },
  {
    'Rank': 120000,
    'Sales': 90,
  },
  {
    'Rank': 130000,
    'Sales': 85,
  },
  {
    'Rank': 140000,
    'Sales': 80,
  },
  {
    'Rank': 150000,
    'Sales': 75,
  },
  {
    'Rank': 160000,
    'Sales': 70,
  },
  {
    'Rank': 170000,
    'Sales': 65,
  },
  {
    'Rank': 180000,
    'Sales': 60,
  },
  {
    'Rank': 190000,
    'Sales': 55,
  },
  {
    'Rank': 200000,
    'Sales': 50,
  },
  {
    'Rank': 210000,
    'Sales': 45,
  },
  {
    'Rank': 220000,
    'Sales': 40,
  },
  {
    'Rank': 230000,
    'Sales': 35,
  },
  {
    'Rank': 240000,
    'Sales': 30,
  },
  {
    'Rank': 250000,
    'Sales': 28,
  },
  {
    'Rank': 260000,
    'Sales': 24,
  },
  {
    'Rank': 270000,
    'Sales': 22,
  },
  {
    'Rank': 280000,
    'Sales': 20,
  },
  {
    'Rank': 290000,
    'Sales': 18,
  },
  {
    'Rank': 300000,
    'Sales': 15,
  },
  {
    'Rank': 350000,
    'Sales': 12,
  },
  {
    'Rank': 400000,
    'Sales': 9,
  },
  {
    'Rank': 500000,
    'Sales': 5,
  },
  {
    'Rank': 750000,
    'Sales': 2.5,
  },
  {
    'Rank': 1000000,
    'Sales': 1,
  },
]

const rankSalesCorrelationAlt = [
  {
    'Rank': 500,
    'Sales': 3000,
  },
  {
    'Rank': 1000,
    'Sales': 2500,
  },
  {
    'Rank': 2000,
    'Sales': 2000,
  },
  {
    'Rank': 3000,
    'Sales': 1750,
  },
  {
    'Rank': 4000,
    'Sales': 1500,
  },
  {
    'Rank': 5000,
    'Sales': 1200,
  },
  {
    'Rank': 7500,
    'Sales': 1000,
  },
  {
    'Rank': 10000,
    'Sales': 800,
  },
  {
    'Rank': 12500,
    'Sales': 600,
  },
  {
    'Rank': 15000,
    'Sales': 500,
  },
  {
    'Rank': 17500,
    'Sales': 450,
  },
  {
    'Rank': 20000,
    'Sales': 400,
  },
  {
    'Rank': 25000,
    'Sales': 350,
  },
  {
    'Rank': 30000,
    'Sales': 300,
  },
  {
    'Rank': 35000,
    'Sales': 250,
  },
  {
    'Rank': 40000,
    'Sales': 200,
  },
  {
    'Rank': 45000,
    'Sales': 175,
  },
  {
    'Rank': 50000,
    'Sales': 150,
  },
  {
    'Rank': 55000,
    'Sales': 125,
  },
  {
    'Rank': 60000,
    'Sales': 100,
  },
  {
    'Rank': 65000,
    'Sales': 80,
  },
  {
    'Rank': 70000,
    'Sales': 70,
  },
  {
    'Rank': 75000,
    'Sales': 60,
  },
  {
    'Rank': 80000,
    'Sales': 50,
  },
  {
    'Rank': 85000,
    'Sales': 40,
  },
  {
    'Rank': 90000,
    'Sales': 30,
  },
  {
    'Rank': 95000,
    'Sales': 25,
  },
  {
    'Rank': 100000,
    'Sales': 22,
  },
  {
    'Rank': 110000,
    'Sales': 20,
  },
  {
    'Rank': 120000,
    'Sales': 18,
  },
  {
    'Rank': 130000,
    'Sales': 15,
  },
  {
    'Rank': 140000,
    'Sales': 12,
  },
  {
    'Rank': 150000,
    'Sales': 10,
  },
  {
    'Rank': 160000,
    'Sales': 8,
  },
  {
    'Rank': 170000,
    'Sales': 7,
  },
  {
    'Rank': 180000,
    'Sales': 6,
  },
  {
    'Rank': 190000,
    'Sales': 5,
  },
  {
    'Rank': 200000,
    'Sales': 5,
  },
  {
    'Rank': 210000,
    'Sales': 4,
  },
  {
    'Rank': 220000,
    'Sales': 4,
  },
  {
    'Rank': 230000,
    'Sales': 3,
  },
  {
    'Rank': 240000,
    'Sales': 3,
  },
  {
    'Rank': 250000,
    'Sales': 2,
  },
  {
    'Rank': 260000,
    'Sales': 2,
  },
  {
    'Rank': 270000,
    'Sales': 2,
  },
  {
    'Rank': 280000,
    'Sales': 2,
  },
  {
    'Rank': 290000,
    'Sales': 2,
  },
  {
    'Rank': 300000,
    'Sales': 2,
  },
  {
    'Rank': 350000,
    'Sales': 2,
  },
  {
    'Rank': 400000,
    'Sales': 1,
  },
  {
    'Rank': 500000,
    'Sales': 1,
  },
  {
    'Rank': 750000,
    'Sales': 0.5,
  },
  {
    'Rank': 1000000,
    'Sales': 0.5,
  },
]

class ProductBrowser extends Component {
  state = {
    product: {
      Name: '',
      ASIN: '',
      UPC: 'Unknown',
      MSRP: -1,
      Price: -1,
      Offers: 0,
      OfferInfo: [],
      SalesRank: -1,
      ImgURL: '../assets/placeholder.png',
      ParentASIN: '',
      ActiveVars: -1,
      AvgVarPrice: -1,
      Vars: [],
      Color: '',
      Size: '',
      Dimensions: {
        Width: -1,
        Length: -1,
        Heigth: -1,
        Weight: -1,
      },
      SizeTier: '',
      Fee: 0,
      Restricted: 'Unknown',
      RestrictedStatus: 'Unchecked',
      Store: '',
      ID: 0,
      Quantity: 1,
      Cost: 0,
      TaxCode: '',
      SKU: '',
      FNSKU: '',
      multiProdUPC: false,
      feeError: false,
      allConsidered: false,
    },
    resultProduct: {
      graphURL: 'https://graph.keepa.com/pricehistory.png?domain=com&salesrank=1&asin='
    },
    code: '',
    cost: '',
    coupon: '',
    subscription: 'NOT ACTIVE',
    credentials: {
      token: ' ',
      sellerID: ' ',
      keepaKey: ' '
    },
    productData: [],
    errorMsg: '',
    snackbarMsg: '',
    snackbarOpened: false,
    snackbarVariant: 'error',
    isLoading: false,
    loadingMsg: '',
    currColl: '',
    oldColl: '',
    collections: [],
    purchasedList: '',
    salesData: [],
  }

  constructor(props) {
    super(props)

    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }


  render() {
    const { classes } = this.props;
    const { auth, userData } = this.props;
    const copy = require('clipboard-copy')

    const columns = [
      {
        name: 'Actions',
        label: 'Save?',
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRender: (_, tableMeta, __) =>
            <Box display="flex" flexDirection="column" width='100%'>
              <IconButton onClick={() => { this.saveProduct(tableMeta.rowData) }}><AddCircleIcon /></IconButton>
            </Box>
        }
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
          customFilterListRender: v => {
            if (v) {
              return `Name includes ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "UPC",
        label: "UPC",
        options: {
          filter: false,
          sort: true,
          display: false,
        }
      },
      {
        name: "ASIN",
        label: "ASIN",
        options: {
          filter: false,
          sort: true,
          display: true,
        }
      },
      // {
      //   name: "shopSKU",
      //   label: "SKU",
      //   options: {
      //     filter: false,
      //     sort: true,
      //     display: true,
      //   }
      // },
      {
        name: "SKU",
        label: "Link",
        options: {
          filter: false,
          sort: true,
          display: true,
          customBodyRender: (value, _, __) => {
            if (value.includes('http')) {
              return <a target="_blank" rel="noopener noreferrer" href={value}>LINK</a>
            } else if (value.includes('-')) {
              return <a target="_blank" rel="noopener noreferrer" href={'https://www.sierra.com/product/bounce/' + value}>{value}</a>
            } else {
              return <a target="_blank" rel="noopener noreferrer" href={'https://www.eastbay.com/product/~/' + value + '.html'}>{value}</a>
            }
          }
        }
      },
      {
        name: "brand",
        label: "Brand",
        options: {
          filter: true,
          sort: true,
          display: false,
          customFilterListRender: v => {
            if (v) {
              return `Brand excludes ${v}`
            }
            return false;
          },
          filterOptions: {
            logic(brand, filterVal) {
              return !(brand !== filterVal)
            }
          }
        }
      },
      {
        name: "isClearance",
        label: "Clearance",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isClearance: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isNewClearance",
        label: "NewClearance",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isNewClearance: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isLowerPrice",
        label: "LowerPrice",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isLowerPrice: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isNew",
        label: "isNew",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isNew: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isPurchased",
        label: "Purchased",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isPurchased: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "TotalOrdersQuantity",
        label: "Orders",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Orders: ${v['min']}, Max Orders: ${v['max']}`;
            } else if (v['min']) {
              return `Min Orders: ${v['min']}`;
            } else if (v['max']) {
              return `Max Orders: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Orders</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },

      {
        name: "AverageDailySales",
        label: "AvgDaily",
        options: {
          filter: true,
          sort: true,
          display: false,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v['min'] && v['max']) {
                return `Min AvgDaily: ${v['min']}, Max AvgDaily: ${v['max']}`;
              } else if (v['min']) {
                return `Min AvgDaily: ${v['min']}`;
              } else if (v['max']) {
                return `Max AvgDaily: ${v['max']}`;
              }
              return false;
            }
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>AvgDaily</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        }
      },
      {
        name: "2MoQTY",
        label: "2MoQty",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min 2MoQTY: ${v['min']}, Max 2MoQTY: ${v['max']}`;
            } else if (v['min']) {
              return `Min 2MoQTY: ${v['min']}`;
            } else if (v['max']) {
              return `Max 2MoQTY: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>2MoQTY</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "InstockQuant",
        label: "Instock",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Instock: ${v['min']}, Max Instock: ${v['max']}`;
            } else if (v['min']) {
              return `Min Instock: ${v['min']}`;
            } else if (v['max']) {
              return `Max Instock: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Instock</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "TotalQuant",
        label: "Total",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Total: ${v['min']}, Max Total: ${v['max']}`;
            } else if (v['min']) {
              return `Min Total: ${v['min']}`;
            } else if (v['max']) {
              return `Max Total: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Total</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "RemDays",
        label: "RemDays",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min RemDays: ${v['min']}, Max RemDays: ${v['max']}`;
            } else if (v['min']) {
              return `Min RemDays: ${v['min']}`;
            } else if (v['max']) {
              return `Max RemDays: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>RemDays</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "LastRestockQty",
        label: "LastRestockQty",
        options: {
          filter: true,
          display: false,
        },
      },
      {
        name: "DaysSinceRestock",
        label: "SinceRestock",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              if (v['min'] && v['max']) {
                return `Min SinceRestock: ${v['min']}, Max SinceRestock: ${v['max']}`;
              } else if (v['min']) {
                return `Min SinceRestock: ${v['min']}`;
              } else if (v['max']) {
                return `Max SinceRestock: ${v['max']}`;
              }
              return false;
            }
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>SinceRestock</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "shopPrice",
        label: "Shop",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Shop: ${v['min']}, Max Shop: ${v['max']}`;
            } else if (v['min']) {
              return `Min Shop: ${v['min']}`;
            } else if (v['max']) {
              return `Max Shop: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Shop</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "adjustPrice",
        label: "Adj",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Adj: ${v['min']}, Max Adj: ${v['max']}`;
            } else if (v['min']) {
              return `Min Adj: ${v['min']}`;
            } else if (v['max']) {
              return `Max Adj: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Adj</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "adjustPriceExpanded",
        label: "Adj2",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Adj2: ${v['min']}, Max Adj2: ${v['max']}`;
            } else if (v['min']) {
              return `Min Adj2: ${v['min']}`;
            } else if (v['max']) {
              return `Max Adj2: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Adj2</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "lowestPrice",
        label: "Low",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Low: ${v['min']}, Max Low: ${v['max']}`;
            } else if (v['min']) {
              return `Min Low: ${v['min']}`;
            } else if (v['max']) {
              return `Max Low: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Low</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: 'currentAmazonPrice',
        label: "AZ",
        options: {
          filter: true,
          display: false,
        },
      },
      {
        name: "currentNewPrice",
        label: "New",
        options: {
          filter: true,
          display: false,
        },
      },
      {
        name: "currentBuyBoxPrice",
        label: "BB",
        options: {
          filter: true,
          display: false,
        },
      },
      {
        name: "currentListPrice",
        label: "List",
        options: {
          filter: true,
          display: false,
        },
      },
      {
        name: "currentSalesRank",
        label: "Rank",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Rank: ${v['min']}, Max Rank: ${v['max']}`;
            } else if (v['min']) {
              return `Min Rank: ${v['min']}`;
            } else if (v['max']) {
              return `Max Rank: ${v['max']}`;
            }
            return false;
          },
          customBodyRender: (value, _, __) => {
            return parseInt(value)
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Rank</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "salesRank30Avg",
        label: "RankAvg",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min RankAvg: ${v['min']}, Max RankAvg: ${v['max']}`;
            } else if (v['min']) {
              return `Min RankAvg: ${v['min']}`;
            } else if (v['max']) {
              return `Max RankAvg: ${v['max']}`;
            }
            return false;
          },
          customBodyRender: (value, _, __) => {
            return parseInt(value)
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>RankAvg</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "reviewCount",
        label: "Reviews",
        options: {
          filter: true,
          display: false,
        },
      },
      // {
      //   name: "OnHand",
      //   label: "OnHand",
      //   options: {
      //     filter: true,
      //     display: true,
      //     filterType: 'custom',
      //     customFilterListRender: v => {
      //       if (v['min'] && v['max']) {
      //         return `Min Rank: ${v['min']}, Max Rank: ${v['max']}`;
      //       } else if (v['min']) {
      //         return `Min Rank: ${v['min']}`;
      //       } else if (v['max']) {
      //         return `Max Rank: ${v['max']}`;
      //       }
      //       return false;
      //     },
      //     customBodyRender: (value, _, __) => {
      //       return parseInt(value)
      //     },
      //     filterOptions: {
      //       names: [],
      //       logic(age, filters) {
      //         if (filters['min'] && filters['max']) {
      //           return age < filters['min'] || age > filters['max'];
      //         } else if (filters['min']) {
      //           return age < filters['min'];
      //         } else if (filters['max']) {
      //           return age > filters['max'];
      //         }
      //         return false;
      //       },
      //       display: (filterList, onChange, index, column) => (
      //         <div>
      //           <FormLabel>Rank</FormLabel>
      //           <FormGroup row>
      //             <TextField
      //               label="min"
      //               value={filterList[index]['min'] || ''}
      //               onChange={event => {
      //                 filterList[index]['min'] = event.target.value;
      //                 onChange(filterList[index], index, column);
      //               }}
      //               style={{ width: '45%', marginRight: '5%' }}
      //             />
      //             <TextField
      //               label="max"
      //               value={filterList[index]['max'] || ''}
      //               onChange={event => {
      //                 filterList[index]['max'] = event.target.value;
      //                 onChange(filterList[index], index, column);
      //               }}
      //               style={{ width: '45%' }}
      //             />
      //           </FormGroup>
      //         </div>
      //       ),
      //     },
      //   },
      // },
      {
        name: "rootCategory",
        label: "Category",
        options: {
          filter: false,
          sort: true,
          display: false,
        }
      },
      {
        name: "Fees",
        label: "Fees",
        options: {
          filter: true,
          sort: true,
          display: false,
        }
      },
      {
        name: "variationsASIN",
        label: "Vars",
        options: {
          filter: true,
          sort: true,
          display: true,
          customFilterListRender: v => {
            if (v) {
              return `Min VarCount: ${v}`
            }
            return false;
          },
          customBodyRender: (value, _, __) => {
            let vars = value.split(',')
            return vars.length
          },
          filterOptions: {
            logic(val, filterVal) {
              let vars = val.split(',').length
              return !(vars <= parseFloat(filterVal))
            }
          }
        }
      },
      {
        name: "parentASIN",
        label: "Parent",
        options: {
          filter: true,
          sort: true,
          display: false,
        }
      },
      {
        name: "ParentCount",
        label: "ParentCount",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min ParentCount: ${v['min']}, Max ParentCount: ${v['max']}`;
            } else if (v['min']) {
              return `Min ParentCount: ${v['min']}`;
            } else if (v['max']) {
              return `Max ParentCount: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>ParentCount</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "ROI",
        label: "ROI",
        options: {
          filter: true,
          sort: true,
          display: true,
          customFilterListRender: v => {
            if (v) {
              return `Min ROI: ${v}%`
            }
            return false;
          },
          customBodyRender: (value, _, __) => (
            <span>{parseFloat(value).toFixed(0)}%</span>
          ),
          filterOptions: {
            logic(ROI, filterVal) {
              return !(ROI >= parseFloat(filterVal))
            }
          }
        }
      },
      {
        name: "ROI2",
        label: "ROI2",
        options: {
          filter: true,
          sort: true,
          display: false,
          customFilterListRender: v => {
            if (v) {
              return `Min ROI2: ${v}%`
            }
            return false;
          },
          customBodyRender: (value, _, __) => (
            <span>{parseFloat(value).toFixed(0)}%</span>
          ),
          filterOptions: {
            logic(ROI, filterVal) {
              return !(ROI >= parseFloat(filterVal))
            }
          }
        }
      },
      {
        name: "Restocked",
        label: "Restocked",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `Restocked: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isBBUnqualified",
        label: "BBUnqualified",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `BBUnqualified: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "EstimatedSales",
        label: "EstSales",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min EstSales: ${v['min']}, Max EstSales: ${v['max']}`;
            } else if (v['min']) {
              return `Min EstSales: ${v['min']}`;
            } else if (v['max']) {
              return `Max EstSales: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>EstSales</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "shopSKU",
        label: "Code",
        options: {
          filter: true,
          display: false,
        }
      },
      {
        name: "KeepaDataURL",
        label: "Keepa",
        options: {
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, _, __) => (
            <a target="_blank" rel="noopener noreferrer" href={value}>LINK</a>
          )
        }
      },
      {
        name: "graphURL",
        label: "Graph",
        options: {
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, _, __) => (
            <KeepaGraph graphURL={value} />
          )
        }
      },
    ]

    const options = {
      filterType: 'textField',
      selectableRows: 'none',
      print: false,
      onCellClick: (colData, celMeta) => {
        console.log(colData);
        console.log(celMeta);
        if (celMeta.colIndex === 4) {
          copy(`${colData.props.href}`)
      } else {
          copy(`${colData}`)
      }
      },
      downloadOptions: {
        filename: 'productBrowserExport.csv',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        }
      },
      responsive: 'standard',
      fixedHeader: true,
    };

    if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

    return (
      <Container display="flex" maxWidth={false} height="80%">
        <Container width='100%' m={3} >
          <Box display="flex" flexDirection="row" className={classes.container} justifyContent="center" alignItems="center">
            <Select
              value={this.state.currColl}
              onChange={this.handleChange}
              inputProps={{
                name: 'currColl',
                id: 'choose-curr-coll',
              }}
            >
              {this.state.collections.map((name) => {
                return <MenuItem value={name}>{name}</MenuItem>
              })}
            </Select>
            <Select
              value={this.state.oldColl}
              onChange={this.handleChange}
              inputProps={{
                name: 'oldColl',
                id: 'choose-old-coll',
              }}
            >
              {this.state.collections.map((name) => {
                return <MenuItem value={name}>{name}</MenuItem>
              })}
            </Select>
            <TextField
              id="coupon"
              label="Coupon"
              type="text"
              className={classes.textField}
              value={this.state.coupon}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              fullWidth
              onSubmit={this.handleSubmit}
              inputRef={this.textInput}
            />
            <Button onClick={() => this.newGetData(this.state.currColl, this.state.oldColl)}>
              Generate
                        </Button>
            <Button onClick={() => this.deleteColl(this.state.currColl)}>
              Delete
                        </Button>
          </Box>
        </Container>

        <Container width='100%' maxWidth="xl" className={classes.container}>
          {/* <MuiThemeProvider theme={niceScrollTheme}> */}
          <CustomDatatable
            title={"Product Data"}
            data={this.state.productData}
            columns={columns}
            options={options}
          />
          {/* </MuiThemeProvider> */}


        </Container>

        <CustomSnackbar variant={this.state.snackbarVariant} duration={2000} onClose={this.onSnackbarClose} className={classes.margin} opened={this.state.snackbarOpened} message={this.state.snackbarMsg} />
      </Container>
    )
  }

  componentDidMount() {
    // ifvisible.on('focus', () => { this.focusTextInput() })
    // ifvisible.on('wakeup', () => { this.focusTextInput() })
    // this.getData(this.state.currColl)
    this.getCollections()
  }

  focusTextInput() {

    this.textInput.current.focus();
  }

  getCollections = () => {
    fetch('https://api.projectsuite.io/collections?list').then(response => {
      return response.json();
    }).then(jsonResponse => {
      let collections = jsonResponse["collections"]
      this.setState({
        collections: collections.map((coll) => {
          return coll["name"]
        })
      })
    })
  }

  deleteColl = (coll) => {
    fetch('https://api.projectsuite.io/delete?coll=' + coll).then(response => {
      return response.json();
    }).then(jsonResponse => {
      if (jsonResponse.error !== null) {
        this.setState({ snackbarOpened: true, errorMsg: jsonResponse.error })
      } else {
        this.getCollections()
      }
    })
  }

  handleChange = e => {
    if (e.target.id !== undefined) {
      this.setState({
        [e.target.id]: e.target.value
      })
    } else if (e.target.name !== undefined) {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  handleSubmit = (e) => {
    console.log('Submitted wtf lol')
  }

  changePage = (page) => {
    this.setState({
      isLoading: true,
    });
  };

  saveProduct = async (data) => {
    try {
      // Maybe the token is saved somewhere, idk really
      // let token = await getToken()
      let postData = { 'data': data };

      let postBody = {
        'products': [postData],
        'key': 'maximumsecurity',
        'dbname': 'browser',
        'collection': 'saved',
        'reset': false,
      }

      let body = JSON.stringify(postBody);

      let res = await fetch('https://api.projectsuite.io/repricer', {
        method: "POST",
        body: body,
        headers: { 'Content-Type': 'application/json' }
      }).then(res => {
        return res.json()
      })
      if (!res.error) {
        console.log('Saved!');
        this.setState({ snackbarOpened: true, snackbarMsg: 'Saved!', snackbarVariant: 'success' })
      } else {
        throw Error(res.error)
      }
    } catch (e) {
      console.log(e)
      this.setState({ snackbarOpened: true, snackbarMsg: 'Error when saving! Check console for details!', snackbarVariant: 'error' })
    }
  }

  getInvData = async (token) => {
    let invData = await fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=inventory&collection=inv`).then(resp => resp.json())
    return invData.result
  }

  getBuyData = async (token) => {
    let buyData = await fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=buydata&collection=luOjCSlnssfwomE0Lic6gHzmDcw1`).then(resp => resp.json())
    return buyData.result
  }

  getSettings = async (token) => {
    let buyData = await fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=browser&collection=settings`).then(resp => resp.json())
    return buyData.result
  }

  getOrderData = async (token) => {
    const orderData = await fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=luOjCSlnssfwomE0Lic6gHzmDcw1&collection=order_data`).then((resp) => resp.json());
    return orderData.result;
  };

  getOFFBData = async (token) => {
    let offbData = await fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=luOjCSlnssfwomE0Lic6gHzmDcw1&collection=offb_asins`).then(resp => resp.json())
    return offbData.result
  }

  parseBuyFrame = (buyData) => {
    const buyDataFrame = new dataForge.DataFrame(buyData).select((row) => {
      row['PurchasedQuantity'] = parseInt(row['QTY']);
      row['Cost'] = row['COST'] ? parseFloat(row['COST']) : 0;
      row['PurchasedDate'] = row['PURCHASEDATE'].split('/').length === 3 ? moment(row['PURCHASEDATE'], 'M/D/YYYY') : moment(row['PURCHASEDATE'], 'MM/DD');
      row['Supplier'] = row['SUPPLIER'];
      return row;
    })
      .dropSeries(['QTY', 'COST', 'LISTPRICE', 'PURCHASEDATE', 'TITLE', 'SUPPLIER'])
      .orderByDescending((row) => row['PurchasedDate']);

    const pivoted = buyDataFrame.pivot('ASIN', {
      'PurchasedQuantity': {
        PurchasedQuantity: (series) => series.sum().toFixed(2),
        LastRestockQty: (series) => series.first().toFixed(2),
      },
      'Cost': {
        Cost: (series) => series.average().toFixed(2),
      },
      'PurchasedDate': {
        PurchasedDate: (series) => series.first(),
        DaysSinceRestock: (series) => Math.floor(moment.duration(moment().diff(moment(series.first()))).asDays())
      },
      'Supplier': {
        Supplier: (series) => series.last(),
      },
    })
      .withIndex((row) => row['ASIN']);

    console.log(pivoted.toArray())

    return pivoted;
  };

  newGetData = async (currColl, oldColl) => {
    const start = Date.now();
    const token = await getToken();
    const [invData, buyData, orderData, settingsData, offbData] = await Promise.all([this.getInvData(token), this.getBuyData(token), this.getOrderData(token), this.getSettings(token), this.getOFFBData(token)]);
    console.log(settingsData)

    const offbFrame = new dataForge.DataFrame(offbData).withIndex(row => row.ASIN);
    
    const invFrame = new dataForge.DataFrame(invData)
      .renameSeries({ 'Quantity': 'TotalQuant', 'FulfillableQuantity': 'InstockQuant' })
      .parseInts('TotalQuant', 'InstockQuant', 'InboundWorkingQuantity', 'InboundShippedQuantity', 'InboundReceivingQuantity', 'TotalQuantity')
      .select((row) => {
        const newRow = { ...row };
        newRow['InboundQuant'] = parseInt(row['InboundWorkingQuantity']) + parseInt(row['InboundShippedQuantity']) + parseInt(row['InboundReceivingQuantity'])
        newRow['InstockQuant'] = parseInt(row['InstockQuant']);
        newRow['TotalQuant'] = newRow['InboundQuant'] + newRow['InstockQuant']
        return newRow;
      })
      .pivot('ASIN', {
        'InboundQuant': {
          InboundQuant: (series) => series.sum(),
        },
        'InstockQuant': {
          InstockQuant: (series) => series.sum(),
        },
        'TotalQuant': {
          TotalQuant: (series) => series.sum(),
        },
      })
      .withIndex((row) => row['ASIN']);

    const ordersFrame = new dataForge.DataFrame(orderData)
      .subset(['ASIN', 'TotalOrdersQuantity', 'AverageDailySales'])
      .parseInts('TotalOrdersQuantity')
      .withIndex((row) => row['ASIN']);
    const buyDataFrame = this.parseBuyFrame(buyData);

    console.log('memory load done', (Date.now() - start) / 1000);

    fetch('https://api.projectsuite.io/products?collection=' + currColl + '&trim=none').then((response) => {
      console.log('first coll done', (Date.now() - start) / 1000);
      return response.json();
    }).then((jsonResponse) => {
      console.log('json parse done', (Date.now() - start) / 1000);
      const prods = jsonResponse['products'];
      console.log('memory load done', (Date.now() - start) / 1000);
      fetch('https://api.projectsuite.io/products?collection=' + oldColl + '&trim=asin').then((responseOld) => {
        console.log('second coll done', (Date.now() - start) / 1000);
        return responseOld.json();
      }).then(async (jsonResponseOld) => {
        console.log('old load done', (Date.now() - start) / 1000);
        const oldProds = jsonResponseOld['products'];
        const asins = {};
        const prices = {};
        const parentASINs = {};

        for (let i = 0; i < oldProds.length; i++) {
          asins[oldProds[i]['ASIN']] = true;
          prices[oldProds[i]['ASIN']] = parseFloat(oldProds[i]['shopPrice']);
        }

        for (let j = 0; j < prods.length; j++) {
          if (prods[j]['parentASIN'] !== undefined && prods[j]['parentASIN'] !== '' && parentASINs[prods[j]['parentASIN']] === undefined) {
            parentASINs[prods[j]['parentASIN']] = 1;
          } else if (prods[j]['parentASIN'] !== undefined && prods[j]['parentASIN'] !== '') {
            parentASINs[prods[j]['parentASIN']] += 1;
          }
        }
        console.log('maps done', (Date.now() - start) / 1000);

        const prodFrame = new dataForge.DataFrame(prods)
          .distinct((row) => row['ASIN'])
          .withIndex((row) => row['ASIN'])
          .merge(ordersFrame, invFrame, buyDataFrame)
          .where((row) => row['reviewCount'] !== undefined)
          .select((row) => {
            const newRow = { ...row };
            if (newRow['TotalOrdersQuantity'] === undefined) {
              newRow['TotalOrdersQuantity'] = -1;
              newRow['RemDays'] = -1
              newRow['AverageDailySales'] = -1;
            }

            if (row['InstockQuant'] !== undefined && row['AverageDailySales'] !== undefined) {
              newRow['RemDays'] = parseFloat((row['InstockQuant'] / row['AverageDailySales']).toFixed(2))
            } else {
              newRow['RemDays'] = -1
            }

            if (newRow['InstockQuant'] === undefined) {
              newRow['isPurchased'] = 'False';
              newRow['InstockQuant'] = -1;
              newRow['InboundQuant'] = -1;
              newRow['TotalQuant'] = -1;
            } else {
              newRow['isPurchased'] = 'True';
            }

            if (newRow['PurchasedQuantity'] === undefined) {
              newRow['2MoQTY'] = -1;
              newRow['DaysSinceRestock'] = -1
              newRow['LastRestockQty'] = -1
            } else {
              newRow['2MoQTY'] = parseInt(row['PurchasedQuantity']);
              newRow['DaysSinceRestock'] = parseInt(row['DaysSinceRestock']);
              newRow['LastRestockQty'] = parseInt(row['LastRestockQty']);
            }

            newRow.graphURL = newRow.graphURL + '&range=365';
            newRow.currentNewPrice = parseFloat(row.currentNewPrice);
            newRow.currentAmazonPrice = parseFloat(row.currentAmazonPrice);
            newRow.currentBuyBoxPrice = parseFloat(row.currentBuyBoxPrice);
            newRow.currentListPrice = parseFloat(row.currentListPrice);
            newRow.shopPrice = parseFloat(row.shopPrice);
            newRow['currentSalesRank'] = parseInt(row['currentSalesRank']);
            newRow['salesRank30Avg'] = parseInt(row['salesRank30Avg']);
            newRow.calcRank = newRow.currentSalesRank === -1 ? (newRow.salesRank30Avg === -1 ? -1 : newRow.salesRank30Avg) : newRow.currentSalesRank;
            newRow['adjustPrice'] = parseFloat((parseFloat(row['shopPrice']) * (1 - parseFloat(this.state.coupon))).toFixed(2));
            newRow['adjustPriceExpanded'] = parseFloat((parseFloat((row['shopPrice'] * (1 - parseFloat(this.state.coupon))).toFixed(2))
              + parseFloat(settingsData.find(setting => setting.key === "weight").value) * (row['weight'] === undefined || row['weight'] === -1 ? 2 : parseFloat(row['weight'])) / 454
              + parseFloat(settingsData.find(setting => setting.key === "prep").value)).toFixed(2));
            newRow.ParentCount = parentASINs[row.parentASIN] !== undefined ? parentASINs[row.parentASIN] : -1;

            if (newRow.calcRank !== -1) {
              newRow.EstimatedSales = rankSalesCorrelation.find((rank) => !(newRow.calcRank > rank.Rank)) !== undefined ? parseFloat((rankSalesCorrelation.find((rank) => !(newRow.calcRank > rank.Rank)).Sales / newRow.variationsASIN.split(',').length).toFixed(2)) : -1;
              newRow.applied = "clothing applied"
              if (row.rootCategory !== "Clothing, Shoes & Jewelry") {
                newRow.EstimatedSales = rankSalesCorrelationAlt.find((rank) => !(newRow.calcRank > rank.Rank)) !== undefined ? parseFloat((rankSalesCorrelationAlt.find((rank) => !(newRow.calcRank > rank.Rank)).Sales / newRow.variationsASIN.split(',').length).toFixed(2)) : -1;;
                newRow.applied = "trash applied"
              }
            } else {
              newRow.EstimatedSales = -1;
            }

            if (asins[newRow.ASIN]) {
              newRow.isNew = 'False';
            } else {
              newRow.isNew = 'True';
            }

            if ((newRow.shopPrice % 1) === 0 && (prices[newRow.ASIN] % 1) !== 0) {
              newRow.isNewClearance = 'True';
            } else {
              newRow.isNewClearance = 'False';
            }

            if (newRow.shopPrice < prices[row.ASIN]) {
              newRow.isLowerPrice = 'True';
            } else {
              newRow.isLowerPrice = 'False';
            }

            if ((newRow.shopPrice % 1) === 0) {
              newRow.isClearance = 'True';
            } else {
              newRow.isClearance = 'False';
            }

            if (newRow.BBUnqualified === "yes") {
              newRow.isBBUnqualified = "True"
            } else if (newRow.BBUnqualified === "no") {
              newRow.isBBUnqualified = "False"
            } else {
              newRow.isBBUnqualified = "Unknown"
            }

            const nums = [];
            if (newRow.currentBuyBoxPrice !== -1) {
              newRow.lowestPrice = newRow.currentBuyBoxPrice;
            } else {
              if (newRow.currentNewPrice !== -1) {
                nums.push(newRow.currentNewPrice);
              }
              if (newRow.currentAmazonPrice !== -1) {
                nums.push(newRow.currentAmazonPrice);
              }
              if (nums.length >= 1) {
                newRow.lowestPrice = Math.min(...nums);
              } else {
                newRow.lowestPrice = -1;
              }
            }

            newRow.Fees = parseFloat(newRow.Fees);
            if (newRow.Fees !== -1 && newRow.lowestPrice !== -1) {
              newRow.ROI = parseFloat(((newRow.lowestPrice * 0.85 - newRow.adjustPrice - newRow.Fees) / newRow.adjustPrice * 100).toFixed(0));
              newRow.ROI2 = parseFloat(((newRow.lowestPrice * 0.85 - newRow.adjustPriceExpanded - newRow.Fees) / newRow.adjustPriceExpanded * 100).toFixed(0));
            } else if (newRow.lowestPrice !== -1) {
              newRow.ROI = parseFloat(((newRow.lowestPrice * 0.85 - newRow.adjustPrice - 5) / newRow.adjustPrice * 100).toFixed(0));
              newRow.ROI2 = parseFloat(((newRow.lowestPrice * 0.85 - newRow.adjustPriceExpanded - 5) / newRow.adjustPriceExpanded * 100).toFixed(0));
            } else {
              newRow.ROI = -2;
              newRow.ROI2 = -2;
            }

            if (offbFrame.at(newRow.ASIN) !== undefined) {
              newRow.Restocked = 'True'
            } else {
              newRow.Restocked = 'False'
            }

            newRow.KeepaDataURL = `https://keepa.com/#!product/1-${row.ASIN}`

            return newRow;
          })
          .bake().where((row) => row['calcRank'] !== -1).toArray();

        console.log(prodFrame)
        console.log('prod frame done', prodFrame.length, (Date.now() - start) / 1000);

        this.setState({
          productData: prodFrame, isLoading: false
        }, () => {
          console.log('actual table done', prodFrame.length, (Date.now() - start) / 1000);
        });
      });
    });
  }


  uniq = (a) => {
    var seen = {};
    return a.filter(function (item) {
      return seen.hasOwnProperty(item.ASIN) ? false : (seen[item.ASIN] = true);
    });
  }

  onSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackbarOpened: false, snackbarMsg: null })
  }

  _resetProduct = () => {
    let newProduct = {
      Name: '',
      ASIN: '',
      UPC: 'Unknown',
      MSRP: -1,
      Price: -1,
      Offers: 0,
      OfferInfo: [],
      SalesRank: -1,
      ImgURL: '../assets/placeholder.png',
      ParentASIN: 'none',
      ActiveVars: -1,
      AvgVarPrice: -1,
      Vars: [],
      Color: '',
      Size: '',
      Dimensions: {
        Width: -1,
        Length: -1,
        Heigth: -1,
        Weight: -1,
      },
      SizeTier: '',
      Fee: 0,
      Restricted: 'Unknown',
      RestrictedStatus: 'Unchecked',
      Store: this.state.product.Store,
      Quantity: 1,
      Cost: 0,
      TaxCode: '',
      SKU: '',
      FNSKU: '',
      multiProdUPC: false,
      feeError: false,
      ID: 0,
      allConsidered: false,
    };
    this.setState({ product: newProduct, labelSrc: 'data:image/png;base64,', code: '' })
  }
}

ProductBrowser.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.info,
    userData: state.db.userData,
    salesData: state.db.salesData,
    purchasedData: state.db.purchasedData,
  }
}


export default connect(mapStateToProps)(withStyles(styles)(ProductBrowser))