import React from 'react';
import './ForbiddenPage.scss';

export default function ForbiddenPage() {
    return (
        <div className='wrapper'>
            <h1>403 Forbidden</h1>

            <p>You shall not pass!</p>

            <svg
                id='gandalf-svg'
                className='grid-lines'
                viewBox='0 0 100 150'
                preserveAspectRatio='xMidYMid slice'
                height='150'
                width='100'
            >
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--hor'
                    d='m0,39 l100,0'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--hor'
                    d='m0,75 l100,0'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--hor'
                    d='m0,111 l100,0'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--ver'
                    d='m14,0 l0,150'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--ver'
                    d='m32,0 l0,150'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--ver'
                    d='m50,0 l0,150'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--ver'
                    d='m68,0 l0,150'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--ver'
                    d='m86,0 l0,150'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--diag'
                    d='m14,0 l72,150'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--diag'
                    d='m0,25 l100,100'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--diag'
                    d='m100,25 l-100,100'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--diag'
                    d='m86,0 l-72,150'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--cir'
                    d='m50,57 c18,0 18,18 18,18
      c0,18 -18,18 -18,18
      c-18,0 -18,-18 -18,-18
      c0,-18 18,-18 18,-18'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--cir'
                    d='m50,39 c36,0 36,36 36,36
      c0,36 -36,36 -36,36
      c-36,0 -36,-36 -36,-36
      c0,-36 36,-36 36,-36'
                />
                <path
                    className='gandalf-path grid-lines__line grid-lines__line--cir'
                    d='m50,21 c54,0 54,54 54,54
      c0,54 -54,54 -54,54
      c-54,0 -54,-54 -54,-54
      c0,-54 54,-54 54,-54'
                />

                <path
                    className='gandalf-path wiz wiz--hair-bg'
                    d='m50,48
      c0,0 6,0 6,2
      c0,0 0,3 2,3
      c0,0 2,0 2,4
      c0,0 2,0 2,4
      c0,0 3,0 3,6
      c0,0 2,4 -2,6
      c0,0 -2,4 -2,6
      c0,0 0,6 -3,6
      c0,0 0,0 -8,0
      c0,0 0,0, -8,0
      c0,0 -3,0 -3,-6
      c0,0 -2,-4 -2,-6
      c0,0 -4,-2 -2,-6
      c0,0 0,-6 3,-6
      c0,0 0,-4 2,-4
      c0,0 0,-4 2,-4
      c0,0 2,0 2,-3
      c0,0 6,0 6,-2'
                />

                <path
                    className='gandalf-path wiz wiz--head'
                    d='m50,50
      c0,0 8,0 8,10
      c0,0 0,10 -8,10
      c0,0 -8,0 -8,-10
      c0,0 0,-10 8,-10'
                />

                <path
                    className='gandalf-path wiz wiz--hair'
                    d='m50,48
      c0,0 6,0 6,2
      c0,0 0,3 2,3
      c0,0 2,0 2,4
      c0,0 2,0 2,4
      c0,0 3,0 3,6
      c0,0 2,4 -2,6
      c0,0 -2,4 -2,6
      c0,0 0,6 -3,6
      c0,0 0,0 -8,0
      c0,0 6,0 6,-2
      c0,0 4,0 2,-4
      c0,0 4,0 2,-4
      c0,0 -2,-2 2,-6
      c0,0 2,-4 -2,-6
      c0,0 -4,0 -4,-4
      c0,0 0,-4 -2,-4
      c0,0 0,-6 -4,-3'
                />

                <path
                    className='gandalf-path wiz wiz--hair'
                    d='m50,52
      c0,0 -6,-4 -6,4
      c0,0 0,4 -2,4
      c0,0 0,4 -2,4
      c0,0 -2,0 0,4
      c0,0 0,2 0,4
      c0,0 2,2 0,4
      c0,0 -2,4 4,3
      c0,0 -2,4 2,4
      c0,0 0,2 4,2
      c0,0 0,0, -8,0
      c0,0 -3,0 -3,-6
      c0,0 -2,-4 -2,-6
      c0,0 -4,-2 -2,-6
      c0,0 0,-6 3,-6
      c0,0 0,-4 2,-4
      c0,0 0,-4 2,-4
      c0,0 -1,0 0,-4
      c0,0 2,1 8,-1'
                />

                <path
                    className='gandalf-path wiz wiz--beard'
                    d='m50,69
      c0,0 5,0 5,-1
      c0,0 4,0 4,2
      c0,0 2,0 1,4
      c0,0 2,0 1,6
      c0,0 2,0 1,6
      c0,0 -2,4 -2,4
      c0,0 -2,4 0,6
      c0,0 -4,0 -4,-4
      c0,0 0,4 -2,4
      c0,0 0,2 -2,2
      c0,0 -4,0 -4,4
      c0,0 -4,0 -2,-6
      c0,0 -2,4 -6,4
      c0,0 4,-2 2,-6
      c0,0 -4,0 -4,-4
      c0,0 -4,0 -4,-4
      c0,0 4,4 4,-4
      c0,0 0,-6 2,-6
      c0,0 0,-4 2,-4
      c0,0 0,-4 2,-4
      c0,0 0,1 6,1'
                />

                <path
                    className='gandalf-path wiz wiz--mouth'
                    d='m50,65
      c0,0 5,0 5,3
      c0,0 0,2 -5,2
      c0,0 -5,0 -5,-2
      c0,0 0,-4 5,-3'
                />

                <path
                    className='gandalf-path wiz wiz--mustache'
                    d='m50,64
      c0,0 4,0 4,1
      c0,0 2,0 2,2
      c0,0 2,0 1,3
      c0,0 2,0 0,3
      c0,0 0,0 0,0
      c0,0 0,-2 -2,-2
      c0,0 1,-3 -1,-3
      c0,0 0,-1 -4,-2
      c0,0 -4,-2 -4,2
      c0,0 -1,0 -1,2
      c0,0 -2,0 -1,3
      c0,0 -4,-2 -1,-4
      c0,0 -2,-3 1,-3
      c0,0 -1,-5 6,-2'
                />

                <path
                    className='gandalf-path wiz wiz--eye wiz--left'
                    d='m45,60
       c0,0 2,0 2,1
       c0,0 0,1 -2,0
       c0,0 -1,0 0,-1'
                />

                <path
                    className='gandalf-path wiz wiz--eye wiz--right'
                    d='m52,60
       c0,0 1,-1 2,0
       c0,0 0,1 -2,1
       c0,0 -1,0 0,-1'
                />

                <path
                    className='gandalf-path wiz wiz--eye-brow wiz--left'
                    d='m42,61
       c0,0 0,-3 1,-3
       c0,0 3,0 4,2
       c0,0 -1,-1 -4,-1
       c0,0 -1,0 -1,2'
                />

                <path
                    className='gandalf-path wiz wiz--eye-brow wiz--right'
                    d='m51,60
       c0,0 2,-3 4,-3
       c0,0 2,0 2,3
       c0,0 -1,-2 -2,-2
       c0,0 -2,0 -4,2'
                />

                <path
                    className='gandalf-path wiz wiz--sword'
                    d='m50,30
       c0,0 2,2 2,6
       l0,50
       l6,0
       c0,0 2,0 2,-2
       l1,0
       c0,0 0,4 -4,4
       l-6,0
       l0,14
       c0,0 2,0 2,2
       c0,0 0,2 -4,2
       c0,0 -3,0 -4,-2
       c0,0 0,-2 2,-2
       l0,-14
       l-6,0
       c0,0 -4,0 -4,-4
       l1,0
       c0,0 0,2 2,2
       l6,0
       l0,-50
       c0,0 0,-3 2,-6
       z'
                />

                <path
                    className='gandalf-path wiz wiz--staff'
                    d='m46,30
       l2,0
       c0,0 1,0 1,1
       l0,2
       c0,0 2,0 2,-2
       l1,0
       c0,0 1,0 1,2
       c0,0 2,0 2,-2
       l2,0
       c0,0 0,4 -2,4
       c0,0 0,4 -2,4
       c0,0 0,2 -1,2
       l0,40
       c0,0 0,4 -1,4
       l0,40
       c0,0 0,4 -1,4
       c0,0 -1,0 -1,-4
       c0,0 -2,0 -2,-4
       l0,-60
       c0,0 -1,0 -1,-4
       l0,-20
       c0,0 -1,0 -1,-4
       c0,0 0,-3 1,-3'
                />
            </svg>
        </div>
    );
}
