import React from 'react';
import { connect } from 'react-redux';
import CustomSnackbar from '../layout/CustomSnackbar';
import './ReturnItemsPage.scss';
import { Redirect } from 'react-router-dom';
import { returns } from '../../utils/project_api';
import { CircularProgress, Button } from '@material-ui/core';
import MaterialTable from 'material-table'
import './StatsPage.scss';

class StatsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            snackbarMsg: '',
            snackbarOpened: false,
            snackbarVariant: 'error',
        }
    }

    render() {
        const { auth, userData } = this.props;

        if (!auth || !userData) return <Redirect to='/signin' />;
        if (userData.role !== 'ADMIN' && !userData.role?.includes('MANAGER')) return <Redirect to='/returns' />;

        const columns = [
            { title: 'Title', field: 'product-name', editable: 'never' },
            {
                title: 'Image', field: 'ImgURL', editable: 'never', render: rowData => {
                    return (
                        <div className="table-image-cell">
                            <img alt="Product" src={rowData.ImgURL} style={{ width: '100px', height: 'auto' }} />
                        </div>
                    )
                },
                filtering: false
            },
            { title: 'FNSKU', field: 'fnsku', editable: 'never' },
            { title: 'ASIN', field: 'asin', editable: 'never', hidden: true },
            { title: 'Reason', field: 'reason', editable: 'never', hidden: true },
            { title: 'LPN', field: 'license-plate-number', editable: 'never' },
            { title: 'Removal ID', field: 'order-id', editable: 'never' },
            { title: 'Tracking Number', field: 'tracking-number', editable: 'never', hidden: true },
            { title: 'Tracking Number', field: 'tracking-number', editable: 'never', hidden: true },
            {
                title: 'Action', field: 'action', editable: 'never', hidden: true,
                lookup: { 
                    'fraud': 'Fraud', 
                    'relist': 'Relist', 
                    'damage': 'Damage', 
                    'donate': 'Donate',
                    'damage-discard': 'Damage - Donate/Discard', 
                    'damage-keep': 'Damage - Keep', 
                    'fraud-discard': 'Fraud - Donate/Discard', 
                    'fraud-keep': 'Fraud - Keep',
                    'other-sell-elsewhere': 'Other - Sell elsewhere',
                }
            },
            {
                title: 'Timestamp', field: 'scanDate', editable: 'never', render: rowData => {
                    let d = new Date(rowData.scanDate)
                    return (
                        <p>{d.toLocaleString("en-US")}</p>
                    )
                },
                type: 'date'
            },
        ];

        return (
            <div className="stats-page-container">
                <Button onClick={this.getProducts} style={{ margin: '0.2em', width: '200px' }} color='primary' variant='contained'>
                    {this.state.loading ? <CircularProgress color="#ffffff" style={{ width: '20px', height: '20px' }} /> : 'Get products'}
                </Button>
                <MaterialTable
                    title=''
                    columns={columns}
                    data={this.state.products}
                    options={{
                        columnsButton: true,
                        filtering: true
                    }}
                    className="view-products-table"
                    style={{ width: '100%' }}
                />
                <CustomSnackbar variant={this.state.snackbarVariant} duration={6000} onClose={() => this.setState({ snackbarOpened: false })} opened={this.state.snackbarOpened} message={this.state.snackbarMsg} />
            </div>
        )
    }

    getProducts = async () => {
        this.setState({ loading: true });
        try {
            let data = await returns.get('getProducts', { db: this.props.userData.uid });
            if (!data.result.length) {
                throw Error("Something went wrong");
            } else {
                let products = data.result.sort((a, b) => {
                    if (a.scanDate < b.scanDate)
                        return 1;
                    if (a.scanDate > b.scanDate)
                        return -1;
                    return 0;
                })
                this.setState({ products });
            }
        } catch (error) {
            this.setState({ snackbarOpened: true, snackbarMsg: error.message, isLoading: false, snackbarVariant: 'error' });
        }
        this.setState({ loading: false })
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        address: state.db.address
    }
}

export default connect(mapStateToProps)(StatsPage);
