
export const loginSuccess = (auth) => ({
  type: 'LOGIN_SUCCESS',
  auth
})


export const signupSuccess = (auth) => ({
  type: 'SIGNUP_SUCCESS',
  auth
})


export const signoutSuccess = (auth) => ({
  type: 'SIGNOUT_SUCCESS',
  auth
})
