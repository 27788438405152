import React from 'react';
import { List, IconButton } from '@material-ui/core';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import './ProductsList.scss';

export default function ProductsList(props) {
    const { products, onExtraClick } = props;
    return (
        <div className='products-list-container'>
            <List className='products-list'>
                {products.map(p => (
                    <div className='products-list-row' key={p.sku}>
                        <div className='product-name-container'>
                            <p className='product-detail-label'>Name</p>
                            <p>{p['product-name']}</p>
                        </div>
                        <div className='separator' />
                        <div className='product-sku-container'>
                            <p className='product-detail-label'>SKU</p>
                            <p>{p['sku']}</p>
                        </div>
                        <div className='separator' />
                        <div className='row-icon-container'>
                            {p.finished ? (
                                <CheckRoundedIcon id='icon-scanned' />
                            ) : (
                                <>
                                    <HighlightOffRoundedIcon id='icon-not-scanned' />
                                    <IconButton
                                        onClick={() => onExtraClick(p.sku)}
                                    >
                                        <ErrorOutline id='icon-error' />
                                    </IconButton>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </List>
        </div>
    );
}
