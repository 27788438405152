import React from 'react';
import './InfoPanel.scss';

export default function InfoPanel(props) {
    const { message } = props;

    return (
        <>
            {
                message &&
                <div className="info-container">
                    <p>{message}</p>
                </div>
            }
        </>

    )
}