import React from 'react';
import { getProductForASIN } from '../../amazon/KeepaCall'
import { connect } from 'react-redux';
import CustomSnackbar from '../layout/CustomSnackbar';
import './ReturnItemsPage.scss';
import { Tabs, Tab } from '@material-ui/core';
import ProductDialog from './ProductDialog';
import BoxesDialog from './BoxesDialog';
import UploadPhotos from './UploadPhotos';
import ScanBoxesPage from './ScanBoxesPage';
import ViewProductsPage from './ViewProductsPage';
import { Redirect } from 'react-router-dom';
import { randID } from '../../utils/random';
import { returns } from '../../utils/project_api';
import { catalogItems, MARKETPLACE_ID } from '../../utils/project_api/selling_partner';

const STEPS = [
    { msg: 'Scan tracking ID to begin' },
    { msg: 'Scan every LPN in the box' }
]

/**
 * Makes sure that the tracking number is not too long. Typically tracking numbers
 * have 18 characters, but USPS barcodes encode over 30 digits in which only last 22
 * are the tracking number.
 * @param {string} trackingNumber 
 */
function parseTrackingNumber(trackingNumber) {
    return trackingNumber.slice(-22);
}

class ReturnItemsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: "",
            expectedItems: [],
            processStep: 0,
            snackbarMsg: '',
            snackbarOpened: false,
            snackbarVariant: 'error',
            productDialogOpened: false,
            extractedProducts: [],
            firebaseDocument: null,
            boxInfo: null,
            boxSaved: false,
            boxes: [],
            boxesDialogOpened: false,
            uploadPhotosDialogOpened: false,
            loading: false,
            photos: [],
            page: 0,
            loadingProducts: false,
            photoProducts: [],
            photosLoadingProgress: {},
            action: ''
        }
        this.textInput = React.createRef();
        this.focusTextInput.bind(this);
    }

    render() {
        const { auth, userData } = this.props;

        if (!auth || !userData) return <Redirect to='/signin' />
        let isManager = userData.role === 'ADMIN' || userData.role?.includes('MANAGER');

        let uploadPhotosParams = {};
        if (this.state.extractedProducts[0]) {
            let lpn = this.state.extractedProducts[0]['license-plate-number'];
            let trackingID = this.state.extractedProducts[0]['tracking-number'];
            uploadPhotosParams = {
                db: this.state.firebaseDocument,
                lpn,
                trackingID
            };
        }

        return (
            <div className="page-container">
                <Tabs
                    value={this.state.page}
                    onChange={(ev, newValue) => this.setState({ page: newValue })}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Boxes" />
                    <Tab label="View products" />
                </Tabs>
                {this.state.page === 0
                    ?
                    <ScanBoxesPage
                        stepMessage={STEPS[this.state.processStep].msg}
                        handleInputChange={this.handleChange}
                        input={this.state.input}
                        onKeyPress={this.onKeyPress}
                        inputRef={this.textInput}
                        handleSubmit={this.handleSubmit}
                        loading={this.state.loading}
                        reset={this.reset}
                        showBoxes={this.showBoxes}
                        expectedItems={this.state.expectedItems}
                        showStatsButton={isManager}
                        onProcessWithoutLPN={this.processWithoutLPN}
                    />
                    :
                    <ViewProductsPage
                        showError={this.showError}
                        db={this.state.firebaseDocument}
                        products={this.state.photoProducts}
                        setProducts={(products) => this.setState({ photoProducts: products })}
                        photosLoadingProgress={this.state.photosLoadingProgress}
                        setPhotosLoadingProgress={(progress) => this.setState({ photosLoadingProgress: progress })}
                    />
                }

                <CustomSnackbar variant={this.state.snackbarVariant} duration={6000} onClose={() => this.setState({ snackbarOpened: false })} opened={this.state.snackbarOpened} message={this.state.snackbarMsg} />
                <ProductDialog
                    products={this.state.extractedProducts}
                    onClose={() => { this.setState({ productDialogOpened: false }) }}
                    opened={this.state.productDialogOpened}
                    onProceed={this.handleAction}
                />
                <BoxesDialog
                    boxes={this.state.boxes}
                    opened={this.state.boxesDialogOpened}
                    onClose={() => this.setState({ boxesDialogOpened: false })}
                    loadBox={this.getBoxContent}
                />
                <UploadPhotos
                    opened={this.state.uploadPhotosDialogOpened}
                    onClose={() => { this.setState({ uploadPhotosDialogOpened: false, productDialogOpened: true }) }}
                    finish={this.finishPhotoUpload}
                    urlParams={uploadPhotosParams}
                />
            </div>
        )
    }

    componentDidMount() {
        let { userData } = this.props;
        console.log(userData)
        if (userData) {
            this.setState({ firebaseDocument: (userData.role === 'ADMIN' || userData.role === 'MANAGER') && !userData.manager ? userData.uid : userData.manager }, () => {
                this.loadBoxes();
            });
        }
    }

    focusTextInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInput.current.focus();
    }

    finishPhotoUpload = () => {
        let product = this.state.extractedProducts[0];
        product.photosTaken = true;
        console.log(this.state.action);
        this.confirmProduct(product, this.state.action);
        this.setState({ uploadPhotosDialogOpened: false, photos: [] });
    }

    showError = (error) => {
        this.setState({ snackbarOpened: true, snackbarMsg: error, isLoading: false, snackbarVariant: 'error' });
    }

    reset = () => {
        this.setState({ processStep: 0, expectedItems: [], boxInfo: null, boxSaved: false });
        this.focusTextInput();
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    onKeyPress = (ev) => {
        console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === 'Enter') {
            // Do code here  
            this.handleSubmit(ev)
        }
    }

    onKeyPressProductInput = (ev) => {
        console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === 'Enter') {
            // Do code here  
            this.handleSearch();
        }
    }

    loadBoxes = async () => {
        try {
            let data = await returns.get('getBoxes', { db: this.state.firebaseDocument, limit: 20 });
            console.log(data)
            this.setState({ boxes: data.result });
        } catch (error) {
            this.setState({ snackbarOpened: true, snackbarMsg: error.message + '. Try again.', isLoading: false, snackbarVariant: 'error' });
        }

    }

    showBoxes = () => {
        this.setState({ boxesDialogOpened: true })
    }

    getBoxContent = async (trackingID) => {
        this.setState({ loading: true });

        trackingID = parseTrackingNumber(trackingID)
        try {
            let data = await returns.get('getProducts', { db: this.state.firebaseDocument, trackingID });
            let savedItems = data.result;
            data = await returns.get('getExpectedProducts', { db: this.state.firebaseDocument, trackingNumber: trackingID });
            let expectedItems = data.result;
            if (!expectedItems.length) {
                throw Error("Something went wrong");
            }
            expectedItems = expectedItems.map(item => {
                let index = savedItems.findIndex(p => p && p.sku === item.sku);
                if (index === -1) return item;
                item = { ...item, ...savedItems[index] };
                savedItems[index] = null;
                return item;
            })
            let boxInfo = this.state.boxes.find(b => b.trackingID === trackingID);
            this.setState({ expectedItems, boxesDialogOpened: false, loading: false, boxSaved: true, processStep: 1, boxInfo });
        } catch (error) {
            this.setState({ snackbarOpened: true, snackbarMsg: error.message + '. Try again.', loading: false, snackbarVariant: 'error' });
        }
    }

    saveProduct = async (product) => {
        try {
            await returns.post('addProducts', { db: this.state.firebaseDocument }, { data: { products: [product] } })
            this.setState({ snackbarOpened: true, snackbarMsg: `Item marked as ${product.action}`, loading: false, snackbarVariant: 'success' });
        } catch (e) {
            console.log(e)
        }
    }

    updateBox = async (clearProgress) => {
        let boxInfo = this.state.boxInfo;
        boxInfo.retrievedItems = this.state.expectedItems.length;
        boxInfo.finishedItems = this.state.expectedItems.filter(item => item.finished).length;
        await returns.post('addBox', { db: this.state.firebaseDocument, clearProgress }, { data: { box: boxInfo } })
        return boxInfo;
    }

    saveBox = async () => {
        if (!this.state.boxInfo) return;
        this.setState({ loading: true });
        try {
            let boxInfo = await this.updateBox(!this.state.boxSaved);

            let index = this.state.boxes.findIndex(b => b.trackingID === boxInfo.trackingID)
            if (index > -1) {
                this.state.boxes[index] = boxInfo;
            } else {
                this.state.boxes.unshift(boxInfo);
            }
            this.setState({ snackbarOpened: true, snackbarMsg: 'Saved!', loading: false, boxSaved: true, snackbarVariant: 'success' });
        } catch (error) {
            this.setState({ snackbarOpened: true, snackbarMsg: error.message + '. Try again.', loading: false, snackbarVariant: 'error' });
        }
        this.focusTextInput();
    }

    handleAction = (action) => {
        console.log(action, this.state.extractedProducts[0]);
        if (action.includes('fraud')) {
            this.setState({ productDialogOpened: false, uploadPhotosDialogOpened: true, photos: [], action });
        } else {
            this.confirmProduct(this.state.extractedProducts[0], action);
        }
    }

    confirmProduct = (product, action) => {
        this.setState(prevState => {
            let index = prevState.expectedItems.findIndex(p => p.sku === product.sku && p['license-plate-number'] === product['license-plate-number']);
            // If no item is found, search only by sku
            if (index === -1) {
                index = prevState.expectedItems.findIndex(p => p.sku === product.sku);
            }
            if (index !== -1) {
                prevState.expectedItems[index] = { ...product, ...prevState.expectedItems[index], ...{ finished: true, action, submitted: false, scanDate: new Date() } };
                this.saveProduct(prevState.expectedItems[index]);
            }
            prevState.productDialogOpened = false;
            prevState.action = '';
            return prevState;
        }, () => {
            this.saveBox();
        });
        this.focusTextInput();
    }

    processWithoutLPN = (sku) => {
        const index = this.state.expectedItems.findIndex(p => p.sku === sku && !p.finished);
        const asin = this.state.expectedItems[index].asin;
        if (index !== -1) {
            this.setState(
                prevState => {
                    // Create random LPN
                    const prod = prevState.expectedItems[index];
                    const lpn = `NO-LPN-${prod['order-id']}-${prod.fnsku}-${index}`;
                    const newState = { ...prevState };
                    newState.expectedItems[index] = { 
                        ...newState.expectedItems[index],
                        ...{ 'license-plate-number': lpn, submitted: false, scanDate: new Date() }
                    };
                    newState.input = lpn;
                    newState.loading = true;
                    return newState;
                },
                () => {
                    this.searchByASIN(asin);
                }
            )
        }
    }

    handleSubmit = async () => {
        const { auth, userData } = this.props;

        if (!auth || !userData) {
            this.setState({ snackbarOpened: true, snackbarMsg: 'Waiting for data', snackbarVariant: 'info', input: "" });
            this.focusTextInput();
            return;
        }
        this.setState({ loading: true });

        // Log the entered code to Sentry
        // Sentry.withScope((scope) => {
        //     Sentry.setContext('returns', {
        //         email: auth.email,
        //         uid: auth.uid
        //     });
        //     Sentry.captureMessage(`scanned code: ${this.state.input}`);
        // });

        try {
            if (this.state.processStep === 0) {
                const trackingNumber = parseTrackingNumber(this.state.input);
                let data = await returns.get('getExpectedProducts', { db: this.state.firebaseDocument, trackingNumber: trackingNumber });
                // let backendResult = await axios.get(`${URL}/returns/getExpectedProducts?key=maximumsecurity&db=${this.state.firebaseDocument}&trackingNumber=${this.state.input}`);
                let expectedItems = data.result;
                if (data.error) {
                    throw new Error(data.error);
                }

                let date = new Date();
                expectedItems = expectedItems.map(item => {
                    return { ...item, ...{ id: randID(), finished: false, "tracking-number": trackingNumber } };
                });
                console.log(expectedItems);
                let boxInfo = {
                    trackingID: trackingNumber,
                    date
                };

                if (expectedItems.length > 0) {
                    boxInfo.trackingID = expectedItems[0]["tracking-number"];
                }

                this.setState({ expectedItems, processStep: 1, boxInfo, loading: false, input: "" }, () => {
                    if (expectedItems.length) {
                        this.saveBox();
                    }
                });
            } else if (this.state.processStep === 1) {
                let data = await returns.get('searchCustomerReturns', { db: this.state.firebaseDocument, lpn: this.state.input });
                let scannedItem = data.result[0];
                console.log(scannedItem);
                if (scannedItem) {
                    // Merge results with the current state of the list
                    let expectedIndex = this.state.expectedItems.findIndex(item => item.sku === scannedItem.sku && !item.finished);
                    if (expectedIndex === -1) throw new Error('Product not found in the box or already scanned');

                    scannedItem['customer-order-id'] = scannedItem['order-id'];
                    delete scannedItem['order-id'];
                    this.state.expectedItems[expectedIndex] = { ...this.state.expectedItems[expectedIndex], ...scannedItem };
                    this.searchByASIN(scannedItem.asin);
                } else {
                    throw new Error('Product not found in reports');
                }
                // this.searchByASIN(this.state.input);
            }
        } catch (error) {
            this.focusTextInput();
            this.setState({ snackbarOpened: true, snackbarMsg: error.message, snackbarVariant: 'error', loading: false, input: '' });
        }

        this.focusTextInput();
    }

    searchByASIN = async (asin) => {
        try {
            const item = await catalogItems.getCatalogItem({
                asin,
                marketplaceIds: [MARKETPLACE_ID],
                includedData: ['attributes', 'images', 'summaries', 'dimensions', 'salesRanks'],
                devMode: false
            })
            const extractedProd = catalogItems.extractGetCatalogItem(item);
            const keepaResp = await getProductForASIN(asin, this.props.userData.credentials.keepaKey);
            const keepaProd = keepaResp.products[0];
            let upc = keepaProd?.upc;
            if (!upc && keepaProd.upcList) {
                upc = keepaProd.upcList[0];
            }
            this.handleExtractedProducts([extractedProd], upc)
        } catch (e) {
            const errorMsg = e?.response?.data?.error || e.message;
            this.setState({ snackbarOpened: true, snackbarMsg: errorMsg, loading: false, snackbarVariant: 'error', input: "" })
        }
    }

    handleExtractedProducts = (products, motherUPC) => {
        console.log(products, motherUPC);
        let product = products[0];
        let reportData = this.state.expectedItems.find(c => c["license-plate-number"] === this.state.input);

        if (product) {
            product = { ...product, ...reportData };
            product.UPC = motherUPC;
            products[0] = product;
        }
        console.log(products, reportData, this.state.input)
        this.setState({ extractedProducts: products, productDialogOpened: true, loading: false, input: '' });
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        address: state.db.address
    }
}

export default connect(mapStateToProps)(ReturnItemsPage);
