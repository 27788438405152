import React from 'react';
import { utils } from './shipments';
import { CircularProgress } from '@material-ui/core';

/**
 * Displays a single shipment in a row.
 * @param {Object} param0 component props
 */
function ShipmentRow({ shipment, selectShipment, selectedId }) {
    let className = 'all-shipments-list-row';
    if (shipment.ShipmentId === selectedId) {
        className += ' selected-shipment-row';
    }

    return (
        <li
            className={className}
            onClick={() => selectShipment(shipment.ShipmentId)}
        >
            <div className='shipmentid-container'>
                <p>
                    {shipment.DestinationFulfillmentCenterId} /{' '}
                    {shipment.ShipmentId}
                </p>
            </div>
            <div className='shipment-name-container'>
                <p>
                    {shipment.ShipmentName}{' '}
                    <span>({shipment.ShipmentStatus})</span>
                </p>
            </div>
            <div className='stats-container'>
                <div className='shipment-stats-container'>
                    <p className='label'>Units</p>
                    <p className='info'>
                        {utils.calcShipmentSize(shipment)}/
                        {shipment.ExpectedItems.reduce((curr, prev) => prev.QuantityShipped + curr, 0)}
                    </p>
                </div>
                <div className='shipment-stats-container'>
                    <p className='label'>SKUs</p>
                    <p className='info'>
                        {utils.countSkus(shipment)}/
                        {shipment.ExpectedItems.length}
                    </p>
                </div>
                <div className='shipment-stats-container'>
                    <p className='label'>Boxes</p>
                    <p className='info'>
                        {utils.calcNonemptyBoxes(shipment.Boxes)}
                    </p>
                </div>
            </div>
        </li>
    );
}

/**
 * Displays a list of shipments.
 * @param {Object} props - Component props.
 * @param {Array} props.shipments - A list of shipments to display.
 * @param {function} props.selectShipment - Callback function executed when a single shipment
 * is selected.
 * @param {string} props.selectedId - Selected shipment identifier.
 * @param {boolean} props.loading - Indicates if shipments are being downloaded.
 */
export default function AllShipmentsList({
    shipments,
    selectShipment,
    selectedId,
    loading,
}) {
    // console.log(shipments);
    return shipments.length > 0 ? (
        <ul className='all-shipments-list'>
            {shipments.map(shipment => (
                <ShipmentRow
                    shipment={shipment}
                    selectShipment={selectShipment}
                    selectedId={selectedId}
                    key={shipment.ShipmentId}
                />
            ))}
        </ul>
    ) : (
        <div
            className='button-container'
            style={{ width: '100%', height: '100%' }}
        >
            {loading ? (
                <CircularProgress style={{ width: '50px', height: '50px' }} />
            ) : (
                <p className='title'>No shipments found</p>
            )}
        </div>
    );
}
