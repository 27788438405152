import { takeLatest } from 'redux-saga/effects';
import { getShipments } from './getShipments';
import { saveShipments } from './saveShipments';
import { updateCurrentShipments, updateSelectedShipment } from './getItems';
import {
    voidShipmentSaga,
    confirmShipmentSaga,
    completeShipmentSaga,
} from './confirmVoidShipment';
import { getUniqueLabels } from './printLabels';
import { submitShipment, putContent } from './contents';
import { deleteShipmentItemSaga } from './deleteShipmentItem';

export function* saga() {
    yield takeLatest('GET_SHIPMENTS', getShipments);
    yield takeLatest('SAVE_SHIPMENTS', saveShipments);
    // yield takeLatest('GET_SHIPMENT_ITEMS', getShipmentItems);
    yield takeLatest('UPDATE_CURRENT_SHIPMENTS', updateCurrentShipments);
    yield takeLatest('UPDATE_SELECTED_SHIPMENT', updateSelectedShipment);
    yield takeLatest('CONFIRM_SHIPMENT', confirmShipmentSaga);
    yield takeLatest('VOID_SHIPMENT', voidShipmentSaga);
    yield takeLatest('GET_UNIQUE_LABELS', getUniqueLabels);
    yield takeLatest('SUBMIT_SHIPMENT', submitShipment);
    yield takeLatest('PUT_CONTENT', putContent);
    yield takeLatest('COMPLETE_SHIPMENT', completeShipmentSaga);
    yield takeLatest('DELETE_SHIPMENT_ITEM', deleteShipmentItemSaga);
}
