import { FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

const niceScrollTheme = createMuiTheme({
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: '720px'
        }
      }
}});

class SierraDB extends Component {
    state = {
        keyword: '',
        subscription: 'NOT ACTIVE',
        credentials: {
            token: ' ',
            sellerID: ' ',
            keepaKey: ' '
        },
        productData: [],
        errorMsg: '',
        snackbarOpened: false,
        isLoading: false,
        loadingMsg: '',
        currColl: '',
        collections: [],
        purchasedList: '',
        salesData: [],
    }

    constructor(props) {
        super(props)

        this.textInput = React.createRef();
        this.focusTextInput = this.focusTextInput.bind(this);
    }

    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;

        const columns = [
            {
                name: "Name",
                label: "Name",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Name includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "Id",
                label: "Id",
                options: {
                    filter: false,
                    sort: true,
                    display: true,
                    customBodyRender: (value, _, __) => {
                      return <a target="_blank" rel="noopener noreferrer" href={'https://www.sierra.com/product/bounce/' + value}>{value}</a>
                    }
                }
            },
            {
                name: "FinalPrice",
                label: "FinalPrice",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min FinalPrice: ${v['min']}, Max FinalPrice: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min FinalPrice: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max FinalPrice: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>FinalPrice</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            }, 
            {
              name: "IsClearance",
              label: "Clearance",
              options: {
                  filter: true,
                  display: true,
                  filterType: 'dropdown',
                  customFilterListRender: v => {
                    if (v) {
                        return `isClearance: ${v}`
                    }
                    return false;
                  },
              }
            },
            {
              name: "IsNew",
              label: "New",
              options: {
                  filter: true,
                  display: true,
                  filterType: 'dropdown',
                  customFilterListRender: v => {
                    if (v) {
                        return `isNew: ${v}`
                    }
                    return false;
                  },
              }
            },
            {
                name: "SizeCount",
                label: "SizeCount",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min SizeCount: ${v['min']}, Max SizeCount: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min SizeCount: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max SizeCount: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>SizeCount</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "SizeList",
                label: "SizeList",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `SizeList includes ${v}`
                        }
                        return false;
                    },
                }
            }, 
            {
                name: "ColorCount",
                label: "ColorCount",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min ColorCount: ${v['min']}, Max ColorCount: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min ColorCount: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max ColorCount: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>ColorCount</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "ColorList",
                label: "ColorList",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `ColorList includes ${v}`
                        }
                        return false;
                    },
                }
            }, 
            {
                name: "WidthCount",
                label: "WidthCount",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min WidthCount: ${v['min']}, Max WidthCount: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min WidthCount: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max WidthCount: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>WidthCount</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "WidthList",
                label: "WidthList",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `WidthList includes ${v}`
                        }
                        return false;
                    },
                }
            }, 
            {
                name: "AZSearch",
                label: "AZ",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, _, __) => {
                      return <a target="_blank" rel="noopener noreferrer" href={value}>AZ Search</a>
                    }
                }
            },

        ]
        
        const options = {
          filterType: 'textField',
          selectableRows: 'none',
          print: false,
          downloadOptions: {
              filename: 'sierraBrowserExport.csv',
              filterOptions: {
                  useDisplayedColumnsOnly: true,
                  useDisplayedRowsOnly: true,
              }
          },
          responsive: 'standard',
          fixedHeader: true,
      };

        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container  display="flex" maxWidth={false} height="100%">
                <Container width='100%' m={3} >
                    <Box display="flex" flexDirection="row"  className={classes.container}  justifyContent="center" alignItems="center">
                        <TextField
                            id="keyword"
                            label="Keyword"
                            type="text"
                            className={classes.textField}
                            value={this.state.keyword}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onSubmit={this.handleSubmit}
                            inputRef={this.textInput}
                        />
                        <Button onClick={() => this.updateData()}>
                            Update
                        </Button>
                        <Button onClick={() => this.getData(this.state.keyword)}>
                            Generate
                        </Button>
                    </Box>
                </Container>
                

                <Container width='100%' maxWidth="xl" height="100%" className={classes.container}>
                    <MuiThemeProvider theme={niceScrollTheme}>
                        <MUIDataTable
                                title={"Product Data"}
                                data={this.state.productData}
                                columns={columns}
                                options={options}
                                />
                    </MuiThemeProvider>
                
                    
                </Container>
                
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbarOpened}
                    autoHideDuration={6000}
                >
                    <SnackbarContent
                        className={clsx(classes.error, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                                {this.state.errorMsg}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="Close" color="inherit" onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                this.setState({ snackbarOpened: false })
                            }} >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </Container>
        )
    }

    componentDidMount() {
        // this.getCollections()
    }

    focusTextInput() {
        this.textInput.current.focus();
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    changePage = (page) => {
        this.setState({
          isLoading: true,
        });
    };

    updateData = () => {
      fetch ('https://api.projectsuite.io/sierra?keyword=update').then(response => {
        return response.json();
      }).then(jsonResponse => {
        if (jsonResponse.error != null) {
          this.setState({ snackbarOpened: true, errorMsg: jsonResponse.error })
        } else {
          this.getData('')
        }
      })
    }

    getData = (keyword) => {
        // this.setState({ isLoading: true });
        fetch('https://api.projectsuite.io/sierra?keyword=' + keyword).then(response => {
            return response.json();
        }).then(jsonResponse => {
            let prods = jsonResponse["products"]
            if (prods != null) {
                prods = prods.map(prod => {
                    // ADD NEW FIELDS
                    prod.AZSearch = `https://www.amazon.com/s?k=${prod.Name}&ref=nb_sb_noss_1`
              
                    // MODIFY EXISTING FIELDS
                    if (prod.IsClearance === true) {
                        prod.IsClearance = 'True'
                    } else {
                        prod.IsClearance = 'False'
                    }
    
                    if (prod.IsNew === true) {
                        prod.IsNew = 'True'
                    } else {
                        prod.IsNew = 'False'
                    }

                    if (prod.SizesAvailable.SIZE !== undefined) {
                        prod.SizeCount = prod.SizesAvailable.SIZE.length
                        prod.SizeList = prod.SizesAvailable.SIZE.join(', ')
                    } else {
                        prod.SizeCount = 0
                        prod.SizeList = ''
                    }

                    if (prod.SizesAvailable.WIDTH !== undefined) {
                        prod.WidthCount = prod.SizesAvailable.WIDTH.length
                        prod.WidthList = prod.SizesAvailable.WIDTH.join(', ')
                    } else {
                        prod.WidthCount = 0
                        prod.WidthList = ''
                    }

                    if (prod.Colors !== undefined) {
                        prod.ColorCount = prod.Colors.length
                        prod.ColorList = prod.Colors.map(color => {return color.ColorName}).join(', ')
                    } else {
                        prod.Colors = 0
                        prod.ColorList = ''
                    }
    
                    return prod
                })

                this.setState({ productData: prods })
            } else {
                this.setState({ snackbarOpened: true, errorMsg: 'No results found', isLoading: false })
            }
        })
      }
}

SierraDB.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(SierraDB))