export const passCredentials = (cred) => ({
  type: 'PASS_CREDENTIALS',
  credentials: cred
})

export const passAddress = (add) => ({
  type: 'PASS_ADDRESS',
  address: add
})

export const passSalesData = (sd) => ({
  type: 'PASS_SALESDATA',
  salesData: sd,
})

export const passPurchasedData = (pd) => ({
  type: 'PASS_PURCHASEDDATA',
  purchasedData: pd,
})