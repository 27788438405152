import React, { useState } from 'react';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import './InfoBanner.scss';
import { utils } from './shipments';
import UnboxedItemsDialog from './UnboxedItemsDialog';

/**
 * Displays information about the given shipment. Also allows to view the shipment's unboxed
 * items in a special dialog (UnboxedItemsDialog).
 * @param {Object} props - Component props.
 * @param {Object} props.shipment - Shipment data.
 * @param {Object|undefined} props.status - Indicates if the shipment is currently in the
 * loading state.
 * @param {function} props.onSubmit - Callback function executed when the submit button is clicked.
 * @param {function} props.onChargesClicked - Callback function executed when the charges button
 * is clicked.
 * @param {function} props.onConfirmBoxes - Callback function executed when the confirm button is
 * clicked.
 */
export default function ShipmentInfoBanner({
    shipment,
    status,
    onSubmit,
    onChargesClicked,
    onConfirmBoxes,
}) {
    const [unboxedItemsDialogOpened, setDialogOpened] = useState(false);
    let feedInfo = shipment.LatestFeedInfo;
    let feedResult = shipment.LatestFeedResult || {};
    let boxes = shipment ? shipment.Boxes : [];
    let lastSubmitted = shipment.LastSubmitted
        ? new Date(shipment.LastSubmitted)
        : new Date(0);
    let lastUpdated = shipment.LastUpdated
        ? new Date(shipment.LastUpdated)
        : new Date(0);

    return (
        <Box className='centered-container' flexDirection='column' width='100%'>
            <Grid className='shipment-info-banner' container={true}>
                <Grid item={true} sm={12}>
                    <p className='title-info'>{shipment.ShipmentName}</p>
                </Grid>
                <Grid container={true}>
                    <Grid item={true} sm={6}>
                        <Box className='row'>
                            <p className='label-info'>Submission Status:</p>
                            <p className='info-text'>
                                {feedInfo
                                    ? feedInfo.processingStatus
                                    : '---'}
                            </p>
                        </Box>
                        <Box className='row'>
                            <p className='label-info'>Transport Status:</p>
                            <p className='info-text'>
                                {shipment.TransportStatus || '---'}
                            </p>
                        </Box>
                    </Grid>
                    <Grid item={true} sm={6}>
                        <Box className='row'>
                            <p className='label-info'>Submitted Date:</p>
                            <p className='info-text'>
                                {feedInfo
                                    ? new Date(
                                          feedInfo.createdTime
                                      ).toLocaleString()
                                    : '---'}
                            </p>
                        </Box>
                        <Box className='row'>
                            <p className='label-info'>Errors:</p>
                            <p className='info-text'>
                                {feedResult.Messages &&
                                feedResult.Messages.length > 0
                                    ? feedResult.Messages.length
                                    : 'NONE'}
                            </p>
                        </Box>
                    </Grid>
                </Grid>
                {(status ||
                    (feedInfo &&
                        feedInfo.processingStatus !== 'DONE')) && (
                    <CircularProgress className='shipment-info-progress' />
                )}
            </Grid>
            <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-evenly'
                width='100%'
            >
                <Button
                    onClick={() => onSubmit(lastSubmitted < lastUpdated)}
                    style={{
                        borderRadius: '1em',
                    }}
                    className='white-button'
                    variant='outlined'
                    disabled={
                        shipment.TransportStatus === 'CONFIRMED' ||
                        utils.calcNonemptyBoxes(boxes) === 0
                    }
                >
                    SUBMIT
                </Button>
                <Button
                    onClick={onConfirmBoxes}
                    style={{
                        borderRadius: '1em',
                    }}
                    className='white-button'
                    variant='outlined'
                    disabled={
                        !(
                            feedResult.Messages &&
                            feedResult.Messages.length === 0 &&
                            shipment.TransportStatus !== 'CONFIRMED'
                        ) || lastSubmitted < lastUpdated
                    }
                >
                    CONFIRM BOXES
                </Button>
                <Button
                    onClick={onChargesClicked}
                    style={{
                        borderRadius: '1em',
                    }}
                    className='white-button'
                    variant='outlined'
                    disabled={!shipment.Estimate || lastSubmitted < lastUpdated}
                >
                    CHARGES
                </Button>
                <Button
                    onClick={() => setDialogOpened(true)}
                    style={{
                        borderRadius: '1em',
                    }}
                    className='white-button'
                    variant='outlined'
                >
                    UNBOXED
                </Button>
            </Box>
            <UnboxedItemsDialog
                opened={unboxedItemsDialogOpened}
                onClose={() => setDialogOpened(false)}
            />
        </Box>
    );
}
