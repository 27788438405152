import { FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import firebase from 'firebase';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import KeepaGraph from '../upc/KeepaGraph';

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

const niceScrollTheme = createMuiTheme({
    overrides: {
        MUIDataTable: {
            responsiveScroll: {
                maxHeight: '720px'
            }
        }
    }
});

class BuylistViewer extends Component {
    state = {
        productData: [],
        errorMsg: '',
        snackbarOpened: false,
        isLoading: false,
        loadingMsg: '',
        currColl: '',
        collections: [],
        shopperMap: [],
    }

    render() {
        const { classes } = this.props;
        const { auth } = this.props;
        const copy = require('clipboard-copy')

        const columns = [
            {
                name: "ImgURL",
                label: "Image",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, _, __) => {
                        return <img src={value.replace('SL250', 'SL100')} alt='Keepa graph' />
                    }
                }
            },
            {
                name: "Name",
                label: "Name",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Name includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "Store",
                label: "Supplier",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Supplier includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "TaxCode",
                label: "TaxCode",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                    customFilterListRender: v => {
                        if (v) {
                            return `TaxCode includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "TaxCode",
                label: "TaxCode",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                    customFilterListRender: v => {
                        if (v) {
                            return `TaxCode includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "UPC",
                label: "UPC",
                options: {
                    filter: false,
                    sort: true,
                    display: false,
                }
            },
            {
                name: "ASIN",
                label: "ASIN",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "Size",
                label: "Size",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "SizeTier",
                label: "SizeTier",
                options: {
                    filter: false,
                    display: false,
                }
            },
            {
                name: "Price",
                label: "Price",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Price: ${v['min']}, Max Price: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Price: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Price: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Price</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "Cost",
                label: "Cost",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Cost: ${v['min']}, Max Cost: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Cost: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Cost: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Cost</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "Fee",
                label: "Fee",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Fee: ${v['min']}, Max Fee: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Fee: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Fee: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Fee</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: 'AvgVarPrice',
                label: "AvgVarPrice",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min AvgVarPrice: ${v['min']}, Max AvgVarPrice: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min AvgVarPrice: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max AvgVarPrice: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>AvgVarPrice</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "ActiveVars",
                label: "ActiveVars",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min ActiveVars: ${v['min']}, Max ActiveVars: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min ActiveVars: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max ActiveVars: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>ActiveVars</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "Offers",
                label: "Offers",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Offers: ${v['min']}, Max Offers: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Offers: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Offers: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Offers</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "Quantity",
                label: "Quantity",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Quantity: ${v['min']}, Max Quantity: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Quantity: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Quantity: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Quantity</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "SalesRank",
                label: "SalesRank",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Rank: ${v['min']}, Max Rank: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Rank: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Rank: ${v['max']}`;
                        }
                        return false;
                    },
                    customBodyRender: (value, _, __) => {
                        return parseInt(value)
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>SalesRank</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "ROI",
                label: "ROI",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Min ROI: ${v}%`
                        }
                        return false;
                    },
                    customBodyRender: (value, _, __) => (
                        <span>{parseFloat(value).toFixed(0)}%</span>
                    ),
                    filterOptions: {
                        logic(ROI, filterVal) {
                            return !(ROI >= parseFloat(filterVal))
                        }
                    }
                }
            },
            {
                name: "Purchaseddate",
                label: "Purchaseddate",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customBodyRender: (value, _, __) => (
                        `${getCurrDate(value)}`
                    ),
                    customFilterListRender: v => {
                        if (v) {
                            return `Purchaseddate includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "azLink",
                label: "AZ Link",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, _, __) => (
                        <a target="_blank" rel="noopener noreferrer" href={value}>AZ</a>
                    )
                }
            },
            {
                name: "graphURL",
                label: "Graph",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, _, __) => (
                        <KeepaGraph graphURL={value} />
                    )
                }
            },
        ]

        const options = {
            filterType: 'textField',
            selectableRows: 'none',
            responsive: 'scroll',
            downloadOptions: {
                filename: 'buylistExport.csv',
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                }
            },
            print: false,
            onCellClick: (colData, celMeta) => {
                copy(`${colData}`)
            }
        };

        if (!auth) return <Redirect to='/signin' />

        return (
            <Container display="flex" maxWidth={false} height="100%">
                <Container width='100%' m={3} >
                    <Box display="flex" flexDirection="row" className={classes.container} justifyContent="center" alignItems="center">
                        <Select
                            value={this.state.currColl}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'currColl',
                                id: 'choose-curr-coll',
                            }}
                        >
                            {this.state.collections.map((name, index) => {
                                return <MenuItem value={name} key={index}>{name}</MenuItem>
                            })}
                        </Select>
                        <Button onClick={() => this.getData(this.state.currColl)}>
                            Download
                        </Button>
                    </Box>
                </Container>


                <Container width='100%' maxWidth="xl" height="100%" className={classes.container}>
                    <MuiThemeProvider theme={niceScrollTheme}>
                        <MUIDataTable
                            title={"Product Data"}
                            data={this.state.productData}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>


                </Container>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbarOpened}
                    autoHideDuration={6000}
                >
                    <SnackbarContent
                        className={clsx(classes.error, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                                {this.state.errorMsg}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="Close" color="inherit" onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                this.setState({ snackbarOpened: false })
                            }} >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </Container>
        )
    }

    componentDidMount() {
        this.getCollections()
    }

    getCollections = async () => {
        this.setState({ isLoading: true })
        let collections = ['archive', 'current']
        let shopperMap = []
        let promises = []

        this.props.userData.shoppers.forEach(shopperID => {
            promises.push(firebase.firestore().collection('users').doc(shopperID).get().then((snapshot) => {
                if (!snapshot.empty) {
                    let shopperData = {
                        'email': snapshot.data().email,
                        'id': shopperID,
                    }
                    shopperMap.push(shopperData)
                }
            }))
        })
        Promise.all(promises).then(() => {
            let shopperEmails = shopperMap.map(shopper => shopper.email)
            this.setState({ collections: collections.concat(shopperEmails), isLoading: false, shopperMap: shopperMap })
        })
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    getData = (currColl) => {
        this.setState({ isLoading: true })
        let collection = ''
        let document = ''
        if (currColl.includes('@')) {
            document = this.state.shopperMap.filter(shopperData => shopperData.email === currColl).map(shopperData => shopperData.id)[0]
            collection = 'current'
        } else {
            document = this.props.userData.uid
            collection = currColl
        }

        console.log('collection: ', collection, 'document: ', document)

        firebase.firestore().collection('buylists').doc(document).collection(collection)
            .get()
            .then((snapshot) => {
                if (!snapshot.empty) {
                    let prods = []
                    snapshot.forEach((doc) => {
                        let data = doc.data()
                        data.graphURL = 'https://graph.keepa.com/pricehistory.png?domain=com&salesrank=1&asin=' + data.ASIN
                        data.ROI = parseFloat(((parseFloat(data.Price) - parseFloat(data.Cost) - data.Fee) / parseFloat(data.Cost) * 100).toFixed(0))
                        data.azLink = 'https://www.amazon.com/gp/offer-listing/' + data.ASIN + '/ref=dp_olp_new_mbc?ie=UTF8&condition=new'
                        prods.push(data)
                    })

                    let quantMap = new Map()
                    prods.forEach((e) => {
                        let redProd = {
                            "ASIN": e.ASIN,
                            "Cost": e.Cost,
                            "Supplier": e.Supplier,
                        }
                        let stringifiedProd = JSON.stringify(redProd)

                        if (!quantMap.has(stringifiedProd)) {
                            quantMap.set(stringifiedProd, e.Quantity)
                        } else {
                            quantMap.set(stringifiedProd, quantMap.get(stringifiedProd) + e.Quantity)
                        }
                    })

                    let actualProds = []
                    prods.forEach((e) => {
                        let redProd = {
                            "ASIN": e.ASIN,
                            "Cost": e.Cost,
                            "Supplier": e.Supplier,
                        }
                        let stringifiedProd = JSON.stringify(redProd)

                        if (quantMap.has(stringifiedProd)) {
                            e.Quantity = quantMap.get(stringifiedProd)
                            quantMap.delete(stringifiedProd)
                            actualProds.push(e)
                        }
                    })

                    this.setState({ productData: actualProds })
                }
            }).catch(e => {
                console.log('Failed to grab user: ' + e.message)
            })
    }

    uniq = (a) => {
        var seen = {};
        return a.filter(function (item) {
            return seen.hasOwnProperty(item.ASIN) ? false : (seen[item.ASIN] = true);
        });
    }
}

BuylistViewer.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}

function getCurrDate(t) {
    var tFormatted = JSON.parse(JSON.stringify(t).replace(/[_-]/g, ""))
    var today = new firebase.firestore.Timestamp(tFormatted.seconds, tFormatted.nanoseconds).toDate()
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }

    var date = mm + '/' + dd + '/' + yyyy;
    return date;
}


export default connect(mapStateToProps)(withStyles(styles)(BuylistViewer))