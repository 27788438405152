import { FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import KeepaGraph from './KeepaGraph';

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

const niceScrollTheme = createMuiTheme({
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: '720px'
        }
      }
}});

class CombinedBrowser extends Component {
    state = {
        resultProduct: {
            graphURL: 'https://graph.keepa.com/pricehistory.png?domain=com&salesrank=1&asin='
        },
        code: '',
        cost: '',
        coupon: '',
        subscription: 'NOT ACTIVE',
        credentials: {
            token: ' ',
            sellerID: ' ',
            keepaKey: ' '
        },
        productData: [],
        errorMsg: '',
        snackbarOpened: false,
        isLoading: false,
        loadingMsg: '',
        currColl: '',
        oldColl: '',
        collections: [],
        purchasedList: '',
        salesData: [],
    }

    constructor(props) {
        super(props)

        this.textInput = React.createRef();
        this.focusTextInput = this.focusTextInput.bind(this);
    }

    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;
        const copy = require('clipboard-copy')

        const columns = [
            {
                name: "name",
                label: "Name",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Name includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "UPC",
                label: "UPC",
                options: {
                    filter: false,
                    sort: true,
                    display: false,
                }
            },
            {
                name: "ASIN",
                label: "ASIN",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "SKU",
                label: "SKU",
                options: {
                    filter: false,
                    sort: true,
                    display: true,
                    customBodyRender: (value, _, __) => {
                      if (value.includes('http')) {
                        return <a target="_blank" rel="noopener noreferrer" href={value}>LINK</a>
                      } else if (value.includes('-')) {
                        return <a target="_blank" rel="noopener noreferrer" href={'https://www.sierra.com/product/bounce/' + value}>{value}</a>
                      } else {
                        return <a target="_blank" rel="noopener noreferrer" href={'https://www.eastbay.com/product/~/' + value + '.html'}>{value}</a>
                      }
                    }
                }
            },
            {
                name: "brand",
                label: "Brand",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                    customFilterListRender: v => {
                        if (v) {
                            return `Brand excludes ${v}`
                        }
                        return false;
                    },
                    filterOptions: {
                      logic(brand, filterVal) {
                          return !(brand !== filterVal)
                      }
                    }
                }
            },
            {
                name: "size",
                label: "Size",
                options: {
                    filter: false,
                    display: false,
                }
            }, 
            {
              name: "isClearance",
              label: "Clearance",
              options: {
                  filter: true,
                  display: false,
                  filterType: 'dropdown',
                  customFilterListRender: v => {
                    if (v) {
                        return `isClearance: ${v}`
                    }
                    return false;
                  },
              }
            },
            {
              name: "isNew",
              label: "New",
              options: {
                  filter: true,
                  display: false,
                  filterType: 'dropdown',
                  customFilterListRender: v => {
                    if (v) {
                        return `isNew: ${v}`
                    }
                    return false;
                  },
              }
            },
            {
              name: "isPurchased",
              label: "Purchased",
              options: {
                  filter: true,
                  display: false,
                  filterType: 'dropdown',
                  customFilterListRender: v => {
                    if (v) {
                        return `isPurchased: ${v}`
                    }
                    return false;
                  },
              }
            },
            {
                name: "shopPrice",
                label: "Shop",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min Shop: ${v['min']}, Max Shop: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min Shop: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max Shop: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>Shop</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "adjustPrice",
                label: "Adj",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min Adj: ${v['min']}, Max Adj: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min Adj: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max Adj: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>Adj</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "lowestPrice",
                label: "Low",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min Low: ${v['min']}, Max Low: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min Low: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max Low: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>Low</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: 'currentAmazonPrice',
                label: "AZ",
                options: {
                  filter: true,
                  display: false,
                  filterType: 'custom',
                  customFilterListRender: v => {
                    if (v['min'] && v['max']) {
                      return `Min AZ: ${v['min']}, Max AZ: ${v['max']}`;
                    } else if (v['min']) {
                      return `Min AZ: ${v['min']}`;
                    } else if (v['max']) {
                      return `Max AZ: ${v['max']}`;
                    }
                    return false;
                  },
                  filterOptions: {
                    names: [],
                    logic(age, filters) {
                      if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                      } else if (filters['min']) {
                        return age < filters['min'];
                      } else if (filters['max']) {
                        return age > filters['max'];
                      }
                      return false;
                    },
                    display: (filterList, onChange, index, column) => (
                      <div>
                        <FormLabel>AZ</FormLabel>
                        <FormGroup row>
                          <TextField
                            label="min"
                            value={filterList[index]['min'] || ''}
                            onChange={event => {
                              filterList[index]['min'] = event.target.value;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%', marginRight: '5%' }}
                          />
                          <TextField
                            label="max"
                            value={filterList[index]['max'] || ''}
                            onChange={event => {
                              filterList[index]['max'] = event.target.value;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%' }}
                          />
                        </FormGroup>
                      </div>
                    ),
                  },
                },
            },
            {
                name: "currentNewPrice",
                label: "New",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min New: ${v['min']}, Max New: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min New: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max New: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>New</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "avg30New",
                label: "AVG 30",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min AVG 30: ${v['min']}, Max AVG 30: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min AVG 30: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max AVG 30: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>AVG 30</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "avg90New",
                label: "AVG 90",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min AVG 90: ${v['min']}, Max AVG 90: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min AVG 90: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max AVG 90: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>AVG 90</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "currentListPrice",
                label: "List",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min List: ${v['min']}, Max List: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min List: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max List: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>List</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "currentSalesRank",
                label: "Rank",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min Rank: ${v['min']}, Max Rank: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min Rank: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max Rank: ${v['max']}`;
                      }
                      return false;
                    },
                    customBodyRender: (value, _, __) => {
                        return parseInt(value)
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>Rank</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "currentNewOfferCount",
                label: "Offers",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min Offers: ${v['min']}, Max Offers: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min Offers: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max Offers: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>Offers</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
              name: "OnHand",
              label: "OnHand",
              options: {
                  filter: true,
                  display: false,
                  filterType: 'custom',
                  customFilterListRender: v => {
                    if (v['min'] && v['max']) {
                      return `Min On Hand: ${v['min']}, Max On Hand: ${v['max']}`;
                    } else if (v['min']) {
                      return `Min On Hand: ${v['min']}`;
                    } else if (v['max']) {
                      return `Max On Hand: ${v['max']}`;
                    }
                    return false;
                  },
                  filterOptions: {
                    names: [],
                    logic(age, filters) {
                      if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                      } else if (filters['min']) {
                        return age < filters['min'];
                      } else if (filters['max']) {
                        return age > filters['max'];
                      }
                      return false;
                    },
                    display: (filterList, onChange, index, column) => (
                      <div>
                        <FormLabel>On Hand</FormLabel>
                        <FormGroup row>
                          <TextField
                            label="min"
                            value={filterList[index]['min'] || ''}
                            onChange={event => {
                              filterList[index]['min'] = event.target.value;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%', marginRight: '5%' }}
                          />
                          <TextField
                            label="max"
                            value={filterList[index]['max'] || ''}
                            onChange={event => {
                              filterList[index]['max'] = event.target.value;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%' }}
                          />
                        </FormGroup>
                      </div>
                    ),
                  },
              },
            },
            
            {
              name: "Orders",
              label: "Orders",
              options: {
                  filter: true,
                  display: false,
                  filterType: 'custom',
                  customFilterListRender: v => {
                    if (v['min'] && v['max']) {
                      return `Min Orders: ${v['min']}, Max Orders: ${v['max']}`;
                    } else if (v['min']) {
                      return `Min Orders: ${v['min']}`;
                    } else if (v['max']) {
                      return `Max Orders: ${v['max']}`;
                    }
                    return false;
                  },
                  filterOptions: {
                    names: [],
                    logic(age, filters) {
                      if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                      } else if (filters['min']) {
                        return age < filters['min'];
                      } else if (filters['max']) {
                        return age > filters['max'];
                      }
                      return false;
                    },
                    display: (filterList, onChange, index, column) => (
                      <div>
                        <FormLabel>Orders</FormLabel>
                        <FormGroup row>
                          <TextField
                            label="min"
                            value={filterList[index]['min'] || ''}
                            onChange={event => {
                              filterList[index]['min'] = event.target.value;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%', marginRight: '5%' }}
                          />
                          <TextField
                            label="max"
                            value={filterList[index]['max'] || ''}
                            onChange={event => {
                              filterList[index]['max'] = event.target.value;
                              onChange(filterList[index], index, column);
                            }}
                            style={{ width: '45%' }}
                          />
                        </FormGroup>
                      </div>
                    ),
                  },
              },
            },
            {
                name: "SizeCount",
                label: "SizeCount",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min SizeCount: ${v['min']}, Max SizeCount: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min SizeCount: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max SizeCount: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>SizeCount</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "SizeList",
                label: "SizeList",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `SizeList includes ${v}`
                        }
                        return false;
                    },
                }
            }, 
            {
                name: "ColorCount",
                label: "ColorCount",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min ColorCount: ${v['min']}, Max ColorCount: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min ColorCount: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max ColorCount: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>ColorCount</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "ColorList",
                label: "ColorList",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `ColorList includes ${v}`
                        }
                        return false;
                    },
                }
            }, 
            {
                name: "WidthCount",
                label: "WidthCount",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                      if (v['min'] && v['max']) {
                        return `Min WidthCount: ${v['min']}, Max WidthCount: ${v['max']}`;
                      } else if (v['min']) {
                        return `Min WidthCount: ${v['min']}`;
                      } else if (v['max']) {
                        return `Max WidthCount: ${v['max']}`;
                      }
                      return false;
                    },
                    filterOptions: {
                      names: [],
                      logic(age, filters) {
                        if (filters['min'] && filters['max']) {
                          return age < filters['min'] || age > filters['max'];
                        } else if (filters['min']) {
                          return age < filters['min'];
                        } else if (filters['max']) {
                          return age > filters['max'];
                        }
                        return false;
                      },
                      display: (filterList, onChange, index, column) => (
                        <div>
                          <FormLabel>WidthCount</FormLabel>
                          <FormGroup row>
                            <TextField
                              label="min"
                              value={filterList[index]['min'] || ''}
                              onChange={event => {
                                filterList[index]['min'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                              label="max"
                              value={filterList[index]['max'] || ''}
                              onChange={event => {
                                filterList[index]['max'] = event.target.value;
                                onChange(filterList[index], index, column);
                              }}
                              style={{ width: '45%' }}
                            />
                          </FormGroup>
                        </div>
                      ),
                    },
                },
            },
            {
                name: "WidthList",
                label: "WidthList",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `WidthList includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "rootCategory",
                label: "Category",
                options: {
                    filter: false,
                    sort: true,
                    display: false,
                }
            },
            // {
            //     name: "availabilityAmazon",
            //     label: "Avail. AZ",
            //     options: {
            //         filter: false,
            //         sort: true,
            //         display: false,
            //     }
            // },
            {
                name: "Fees",
                label: "Fees",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                }
            },
            {
                name: "variationsASIN",
                label: "Vars",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Min VarCount: ${v}`
                        }
                        return false;
                    },
                    customBodyRender: (value, _, __) => {
                        let vars = value.split(',')
                        return vars.length
                    },
                    filterOptions: {
                        logic(val, filterVal) {
                            let vars = val.split(',').length
                            return !(vars <= parseFloat(filterVal))
                        }
                    }
                }
            },
            {
                name: "ROI",
                label: "ROI",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Min ROI: ${v}%`
                        }
                        return false;
                    },
                    customBodyRender: (value, _, __) => (
                        <span>{parseFloat(value).toFixed(0)}%</span>
                    ),
                    filterOptions: {
                        logic(ROI, filterVal) {
                            return !(ROI >= parseFloat(filterVal))
                        }
                    }
                }
            },
            {
                name: "azLink",
                label: "AZ Link",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, _, __) => (
                        <a target="_blank" rel="noopener noreferrer" href={value}>AZ</a>
                    )
                }
            },
            {
                name: "graphURL",
                label: "Graph",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, _, __) => (
                        <KeepaGraph graphURL={value} />
                    )
                }
            },
        ]
        
        const options = {
            filterType: 'textField',
            selectableRows: 'none',
            responsive: 'scroll',
            print: false,
            onCellClick: (colData, celMeta) => {
              copy(`${colData}`)
            }
        };

        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container  display="flex" maxWidth={false} height="100%">
                <Container width='100%' m={3} >
                    <Box display="flex" flexDirection="row"  className={classes.container}  justifyContent="center" alignItems="center">
                        <Select 
                            value={this.state.currColl}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'currColl',
                                id: 'choose-curr-coll',
                            }}
                            >
                            {this.state.collections.map((name) => {
                                return <MenuItem value={name}>{name}</MenuItem>
                            })}
                        </Select>
                        <Select 
                            value={this.state.oldColl}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'oldColl',
                                id: 'choose-old-coll',
                            }}
                            >
                            {this.state.collections.map((name) => {
                                return <MenuItem value={name}>{name}</MenuItem>
                            })}
                        </Select>
                        <TextField
                            id="coupon"
                            label="Coupon"
                            type="text"
                            className={classes.textField}
                            value={this.state.coupon}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            onSubmit={this.handleSubmit}
                            inputRef={this.textInput}
                        />
                        <Button onClick={() => this.getData(this.state.currColl, this.state.oldColl)}>
                            Generate
                        </Button>
                        <Button onClick={() => this.deleteColl(this.state.currColl)}>
                            Delete
                        </Button>
                    </Box>
                </Container>
                

                <Container width='100%' maxWidth="xl" height="100%" className={classes.container}>
                    <MuiThemeProvider theme={niceScrollTheme}>
                        <MUIDataTable
                                title={"Product Data"}
                                data={this.state.productData}
                                columns={columns}
                                options={options}
                                />
                    </MuiThemeProvider>
                
                    
                </Container>
                
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbarOpened}
                    autoHideDuration={6000}
                >
                    <SnackbarContent
                        className={clsx(classes.error, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                                {this.state.errorMsg}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="Close" color="inherit" onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                this.setState({ snackbarOpened: false })
                            }} >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </Container>
        )
    }

    componentDidMount() {
        // ifvisible.on('focus', () => { this.focusTextInput() })
        // ifvisible.on('wakeup', () => { this.focusTextInput() })
        // this.getData(this.state.currColl)
        this.getCollections()
    }

    focusTextInput() {
      
        this.textInput.current.focus();
    }

    getCollections = () => {
      fetch('https://api.projectsuite.io/collections?list').then(response => {
        return response.json();
      }).then(jsonResponse => {
        let collections = jsonResponse["collections"]
        this.setState({collections: collections.map((coll) => {
          return coll["name"]
        })})
      })
    }

    deleteColl = (coll) => {
      fetch ('https://api.projectsuite.io/delete?coll=' + coll).then(response => {
        return response.json();
      }).then(jsonResponse => {
        if (jsonResponse.error != null) {
          this.setState({ snackbarOpened: true, errorMsg: jsonResponse.error })
        } else {
          this.getCollections()
        }
      })
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('lol submitted')
    }

    getData = (currColl, oldColl) => {
        // this.setState({ isLoading: true });
        fetch('https://api.projectsuite.io/products?collection=' + currColl + '&trim=none').then(response => {
            return response.json();
        }).then(jsonResponse => {
            let prods = jsonResponse["products"]
            fetch('https://api.projectsuite.io/products?collection=' + oldColl + '&trim=asin').then(response_old => {
              return response_old.json();
            }).then(jsonResponse_old => {
                let old_prods = jsonResponse_old["products"]
                fetch('https://api.projectsuite.io/sierra?keyword=').then(response => {
                return response.json();
                }).then(jsonResponse => {
                    let sierra_prods = jsonResponse["products"]
                    if (prods != null) {
                        let asins = []
                        for (var i = 0; i < old_prods.length; i++) {
                            asins.push(old_prods[i]["ASIN"])
                        }

                        prods = prods.map((prod) => {
                            let nums = [];
                            let adjustPrice = parseFloat((prod.shopPrice * (1 - parseFloat(this.state.coupon))).toFixed(2))
                            prod.currentSalesRank = parseInt(prod.currentSalesRank)
                            prod.adjustPrice = adjustPrice

                            // Check if existed previously
                            if (asins.includes(prod.ASIN)) {
                                prod.isNew = 'False'
                            } else {
                                prod.isNew = 'True'
                            }

                            let foundSalesData
                            if (this.props.salesData !== undefined && (foundSalesData = this.props.salesData.find(el => el.ASIN === prod.ASIN)) !== undefined) {
                                prod.Orders = parseInt(foundSalesData.Orders)
                                prod.OnHand = parseInt(foundSalesData.OnHand)
                            } else {
                                prod.Orders = -1
                                prod.OnHand = -1
                            }

                            // Check if on purchased list
                            if (this.props.purchasedData === undefined) {
                                prod.isPurchased = 'Unknown'
                            } else {
                                if (this.props.purchasedData.includes(prod.ASIN)) {
                                prod.isPurchased = 'True'
                                } else {
                                prod.isPurchased = 'False'
                                }
                            }
                            
                            // Check for clearance
                            if ((prod.shopPrice % 1) === 0) {
                                prod.isClearance = 'True'
                            } else {
                                prod.isClearance = 'False'
                            }

                            // Pick low price
                            if (prod.currentNewPrice !== -1) {
                                nums.push(prod.currentNewPrice)
                            }
                            if (prod.currentAmazonPrice !== -1) {
                                nums.push(prod.currentAmazonPrice)
                            }
                            if (nums.length >= 1) {
                                let lowestPrice = Math.min.apply(Math, nums)
                                prod.lowestPrice = lowestPrice
                            } else {
                                prod.lowestPrice = -1
                            }
                            if (prod.lowestPrice === -1) {
                                prod.graphURL = prod.graphURL + '&range=365'
                            }

                            // Calculate ROI
                            if (prod.Fees !== -1 && prod.lowestPrice !== -1) {
                                prod.ROI = parseFloat(((prod.lowestPrice*0.85  - adjustPrice - prod.Fees) / adjustPrice * 100).toFixed(0))
                                return prod
                            } else if (prod.lowestPrice !== -1) {
                                prod.ROI = -1
                                return prod
                            } else {
                                prod.ROI = -2
                                return prod
                            }
                        })

                        // Remove duplicates
                        prods = this.uniq(prods)

                        sierra_prods = sierra_prods.map(prod => {
                            // ADD NEW FIELDS
                            prod.AZSearch = `https://www.amazon.com/s?k=${prod.Name}&ref=nb_sb_noss_1`
                    
                            // MODIFY EXISTING FIELDS
                            if (prod.IsNew === true) {
                                prod.IsNew = 'True'
                            } else {
                                prod.IsNew = 'False'
                            }

                            if (prod.SizesAvailable.SIZE !== undefined) {
                                prod.SizeCount = prod.SizesAvailable.SIZE.length
                                prod.SizeList = prod.SizesAvailable.SIZE.join(', ')
                            } else {
                                prod.SizeCount = 0
                                prod.SizeList = ''
                            }

                            if (prod.SizesAvailable.WIDTH !== undefined) {
                                prod.WidthCount = prod.SizesAvailable.WIDTH.length
                                prod.WidthList = prod.SizesAvailable.WIDTH.join(', ')
                            } else {
                                prod.WidthCount = 0
                                prod.WidthList = ''
                            }

                            if (prod.Colors !== undefined) {
                                prod.ColorCount = prod.Colors.length
                                prod.ColorList = prod.Colors.map(color => {return color.ColorName}).join(', ')
                            } else {
                                prod.Colors = 0
                                prod.ColorList = ''
                            }
            
                            return prod
                        })

                        prods = prods.map(prod => {
                            let foundSierraProd = sierra_prods.find(el => el.Id === prod.SKU.split('-')[0])
                            if (foundSierraProd !== undefined) {
                                if (foundSierraProd.SizesAvailable.SIZE !== undefined) {
                                    prod.SizeCount = foundSierraProd.SizesAvailable.SIZE.length
                                    prod.SizeList = foundSierraProd.SizesAvailable.SIZE.join(', ')
                                } else {
                                    prod.SizeCount = 0
                                    prod.SizeList = ''
                                }
        
                                if (foundSierraProd.SizesAvailable.WIDTH !== undefined) {
                                    prod.WidthCount = foundSierraProd.SizesAvailable.WIDTH.length
                                    prod.WidthList = foundSierraProd.SizesAvailable.WIDTH.join(', ')
                                } else {
                                    prod.WidthCount = 0
                                    prod.WidthList = ''
                                }
        
                                if (foundSierraProd.Colors !== undefined) {
                                    prod.ColorCount = foundSierraProd.Colors.length
                                    prod.ColorList = foundSierraProd.Colors.map(color => {return color.ColorName}).join(', ')
                                } else {
                                    prod.Colors = 0
                                    prod.ColorList = ''
                                }
                            }
                            return prod
                        })
                        
                        this.setState({ productData: prods.filter((prod) => {
                            return prod.currentSalesRank !== -1
                        })});
                    }
                })
            })
        })
    }

    uniq = (a) => {
      var seen = {};
      return a.filter(function(item) {
          return seen.hasOwnProperty(item.ASIN) ? false : (seen[item.ASIN] = true);
      });
    }
}

CombinedBrowser.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(CombinedBrowser))