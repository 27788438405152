import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { LinearProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'material-ui-image';
import PropTypes from 'prop-types';
import React from 'react';
import placeholder from '../../../assets/placeholder.png';

const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    card: {
        width: '100%',
        marginBottom: 10,
        background: '#eceff1',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 5,
    },
    button: {
        alignSelf: 'flex-end',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));

export default function ProductDialog(props) {
    const classes = useStyles();
    const {
        onClose,
        opened,
        products,
        onConfirm,
        onQuantityChange,
        onEntered,
        loading,
    } = props;

    // products.sort((a, b) => (a.InvQuantity > b.InvQuantity) ? 1 : -1)
    const highestQuant =
        products.length > 0
            ? products.reduce((max, curr) => Math.max(max, curr.InvQuantity), 0)
            : -1;
    return (
        <Dialog
            open={opened}
            onClose={onClose}
            aria-labelledby='form-dialog-title'
            onEntered={onEntered}
        >
            <DialogTitle id='form-dialog-title'>
                {'Confirm product'}
                <IconButton
                    aria-label='Close'
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {products.map(p => (
                    <Card key={p.ID} className={classes.card}>
                        <CardContent style={{ paddingBottom: '16px' }}>
                            <Typography variant='h6'>{p.Name}</Typography>
                            <div
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: '100px',
                                            height: 'auto',
                                        }}
                                        src={p.ImgURL ? p.ImgURL : placeholder}
                                    />
                                </div>
                                <div
                                    style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            flexDirection: 'column',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <p
                                            style={{
                                                margin: 0,
                                                fontWeight: '500',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {p.ASIN}
                                        </p>
                                        <p
                                            style={{
                                                margin: 0,
                                                fontWeight: '500',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {p.SKU}
                                        </p>
                                        <p
                                            style={{
                                                margin: 0,
                                                fontWeight: '500',
                                                fontSize: '13px',
                                            }}
                                        >
                                            In inventory: {p.InvQuantity}
                                        </p>
                                        <p
                                            style={{
                                                margin: 0,
                                                fontWeight: '500',
                                                fontSize: '13px',
                                            }}
                                        >
                                            Condition: {p.Condition ?? 'N/A'}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <TextField
                                            id='quantity'
                                            label='Quantity'
                                            type='number'
                                            value={p.TempQuantity}
                                            onChange={e => {
                                                return onQuantityChange(e, p);
                                            }}
                                            margin='normal'
                                            variant='outlined'
                                            onSubmit={onConfirm}
                                            disabled={loading}
                                            style={{
                                                width: '100px',
                                                padding: 0,
                                                marginBottom: '0px',
                                                marginTop: '0px',
                                            }}
                                            inputProps={{ min: '1' }}
                                            {...(highestQuant >= 0
                                                ? p.InvQuantity === highestQuant
                                                    ? {
                                                          inputRef:
                                                              props.inputFieldRef,
                                                      }
                                                    : null
                                                : {
                                                      inputRef:
                                                          props.inputFieldRef,
                                                  })}
                                            onFocus={event => {
                                                event.preventDefault();
                                                event.target.select();
                                            }}
                                            onKeyPress={ev => {
                                                console.log(
                                                    `Pressed keyCode ${ev.key}`
                                                );
                                                if (ev.key === 'Enter') {
                                                    // Do code here
                                                    onConfirm(p);
                                                }
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Button
                                                onClick={() => onConfirm(p)}
                                            >
                                                Select
                                            </Button>
                                            {p.AddedQuantity && (
                                                <p
                                                    style={{
                                                        fontWeight: '500',
                                                        fontSize: '14px',
                                                        margin: 0,
                                                    }}
                                                >
                                                    Total:{' '}
                                                    {p.TempQuantity +
                                                        p.AddedQuantity}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {p.notifications &&
                                p.notifications.length > 0 &&
                                p.notifications?.map(notif => (
                                    <Typography
                                        style={{
                                            marginTop: '5px',
                                            paddingBottom: '0px',
                                        }}
                                        align='center'
                                    >
                                        <b>Notification:</b> {notif.message}
                                    </Typography>
                                ))}
                        </CardContent>
                    </Card>
                ))}
            </DialogContent>
            {loading ? <LinearProgress className={classes.progress} /> : null}
        </Dialog>
    );
}

ProductDialog.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    products: PropTypes.array,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    quantity: PropTypes.number,
    onQuantityChange: PropTypes.func,
    onEntered: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
};
