import React from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase';
import { loginSuccess } from './store/actions/authActions'
import { passCredentials, passAddress, passSalesData, passPurchasedData } from './store/actions/dbActions'
import Appbar from './components/layout/Appbar';
import Notifier from './components/common/Notifier';


class App extends React.Component {
    state = {
        isAuthenticating: true,
    }

    render() {
        if (this.state.isAuthenticating) return null

        return (
            <React.Fragment>
                <Notifier />
                <div className="App">
                    <Appbar />
                </div>
            </React.Fragment>
        );
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                firebase.firestore().collection('users').doc(user.uid)
                    .get()
                    .then((snapshot) => {
                        if (!snapshot.empty) {
                            var userData = {
                                uid: user.uid,
                                subscription: snapshot.data().subscription,
                                role: snapshot.data().role,
                                manager: snapshot.data().manager,
                                credentials: {
                                    token: snapshot.data().auth_token,
                                    sellerID: snapshot.data().seller_id,
                                    keepaKey: snapshot.data().keepa_key
                                }
                            }

                            if ((userData.role?.includes('MANAGER') || userData.role === "ADMIN") && snapshot.data().shoppers !== undefined) {
                                userData.shoppers = snapshot.data().shoppers
                            } else {
                                userData.shoppers = []
                            }

                            this.props.dispatch(loginSuccess({ info: user }))
                            this.props.dispatch(passCredentials(userData))
                            this.props.dispatch(passAddress(snapshot.data().address))
                            this.props.dispatch(passSalesData(snapshot.data().salesData))
                            this.props.dispatch(passPurchasedData(snapshot.data().purchasedData))
                            this.setState({ isAuthenticating: false })
                        }
                    }).catch(e => {
                        console.log('Failed to grab user: ' + e.message)
                    })
            } else {
                this.setState({ isAuthenticating: false })
            }
        })
    }
}

export default connect()(App)
