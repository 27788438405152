import * as shippingActions from '../../actions/shippingActions';
import { put, all } from 'redux-saga/effects';
import * as notifications from '../../actions/notificationsActions';
import { extractSubmitResult } from '../../../amazon/MWSExtract';
import * as Sentry from '@sentry/react';
import { inboundShipments } from '../../../utils/project_api';
import {
    feeds,
    fulfillmentInbound,
} from '../../../utils/project_api/selling_partner';
import { extractTransportContent } from '../../../utils/project_api/selling_partner/utils';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Helper saga function responsible for updating the shipment status in the local
 * state.
 *
 * @param {string} shipmentId
 * @param {Object} data status data
 */
function* updateStatus(shipmentId, data) {
    yield put(
        shippingActions.updateShipment(shipmentId, {
            LatestFeedInfo: data,
        })
    );
}

/**
 * Saga function responsible for submitting the shipment to Seller Central using the
 * FBA Inbound Shipment Carton Information Feed. If the optional parameter 'needsUpdating' is set
 * to true, the putTransportDetails operation from SP API will be called before submitting. It's necessary if the
 * shipment was modified in any way.
 *
 * @param {Object} actionData
 */
export function* submitShipment(actionData) {
    let { shipment, needsUpdating } = actionData;

    // Remove all unfinished boxes. Box is considered finished if its dimensions are set.
    shipment.Boxes = shipment.Boxes.filter(
        box =>
            box.weight > -1 &&
            box.length > -1 &&
            box.width > -1 &&
            box.height > -1
    );

    yield all([
        // Update the shipment locally
        put(
            shippingActions.updateShipment(shipment.ShipmentId, {
                LatestFeedResult: null,
                LastSubmitted: new Date().getTime(),
                TransportStatus: null,
                Estimate: null,
            })
        ),
        put(
            notifications.enqueueSnackbar({
                message: 'Submitted',
                options: { variant: 'info' },
            })
        ),
    ]);
    try {
        if (needsUpdating) {
            yield fulfillmentInbound.putTransportDetailsPartneredSmallParcelData(
                shipment.ShipmentId,
                shipment.Boxes
            );
        }

        // Submit the shipment and extract the status of the feed
        let result = yield inboundShipments.post(
            'submitContents',
            {},
            {
                data: {
                    shipments: [shipment],
                },
            }
        );

        let submitInfo = yield feeds.getFeed({
            feedId: result.result.feedId,
            devMode: false,
        });

        // Repeat the process of calling the getFeed operation until the feed
        // processing is finished
        while (
            submitInfo.processingStatus !== 'DONE' &&
            submitInfo.processingStatus !== 'CANCELLED' &&
            submitInfo.processingStatus !== 'FATAL'
        ) {
            yield updateStatus(shipment.ShipmentId, submitInfo);
            yield sleep(25000);
            submitInfo = yield feeds.getFeed({
                feedId: result.result.feedId,
                devMode: false,
            });
        }

        // Retrieve the status of the submitted feed and possible errors
        let feedSubmissionResult = yield feeds.downloadReport({
            feedDocumentId: submitInfo.resultFeedDocumentId,
            devMode: false,
        });

        feedSubmissionResult = extractSubmitResult(feedSubmissionResult);
        yield updateStatus(shipment.ShipmentId, submitInfo);
        yield all([
            put(
                shippingActions.updateShipment(shipment.ShipmentId, {
                    LatestFeedResult: {
                        ...feedSubmissionResult,
                        Timestamp: new Date(),
                    },
                })
            ),
            put(shippingActions.saveShipments([shipment.ShipmentId])),
        ]);
    } catch (e) {
        console.log(e);
        Sentry.withScope(function (scope) {
            scope.setContext('submitShipment', {
                shipmentId: shipment.ShipmentId,
            });
            Sentry.captureException(e);
        });
        yield all([
            updateStatus(shipment.ShipmentId, null),
            put(
                notifications.enqueueSnackbar({
                    message: `Submitting failed! \n${e.message}`,
                    options: {
                        variant: 'error',
                    },
                })
            ),
        ]);
    }
}

/**
 * Saga function responsible for sending detailed information about boxes to Seller Central.
 * It uses the estimateTransport and the putTransportDetails operations.
 *
 * @param {Object} actionData
 */
export function* putContent(actionData) {
    let { shipment } = actionData;

    // Remove all unfinished boxes. Box is considered finished if its dimensions are set.
    shipment.Boxes = shipment.Boxes.filter(
        box =>
            box.weight > -1 &&
            box.length > -1 &&
            box.width > -1 &&
            box.height > -1
    );

    yield all([
        put(
            shippingActions.addLoadingShipment(
                shipment.ShipmentId,
                'Sending content'
            )
        ),
        put(
            notifications.enqueueSnackbar({
                message: 'Sending content',
                options: { variant: 'info' },
            })
        ),
    ]);
    try {
        // Call the PutTransportContent operation and wait 1 second
        yield fulfillmentInbound.putTransportDetailsPartneredSmallParcelData(
            shipment.ShipmentId,
            shipment.Boxes,
            false
        );
        yield sleep(1000);
        // Call the EstimateTransportRequest operation and wait 1 second
        yield fulfillmentInbound.estimateTransport({
            shipmentId: shipment.ShipmentId,
            devMode: false,
        });
        yield sleep(1000);

        // Check if the cost of the shipment is estimated
        let transportContent = extractTransportContent(
            yield fulfillmentInbound.getTransportDetails({
                shipmentId: shipment.ShipmentId,
                devMode: false,
            })
        );

        // Repeat the process until an error occurs or the cost is estimated
        while (
            !(transportContent.Estimate && transportContent.Estimate.Amount) &&
            transportContent.Status !== 'ERROR_ON_ESTIMATING'
        ) {
            yield sleep(1000);
            transportContent = extractTransportContent(
                yield fulfillmentInbound.getTransportDetails({
                    shipmentId: shipment.ShipmentId,
                    devMode: false,
                })
            );
        }

        yield all([
            // Update the shipment locally
            put(
                shippingActions.updateShipment(shipment.ShipmentId, {
                    Estimate: transportContent.Estimate,
                    PackageList: transportContent.PackageList,
                    TransportStatus: transportContent.Status,
                })
            ),
            put(shippingActions.deleteLoadingShipment(shipment.ShipmentId)),
        ]);
    } catch (e) {
        Sentry.withScope(function (scope) {
            scope.setContext('putContent', {
                shipmentId: shipment.ShipmentId,
            });
            Sentry.captureException(e);
        });
        yield all([
            put(
                notifications.enqueueSnackbar({
                    message: `Submitting failed! \n${e.message}`,
                    options: {
                        variant: 'error',
                    },
                })
            ),
            put(shippingActions.deleteLoadingShipment(shipment.ShipmentId)),
            put(
                shippingActions.updateShipment(shipment.ShipmentId, {
                    PutContentError: e.message,
                })
            ),
        ]);
    }
    yield put(shippingActions.saveShipments([shipment.ShipmentId]));
}
