import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import PropTypes from 'prop-types';
import React from 'react';
import BoxCard from './BoxCard';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 10,
        paddingBottom: 0,
    },
    progressContainer: {
        flex: 1,
        height: 15,
        marginTop: 10,
    },
    progress: {
        width: '100%',
        alignSelf: 'bottom',
    },
}));

export default function RejectedShipmentsBox(props) {
    const classes = useStyles();
    const { rejectedShipments, onRefresh, loading } = props;

    return (
        <Box
            display='flex'
            flexDirection='column'
            width='100%'
            className={classes.container}
        >
            <Box
                flexDirection='row'
                display='flex'
                style={{ justifyContent: 'space-between' }}
            >
                <Typography variant='h5'>Rejected shipments:</Typography>
                <Button
                    onClick={onRefresh}
                    color='primary'
                    style={{ margin: 2 }}
                    variant='contained'
                >
                    TRY AGAIN
                </Button>
            </Box>
            <GridList cellHeight='auto' cols={3}>
                {rejectedShipments.map(box => (
                    <GridListTile key={box.ID}>
                        <BoxCard data={box} buttonFree={true} />
                    </GridListTile>
                ))}
            </GridList>

            <Box className={classes.progressContainer}>
                {loading ? (
                    <LinearProgress className={classes.progress} />
                ) : null}
            </Box>
        </Box>
    );
}

RejectedShipmentsBox.propTypes = {
    rejectedShipments: PropTypes.array,
    onRefresh: PropTypes.func,
    loading: PropTypes.bool,
};
