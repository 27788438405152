import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loginSuccess } from '../../store/actions/authActions'
import { passCredentials } from '../../store/actions/dbActions'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';




const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: 40
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        marginTop: theme.spacing(4),
    }
});


class SignIn extends Component {
    state = {
        email: '',
        password: '',
        errorMsg: null,
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            errorMsg: null
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        firebase.auth().signInWithEmailAndPassword(
            this.state.email,
            this.state.password
        ).then((resp) => {
            firebase.firestore().collection('users').doc(resp.user.uid)
                .get()
                .then((snapshot) => {
                    if (!snapshot.empty) {
                        var userData = {
                            subscription: snapshot.data().subscription,
                            credentials: {
                                token: snapshot.data().auth_token,
                                sellerID: snapshot.data().seller_id,
                                keepaKey: snapshot.data().keepa_key
                            }
                        }

                        this.props.dispatch(loginSuccess({ info: resp.user }))
                        this.props.dispatch(passCredentials(userData))
                    }
                }).catch(e => {
                    console.log('Failed to grab user: ' + e.message)
                })
        }).catch((err) => {
            console.log(err.message)
            this.setState({ errorMsg: err.message })
        })
    }

    render() {
        const { classes, updateTitle, auth } = this.props;
        const link = React.forwardRef((props, ref) => <div ref={ref}><NavLink {...props} /></div>)
        if (auth) {
            updateTitle('Home')
            return <Redirect to='/' />
        }
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link href="#" variant="body2" component={link} to='/signup'>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                    <Typography className={classes.error} color='error'>
                        {this.state.errorMsg}
                    </Typography>
                </div>
            </Container>
        )
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info
    }
}

export default connect(mapStateToProps)(withStyles(styles)(SignIn))
