import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Dropzone from './elements/Dropzone'
import Progress from './elements/Progress'
import './ProductImport.css'

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

class ProductImport extends Component {
    state = {
        currColl: '',
    }

    constructor(props) {
        super(props);
        this.state = {
          files: [],
          uploading: false,
          uploadProgress: {},
          successfullUploaded: false
        };
    
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }
  
    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;

        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container>
                <Box display="flex" flexDirection="row"  className={classes.container}  justifyContent="center" alignItems="center">
                        <TextField
                            id="currColl"
                            label="Collection"
                            type="text"
                            className={classes.textField}
                            value={this.state.currColl}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                        />
                </Box>
                <Box display="flex" flexDirection="row"  className={classes.container}  justifyContent="center" alignItems="center">
                <div className="Upload">
                    <span className="Title">Upload Files</span>
                    <div className="Content">
                        <div>
                            <Dropzone
                            onFilesAdded={this.onFilesAdded}
                            disabled={this.state.uploading || this.state.successfullUploaded}
                            />
                        </div>
                        <div className="Files">
                            {this.state.files.map(file => {
                            return (
                                <div key={file.name} className="Row">
                                <span className="Filename">{file.name}</span>
                                {this.renderProgress(file)}
                                </div>
                            );
                            })}
                        </div>
                    </div>
                    <div className="Actions">{this.renderActions()}</div>
                </div>
                </Box>
            </Container>
        );
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }


    renderActions() {
        if (this.state.successfullUploaded) {
          return (
            <button
              className="upload-files-action-buttons"
              onClick={() =>
                this.setState({ files: [], successfullUploaded: false })
              }
            >
              Clear
            </button>
          );
        } else {
          return (
            <button
              className="upload-files-action-buttons"
              disabled={this.state.files.length < 0 || this.state.uploading}
              onClick={this.uploadFiles}
            >
              Upload
            </button>
          );
        }
    }
    onFilesAdded(files) {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }));
    }
    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullUploaded) {
          return (
            <div className="ProgressWrapper">
              <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
              <img
                className="CheckIcon"
                alt="done"
                src="baseline-check_circle_outline-24px.svg"
                style={{
                  opacity:
                    uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
                }}
              />
            </div>
          );
        }
    }
    async uploadFiles() {
        this.setState({ uploadProgress: {}, uploading: true });
        try {
            console.log('Sending files!')
            await this.sendFiles(this.state.files[0], this.state.files[1]);
      
             this.setState({ successfullUploaded: true, uploading: false });
        } catch (e) {
            // Not Production ready! Do some error handling here instead...
            this.setState({ successfullUploaded: true, uploading: false });
        }
    }
    sendRequest(file) {
        return new Promise((resolve, reject) => {
         const req = new XMLHttpRequest();
       
         req.upload.addEventListener("progress", event => {
          if (event.lengthComputable) {
           const copy = { ...this.state.uploadProgress };
           copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
           };
           this.setState({ uploadProgress: copy });
          }
         });
          
         req.upload.addEventListener("load", event => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "done", percentage: 100 };
          this.setState({ uploadProgress: copy });
          resolve(req.response);
         });
          
         req.upload.addEventListener("error", event => {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "error", percentage: 0 };
          this.setState({ uploadProgress: copy });
          reject(req.response);
         });
       
         const formData = new FormData();
         formData.append("file", file, file.name);
       
         req.open("POST", "https://localhost:3001/upload?collection=test");
         req.send(formData);
        });
    }
    sendFiles(file1, file2) {
        return new Promise((resolve, reject) => {
         const req = new XMLHttpRequest();
       
         req.upload.addEventListener("progress", event => {
          if (event.lengthComputable) {
           const copy = { ...this.state.uploadProgress };
           copy[file1.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
           };
           this.setState({ uploadProgress: copy });
          }
         });
          
         req.upload.addEventListener("load", event => {
          const copy = { ...this.state.uploadProgress };
          copy[file1.name] = { state: "done", percentage: 100 };
          this.setState({ uploadProgress: copy });
          resolve(req.response);
         });
          
         req.upload.addEventListener("error", event => {
          const copy = { ...this.state.uploadProgress };
          copy[file1.name] = { state: "error", percentage: 0 };
          this.setState({ uploadProgress: copy });
          reject(req.response);
         });
         console.log('File1data: ' + file1.name)
         console.log('File2data: ' + file2.name)
         const formData = new FormData();
         formData.append("keepa", file1, file1.name);
         formData.append("shop", file2, file2.name);
       
         req.open("POST", "https://api.projectsuite.io/upload?collection=" + this.state.currColl);
         req.send(formData);
        });
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ProductImport))