import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
    card: {
        width: 400,
        margin: 10,
        background: '#eceff1'
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    }
}));

export default function FulfillmentCenterDialog(props) {
    const classes = useStyles();
    const { opened, plans, onConfirm, onClose, loading } = props

    if (!plans) return null

    return (
        <Dialog
            open={opened}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
        >
            <DialogTitle id="alert-dialog-title">{"Create new shipment?"}</DialogTitle>
            <DialogContent>
                {plans.map(p => {
                    var q = 0
                    p.Items.forEach(item => {
                        q += item.Quantity
                    })
                    return (
                    <Card key={p.DestinationFulfillmentCenterId} className={classes.card}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box textAlign='center' fontWeight="fontWeightBold" marginBottom={3} fontSize={20}>
                                        {q} item(s) to {p.DestinationFulfillmentCenterId} Center
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box textAlign='right' fontWeight="fontWeightBold">Address:</Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box textAlign='left' marginLeft={1}>{p.ShipToAddress.AddressLine1}</Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box textAlign='right' fontWeight="fontWeightBold">City:</Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box textAlign='left' marginLeft={1}>{p.ShipToAddress.City}</Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box textAlign='right' fontWeight="fontWeightBold">Postal Code:</Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box textAlign='left' marginLeft={1}>{p.ShipToAddress.PostalCode}</Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box textAlign='right' fontWeight="fontWeightBold">State Code:</Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box textAlign='left' marginLeft={1}>{p.ShipToAddress.StateOrProvinceCode}</Box>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                )})}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Disagree
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Agree
                 </Button>
            </DialogActions>
            {loading ? <LinearProgress className={classes.progress} /> : null}
        </Dialog>
    );
}

FulfillmentCenterDialog.propTypes = {
    opened: PropTypes.bool,
    address: PropTypes.object,
    id: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    plans: PropTypes.array,
    loading: PropTypes.bool
};