import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import { Provider } from 'react-redux';
import './config/fbConfig';
import './components/common/common.scss';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './store/sagas/rootSaga';
import { SnackbarProvider } from 'notistack';
import CloseButton from './components/common/CloseButton';
import * as Sentry from "@sentry/react";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

Sentry.init({
    dsn: "https://abab778e7729422b9cf6f182f0ca3095@o254041.ingest.sentry.io/5497671",
  });

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            action={key => <CloseButton id={key} />}
        >
            <App />
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
