const initState = {
    authError: null,
    info: null,
}

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case 'LOGIN_SUCCESS':
            console.log('Login to Firebase successful!')
            // console.log(action.auth)
            return {
                authError: null,
                info: action.auth.info
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Sign-out from Firebase successful!')
            return {
                ...state,
                info: null
            }
        
        case 'SIGNUP_SUCCESS':
            console.log('Sign-up to Firebase successful!')
            return {
                ...state,
                authError: null,
                info: action.auth.info
            }
        default:
            return state
    }
}

export default authReducer