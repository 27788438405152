import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { LinearProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import './common.scss';

export default function LoadingDialog({
    opened,
    onClose,
    loading,
    onEntered,
    title,
    children,
    actions,
    className,
    style,
    ...props
}) {
    return (
        <Dialog
            open={opened}
            onClose={onClose}
            aria-labelledby='form-dialog-title'
            onEntered={onEntered}
            className={`loading-dialog ${className}`}
            style={style}
            {...props}
        >
            {title && (
                <DialogTitle id='form-dialog-title'>
                    {title}
                    <IconButton
                        aria-label='Close'
                        className='close-button'
                        style={{
                            position: 'absolute',
                            right: '0.4em',
                            top: '0.4em',
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>
            <DialogActions>{actions}</DialogActions>
            {loading ? (
                <LinearProgress className='linear-progress-bar' />
            ) : null}
        </Dialog>
    );
}
