import React from 'react';
import { Button } from '@material-ui/core';
import LoadingDialog from '../../common/LoadingDialog';

export default function BrandRestrictedDialog({
    opened,
    onClose,
    loading,
    onConfirm,
}) {
    return (
        <LoadingDialog
            onClose={onClose}
            opened={opened}
            classes={{
                paper: 'notification-dialog',
            }}
            loading={loading}
            actions={
                <>
                    <Button onClick={onClose} color='secondary'>
                        Go Back
                    </Button>
                    {onConfirm && (
                        <Button color='primary' onClick={onConfirm}>
                            Continue Anyway
                        </Button>
                    )}
                </>
            }
            title={'Brand restricted'}
        >
            <p className='dialog-message' style={{ fontSize: 24 }}>
                This brand is restricted from being sent in. If you believe
                you've received this message in error, please message this item
                in the #items channel.
            </p>
        </LoadingDialog>
    );
}
