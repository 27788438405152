import { Box, Divider, LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    list: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        backgroundColor: theme.palette.background.paper,
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    }
}));

export default function SelectStaffMemberDialog(props) {
    const classes = useStyles();
    const { onClose, opened, shopperList, onSelectShopper, loading } = props;

    return (
        <Dialog open={opened} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth='md' >
            <DialogTitle id="alert-dialog-title">
                {"Choose your shopper"}
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box justifyContent='center' alignItems='center' display='flex' style={{ maxHeight: '100%', maxWidth: '100%', overflowY: 'auto', padding: 10, minWidth: 400 }}>
                    <List className={classes.list}>
                        {shopperList.map((shopper, i) => (
                            <Box key={shopper.id}>
                                <ListItem onClick={() => { return onSelectShopper(shopper) }} button>
                                    <Typography variant='h6'>
                                        {shopper.email}
                                    </Typography>
                                </ListItem>
                                {i < shopperList.length - 1 ? <Divider variant="fullWidth" component="li" /> : null}
                                
                            </Box>

                        ))}
                    </List>
                </Box>
            </DialogContent>
            {loading ? <LinearProgress className={classes.progress} /> : null}
        </Dialog>
    );
}

SelectStaffMemberDialog.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    shopperList: PropTypes.array,
    onClose: PropTypes.func,
    onSelectShopper: PropTypes.func,
    loading: PropTypes.bool
};