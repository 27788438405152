import { Box, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    list: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        backgroundColor: theme.palette.background.paper,
    }
}));

export default function SelectPrinterDialog(props) {
    const classes = useStyles();
    const { onClose, opened, printers, onSelectPrinter, onLoadPrinters } = props;

    return (
        <Dialog open={opened} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth='md' >
            <DialogTitle id="alert-dialog-title">
                {"Choose your printer"}
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box justifyContent='center' alignItems='center' display='flex' style={{ maxHeight: '100%', maxWidth: '100%', overflowY: 'auto', padding: 10, minWidth: 400 }}>
                    <List className={classes.list}>
                        {printers.map((printer, i) => (
                            <Box key={printer}>
                                <ListItem onClick={() => { return onSelectPrinter(printer) }} button>
                                    <Typography variant='h6'>
                                        {printer}
                                    </Typography>
                                </ListItem>
                                {i < printers.length - 1 ? <Divider variant="fullWidth" component="li" /> : null}
                            </Box>
                        ))}
                    </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onLoadPrinters}>
                    Scan
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SelectPrinterDialog.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    printers: PropTypes.array,
    onClose: PropTypes.func,
    onSelectPrinter: PropTypes.func,
    loading: PropTypes.bool
};