
export function extractVoidShipment(response) {
    var data = typeof response === 'string' ? JSON.parse(response) : response;
    return data.VoidTransportRequestResponse.VoidTransportRequestResult.TransportResult.TransportStatus;
}

export function extractConfirmShipment(response) {
    var data = typeof response === 'string' ? JSON.parse(response) : response;
    return data.ConfirmTransportRequestResponse.ConfirmTransportRequestResult.TransportResult.TransportStatus;
}

export function extractTransportContent(response) {
    console.log(response);
    var data = typeof response === 'string' ? JSON.parse(response) : response;
    var transportContent = data.GetTransportContentResponse.GetTransportContentResult.TransportContent;
    var transportDetails = transportContent.TransportDetails;
    var status = transportContent.TransportResult.TransportStatus;
    var estimate = transportDetails.PartneredSmallParcelData.PartneredEstimate;
    var packageList = transportDetails.PartneredSmallParcelData.PackageList.member;
    if (!Array.isArray(packageList)) packageList = [packageList];

    return {
        Status: status,
        Estimate: estimate,
        PackageList: packageList
    }
}

export function extractReportList(response) {
    var data = typeof response === 'string' ? JSON.parse(response) : response;
    if (data.ErrorResponse) {
        throw new Error(data.ErrorResponse.Error.Message);
    } else {
        return data.GetReportListResponse.GetReportListResult.ReportInfo;
    }
}

export function extractSubmitResult(response) {
    var data = typeof response === 'string' ? JSON.parse(response) : response;
    var report = data.AmazonEnvelope.Message.ProcessingReport

    var m = []
    var result = report.Result;
    if (report.Result) {
        var messages = Array.isArray(report.Result) ? report.Result : [report.Result];
        messages.forEach(message => {
            m.push({
                ResultCode: message.ResultCode,
                ResultDescription: message.ResultDescription
            })
        })
    }
    
    result = {
        StatusCode: report.StatusCode,
        Messages: m
    }

    return result
}

export function extractSubmissionList(response) {
    var data = typeof response === 'string' ? JSON.parse(response) : response;
    var info = data.GetFeedSubmissionListResponse.GetFeedSubmissionListResult.FeedSubmissionInfo;
    info.FeedSubmissionId = info.FeedSubmissionId.toString();
    return info;
}

export function extractSubmitFeed(response) {
    var data = typeof response === 'string' ? JSON.parse(response) : response;
    var info = data.SubmitFeedResponse.SubmitFeedResult.FeedSubmissionInfo;
    // var result = {
    //     id: info.FeedSubmissionId,
    //     status: info.FeedProcessingStatus
    // }
    info.FeedSubmissionId = info.FeedSubmissionId.toString();
    return info;
}

function extractPlan(member) {
    var { City, CountryCode, PostalCode, Name, AddressLine1, AddressLine2, StateOrProvinceCode } = member.ShipToAddress
    var address = {
        city: City,
        countryCode: CountryCode,
        postalCode: PostalCode,
        name: Name,
        address1: AddressLine1,
        address2: AddressLine2 ? AddressLine2 : '',
        stateCode: StateOrProvinceCode
    }

    var items = []
    if (Array.isArray(member.Items.member)) {
        member.Items.member.forEach(p => {
            items.push({
                FNSKU: p.FulfillmentNetworkSKU,
                SKU: p.SellerSKU,
                Quantity: p.Quantity
            })
        })
    } else {
        items.push({
            FNSKU: member.Items.member.FulfillmentNetworkSKU,
            SKU: member.Items.member.SellerSKU,
            Quantity: member.Items.member.Quantity
        })
    }

    return {
        ID: member.ShipmentId,
        FulfillmentCenterId: member.DestinationFulfillmentCenterId,
        Address: address,
        Items: items
        //ShipmentID: member.ShipmentId
    }
}

export function extractShipmentPlan(response) {
    var shipmentPlans = []
    var productData = JSON.parse(response)
    if (productData.ErrorResponse) {
        throw new Error(productData.ErrorResponse.Error.Message)
    } else if (productData.CreateInboundShipmentPlanResponse.CreateInboundShipmentPlanResult.Error !== undefined) {
        throw new Error(productData.CreateInboundShipmentPlanResponse.CreateInboundShipmentPlanResult.Error.Message)
    } else {
        var plans = productData.CreateInboundShipmentPlanResponse.CreateInboundShipmentPlanResult.InboundShipmentPlans
        if (plans === null) {
            throw new Error('Could not get the shipment plan')
        } else {
            console.log(plans)
            if (Array.isArray(plans.member)) {
                plans.member.forEach(p => {
                    shipmentPlans.push(extractPlan(p))
                })
            } else {
                shipmentPlans.push(extractPlan(plans.member))
            }
        }
    }

    return shipmentPlans
}

export function extractSKU(product, response) {
    var productData = JSON.parse(response)
    if (productData.GetMyPriceForASINResponse.GetMyPriceForASINResult.Error !== undefined) {
        product.isError = true;
        product.ErrorCode = productData.GetMyPriceForASINResponse.GetMyPriceForASINResult.Error.Code;
        product.ErrorMsg = productData.GetMyPriceForASINResponse.GetMyPriceForASINResult.Error.Message;
    } else if (productData.GetMyPriceForASINResponse.GetMyPriceForASINResult.Product.Offers.Offer.SellerSKU === null) {
        product.isError = true
        product.ErrorMsg = 'Could not extract SKU value'
    } else {
        product.isError = false;
        product.SKU = productData.GetMyPriceForASINResponse.GetMyPriceForASINResult.Product.Offers.Offer.SellerSKU
    }

    return product
}

export function extractGetMatchingProductForId(response) {
    var products = []
    var product = {}
    var productData = JSON.parse(response);
    console.log("(MATCHING) Product Data: " + JSON.stringify(productData))
    if (productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Error !== undefined) {
        product.isError = true;
        product.ErrorCode = productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Error.Code;
        product.ErrorMsg = productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Error.Message;
        products.push(product)
    } else {
        product.isError = false;
        if (!Array.isArray(productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Products.Product)) {
            product = extractGetMatchingProductForIdHelper(productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Products.Product);
            products.push(product)
        } else {
            products = extractMultiProd(productData)
        }
    }
    return products;
}

export function extractMultiProd(productData) {
    var products = []
    var prodCount = productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Products.Product.length;
    for (var i = 0; i < prodCount; i++) {
        products.push(extractGetMatchingProductForIdHelper(productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Products.Product[i]));
    }
    return products;
}

function extractGetMatchingProductForIdHelper(productData) {
    var product = {}
    product.ASIN = productData.Identifiers.MarketplaceASIN.ASIN;
    product.ParentASIN = productData.Relationships.VariationParent != null ? productData.Relationships.VariationParent.Identifiers.MarketplaceASIN.ASIN : 'none';

    var productAttrs = productData.AttributeSets["ns2:ItemAttributes"];
    product.Name = productAttrs["ns2:Title"];
    product.Brand = productAttrs["ns2:Brand"];
    if (product.Name !== undefined) {
        product.Name = product.Name.replace("&quot;", '"').replace("&amp; ", "")
    }
    if (productAttrs["ns2:ListPrice"] != null) {
        product.MSRP = productAttrs["ns2:ListPrice"]["ns2:Amount"];
    }
    product.ImgURL = productAttrs["ns2:SmallImage"]["ns2:URL"];
    product.ImgURL = product.ImgURL.replace("_SL75_", "_SL500_");
    if (productAttrs["ns2:PackageDimensions"] != null) {
        var dims = productAttrs["ns2:PackageDimensions"];
        product.Dimensions = {
            Width: dims["ns2:Width"] != null ? parseFloat(dims["ns2:Width"]["#text"]).toFixed(2) : -2,
            Height: dims["ns2:Height"] != null ? parseFloat(dims["ns2:Height"]["#text"]).toFixed(2) : -2,
            Length: dims["ns2:Length"] != null ? parseFloat(dims["ns2:Length"]["#text"]).toFixed(2) : -2,
            Weight: dims["ns2:Weight"] != null ? parseFloat(dims["ns2:Weight"]["#text"]).toFixed(2) : -2,
            WeightUnits: dims["ns2:Weight"] != null ? dims["ns2:Weight"]["@_Units"] : -2,
        }
        setSizeTier(product);
    }
    product.Color = productAttrs["ns2:Color"] != null ? productAttrs["ns2:Color"] : 'Unknown'
    product.Size = productAttrs["ns2:Size"] != null ? productAttrs["ns2:Size"] : 'Unknown'

    var rank = productData.SalesRankings.SalesRank;
    product.SalesRank = (rank != null ? (rank[0] != null ? rank[0].Rank : rank.Rank) : -1);
    return product;
}

function setSizeTier(product) {
    var dims = [
        parseFloat(product.Dimensions.Width),
        parseFloat(product.Dimensions.Height),
        parseFloat(product.Dimensions.Length)
    ];
    dims.sort(function (a, b) { return a - b });

    var weight = product.Dimensions.Weight;

    if (weight !== -1 && dims[2] > 0) {
        //Small standard-size
        if ((weight <= 12 && product.Dimensions.WeightUnits === "ounces") || (weight <= 0.75 && product.Dimensions.WeightUnits === "pounds")) {
            if (dims[2] <= 15 && dims[1] <= 12 && dims[0] <= 0.75) {
                product.SizeTier = "Small";
                return;
            }
        }

        //Large standard-size
        if (weight <= 20 && product.Dimensions.WeightUnits === "pounds") {
            if (dims[2] <= 18 && dims[1] <= 14 && dims[0] <= 8) {
                product.SizeTier = "Large";
                return;
            }
        }

        //Small oversize
        var girth = parseFloat((parseFloat(dims[0]) + parseFloat(dims[1])) * 2);
        // console.log('Girth: ' + girth);
        if (weight <= 70 && product.Dimensions.WeightUnits === "pounds") {
            if (dims[2] <= 60 && dims[1] <= 30 && (dims[2] + girth) <= 130) {
                product.SizeTier = "Small oversize";
                return;
            }
        }

        //Medium overisze
        if (weight <= 150 && product.Dimensions.WeightUnits === "pounds") {
            if (dims[2] <= 108 && (dims[2] + girth) <= 130) {
                product.SizeTier = "Medium oversize";
                return;
            }
        }

        //Large oversize
        if (weight <= 150) {
            if ((dims[2] + girth) <= 165) {
                product.SizeTier = "Large oversize";
                return;
            }
        }

        product.SizeTier = "Special oversize";
        return;
    } else {
        product.SizeTier = 'Unspecified';
        return;
    }

}


export function extractGetMatchingProductForIdParent(product, response) {
    var productData = JSON.parse(response);
    console.log("(PARENT) Product Data: " + JSON.stringify(productData));
    if (productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Error !== undefined) {
        product.isError = true;
        product.ErrorCode = productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Error.Code;
        product.ErrorMsg = productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Error.Message;
    } else {
        product.isError = false;

        var childVars = productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Products.Product.Relationships["ns2:VariationChild"] != null ? productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Products.Product.Relationships["ns2:VariationChild"] : 'none';
        var currVars = []
        if (childVars !== 'none' && Array.isArray(childVars)) {
            for (var i = 0; i < childVars.length; i++) {
                var currVar = childVars[i];
                var variation = {
                    ASIN: currVar.Identifiers.MarketplaceASIN.ASIN,
                    Color: currVar["ns2:Color"] != null ? currVar["ns2:Color"] : 'Unknown',
                    Size: currVar["ns2:Size"] != null ? currVar["ns2:Size"] : 'Unknown',
                    ImgURL: '',
                };
                currVars.push(variation)
            }
            product.Vars = currVars;
        }

        if (product.SalesRank === -1) {
            var rank = productData.GetMatchingProductForIdResponse.GetMatchingProductForIdResult.Products.Product.SalesRankings.SalesRank
            product.SalesRank = (rank != null ? (rank[0] != null ? rank[0].Rank : rank.Rank) : -1);
        }
    }
    return product;
}

export function extractGetMyFeesEstimate(product, response) {
    var productData = JSON.parse(response);
    // console.log("(FEES) Product Data: " + JSON.stringify(productData));
    if (productData.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.FeesEstimateResultList.FeesEstimateResult.Error !== undefined) {
        product.isError = true;
        product.ErrorCode = productData.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.FeesEstimateResultList.FeesEstimateResult.Error.Code;
        product.ErrorMsg = productData.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.FeesEstimateResultList.FeesEstimateResult.Error.Message;
    } else if (productData.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.Error !== undefined) {
        product.isError = true;
        product.ErrorCode = productData.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.Error.Code;
        product.ErrorMsg = productData.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.Error.Message;
    } else {
        product.isError = false;
        product.Fee = productData.GetMyFeesEstimateResponse.GetMyFeesEstimateResult.FeesEstimateResultList.FeesEstimateResult.FeesEstimate.TotalFeesEstimate.Amount;
    }
    return product;
}
