import { put, all } from 'redux-saga/effects';
import * as shippingActions from '../../actions/shippingActions';
import * as notifications from '../../actions/notificationsActions';
import { inboundShipments } from '../../../utils/project_api';
import * as Sentry from '@sentry/react';

/**
 * Saga function responsible for deleting an item from a shipment. It makes a simple
 * request to the server which handles the rest.
 *
 * @param {Object} actionData
 */
export function* deleteShipmentItemSaga(actionData) {
    let { shipmentId, sku } = actionData;

    try {
        let result = yield inboundShipments.get(
            'deleteItem',
            { shipmentId, sku },
            {}
        );
        yield all([
            put(
                shippingActions.updateShipment(
                    shipmentId,
                    {
                        LastUpdated: new Date().getTime(),
                    },
                    true
                )
            ),
            // Passing no arguments, sets the deleted item in the state to null
            put(shippingActions.setDeletedShipmentItem()),
            // The request returns the updated list of expected items
            put(shippingActions.setExpectedItems(shipmentId, result.result)),
            // The item also needs to be deleted from all boxes in this shipment
            put(shippingActions.deleteShipmentItemLocal(shipmentId, sku)),
            put(shippingActions.saveShipments([shipmentId])),
            put(
                notifications.enqueueSnackbar({
                    message: 'Item deleted',
                    options: { variant: 'success' },
                })
            ),
        ]);
    } catch (e) {
        console.log(e);
        Sentry.withScope(function (scope) {
            scope.setContext('deleteShipmentItem', {
                shipmentId,
            });
            Sentry.captureException(e);
        });
        yield all([
            // Passing no arguments, sets the deleted item in the state to null
            put(shippingActions.setDeletedShipmentItem()),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}
