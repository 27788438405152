import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';


const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    }
}));

export default function ConfirmActionDialog(props) {
    const classes = useStyles();
    const { onClose, opened, message, onConfirm, loading } = props;

    return (
        <Dialog open={opened} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth='md' >
            <DialogTitle id="alert-dialog-title">{message}</DialogTitle>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    No
                </Button>
                <Button onClick={onConfirm} color="secondary">
                    Yes
                </Button>
            </DialogActions>
            {loading ? <LinearProgress className={classes.progress} /> : null}
        </Dialog>
    );
}

ConfirmActionDialog.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    box: PropTypes.object,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    loading: PropTypes.bool
};