import React, { useEffect } from 'react';
import {
    Button,
    GridList,
    GridListTile,
    withWidth,
    IconButton,
    Checkbox,
} from '@material-ui/core';
import { utils } from './shipments';
import EditBoxDialog from './EditBoxDialog';
import { isWidthUp } from '@material-ui/core/withWidth';
import MoveItemDialog from './MoveItemDialog';
import ShipmentInfoBanner from './ShipmentInfoBanner';
import ConfirmBoxesInfoDialog from './ConfirmBoxesInfoDialog';
import ShippingChargesDialog from './ShippingChargesDialog';
import { useDispatch, batch } from 'react-redux';
import * as actions from '../../store/actions/shippingActions';
import * as notifications from '../../store/actions/notificationsActions';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const _ = require('lodash');

/**
 * Displays a single box's data. Also allows to view added products and modify dimensions.
 * @param {Object} props - Component props.
 * @param {function} props.onClick - Callback function executed when a button allowing to view
 * added products is clicked.
 * @param {Object} props.box - Object with all box's data.
 * @param {function} props.addDimensions - Callback function executed when a button allowing to
 * edit box's dimensions is clicked.
 * @param {Object} props.shipment - Object with information about this box's shipment.
 */
function Box({ onClick, box, addDimensions, shipment }) {
    const dispatch = useDispatch();
    let className = 'box-tile-container';
    const getValue = value => (value > -1 ? value : '--');
    let weight = getValue(box.weight);
    let length = getValue(box.length);
    let width = getValue(box.width);
    let height = getValue(box.height);

    const showWarningSnackbar = () => {
        dispatch(
            notifications.enqueueSnackbar({
                message: "This shipment is confirmed. You can't modify it.",
                options: { variant: 'warning' },
            })
        );
    };

    return (
        <div className={className} key={box.id}>
            <IconButton
                className='delete-icon'
                onClick={() => {
                    if (shipment.TransportStatus === 'CONFIRMED') {
                        showWarningSnackbar();
                    } else {
                        dispatch(actions.confirmBoxDeletion(box.id));
                    }
                }}
            >
                <DeleteIcon />
            </IconButton>
            <Checkbox
                className='checkbox'
                color='default'
                checked={shipment.CurrentBox === box.id}
                onChange={event => {
                    if (event.target.checked) {
                        dispatch(
                            actions.updateShipment(
                                shipment.ShipmentId,
                                {
                                    CurrentBox: box.id,
                                },
                                true
                            )
                        );
                    }
                }}
            />
            <div className='title-container'>
                <p>Box {box.number}</p>
            </div>
            <div className='stats-container'>
                <p>{utils.calcBoxSize(box)} Units</p>
                <p>{box.items.length} Skus</p>
            </div>
            <div className='box-info-container'>
                <p>{weight} lbs</p>
                <p>
                    {length} <span>x</span> {width} <span>x</span> {height}
                </p>
            </div>
            <div className='actions-container'>
                <Button
                    onClick={() => {
                        if (utils.calcBoxSize(box) > 0) {
                            onClick(box);
                        }
                    }}
                    variant='outlined'
                    className='box-button'
                >
                    View
                </Button>
                <Button
                    onClick={() => {
                        if (shipment.TransportStatus === 'CONFIRMED') {
                            showWarningSnackbar();
                        } else if (utils.calcBoxSize(box) > 0) {
                            addDimensions(box);
                        }
                    }}
                    variant='outlined'
                    className='box-button'
                >
                    Dimensions
                </Button>
            </div>
        </div>
    );
}

/**
 * Displays a list of boxes belonging to the given shipment. Also dipslays basic information about the given
 * shipment and dialogs to edit a box (EditBoxDialog), move items between boxes (MoveItemDialog), view
 * boxes' data before confirming the shipment in Seller Central (ConfirmBoxesInfoDialog) and view charges
 * (ShippingChargesDialog).
 * @param {Object} props
 */
function BoxList({
    shipment,
    addDimensions,
    selectBox,
    width,
    submit,
    putContent,
    loadingStatus,
    focusInput,
}) {
    const [dialogs, setDialogs] = React.useState({
        editBoxDialogOpened: false,
        moveItemsDialog: false,
        confirmBoxesDialogOpened: false,
        chargesDialogOpened: false,
    });
    const [viewedBox, setViewedBox] = React.useState(null);
    const [itemToMove, setItemToMove] = React.useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        let box = shipment
            ? shipment.Boxes.find(
                  b => b.id === (viewedBox ? viewedBox.id : null)
              )
            : null;
        setViewedBox(box);
    }, [shipment, viewedBox]);

    let boxes = shipment ? shipment.Boxes : [];

    const getGridListCols = () => {
        if (isWidthUp('xl', width)) return 4;
        if (isWidthUp('lg', width)) return 3;
        if (isWidthUp('md', width)) return 2;
        return 1;
    };

    const addBox = () => {
        if (shipment.TransportStatus === 'CONFIRMED') {
            dispatch(
                notifications.enqueueSnackbar({
                    message: "This shipment is confirmed. You can't modify it.",
                    options: {
                        variant: 'warning',
                    },
                })
            );
            return;
        }
        batch(() => {
            dispatch(actions.createBox(shipment.ShipmentId));
            dispatch(
                actions.updateShipment(
                    shipment.ShipmentId,
                    {
                        LastUpdated: new Date().getTime(),
                    },
                    true
                )
            );
            dispatch(actions.saveShipments([shipment.ShipmentId]));
        });
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                margin: '0.6em 0 0 0',
                position: 'relative',
                flexGrow: 1,
            }}
        >
            <ShipmentInfoBanner
                shipment={shipment}
                onConfirmBoxes={() =>
                    setDialogs({ ...dialogs, confirmBoxesDialogOpened: true })
                }
                status={loadingStatus}
                onSubmit={needsUpdating => submit(shipment, needsUpdating)}
                onChargesClicked={() =>
                    setDialogs({
                        ...dialogs,
                        chargesDialogOpened: true,
                    })
                }
            />

            <div className='box-grid-list-container'>
                <GridList cols={getGridListCols()} className='box-grid-list'>
                    {boxes.map(box => (
                        <GridListTile
                            key={box.id}
                            classes={{ tile: 'box-tile' }}
                        >
                            <Box
                                box={box}
                                onClick={box => {
                                    setViewedBox(box);
                                    setDialogs({
                                        ...dialogs,
                                        editBoxDialogOpened: true,
                                        moveItemsDialog: false,
                                    });
                                }}
                                addDimensions={addDimensions}
                                onSelect={selectBox}
                                shipment={shipment}
                            />
                        </GridListTile>
                    ))}
                    {utils.calcNonemptyBoxes(boxes) === boxes.length && (
                        <GridListTile key='plus-button' className='box-tile'>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                className='centered-container'
                            >
                                <IconButton
                                    onClick={addBox}
                                    style={{
                                        borderRadius: '1em',
                                    }}
                                    size='medium'
                                >
                                    <AddIcon style={{ fontSize: '3em' }} />
                                </IconButton>
                            </div>
                        </GridListTile>
                    )}
                </GridList>
            </div>

            <EditBoxDialog
                opened={dialogs.editBoxDialogOpened}
                box={viewedBox}
                shipment={shipment}
                onClose={() => {
                    setDialogs({
                        ...dialogs,
                        editBoxDialogOpened: false,
                        moveItemsDialog: false,
                    });
                }}
                onSave={products => {
                    if (!_.isEqual(viewedBox.items, products)) {
                        let filteredProducts = products.filter(
                            p => p.BoxQuantity > 0
                        );
                        batch(() => {
                            dispatch(
                                actions.updateBox(
                                    viewedBox.id,
                                    filteredProducts
                                )
                            );
                            dispatch(
                                actions.updateShipment(
                                    shipment.ShipmentId,
                                    {
                                        LastUpdated: new Date().getTime(),
                                    },
                                    true
                                )
                            );
                            dispatch(
                                actions.saveShipments([shipment.ShipmentId])
                            );
                        });
                    }
                    setDialogs({
                        ...dialogs,
                        editBoxDialogOpened: false,
                        moveItemsDialog: false,
                    });
                }}
                moveItem={item => {
                    setItemToMove(item);
                    batch(() => {
                        dispatch(actions.updateItem(viewedBox.id, item));
                        dispatch(
                            actions.updateShipment(
                                shipment.ShipmentId,
                                {
                                    LastUpdated: new Date().getTime(),
                                },
                                true
                            )
                        );
                        dispatch(actions.saveShipments([shipment.ShipmentId]));
                    });
                    setDialogs({
                        ...dialogs,
                        editBoxDialogOpened: false,
                        moveItemsDialog: true,
                    });
                }}
            />

            <MoveItemDialog
                opened={dialogs.moveItemsDialog}
                onClose={() => {
                    setDialogs({
                        ...dialogs,
                        editBoxDialogOpened: true,
                        moveItemsDialog: false,
                    });
                }}
                item={itemToMove}
                boxes={boxes
                    .filter(b => b.id !== (viewedBox ? viewedBox.id : null))
                    .map(b => {
                        return { id: b.id, number: b.number };
                    })}
                onMove={(selectedBoxId, item, quantity) => {
                    batch(() => {
                        dispatch(
                            actions.updateItem(viewedBox.id, item, -quantity)
                        );
                        dispatch(
                            actions.updateItem(
                                selectedBoxId,
                                { ...item, BoxQuantity: quantity },
                                quantity
                            )
                        );
                        dispatch(
                            actions.updateShipment(
                                shipment.ShipmentId,
                                {
                                    LastUpdated: new Date().getTime(),
                                },
                                true
                            )
                        );
                        dispatch(actions.saveShipments([shipment.ShipmentId]));
                        // focusInput();
                    });
                    setDialogs({
                        ...dialogs,
                        editBoxDialogOpened: true,
                        moveItemsDialog: false,
                    });
                }}
            />

            <ConfirmBoxesInfoDialog
                opened={dialogs.confirmBoxesDialogOpened}
                onClose={() => {
                    setDialogs({
                        ...dialogs,
                        confirmBoxesDialogOpened: false,
                    });
                }}
                boxes={boxes}
                onConfirm={() => {
                    putContent(shipment);
                    setDialogs({
                        ...dialogs,
                        confirmBoxesDialogOpened: false,
                    });
                }}
            />

            <ShippingChargesDialog
                opened={dialogs.chargesDialogOpened}
                shipment={shipment}
                onClose={() => {
                    setDialogs({
                        ...dialogs,
                        chargesDialogOpened: false,
                    });
                }}
            />
        </div>
    );
}

export default withWidth()(BoxList);
