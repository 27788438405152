import authReducer from './authReducer';
import dbReducer from './dbReducer';
import { combineReducers } from 'redux';
import { inboundShipmentsReducer } from './shippingReducer';
import { notificationsReducer } from './notificationsReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    db: dbReducer,
    inboundShipments: inboundShipmentsReducer,
    notifications: notificationsReducer,
});

export default rootReducer;
