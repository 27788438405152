import React, { useState } from 'react';
import { Button, List, ListItem, IconButton, ListItemText, CircularProgress, Grid, Typography } from '@material-ui/core';
import LoadingDialog from './LoadingDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { upcNotifs } from '../../utils/project_api';


export default function NotificationDialog({
    opened,
    onClose,
    notifications,
    loading,
    onConfirm,
    onNotificationDelete,
    onNotificationDeleteError,
    title
}) {
    const [isLoading, setIsLoading] = useState({})

    const deleteNotification = async (upc) => {
        try {
            setIsLoading({ ...isLoading, [upc]: true });
            let result = await upcNotifs.delete('delete', { upc }, {});
            if (onNotificationDelete) onNotificationDelete(upc)
        } catch (e) {
            if (onNotificationDeleteError) onNotificationDeleteError(e)
        }
        setIsLoading({ ...isLoading, [upc]: false });
    }

    return (
        <LoadingDialog
            onClose={onClose}
            opened={opened}
            classes={{
                paper: 'notification-dialog',
            }}
            loading={loading}
            actions={
                <>
                    <Button onClick={onClose} color='secondary'>
                        Go Back
                    </Button>
                    {onConfirm && (
                        <Button color='primary' onClick={onConfirm}>
                            Continue Anyway
                        </Button>
                    )}
                </>
            }
            title={title}
        >
            <List className="notifications-list">
                {
                    notifications.map(notif => (
                        <ListItem
                            key={notif.upc}
                            divider={true}
                        >
                            <Grid container direction='row' justifyContent='center' alignItems='center'>
                                <Grid xs={4}>
                                    <Typography>{notif.upc}</Typography>
                                </Grid>
                                <Grid xs={7}>
                                    <Typography >{notif.message}</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <div style={{ width: 50, position: 'relative' }}>
                                        {
                                            isLoading[notif.upc] &&
                                            <CircularProgress size={36} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -18, marginLeft: -18 }} />
                                        }
                                        <IconButton disabled={isLoading[notif.upc]} aria-label="delete" onClick={() => deleteNotification(notif.upc)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>

                                </Grid>
                            </Grid>
                        </ListItem>
                    ))
                }
            </List>
        </LoadingDialog>
    );
}
