import firebase from 'firebase';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signoutSuccess } from '../../store/actions/authActions';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from '@material-ui/core/Divider';


const NotifLinks = (props) => {
    let { onItemClick } = props;
    var signOut = () => {
        onItemClick("Project")()
        firebase.auth().signOut().then((resp) => { props.dispatch(signoutSuccess(resp)) })
    }

    return (
        <List>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/"
                onClick={onItemClick("Notifs")}
            >
                <ListItemText color="#000">Notifs</ListItemText>
            </ListItem>
            <Divider></Divider>
            <ListItem
                button
                onClick={signOut}
            >
                <ListItemText>Log out</ListItemText>
            </ListItem>
        </List>
    )
}

export default connect()(NotifLinks)