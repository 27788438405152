import React from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

export default function CloseButton({ id }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={() => closeSnackbar(id)}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );
}
