import { getToken } from '../auth';
import { DOMAIN } from './utils';
import { stringify } from 'qs';
import axios from 'axios';

/**
 * Creates a request call using axios. Takes all parts of the request and puts them together.
 * Additionally takes lists of available POST and GET actions in order to control the URl path.
 *
 * @param {string} serverRoute - the first part of the URL path.
 * For https://api.projectsuite.io/returns/getBoxes?<params> it would
 * be simply 'returns'.
 * @param {string} action - action which is in the second part of the URL path.
 * For https://api.projectsuite.io/returns/getBoxes?<params> it would be 'getBoxes'.
 * @param {string} method HTTP method
 * @param {Object} urlParams - object containing URL request parameters
 * @param {Object} methodParams - axios params
 */
async function call(serverRoute, action, method, urlParams, methodParams) {
    let token = await getToken();
    let domain = DOMAIN;
    if (methodParams && methodParams.domain) {
        domain = methodParams.domain;
    }
    urlParams.firebase_token = token;
    if (process.env.NODE_ENV === 'development') {
        urlParams.devMode = 1;
    }

    let url = `${domain}/${serverRoute ? serverRoute + '/' : ''}${action}`;

    let axiosObject = {
        url,
        method: method || 'get',
        params: urlParams,
        paramsSerializer: {
            serialize: params => stringify(params),
        },
    };

    if (methodParams) {
        let { onDownloadProgress, onUploadProgress, data, headers } =
            methodParams;
        if (onDownloadProgress)
            axiosObject.onDownloadProgress = onDownloadProgress;
        if (onUploadProgress) axiosObject.onUploadProgress = onUploadProgress;
        if (data) axiosObject.data = data;
        if (headers) axiosObject.headers = headers;
    }

    try {
        let response = await axios(axiosObject);
        return response.data;
    } catch (e) {
        throw new Error(e.response?.data?.error);
    }
}

/**
 * A wrapper for the call function. Calls the returns/ route.
 *
 * @param {string} method HTTP method
 * @param {string} action - action available for the returns/ route.
 * @param {Object} urlParams - object containing URL request parameters.
 * @param {Object} methodParams - axios params.
 */
async function callReturns(method, action, urlParams, methodParams) {
    return call('returns', action, method, urlParams || {}, methodParams || {});
}

/**
 * A wrapper for the call function. Calls the shipments/ route.
 *
 * @param {string} method HTTP method
 * @param {string} action - action available for the shipments/ route.
 * @param {Object} urlParams - object containing URL request parameters.
 * @param {Object} methodParams - axios params.
 */
async function callInboundShipments(method, action, urlParams, methodParams) {
    return call(
        'shipments',
        action,
        method,
        urlParams || {},
        methodParams || {}
    );
}

/**
 * A wrapper for the call function. Calls the root route.
 *
 * @param {string} method HTTP method
 * @param {string} action - action available for the root route.
 * @param {Object} urlParams - object containing URL request parameters.
 * @param {Object} methodParams - axios params.
 */
async function callSkuAsin(method, action, urlParams, methodParams) {
    return call(null, action, method, urlParams || {}, methodParams || {});
}

/**
 * A wrapper for the call function. Calls the upcNotifs/ route.
 *
 * @param {string} method HTTP method
 * @param {string} action - action available for the upcNotifs/ route.
 * @param {Object} urlParams - object containing URL request parameters.
 * @param {Object} methodParams - axios params.
 */
async function callUpcNotifs(method, action, urlParams, methodParams) {
    return call(
        'upcNotifs',
        action,
        method,
        urlParams || {},
        methodParams || {}
    );
}

/**
 * A wrapper for the call function. Calls the brandNotifs/ route.
 *
 * @param {string} method HTTP method
 * @param {string} action - action available for the upcNotifs/ route.
 * @param {Object} urlParams - object containing URL request parameters.
 * @param {Object} methodParams - axios params.
 */
async function callBrandNotifs(method, action, urlParams, methodParams) {
    return call(
        'brandNotifs',
        action,
        method,
        urlParams || {},
        methodParams || {}
    );
}

const returns = {
    get: (action, urlParams, methodParams) =>
        callReturns('get', action, urlParams, methodParams),
    post: (action, urlParams, methodParams) =>
        callReturns('post', action, urlParams, methodParams),
    call: callReturns,
};

const inboundShipments = {
    get: (action, urlParams, methodParams) =>
        callInboundShipments('get', action, urlParams, methodParams),
    post: (action, urlParams, methodParams) =>
        callInboundShipments('post', action, urlParams, methodParams),
    call: callInboundShipments,
};

const skuAsin = {
    get: (action, urlParams, methodParams) =>
        callSkuAsin('get', action, urlParams, methodParams),
    post: (action, urlParams, methodParams) =>
        callSkuAsin('post', action, urlParams, methodParams),
    call: callSkuAsin,
};

const upcNotifs = {
    get: (action, urlParams, methodParams) =>
        callUpcNotifs('get', action, urlParams, methodParams),
    post: (action, urlParams, methodParams) =>
        callUpcNotifs('post', action, urlParams, methodParams),
    delete: (action, urlParams, methodParams) =>
        callUpcNotifs('delete', action, urlParams, methodParams),
    call: callUpcNotifs,
};

const brandNotifs = {
    get: (action, urlParams, methodParams) =>
        callBrandNotifs('get', action, urlParams, methodParams),
    post: (action, urlParams, methodParams) =>
        callBrandNotifs('post', action, urlParams, methodParams),
    delete: (action, urlParams, methodParams) =>
        callBrandNotifs('delete', action, urlParams, methodParams),
    call: callBrandNotifs,
};

const SendSlack = text => {
    const bodyData = {
        text: text,
    };

    const body = JSON.stringify(bodyData);

    return axios.post(
        'https://hooks.slack.com/services/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',
        body
    );
};

export {
    returns,
    inboundShipments,
    skuAsin,
    upcNotifs,
    brandNotifs,
    SendSlack,
};

export * as spApi from './selling_partner';
