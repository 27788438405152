export const RU_NAME = 'Karol_Kobia_ka-KarolKob-Projec-truufxkcg';
const APP_ID = 'KarolKob-ProjectS-SBX-ac62ba413-be074cfc';
const DEV_ID = '79cd7c93-5858-4318-b8bc-7b787dd1640e';
const CERT_ID = 'SBX-c62ba4136f6e-f47b-42e0-ab22-2c0c';
const URL = 'https://api.sandbox.ebay.com/ws/api.dll';
const { create } = require('xmlbuilder2');
const axios = require('axios');

function parseToXML(json) {
    let doc = create({ encoding: "UTF-8" }, json);
    return doc.end();
}

function makeCall(body, headers, url) {
    var proxyUrl = 'https://proxy.projectsuite.io/'

    return axios.post(proxyUrl + url, body, { headers });
}

export function getSessionID() {
    let obj = {
        GetSessionIDRequest: {
            '@xmlns': 'urn:ebay:apis:eBLBaseComponents',
            RuName: RU_NAME
        }
    }

    let headers = {
        'X-EBAY-API-SITEID': 0,
        'X-EBAY-API-COMPATIBILITY-LEVEL': 967,
        'X-EBAY-API-APP-NAME': APP_ID,
        'X-EBAY-API-DEV-NAME': DEV_ID,
        'X-EBAY-API-CERT-NAME': CERT_ID,
        'X-EBAY-API-CALL-NAME': 'GetSessionID'
    }

    let url = URL;
    return makeCall(parseToXML(obj), headers, url);
}