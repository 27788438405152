import { Item } from '@scaleleap/selling-partner-api-sdk/lib/api-models/catalog-items-api-model-v20220401';
import { SizeTier, sizeTiers } from '../types';

export function extractGetCatalogItem(item: Item) {
    let ASIN = item.asin;

    let attributeSet = item.attributes as any;
    let Name = attributeSet?.item_name?.[0].value;
    let Brand = attributeSet?.brand?.[0].value;
    let MSRP = attributeSet?.list_price?.[0].value;


    // @ts-ignore
    let dims = item.dimensions?.[0].package;
    console.log('Item', item)
    let packageDims = attributeSet?.item_package_dimensions?.[0]
    let packageWeight = attributeSet?.item_package_weight?.[0]
    let Dimensions = {
        Width: dims?.width?.value || -1,
        Height: dims?.height?.value || -1,
        Length: dims?.length?.value || -1,
        Weight: dims?.weight?.value || -1,
        WeightUnits: dims?.weight?.unit || 'N/A',
    };
    let SizeTier = countSizeTier(
        Dimensions.Width,
        Dimensions.Length,
        Dimensions.Height,
        Dimensions.Weight,
        Dimensions.WeightUnits
    );


    let Color = attributeSet?.color?.[0].value;
    let Size = item.summaries?.[0].size;

    let SalesRank = item.salesRanks?.[0].classificationRanks?.[0]?.rank;

    let images = item.images?.[0].images || [];
    // Find the first main image and scale it to 500x500
    let mainImage = images.find((img) => img.variant === 'MAIN');
    let ImgURL = mainImage?.link || '';
    if (ImgURL.includes('__SL75__')) {
        ImgURL = ImgURL.replace('_SL75_', '_SL500_');
    } else {
        ImgURL = ImgURL.replace('.jpg', '._SL500_.jpg');
    }

    return {
        ASIN,
        Brand,
        Name,
        MSRP,
        ImgURL,
        Color,
        Size,
        SalesRank,
        SizeTier,
    };
}

function countSizeTier(
    width: number,
    length: number,
    height: number,
    weight: number,
    weightUnits: string
): SizeTier {
    let dims = [width, height, length];
    dims.sort((a, b) => a - b);

    if (weight === -1 || dims[2] <= 0) {
        return sizeTiers.UNSPECIFIED;
    }

    // Small standard-size
    if (
        ((weight <= 12 && weightUnits === 'ounces') ||
            (weight <= 0.75 && weightUnits === 'pounds')) &&
        dims[2] <= 15 &&
        dims[1] <= 12 &&
        dims[0] <= 0.75
    ) {
        return sizeTiers.SMALL;
    }

    // Large standard-size
    if (
        weight <= 20 &&
        weightUnits === 'pounds' &&
        dims[2] <= 18 &&
        dims[1] <= 14 &&
        dims[0] <= 8
    ) {
        return sizeTiers.LARGE;
    }

    // Small oversize
    let girth = (dims[0] + dims[1]) * 2;
    if (
        weight <= 70 &&
        weightUnits === 'pounds' &&
        dims[2] <= 60 &&
        dims[1] <= 30 &&
        dims[2] + girth <= 130
    ) {
        return sizeTiers.SMALL_OVERSIZE;
    }

    // Medium oversize
    if (
        weight <= 150 &&
        weightUnits === 'pounds' &&
        dims[2] <= 108 &&
        dims[2] + girth <= 130
    ) {
        return sizeTiers.MEDIUM_OVERSIZE;
    }

    // Large oversize
    if (weight <= 150 && dims[2] + girth <= 165) {
        return sizeTiers.LARGE_OVERSIZE;
    }

    return sizeTiers.SPECIAL_OVERSIZE;
}
