import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import CurrentShipmentList from './CurrentShipmentList';
import AllShipmentsList from './AllShipmentsList';
import FinishBoxDialog from './FinishBoxDialog';
import './BoxShipmentPage.scss';
import SubmitShipmentsDialog from './SubmitShipmentsDialog';
import { utils as shipmentUtils, utils } from './shipments';
import * as actions from '../../store/actions/shippingActions';
import * as notifications from '../../store/actions/notificationsActions';
import * as Sentry from '@sentry/react';
import BoxList from './BoxList';
import TextInput from '../common/TextInput';
import ProductDialog from './ProductDialog';
import ConfirmationDialog from '../common/ConfirmationDialog';

/**
 * Displays all components of the 'Shipping' page.
 */
function BoxShipmentPage() {
    const textInput = useRef(null);
    const [state, setNewState] = React.useState({
        input: '',
        submitShipmentsLoading: false,
        finishBoxDialogOpened: false,
        finishBoxDialogData: null,
        submitShipmentsDialogOpened: false,
        loading: false,
    });

    const setState = newState => setNewState({ ...state, ...newState });

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.info);
    const userData = useSelector(state => state.db.userData);
    const address = useSelector(state => state.db.address);
    const shipmentsState = useSelector(state => {
        let { status, scannedItem, ...other } = state.inboundShipments;
        return other;
    });
    const shipmentsStatus = useSelector(state => state.inboundShipments.status);
    const productDialogOpened = useSelector(
        state => state.inboundShipments.productDialogOpened
    );

    useEffect(() => {
        dispatch(actions.getShipments(userData.credentials, address));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        if (!auth || !userData) {
            dispatch(
                notifications.enqueueSnackbar({
                    message: 'Waiting for data',
                    options: { variant: 'error' },
                })
            );
            focusTextInput();
            return;
        }
        setState({ loading: true });
        let items = [],
            allItems = [];
        let input = state.input.toUpperCase();
        try {
            // Take a list of shipments. If the user selected one shipment,
            // create a list containing only one element
            let shipmentsToSearch = shipmentsState.selectedShipmentId
                ? [
                      shipmentsState.shipments.find(
                          s =>
                              s.ShipmentId === shipmentsState.selectedShipmentId
                      ),
                  ]
                : shipmentsState.shipments;
            
            // Make a list of all expected items
            allItems = shipmentsToSearch.reduce(
                (prev, curr) => prev.concat(curr.ExpectedItems),
                []
            );
            items = allItems.filter(i => i.FulfillmentNetworkSKU === input);
            console.log(items);
            if (items.length === 0) {
                throw new Error('Item not found');
            }

            // If a single shipment is selected
            if (shipmentsState.selectedShipmentId) {
                setState({
                    input: '',
                    loading: false,
                });
                // Add 1 item directly to the current box
                // (previously the product dialog was shown at this point)
                dispatch(actions.updateSelectedShipment(items[0], 1));
            } 
            // Update all shipments to which these items belong
            else {
                dispatch(actions.updateCurrentShipments(items));
                setState({
                    input: '',
                    loading: false,
                });
            }
            // Update the scanned item in order to view its
            // basic information
            dispatch(actions.setScannedItem(items[0]));
        } catch (e) {
            Sentry.withScope(function (scope) {
                if (e.message === 'Item not found') {
                    scope.setLevel(Sentry.Severity.Warning);
                }
                scope.setContext('itemsData', {
                    input: input,
                    items: allItems.map(i => i.FulfillmentNetworkSKU),
                    filteredItems: items.map(i => i.FulfillmentNetworkSKU),
                    shipments: shipmentsState.shipments.map(s => s.ShipmentId),
                    selectedShipmentId: shipmentsState.selectedShipmentId,
                });
                Sentry.captureException(e);
            });
            setState({ input: '', loading: false });
            dispatch(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            );
        }
        focusTextInput();
    };

    const handleChange = e => {
        if (e.target.id !== undefined) {
            setState({
                [e.target.id]: e.target.value,
            });
        } else if (e.target.name !== undefined) {
            setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    const onKeyPress = ev => {
        if (ev.key === 'Enter') {
            handleSubmit(ev);
        }
    };

    const saveShipments = async shipmentIds => {
        setTimeout(async () => {
            dispatch(actions.saveShipments(shipmentIds));
        }, 1000);
    };

    const focusTextInput = () => {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        textInput.current.focus();
    };

    if (!auth || !userData) return <Redirect to='/signin' />;

    let currentShipments = shipmentsState.shipments.filter(shipment =>
        shipmentsState.currentShipmentsIds.includes(shipment.ShipmentId)
    );
    let selectedShipment = shipmentsState.shipments.find(
        s => s.ShipmentId === shipmentsState.selectedShipmentId
    );
    return (
        <div className='shipping-page-container shipping-page-background-color'>
            <div className='shipments-list-container'>
                <AllShipmentsList
                    shipments={shipmentsState.shipments}
                    selectShipment={shipmentId => {
                        if (shipmentId !== shipmentsState.selectedShipmentId) {
                            focusTextInput();
                            dispatch(actions.setCurrentShipmentIds([]));
                            dispatch(actions.setSelectedShipmentId(shipmentId));
                        }
                    }}
                    selectedId={shipmentsState.selectedShipmentId}
                    loading={shipmentsStatus.isFetchingShipments}
                />
            </div>
            <div className='main-content'>
                <Grid container={true}>
                    <Grid item={true} sm={9}>
                        <TextInput
                            value={state.input}
                            onChange={handleChange}
                            onKeyPress={onKeyPress}
                            inputRef={textInput}
                            handleSubmit={handleSubmit}
                            loading={state.loading}
                            classes={{
                                container: 'soft-corners',
                                button: 'soft-corners',
                            }}
                        />
                    </Grid>
                    <Grid item={true} sm={3} className='centered-container'>
                        <Button
                            onClick={() => {
                                batch(() => {
                                    dispatch(actions.setCurrentShipmentIds([]));
                                    dispatch(
                                        actions.setSelectedShipmentId(null)
                                    );
                                    focusTextInput();
                                });
                            }}
                            style={{
                                width: '130px',
                                borderRadius: '1em',
                            }}
                            className='white-button'
                            variant='outlined'
                            disabled={
                                shipmentsState.selectedShipmentId === null &&
                                currentShipments.length === 0
                            }
                        >
                            Multiple Shipments
                        </Button>
                    </Grid>
                </Grid>

                {currentShipments.length > 0 && (
                    // Component responsible for viewing all shipments to which
                    // the scanned item belong
                    <CurrentShipmentList
                        shipments={currentShipments}
                        createBox={(shipment, item) => {
                            setState({
                                finishBoxDialogOpened: true,
                                finishBoxDialogData: {
                                    shipmentId: shipment.ShipmentId,
                                    item,
                                },
                            });
                        }}
                        focusInput={focusTextInput}
                    />
                )}
                {shipmentsState.selectedShipmentId !== null && (
                    // Displays the content of the selected shipment
                    <BoxList
                        focusInput={focusTextInput}
                        shipment={selectedShipment}
                        loadingStatus={shipmentsState.loadingShipments.find(
                            s =>
                                s.shipmentId ===
                                shipmentsState.selectedShipmentId
                        )}
                        submit={(shipment, needsUpdating) => {
                            // Warn the user if there are unboxed items
                            let boxed = shipment.Boxes.reduce((prev, curr) => {
                                curr.items.forEach(item => {
                                    if (prev[item.FulfillmentNetworkSKU]) {
                                        prev[item.FulfillmentNetworkSKU] +=
                                            item.BoxQuantity;
                                    } else {
                                        prev[item.FulfillmentNetworkSKU] =
                                            item.BoxQuantity;
                                    }
                                });
                                return prev;
                            }, {});
                            if (
                                shipment.ExpectedItems.filter(
                                    i =>
                                        i.QuantityShipped !==
                                        boxed[i.FulfillmentNetworkSKU]
                                ).length > 0
                            ) {
                                dispatch(
                                    notifications.enqueueSnackbar({
                                        message:
                                            'There are still unboxed items in this shipment',
                                        options: { variant: 'warning' },
                                    })
                                );
                                return;
                            }
                            // Warn the user if there are unfinished boxes
                            if (
                                shipment.Boxes.filter(
                                    box =>
                                        box.weight === -1 ||
                                        box.length === -1 ||
                                        box.width === -1 ||
                                        box.height === -1
                                ).length > 0
                            ) {
                                dispatch(
                                    notifications.enqueueSnackbar({
                                        message: 'Some boxes are not finished',
                                        options: { variant: 'warning' },
                                    })
                                );
                                return;
                            }
                            // Submit the shipment to Seller Central. If needsUpdating is set to true,
                            // PutTransportContent operation will be called before submitting.
                            dispatch(
                                actions.submitShipment(shipment, needsUpdating)
                            );
                        }}
                        addDimensions={box => {
                            setState({
                                finishBoxDialogOpened: true,
                                finishBoxDialogData: {
                                    shipmentId:
                                        shipmentsState.selectedShipmentId,
                                    boxId: box.id,
                                },
                            });
                        }}
                        putContent={shipment =>
                            dispatch(actions.putContent(shipment))
                        }
                    />
                )}
            </div>

            <FinishBoxDialog
                opened={state.finishBoxDialogOpened}
                onClose={() => {
                    setState({ finishBoxDialogOpened: false });
                }}
                onSave={(weight, length, width, height) => {
                    dispatch(
                        actions.addBoxDimensions(
                            state.finishBoxDialogData.shipmentId,
                            weight,
                            length,
                            width,
                            height,
                            state.finishBoxDialogData.boxId
                        )
                    );
                    // Automatically create a new box
                    dispatch(
                        actions.createBox(state.finishBoxDialogData.shipmentId)
                    );
                    dispatch(
                        actions.updateShipment(
                            state.finishBoxDialogData.shipmentId,
                            { LastUpdated: new Date().getTime() },
                            true
                        )
                    );
                    saveShipments([state.finishBoxDialogData.shipmentId]);
                    setState({
                        finishBoxDialogOpened: false,
                        finishBoxDialogData: null,
                    });
                }}
            />
            <SubmitShipmentsDialog
                opened={state.submitShipmentsDialogOpened}
                onClose={() => {
                    setState({ submitShipmentsDialogOpened: false });
                }}
                shipments={shipmentsState.shipments}
                onSubmit={selectedShipments => {
                    let emptyShipments = selectedShipments.filter(
                        shipment =>
                            shipmentUtils.calcShipmentSize(shipment) === 0
                    );
                    if (emptyShipments.length > 0) {
                        dispatch(
                            notifications.enqueueSnackbar({
                                message:
                                    'Some shipments have no assigned items',
                                options: { variant: 'error' },
                            })
                        );
                    } else {
                        // submitShipment(selectedShipments);
                    }
                }}
                loading={state.submitShipmentsLoading}
            />
            <ProductDialog
                opened={productDialogOpened}
                shipment={selectedShipment}
                onClose={() => {
                    dispatch(actions.setProductDialogOpened(false));
                }}
                onConfirm={(product, quantity) => {
                    batch(() => {
                        dispatch(
                            actions.updateSelectedShipment(product, quantity)
                        );
                        dispatch(actions.setProductDialogOpened(false));
                    });
                }}
            />

            <ConfirmationDialog
                opened={shipmentsState.boxDeletionConfirmation}
                message='Are you sure you want to delete this box?'
                onClose={() => {
                    dispatch(actions.setDeleteConfirmationOpened(false));
                }}
                onConfirm={() => {
                    let shipment = utils.findShipment(
                        shipmentsState.shipments,
                        shipmentsState.boxToDeleteId
                    );
                    batch(() => {
                        dispatch(
                            actions.deleteBox(shipmentsState.boxToDeleteId)
                        );
                        dispatch(actions.setDeleteConfirmationOpened(false));
                        dispatch(
                            actions.updateShipment(
                                shipment.ShipmentId,
                                {
                                    LastUpdated: new Date().getTime(),
                                },
                                true
                            )
                        );
                        dispatch(actions.saveShipments([shipment.ShipmentId]));
                    });
                }}
            />
        </div>
    );
}

export default BoxShipmentPage;
