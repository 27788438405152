import { FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const dataForge = require('data-forge');
const moment = require('moment');
moment().format();

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
        zIndex: 0,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

class OrdersBrowser extends Component {
    state = {
        productData: [],
        errorMsg: '',
        snackbarOpened: false,
        isLoading: false,
        loadingMsg: '',
        startDate: '',
        endDate: '',
    }

    constructor(props) {
        super(props)

        this.textInput = React.createRef();
        this.focusTextInput = this.focusTextInput.bind(this);
    }

    handleStartChange = (date) => {
        this.setState({
            startDate: date
        });
    }

    handleEndChange = (date) => {
        this.setState({
            endDate: date
        });
    }

    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;
        const copy = require('clipboard-copy')

        const columns = [
            {
                name: "ASIN",
                label: "ASIN",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `ASIN includes ${v}`
                        }
                        return false;
                    },
                    customBodyRender: (value, _, __) => (
                        <a target="_blank" rel="noopener noreferrer" href={`https://www.amazon.com/dp/${value}/ref=olp_product_details?_encoding=UTF8&me=&th=1&psc=1`}>{value}</a>
                    )
                }
            },
            {
                name: "Name",
                label: "Name",
                options: {
                    filter: true,
                    display: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `ASIN includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "InstockQuant",
                label: "Instock",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min InstockQuant: ${v['min']}, Max InstockQuant: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min InstockQuant: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max InstockQuant: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>InstockQuant</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "InboundQuant",
                label: "Inbound",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min InboundQuant: ${v['min']}, Max InboundQuant: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min InboundQuant: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max InboundQuant: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>InboundQuant</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "TotalQuant",
                label: "Total",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min TotalQuant: ${v['min']}, Max TotalQuant: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min TotalQuant: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max TotalQuant: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>TotalQuant</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "PurchasedQuantity",
                label: "Purchased",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min Purchased: ${v['min']}, Max Purchased: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min Purchased: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max Purchased: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Purchased</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "PurchasedDate",
                label: "PurchasedDate",
                options: {
                    sort: false,
                    display: true,
                    sortDirection: 'desc',
                    customBodyRender: (value, _, __) => {
                        return <p>{value.format('L')}</p>
                    }
                }
            },
            {
                name: "Sales7D",
                label: "7D",
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min 7D: ${v['min']}, Max 7D: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min 7D: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max 7D: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>7D</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                }
            },
            {
                name: "Sales14D",
                label: "14D",
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min 14D: ${v['min']}, Max 14D: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min 14D: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max 14D: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>14D</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                }
            },
            {
                name: "Sales30D",
                label: "30D",
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min 30D: ${v['min']}, Max 30D: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min 30D: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max 30D: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>30D</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                }
            },
            {
                name: "TotalOrdersQuantity",
                label: "Sales",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min Sales: ${v['min']}, Max Sales: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min Sales: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max Sales: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>TotalOrdersQuantity</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                }
            },
            {
                name: "AverageDailySales",
                label: "AvgDaily",
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min AvgDaily: ${v['min']}, Max AvgDaily: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min AvgDaily: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max AvgDaily: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>AvgDaily</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                }
            },
            {
                name: "RemDays",
                label: "RemDays",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min RemDays: ${v['min']}, Max RemDays: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min RemDays: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max RemDays: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>RemDays</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "Diff",
                label: "Diff",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min Diff: ${v['min']}, Max Diff: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min Diff: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max Diff: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Diff</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "ReturnedQuantity",
                label: "Returns",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min Returns: ${v['min']}, Max Returns: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min Returns: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max Returns: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Returns</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "ReturnRate",
                label: "ReturnRate",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customBodyRender: (value, _, __) => (
                        <span>{value.toFixed(0)}%</span>
                    ),
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min ReturnRate: ${v['min']}, Max ReturnRate: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min ReturnRate: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max ReturnRate: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>ReturnRate</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "AverageItemPrice",
                label: "AvgPrice",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min AvgPrice: ${v['min']}, Max AvgPrice: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min AvgPrice: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max AvgPrice: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>AvgPrice</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "Cost",
                label: "Cost",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min Cost: ${v['min']}, Max Cost: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min Cost: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max Cost: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Cost</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "TotalOrdersValue",
                label: "TotalValue",
                options: {
                    filter: true,
                    sort: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min TotalOrdersQuantity: ${v['min']}, Max TotalOrdersQuantity: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min TotalOrdersQuantity: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max TotalOrdersQuantity: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>TotalOrdersQuantity</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                }
            },
            {
                name: "AverageOrdersValue",
                label: "AvgValue",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min AvgValue: ${v['min']}, Max AvgValue: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min AvgValue: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max AvgValue: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>AvgOrder</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "DaysSinceRestock",
                label: "SinceRestock",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'custom',
                    customFilterListOptions: {
                        render: v => {
                            if (v['min'] && v['max']) {
                                return `Min SinceRestock: ${v['min']}, Max SinceRestock: ${v['max']}`;
                            } else if (v['min']) {
                                return `Min SinceRestock: ${v['min']}`;
                            } else if (v['max']) {
                                return `Max SinceRestock: ${v['max']}`;
                            }
                            return false;
                        }
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>SinceRestock</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "LastRestockQty",
                label: "LastRestockQty",
                options: {
                    filter: true,
                    display: false,
                },
            },
            {
                name: "RecentRestock",
                label: "RecentRestock",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'dropdown',
                    customFilterListRender: v => {
                        if (v) {
                            return `RecentRestock: ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "Restocked",
                label: "Restocked",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'dropdown',
                    customFilterListRender: v => {
                        if (v) {
                            return `Restocked: ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "Supplier",
                label: "Supplier",
                options: {
                    sort: false,
                    display: true,
                }
            },
            {
                name: "Code",
                label: "Code",
                options: {
                    sort: false,
                    display: false,
                }
            },
            {
                name: "OFFBInstock",
                label: "OFFBInstock",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'dropdown',
                    customFilterListRender: v => {
                        if (v) {
                            return `Restocked: ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "RRSInstock",
                label: "RRSInstock",
                options: {
                    filter: true,
                    display: false,
                    filterType: 'dropdown',
                    customFilterListRender: v => {
                        if (v) {
                            return `Restocked: ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "Link",
                label: "Link",
                options: {
                    filter: true,
                    display: false,
                    customFilterListRender: v => {
                        if (v) {
                            return `Link includes ${v}`
                        }
                        return false;
                    },
                    customBodyRender: (value, _, __) => (
                        <a target="_blank" rel="noopener noreferrer" href={value}>{value === "" ? "" : "Link"}</a>
                    )
                }
            },
        ]

        columns.forEach(column => {
            column.options.setCellHeaderProps = (value) => {
                return {
                    style: {
                        zIndex: 0,
                    }
                };
            }
        })

        const options = {
            filterType: 'textField',
            selectableRows: 'none',
            responsive: 'tableBodyMaxHeight',
            fixedHeader: false,
            print: false,
            onCellClick: (colData, celMeta) => {
                if (celMeta.colIndex === 0) {
                    copy(`${colData.props.children}`)
                } else {
                    copy(`${colData}`)
                }
            },
        };

        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container display="flex" maxWidth={false} height="100%">
                <Container width='100%' m={3} >
                    <Box display="flex" flexDirection="row" className={classes.container} justifyContent="center" alignItems="center">
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleStartChange}
                        />
                        <DatePicker
                            selected={this.state.endDate}
                            onChange={this.handleEndChange}
                        />
                        <Button onClick={() => this.getData()}>
                            Download
                        </Button>
                    </Box>
                </Container>


                <Container width='100%' maxWidth="xl" className={classes.container}>
                    <MUIDataTable
                        title={"Orders Data"}
                        data={this.state.productData}
                        columns={columns}
                        options={options}
                        style={{
                            zIndex: 0
                        }}
                    />


                </Container>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbarOpened}
                    autoHideDuration={6000}
                >
                    <SnackbarContent
                        className={clsx(classes.error, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                                {this.state.errorMsg}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="Close" color="inherit" onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                this.setState({ snackbarOpened: false })
                            }} >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </Container >
        )
    }

    componentDidMount() {
        // this.getCollections()
    }

    focusTextInput() {
        this.textInput.current.focus();
    }

    changePage = (page) => {
        this.setState({
            isLoading: true,
        });
    };

    getProjectSuiteData = async (db, collection) => {
        const data = await fetch(`https://api.projectsuite.io/repricer?db=${db}&collection=${collection}&firebase_token=${this.state.token}`).then((resp) => resp.json());
        if (data.error) {
            throw Error(data.error);
        } else {
            return data.result;
        }
    };

    parseBuyFrame = (buyData) => {
        const buyDataFrame = new dataForge.DataFrame(buyData).select((row) => {
            row['PurchasedQuantity'] = parseInt(row['QTY']);
            row['Cost'] = row['COST'] ? parseFloat(row['COST']) : 0;
            row['PurchasedDate'] = row['PURCHASEDATE'].split('/').length === 3 ? moment(row['PURCHASEDATE'], 'M/D/YYYY') : moment(row['PURCHASEDATE'], 'MM/DD');
            row['Supplier'] = row['SUPPLIER'];
            return row;
        })
            .dropSeries(['QTY', 'COST', 'LISTPRICE', 'PURCHASEDATE', 'TITLE', 'SUPPLIER'])
            .orderByDescending((row) => row['PurchasedDate']);

        const pivoted = buyDataFrame.pivot('ASIN', {
            'PurchasedQuantity': {
                PurchasedQuantity: (series) => series.first(),
                LastRestockQty: (series) => series.first(),
            },
            'Cost': {
                Cost: (series) => series.average().toFixed(2),
            },
            'PurchasedDate': {
                PurchasedDate: (series) => series.first(),
                DaysSinceRestock: (series) => Math.floor(moment.duration(moment().diff(moment(series.first()))).asDays())
            },
            'Supplier': {
                Supplier: (series) => series.last(),
            },
        })
            .withIndex((row) => row['ASIN']);

        console.log(pivoted.toArray())

        return pivoted;
    };

    parseReturnsFrame = (returnData) => {
        const returnDataFrame = new dataForge.DataFrame(returnData)
            .subset(['asin', 'quantity', 'return-date'])
            .select((row) => {
                row['quantity'] = parseInt(row['quantity']);
                row['return-date'] = moment(row['return-date']);
                return row;
            })
            .renameSeries({
                'asin': 'ASIN',
                'quantity': 'ReturnQuantity',
                'return-date': 'ReturnDate',
            })
            .where((row) => row['ReturnDate'].isAfter(moment().subtract(30, 'days'), 'days'));

        const pivoted = returnDataFrame.pivot('ASIN', {
            'ReturnQuantity': {
                ReturnedQuantity: (series) => series.sum().toFixed(2),
            },
        }).withIndex((row) => row['ASIN']);

        return pivoted;
    };

    joinData = async (orderFrame) => {
        return orderFrame;
    }

    getData = async () => {
        let momentStart, momentEnd;
        if (this.state.startDate === this.state.endDate) {
            momentStart = moment().subtract(31, 'days');
            momentEnd = moment();
        }
        else if (this.state.startDate !== '' && this.state.endDate !== '') {
            momentStart = moment(this.state.startDate);
            momentEnd = moment(this.state.endDate);
        }
        getToken().then(token => {
            this.setState({ token: token }, async () => {
                const ordersData = await this.getProjectSuiteData('luOjCSlnssfwomE0Lic6gHzmDcw1', 'order_raw');
                const dataFrameOrders = (new dataForge.DataFrame(ordersData))
                    .subset(['purchase-date', 'sales-channel', 'order-status', 'fulfillment-channel', 'product-name', 'sku', 'asin', 'quantity', 'currency', 'item-price'])
                    .where((row) => row['currency'] === 'USD' &&
                        !row['order-status'].includes('Cancelled') &&
                        row['sales-channel'] === 'Amazon.com' &&
                        row['item-price'] !== "0.0")
                    .parseDates('purchase-date')
                    .parseInts('quantity')
                    .parseFloats('item-price')
                    .orderByDescending((row) => row['purchase-date']);

                let x;
                const sevenDayFrame = dataFrameOrders.takeUntil((row) => row['purchase-date'] < (x = new Date()).setDate(x.getDate() - 7));
                console.log(sevenDayFrame.count())
                const fourteenDayFrame = dataFrameOrders.takeUntil((row) => row['purchase-date'] < (x = new Date()).setDate(x.getDate() - 14));
                console.log(fourteenDayFrame.count())
                const thirtyDayFrame = dataFrameOrders.takeUntil((row) => row['purchase-date'] < (x = new Date()).setDate(x.getDate() - 30));
                console.log(thirtyDayFrame.count())

                console.log(dataFrameOrders.count())

                const limitedFrame = dataFrameOrders.where((row) => moment(row['purchase-date']).isBetween(momentStart, momentEnd, 'day'))

                const pivotFrames = [sevenDayFrame, fourteenDayFrame, thirtyDayFrame]
                const resultFrames = []

                pivotFrames.forEach(frame => {
                    resultFrames.push(frame.pivot('asin', {
                        'quantity': {
                            TotalOrdersQuantity: (series) => series.sum(),
                        },
                    })
                        .renameSeries({ 'asin': 'ASIN' })
                        .reorderSeries(['ASIN', 'TotalOrdersQuantity'])
                        .orderByDescending((row) => parseInt(row['TotalOrdersQuantity']))
                        .withIndex(row => row['ASIN']));
                })

                resultFrames[0] = resultFrames[0].renameSeries({ 'TotalOrdersQuantity': 'Sales7D' })
                resultFrames[1] = resultFrames[1].renameSeries({ 'TotalOrdersQuantity': 'Sales14D' })
                resultFrames[2] = resultFrames[2].renameSeries({ 'TotalOrdersQuantity': 'Sales30D' })

                const pivoted = limitedFrame.pivot('asin', {
                    'item-price': {
                        AverageOrdersValue: (series) => parseFloat(series.average().toFixed(2)),
                        TotalOrdersValue: (series) => parseFloat(series.sum().toFixed(2)),
                    },
                    'quantity': {
                        TotalOrdersQuantity: (series) => series.sum(),
                    },
                    'purchase-date': {
                        DaysDiff: (series) => Math.floor(moment.duration(moment(series.first()).diff(moment(series.last()))).asDays()),
                    },
                    'product-name': {
                        Name: (series) => series.first(),
                    },
                })
                    .generateSeries((row) => {
                        const AverageItemPrice = parseFloat((row.TotalOrdersValue / row.TotalOrdersQuantity).toFixed(2));
                        const AverageDailySales = parseFloat((row.TotalOrdersQuantity / (row.DaysDiff === 0 ? 1 : row.DaysDiff)).toFixed(2));
                        return { 'AverageItemPrice': AverageItemPrice, 'AverageDailySales': AverageDailySales };
                    })
                    .renameSeries({ 'asin': 'ASIN' })
                    .reorderSeries(['Name', 'ASIN', 'TotalOrdersQuantity', 'AverageDailySales', 'AverageItemPrice', 'TotalOrdersValue', 'AverageOrdersValue', 'DaysDiff'])
                    .orderByDescending((row) => parseInt(row['TotalOrdersQuantity']))
                    .withIndex(row => row['ASIN']);

                console.log(pivoted.head(5).toArray())

                if (pivoted.count() > 0) {
                    const [buyData, returnData, invData, sheetData, offbData, offbInstock, rrsInstock] = await Promise.all([
                        this.getProjectSuiteData('buydata', 'luOjCSlnssfwomE0Lic6gHzmDcw1'),
                        this.getProjectSuiteData('luOjCSlnssfwomE0Lic6gHzmDcw1', 'customerreturns'),
                        this.getProjectSuiteData('luOjCSlnssfwomE0Lic6gHzmDcw1', 'inventory'),
                        this.getProjectSuiteData('luOjCSlnssfwomE0Lic6gHzmDcw1', 'offb_sourcing'),
                        this.getProjectSuiteData('luOjCSlnssfwomE0Lic6gHzmDcw1', 'offb_asins'),
                        this.getProjectSuiteData('sourcing', 'OFFB_alt_internal'),
                        this.getProjectSuiteData('sourcing', 'RRS_alt_internal'),
                    ]);
                    const buyDataFrame = this.parseBuyFrame(buyData);
                    const returnDataFrame = this.parseReturnsFrame(returnData);
                    const sheetDataFrame = new dataForge.DataFrame(sheetData)
                        .withIndex((row) => row['ASIN']);
                    const offbDataFrame = new dataForge.DataFrame(offbData)
                        .withIndex((row) => row['ASIN']);
                    const offbInstockFrame = new dataForge.DataFrame(offbInstock)
                        .withIndex((row) => row['upc']);
                    const rrsInstockFrame = new dataForge.DataFrame(rrsInstock)
                        .withIndex((row) => row['upc']);

                    console.log(sheetDataFrame.first());
                    const invDataFrame = new dataForge.DataFrame(invData)
                        .renameSeries({ 'Quantity': 'TotalQuant', 'FulfillableQuantity': 'InstockQuant' })
                        .parseInts('TotalQuant', 'InstockQuant', 'InboundWorkingQuantity', 'InboundShippedQuantity', 'InboundReceivingQuantity', 'TotalQuantity')
                        .select((row) => {
                            const newRow = { ...row };
                            newRow['InboundQuant'] = parseInt(row['InboundWorkingQuantity']) + parseInt(row['InboundShippedQuantity']) + parseInt(row['InboundReceivingQuantity'])
                            newRow['InstockQuant'] = parseInt(row['InstockQuant']);
                            newRow['TotalQuant'] = newRow['InboundQuant'] + newRow['InstockQuant']
                            return newRow;
                        })
                        .pivot('ASIN', {
                            'InboundQuant': {
                                InboundQuant: (series) => series.sum(),
                            },
                            'InstockQuant': {
                                InstockQuant: (series) => series.sum(),
                            },
                            'TotalQuant': {
                                TotalQuant: (series) => series.sum(),
                            },
                        })
                        .withIndex((row) => row['ASIN']);
                    const ordersFrame = pivoted
                    const resultFrame = ordersFrame.merge(buyDataFrame)
                        .merge(returnDataFrame)
                        .merge(invDataFrame)
                        .merge(sheetDataFrame)
                        .merge(resultFrames[0])
                        .merge(resultFrames[1])
                        .merge(resultFrames[2])
                        .select((row) => {
                            const newRow = { ...row };
                            if (row['Cost'] === undefined) {
                                newRow['PurchasedQuantity'] = -1;
                                newRow['Cost'] = -1;
                                newRow['PurchasedDate'] = moment(0);
                                newRow['DaysSinceRestock'] = -1;
                            } else {
                                newRow['PurchasedQuantity'] = parseInt(row['PurchasedQuantity'])
                                newRow['Diff'] = row['PurchasedQuantity'] - newRow['TotalOrdersQuantity']
                                newRow['Cost'] = parseFloat(row['Cost'])
                                newRow['RecentRestock'] = newRow['DaysSinceRestock'] < 14 && newRow['DaysSinceRestock'] > 0 ? 'True' : 'False'
                            }

                            if (row['InstockQuant'] === undefined) {
                                newRow['InstockQuant'] = -1
                                newRow['InboundQuant'] = -1
                                newRow['TotalQuant'] = -1
                                newRow['RemDays'] = -1
                            } else {
                                newRow['RemDays'] = parseFloat((row['TotalQuant'] / row['AverageDailySales']).toFixed(2))
                            }

                            if (row['Sales7D'] === undefined) {
                                newRow['Sales7D'] = -1
                            }
                            if (row['Sales14D'] === undefined) {
                                newRow['Sales14D'] = -1
                            }
                            if (row['Sales30D'] === undefined) {
                                newRow['Sales30D'] = -1
                            }

                            if (row['ReturnedQuantity'] === undefined) {
                                newRow['ReturnedQuantity'] = 0;
                            } else {
                                newRow['ReturnedQuantity'] = parseInt(row['ReturnedQuantity'])
                            }

                            if (newRow['ReturnedQuantity'] === 0) {
                                newRow['ReturnRate'] = 0
                            } else {
                                newRow['ReturnRate'] = ((newRow['ReturnedQuantity'] / newRow['TotalOrdersQuantity']) * 100);
                            }

                            if (newRow['Code'] === undefined) {
                                newRow['Code'] = ""
                            } else {
                                if (rrsInstockFrame.at(newRow['Code']) !== undefined) {
                                    newRow['RRSInstock'] = 'True'
                                } else {
                                    newRow['RRSInstock'] = 'False'
                                }

                                if (offbInstockFrame.at(newRow['Code']) !== undefined) {
                                    newRow['OFFBInstock'] = 'True'
                                } else {
                                    newRow['OFFBInstock'] = 'False'
                                }
                            }

                            if (newRow['Link'] === undefined) {
                                newRow['Link'] = ""
                            }

                            if (offbDataFrame.at(newRow['ASIN']) !== undefined) {
                                newRow['Restocked'] = 'True'
                            } else {
                                newRow['Restocked'] = 'False'
                            }

                            return newRow;
                        })
                        .where((row) => row['TotalOrdersQuantity'] !== undefined)
                        .reorderSeries([
                            'Name',
                            'ASIN',
                            'TotalOrdersQuantity',
                            'Sales7D',
                            'Sales14D',
                            'Sales30D',
                            'AverageDailySales',
                            'PurchasedQuantity',
                            'Diff',
                            'InstockQuant',
                            'InboundQuant',
                            'TotalQuant',
                            'RemDays',
                            'ReturnedQuantity',
                            'ReturnRate',
                            'AverageItemPrice',
                            'TotalOrdersValue',
                            'AverageOrdersValue',
                            'Cost',
                            'PurchasedDate',
                            'DaysSinceRestock',
                            'LastRestockQty',
                            'RecentRestock',
                            'Restocked',
                            'Supplier',
                            'Code',
                            'OFFBInstock',
                            'RRSInstock',
                            'Link'
                        ])
                        .orderByDescending((row) => parseInt(row['TotalOrdersQuantity'])).toArray();

                    console.log(resultFrame[0])

                    this.setState({ productData: resultFrame, isLoading: false })
                }
            })
        })
    }
}

OrdersBrowser.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(OrdersBrowser))