import React from 'react'
import { Link } from 'react-router-dom'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const SignedOutLinks = ({ onItemClick }) => {
    return (
        <List>
            <ListItem
                button
                component={Link}
                to="/signup"
                onClick={onItemClick("Project: Suite")}
            >
                <ListItemText>Sign up</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/signin"
                onClick={onItemClick("Project: Suite")}
            >
                <ListItemText>Login</ListItemText>
            </ListItem>
        </List>
    )
}

export default SignedOutLinks