
export const DOMAIN =
    process.env.NODE_ENV === 'development'
        ? 
            'http://localhost:3001'
            // 'http://192.168.178.49:3001'
            // 'https://api.projectsuite.io'
        :   
            'https://api.projectsuite.io';

export const sleep = (ms: number) =>
    new Promise(resolve => setTimeout(resolve, ms));
