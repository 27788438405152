import { TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';

/**
 * List of columns extracted from data passed to the MaterialTable component.
 */
const columns = [
    {
        title: 'UPC',
        field: 'upc',
        cellStyle: { width: '150px' },
    },
    {
        title: 'Timestamp', field: 'timestamp', editable: 'never', render: rowData => {
            let d = rowData.timestamp ? new Date(rowData.timestamp) : new Date()
            return (
                <p>{d.toLocaleString("en-US")}</p>
            )
        },
        emptyValue:  <p>{(new Date()).toLocaleString("en-US")}</p>,
        type: 'date'
    },
    {
        title: 'Message',
        field: 'message',
        editComponent: props => (
            <TextField
                style={{ width: '100%' }}
                multiline
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
            />
        ),
        cellStyle: { wordBreak: 'break-all' },
    },
];

/**
 * Renders an editable table of UPC notifications with messages.
 * 
 * @param {Object} props - Component props.
 * @param {boolean} props.loading - Indicates if the table should be in the loading state.
 * @param {Object[]} props.notifs - List of notifications with 'upc' and 'message' properties.
 * @param {function} props.addMessage - Callback function executed when a new notification should be added.
 * @param {function} props.deleteMessage - Callback function executed when a notification should be deleted.
 */
export default function UPCTable({
    loading,
    notifs,
    addMessage,
    deleteMessage,
}) {
    
    return (
        <MaterialTable
            isLoading={loading}
            title=''
            data={notifs}
            columns={columns}
            style={{ width: '100%', marginTop: '1em', padding: '0.2em' }}
            options={{
                exportButton: true,
                exportAllData: true,
            }}
            editable={{
                onRowAdd: addMessage ? newData => addMessage(newData.upc, newData.message) : undefined,
                onRowUpdate: addMessage ? newData => addMessage(newData.upc, newData.message) : undefined,
                onRowDelete: deleteMessage ? oldData => deleteMessage(oldData.upc) : undefined,
            }}
        />
    );
}
