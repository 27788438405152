import { put, select, all } from "redux-saga/effects";
import * as shippingActions from '../../actions/shippingActions';
import * as notifications from '../../actions/notificationsActions';
import { inboundShipments } from "../../../utils/project_api";

/**
 * Saga responsible for saving shipments in the database.
 * It takes a list of shipment IDs as an argument.
 * 
 * @param {Object} actionData 
 */
export function* saveShipments(actionData) {
    let { shipmentIds } = actionData;
    yield put(shippingActions.updateStatus({ error: null, isSaving: true }));

    // Extract the shipments' data from the redux state
    const shipments = yield select(state => state.inboundShipments.shipments);

    // Map the list of IDs to the list of shipments
    let shipmentsToSave = shipmentIds.map(id =>
        shipments.find(s => s.ShipmentId === id)
    );
    try {
        // Send the list of shipments to the server
        let result = yield inboundShipments.post(
            'saveShipments',
            {},
            { data: { shipments: shipmentsToSave } }
        );
        if (result.error) {
            throw new Error(result.error);
        }
        yield put(shippingActions.updateStatus({ isSaving: false }));
    } catch (e) {
        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                    isSaving: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}
