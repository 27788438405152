import React from 'react';
import { Box, Grid } from '@material-ui/core';
import './InfoBanner.scss';

/**
 * Displays basic product information in a view.
 * @param {Object} props - Component props. 
 * @param {Object} props.product - Product data.
 */
export default function ProductInfoBanner({ product }) {
    if (!product) return null;
    return (
        <Box className='centered-container' flexDirection='column' width='100%'>
            <Grid className='shipment-info-banner' container={true}>
                <Grid item={true} sm={3}>
                    <img
                        src={product.ImgURL}
                        alt=''
                        style={{ height: '100px', width: 'auto' }}
                    />
                </Grid>
                <Grid item={true} sm={9}>
                    <p className='title-info'>{product.Name}</p>
                    <Box className='row'>
                        <p className='label-info-2'>FNSKU:</p>
                        <p className='info-text-2'>
                            {product.FulfillmentNetworkSKU}
                        </p>
                    </Box>
                    <Box className='row'>
                        <p className='label-info-2'>ASIN:</p>
                        <p className='info-text-2'>
                            {product.ASIN}
                        </p>
                    </Box>
                    <Box className='row'>
                        <p className='label-info-2'>UPC:</p>
                        <p className='info-text-2'>
                            {product.UPC}
                        </p>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
