import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as notifications from '../../store/actions/notificationsActions';
import { useDispatch } from 'react-redux';
import UPCTable from './UPCTable';

const useStyles = makeStyles((theme) => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 10,
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
}));

export default function ImportNotifs({ refresh, upload }) {
    const dispatch = useDispatch();
    const [notifs, setNotifs] = useState({})
    const [notifsLoading, setNotifsLoading] = useState(false)
    const classes = useStyles();

    const parseNotifs = (text) => {
        const rows = text.split('\n')
        const data = rows
            .map(row => row.trim())
            .map(row => {
                const [upc, ...message] = row.split(',')
                return { upc, message: message.join(',')}
            })

        setNotifs(data.reduce((notifs, notif) => {notifs[notif.upc] = notif; return notifs}, {}))
    }

    useEffect(() => {
        setNotifsLoading(false)
    }, [notifs])

    const handleUpload= async (event) => {
        setNotifsLoading(true);
        const reader = new FileReader()
        reader.onload = async (e) => { 
            const text = (e.target.result)
            parseNotifs(text)
        };

        reader.readAsText(event.target.files[0])
    }

    const addNotif = async (upc, message) => {
        setNotifs((notifs) => ({...notifs, [upc]: {upc: upc, message: message}}))
    }

    const deleteNotif = async (upc) => {
        setNotifs((notifs) => {
            const { [upc]: value, ...cleanedArr } = notifs
            console.log(value)
            return cleanedArr
        })
    }

    const uploadNotifs = async () => {
        setNotifsLoading(true)
        upload(Object.values(notifs)).then(() => refresh()).then(() => {
            dispatch(
                notifications.enqueueSnackbar({
                    message: `${Object.keys(notifs).length} notifications uploaded`,
                    options: { variant: 'success' },
                })
            );
        }).catch((ex) => {
            dispatch(
                notifications.enqueueSnackbar({
                    message: ex.message,
                    options: { variant: 'error' },
                })
            );
        }).finally(() => {
            setNotifsLoading(false)
        })
    }

    return (
        <Container>

            <Box display="flex" flexDirection="row" justifyContent="center" width='100%' className={classes.container}>

                <div className={classes.root}>
                    <input
                        accept="csv/*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleUpload}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                            Upload
                        </Button>
                    </label>
                </div>
                <Button onClick={uploadNotifs} variant="contained" color="primary">
                            Confirm upload
                </Button>
            </Box>

            <UPCTable
                    loading={notifsLoading}
                    notifs={Object.values(notifs)}
                    addMessage={addNotif}
                    deleteMessage={deleteNotif}
                />
        </Container>
    );
}