import React from 'react';
import InfoPanel from './InfoPanel';
import { TextField, Button, LinearProgress } from '@material-ui/core';
import ProductsList from './ProductsList';

export default function ScanBoxesPage(props) {
    const {
        stepMessage,
        input,
        handleInputChange,
        onKeyPress,
        inputRef,
        handleSubmit,
        loading,
        reset,
        showBoxes,
        expectedItems,
        showStatsButton,
        onProcessWithoutLPN,
    } = props;

    return (
        <>
            <InfoPanel message={stepMessage} />
            <div className='input-container'>
                <TextField
                    className='code-input'
                    id='input'
                    label='Code'
                    type='text'
                    value={input}
                    onChange={handleInputChange}
                    variant='outlined'
                    fullWidth
                    onKeyPress={ev => onKeyPress(ev)}
                    inputRef={inputRef}
                />
                <div className='btn-scan-container'>
                    <Button onClick={handleSubmit} id='btn-scan'>
                        Scan
                    </Button>
                </div>
                {loading ? (
                    <LinearProgress className='main-progress-indicator' />
                ) : null}
            </div>
            <Button
                onClick={reset}
                style={{ margin: '0.2em' }}
                color='primary'
                variant='contained'
            >
                Reset
            </Button>
            <Button
                onClick={showBoxes}
                style={{ margin: '0.2em' }}
                color='primary'
                variant='contained'
            >
                Load
            </Button>
            {showStatsButton && (
                <Button
                    onClick={() => (window.location.href = '/returns/stats')}
                    style={{ margin: '0.2em' }}
                    color='primary'
                    variant='contained'
                >
                    Stats
                </Button>
            )}
            {expectedItems.length > 0 && (
                <ProductsList
                    products={expectedItems}
                    onExtraClick={onProcessWithoutLPN}
                />
            )}
        </>
    );
}
