import React from "react";
import LoadingDialog from "../common/LoadingDialog";
import {
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { utils } from "./shipments";

/**
 * Displays a table with information about all given boxes in a dialog. 
 * @param {Object} props - Component props.
 * @param {boolean} props.opened - Setting it to true opens a dialog.
 * @param {function} props.onClose - Callback function executed when a dialog
 * is being closed.
 * @param {function} props.onConfirm - Callback function executed when the confirm
 * button is clicked.
 * @param {boolean} props.loading - Tells if a dialog should display a loading indicator.
 * @param {Object[]} props.boxes - List of boxes to display.
 */
export default function ConfirmBoxesInfoDialog({
    opened,
    onClose,
    boxes,
    loading,
    onConfirm,
}) {
    return (
        <LoadingDialog
            onClose={onClose}
            opened={opened}
            loading={loading}
            title="Confirm boxes"
            classes={{
                paper: "shipping-page-background-color",
            }}
            maxWidth={false}
            disableBackdropClick
            actions={
                <>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={onConfirm}>
                        Confirm
                    </Button>
                </>
            }
        >
            <Table style={{ width: "100%" }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Box</TableCell>
                        <TableCell align="right">Units</TableCell>
                        <TableCell align="right">SKUs</TableCell>
                        <TableCell align="right">Weight (lbs)</TableCell>
                        <TableCell align="right">Dimensions (inches)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {boxes.map((box) => {
                        let units = utils.calcBoxSize(box);
                        let skus = box.items.length;
                        let { weight, length, width, height } = box;
                        return (
                            <TableRow key={box.id}>
                                <TableCell>Box {box.number}</TableCell>
                                <TableCell align="right">{units}</TableCell>
                                <TableCell align="right">{skus}</TableCell>
                                <TableCell align="right">{weight}</TableCell>
                                <TableCell align="right">
                                    {length} x {width} x {height}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </LoadingDialog>
    );
}
