import { put, select, all } from 'redux-saga/effects';
import * as shippingActions from '../../actions/shippingActions';
import * as notifications from '../../actions/notificationsActions';
import * as Sentry from '@sentry/react';
import {
    fulfillmentInbound,
    MARKETPLACE_ID,
} from '../../../utils/project_api/selling_partner';

/**
 * Saga function responsible for confirming the shipment by calling the confirmTransport operation from SP API.
 *
 * @param {Object} actionData - contains saga data and the shipment ID
 */
export function* confirmShipmentSaga(actionData) {
    let { shipmentId } = actionData;

    yield put(
        shippingActions.updateStatus({
            error: null,
            isConfirming: true,
        })
    );
    try {
        let result = yield fulfillmentInbound.confirmAndGetDeadline(shipmentId);
        yield all([
            // Update the shipment locally
            put(
                shippingActions.updateShipment(shipmentId, {
                    Estimate: result.Estimate,
                    PackageList: result.PackageList,
                    TransportStatus: result.Status,
                })
            ),
            put(
                shippingActions.updateStatus({
                    isConfirming: false,
                })
            ),
            // Save the shipment in the db
            put(shippingActions.saveShipments([shipmentId])),
            put(
                notifications.enqueueSnackbar({
                    message: 'Shipment confirmed',
                    options: { variant: 'success' },
                })
            ),
        ]);
    } catch (e) {
        Sentry.withScope(function (scope) {
            scope.setContext('confirmShipment', {
                shipmentId,
            });
            Sentry.captureException(e);
        });
        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                    isConfirming: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}

/**
 * Saga function responsible for voiding the shipment by calling the voidTransport operation from SP API.
 *
 * @param {Object} actionData - contains saga data and the shipment ID
 */
export function* voidShipmentSaga(actionData) {
    let { shipmentId } = actionData;

    yield put(
        shippingActions.updateStatus({
            error: null,
            isVoiding: true,
        })
    );
    try {
        let result = yield fulfillmentInbound.voidAndGetStatus(shipmentId);
        yield all([
            // Update the shipment locally
            put(
                shippingActions.updateShipment(shipmentId, {
                    Estimate: result.Estimate,
                    PackageList: result.PackageList,
                    TransportStatus: result.Status,
                })
            ),
            put(
                shippingActions.updateStatus({
                    isVoiding: false,
                })
            ),
            // Save the shipment in the db
            put(shippingActions.saveShipments([shipmentId])),
            put(
                notifications.enqueueSnackbar({
                    message: 'Shipment voided',
                    options: { variant: 'success' },
                })
            ),
        ]);
    } catch (e) {
        Sentry.withScope(function (scope) {
            scope.setContext('voidShipment', {
                shipmentId,
            });
            Sentry.captureException(e);
        });
        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                    isVoiding: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}

/**
 * Saga function responsible for marking shipments as SHIPPED in Seller Central. This is the last step in the process.
 *
 * @param {Object} actionData - contains saga data and the shipment ID
 */
export function* completeShipmentSaga(actionData) {
    let { shipmentId } = actionData;

    // Extract user data and shipments from the redux state
    let shipments = yield select(state => state.inboundShipments.shipments);

    let shipment = shipments.find(s => s.ShipmentId === shipmentId);
    yield put(
        shippingActions.updateStatus({
            error: null,
            isCompleting: true,
        })
    );
    try {
        yield fulfillmentInbound.updateInboundShipment({
            shipmentId,
            body: {
                MarketplaceId: MARKETPLACE_ID,
                InboundShipmentHeader: {
                    DestinationFulfillmentCenterId:
                        shipment.DestinationFulfillmentCenterId,
                    LabelPrepPreference: 'SELLER_LABEL',
                    ShipFromAddress: shipment.ShipFromAddress,
                    ShipmentName: shipment.ShipmentName,
                    ShipmentStatus: 'SHIPPED',
                },
                InboundShipmentItems: [],
            },
        });
        yield all([
            // Update the shipment status locally
            put(
                shippingActions.updateShipment(shipmentId, {
                    TransportStatus: 'SHIPPED',
                })
            ),
            put(
                shippingActions.updateStatus({
                    isCompleting: false,
                })
            ),
            // Save the shipment in the db
            put(shippingActions.saveShipments([shipmentId])),
            put(
                notifications.enqueueSnackbar({
                    message: 'Shipment completed',
                    options: { variant: 'success' },
                })
            ),
        ]);
    } catch (e) {
        Sentry.withScope(function (scope) {
            scope.setContext('completeShipment', {
                shipmentId,
            });
            Sentry.captureException(e);
        });
        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                    isCompleting: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}
