export const createShipment = shipment => ({
    type: 'CREATE_SHIPMENT',
    shipment,
});

export const updateShipment = (
    shipmentId,
    shipmentData,
    shouldResetStatus
) => ({
    type: 'UPDATE_SHIPMENT',
    shipmentId,
    shipmentData,
    shouldResetStatus,
});

export const createBox = shipmentId => ({
    type: 'CREATE_BOX',
    shipmentId,
});

export const addItem = (boxId, item) => ({
    type: 'ADD_ITEM',
    boxId,
    item,
});

export const addQuantity = (boxId, quantity) => ({
    type: 'ADD_QUANTITY',
    boxId,
    quantity,
});

export const setCurrentShipmentIds = shipmentIds => ({
    type: 'SET_CURRENT_SHIPMENT_IDS',
    shipmentIds,
});

export const setShipments = shipments => ({
    type: 'SET_SHIPMENTS',
    shipments,
});

export const addBoxDimensions = (
    shipmentId,
    weight,
    length,
    width,
    height,
    boxId
) => ({
    type: 'ADD_BOX_DIMENSIONS',
    shipmentId,
    weight,
    length,
    width,
    height,
    boxId,
});

export const updateBox = (boxId, items) => ({
    type: 'UPDATE_BOX',
    boxId,
    items,
});

export const deleteBox = boxId => ({
    type: 'DELETE_BOX',
    boxId,
});

export const updateItem = (boxId, item, quantity) => ({
    type: 'UPDATE_ITEM',
    item,
    boxId,
    quantity,
});

export const addLoadingShipment = (shipmentId, action) => ({
    type: 'ADD_LOADING_SHIPMENT',
    shipmentId,
    action,
});

export const deleteLoadingShipment = shipmentId => ({
    type: 'DELETE_LOADING_SHIPMENT',
    shipmentId,
});

export const clearLoadingShipments = () => ({
    type: 'CLEAR_LOADING_SHIPMENT',
});

export const updateStatus = status => ({
    type: 'UPDATE_STATUS',
    status,
});

export const getShipments = (credentials, address) => ({
    type: 'GET_SHIPMENTS',
    credentials,
    address
});

export const getShipmentItems = (fnsku, shipmentIds) => ({
    type: 'GET_SHIPMENT_ITEMS',
    shipmentIds,
    fnsku,
});

export const saveShipments = shipmentIds => ({
    type: 'SAVE_SHIPMENTS',
    shipmentIds,
});

export const setSelectedShipmentId = shipmentId => ({
    type: 'SET_SELECTED_SHIPMENT_ID',
    shipmentId,
});

export const confirmShipment = shipmentId => ({
    type: 'CONFIRM_SHIPMENT',
    shipmentId,
});

export const voidShipment = shipmentId => ({
    type: 'VOID_SHIPMENT',
    shipmentId,
});

export const getUniqueLabels = shipmentId => ({
    type: 'GET_UNIQUE_LABELS',
    shipmentId,
});

export const setScannedItem = scannedItem => ({
    type: 'SET_SCANNED_ITEM',
    scannedItem,
});

export const submitShipment = (shipment, needsUpdating) => ({
    type: 'SUBMIT_SHIPMENT',
    shipment,
    needsUpdating,
});

export const putContent = shipment => ({
    type: 'PUT_CONTENT',
    shipment,
});

export const updateSelectedShipment = (item, quantity) => ({
    type: 'UPDATE_SELECTED_SHIPMENT',
    item,
    quantity,
});
export const updateCurrentShipments = items => ({
    type: 'UPDATE_CURRENT_SHIPMENTS',
    items,
});

export const completeShipment = shipmentId => ({
    type: 'COMPLETE_SHIPMENT',
    shipmentId,
});

export const setProductDialogOpened = opened => ({
    type: 'SET_PRODUCT_DIALOG_OPENED',
    opened,
});

export const confirmBoxDeletion = boxId => ({
    type: 'CONFIRM_BOX_DELETION',
    boxId,
});

export const setDeleteConfirmationOpened = opened => ({
    type: 'SET_DELETE_CONFIRMATION_OPENED',
    opened,
});

export const setDeletedShipmentItem = (shipmentId, sku) => ({
    type: 'SET_DELETED_SHIPMENT_ITEM',
    shipmentId,
    sku,
});

export const deleteShipmentItem = (shipmentId, sku) => ({
    type: 'DELETE_SHIPMENT_ITEM',
    shipmentId,
    sku,
});

export const setDeleteShipmentItemConfirmation = open => ({
    type: 'SET_DELETE_SHIPMENT_ITEM_CONFIRMATION',
    open,
});

export const setExpectedItems = (shipmentId, items) => ({
    type: 'SET_EXPECTED_ITEMS',
    shipmentId,
    items,
});

export const deleteShipmentItemLocal = (shipmentId, sku) => ({
    type: 'DELETE_ITEM_FROM_SHIPMENT',
    shipmentId,
    sku,
});
