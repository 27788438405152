import { Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './BoxesNotifsPage.scss';
import * as notifications from '../../store/actions/notificationsActions';
import { upcNotifs, brandNotifs } from '../../utils/project_api';
import UPCTable from './UPCTable';
import BrandsTable from './BrandsTable';
import ImportNotifs from './ImportNotifs';

export default function BoxesNotifsPage() {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        page: 0,
        notifsLoading: true,
        notifs: [],
        brands: [],
    });

    const addMessage = async (upc, message) => {
        try {
            await upcNotifs.post(
                'add',
                {},
                {
                    data: {
                        upc,
                        message,
                    },
                }
            );

            // Immediately update the local state. If the UPC already exists in
            // the table, update it instead of adding a new element to the list.
            setState(s => {
                let stateCopy = { ...s };
                let index = s.notifs.findIndex(n => n.upc === upc);
                if (index > -1) {
                    stateCopy.notifs[index] = { upc, message };
                } else {
                    stateCopy.notifs.push({ upc, message });
                }
                return stateCopy;
            });
        } catch (e) {
            dispatch(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            );
        }
    };

    const addMultipleMessages = async (notifs) => {
        try {
            await upcNotifs.post(
                'add',
                {},
                {
                    data: {
                        notifs: notifs
                    },
                }
            );

            // Immediately update the local state. If the UPC already exists in
            // the table, update it instead of adding a new element to the list.
            setState(s => {
                let stateCopy = { ...s };
                notifs.forEach(({ upc, message}) => {
                    let index = s.notifs.findIndex(n => n.upc === upc);
                    if (index > -1) {
                        stateCopy.notifs[index] = { upc, message };
                    } else {
                        stateCopy.notifs.push({ upc, message });
                    }
                })
                return stateCopy;
            });
        } catch (e) {
            dispatch(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            );
        }
    }

    const deleteMessage = async upc => {
        try {
            await upcNotifs.delete('delete', { upc }, {});

            // Immediately update the local state
            setState(s => {
                let stateCopy = { ...s };
                stateCopy.notifs = stateCopy.notifs.filter(n => n.upc !== upc);
                return stateCopy;
            });
        } catch (e) {
            dispatch(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            );
        }
    };

    const addBrand = async brand => {
        try {
            await brandNotifs.post('add', undefined, { data: { brand } });

            // Immediately update the local state.
            setState(s => {
                let stateCopy = JSON.parse(JSON.stringify(s));
                if (
                    !stateCopy.brands.find(
                        b => b.brand.toLowerCase() === brand.toLowerCase()
                    )
                ) {
                    stateCopy.brands.push({ brand });
                }
                return stateCopy;
            });
        } catch (e) {
            dispatch(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            );
        }
    };

    const deleteBrand = async brand => {
        try {
            await brandNotifs.delete('delete', { brand });

            // Immediately update the local state
            setState(s => {
                let stateCopy = { ...s };
                stateCopy.brands = stateCopy.brands.filter(
                    b => b.brand !== brand
                );
                return stateCopy;
            });
        } catch (e) {
            dispatch(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            );
        }
    };

    const getMessages = useCallback(async () => {
        try {
            let notifsResult = await upcNotifs.get('getAll');
            let brandsResult = await brandNotifs.get('get');
            setState(s => ({
                ...s,
                notifs: notifsResult.messages,
                brands: brandsResult.brands,
            }));
        } catch (e) {
            dispatch(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            );
        } finally {
            setState(s => ({ ...s, notifsLoading: false }));
        }
    }, [dispatch]);

    useEffect(() => {
        getMessages();
    }, [getMessages]);

    return (
        <div className='page-container' style={{ position: 'relative' }}>
            <Tabs
                value={state.page}
                onChange={(_, newValue) =>
                    {
                        setState({ ...state, page: newValue})
                    }
                }
                indicatorColor='primary'
                variant='fullWidth'
            >
                <Tab label='UPC' />
                <Tab label='Brands' />
                <Tab label='Import' />
            </Tabs>

            {(() => {
                switch (state.page) {
                    case 0:
                        return <UPCTable
                            loading={state.notifsLoading}
                            notifs={[...state.notifs]}
                            addMessage={addMessage}
                            deleteMessage={deleteMessage}
                        />
                    case 1:
                        return <BrandsTable
                            loading={state.notifsLoading}
                            brands={state.brands}
                            addBrand={addBrand}
                            deleteBrand={deleteBrand}
                        />
                    case 2:
                        return <ImportNotifs
                            refresh={getMessages}
                            upload={addMultipleMessages}
                        />;
                    default:
                        return;
                }
            })()}
        </div>
    );
}
