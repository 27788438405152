import React from 'react';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import LoadingDialog from '../common/LoadingDialog';
import ConfirmationDialog from '../common/ConfirmationDialog';

/**
 * Dialog which allows to edit box's width, length, height and weight.
 * @param {Object} props - Component props.
 * @param {boolean} props.opened - Setting it to true displays a dialog.
 * @param {function} props.onClose - Callback function executed when a dialog
 * is being closed.
 * @param {function} props.onSave - Callback function executed when the user wants to
 * finish editing. 
 */
export default function FinishBoxDialog({
    opened,
    onClose,
    onSave,
}) {
    const [state, setState] = React.useState({
        weightInput: '',
        lengthInput: '',
        widthInput: '',
        heightInput: '',
        errorMsg: null,
        confirmationDialogVisible: false,
    });

    const handleChange = e => {
        if (e.target.id !== undefined) {
            setState({ ...state, [e.target.id]: e.target.value });
        }
    };

    return (
        <>
            <LoadingDialog
                title='Save box'
                opened={opened}
                onClose={() => {
                    setState({ ...state, errorMsg: null });
                    onClose();
                }}
                classes={{
                    paper: 'shipping-page-background-color',
                }}
                actions={
                    <>
                        <Button onClick={onClose} color='primary'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                let weight = Number(state.weightInput);
                                let width = Number(state.widthInput);
                                let length = Number(state.lengthInput);
                                let height = Number(state.heightInput);

                                if (
                                    !isNaN(weight) &&
                                    !isNaN(width) &&
                                    !isNaN(length) &&
                                    !isNaN(height) &&
                                    weight > 0 &&
                                    width > 0 &&
                                    length > 0 &&
                                    height > 0
                                ) {
                                    if (
                                        width > 24 ||
                                        height > 24 ||
                                        length > 24 ||
                                        weight >= 50
                                    ) {
                                        setState({
                                            ...state,
                                            confirmationDialogVisible: true,
                                        });
                                    } else {
                                        onSave(weight, length, width, height);
                                    }
                                }
                            }}
                            color='primary'
                        >
                            Save
                        </Button>
                    </>
                }
            >
                <div className='finish-box-dialog-container TextField-with-border-radius'>
                    <div className='weight-container'>
                        <TextField
                            id='weightInput'
                            label='Weight'
                            className='weight-input'
                            type='number'
                            variant='outlined'
                            value={state.weightInput}
                            onChange={e => {
                                handleChange(e);
                                let weight = Number(e.target.value);
                                if (!isNaN(weight) && weight >= 50) {
                                    setState(prevState => ({
                                        ...prevState,
                                        errorMsg:
                                            'Are you sure you want it to be that heavy?',
                                    }));
                                } else {
                                    setState(prevState => ({
                                        ...prevState,
                                        errorMsg: null,
                                    }));
                                }
                            }}
                            inputProps={{ min: '0' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        style={{ margin: 0 }}
                                    >
                                        lbs
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {/* <p> lbs</p> */}
                    </div>
                    <div className='dimensions-container'>
                        <TextField
                            id='lengthInput'
                            label='Length'
                            className='dimension-input'
                            type='number'
                            variant='outlined'
                            value={state.lengthInput}
                            onChange={handleChange}
                            inputProps={{ min: '0' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        in
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <p>x</p>
                        <TextField
                            id='widthInput'
                            label='Width'
                            type='number'
                            className='dimension-input'
                            variant='outlined'
                            value={state.widthInput}
                            onChange={handleChange}
                            inputProps={{ min: '0' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        in
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <p>x</p>
                        <TextField
                            id='heightInput'
                            label='Height'
                            type='number'
                            className='dimension-input'
                            variant='outlined'
                            value={state.heightInput}
                            onChange={handleChange}
                            inputProps={{ min: '0' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        in
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <p style={{ margin: 0, color: 'red', fontSize: 12 }}>
                        {state.errorMsg}
                    </p>
                </div>
            </LoadingDialog>
            <ConfirmationDialog
                opened={state.confirmationDialogVisible}
                message='Are you sure these dimensions are correct?'
                onClose={() => {
                    setState({ ...state, confirmationDialogVisible: false });
                }}
                onConfirm={() => {
                    setState({ ...state, confirmationDialogVisible: false });
                    let weight = Number(state.weightInput);
                    let width = Number(state.widthInput);
                    let length = Number(state.lengthInput);
                    let height = Number(state.heightInput);
                    onSave(weight, length, width, height);
                }}
            />
        </>
    );
}
