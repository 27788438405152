import { Box, Divider, LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    list: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        backgroundColor: theme.palette.background.paper,
    },
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));

function getCurrDate(t) {
    var tFormatted = JSON.parse(JSON.stringify(t).replace(/[_-]/g, ''));
    var today = new firebase.firestore.Timestamp(
        tFormatted.seconds,
        tFormatted.nanoseconds
    ).toDate();
    return today.toLocaleString('en-US');
}

export default function SelectProductsList(props) {
    const classes = useStyles();
    const { onClose, opened, productsLists, onSelectList, loading } = props;

    return (
        <Dialog
            open={opened}
            onClose={onClose}
            aria-labelledby='form-dialog-title'
            maxWidth='md'
        >
            <DialogTitle id='alert-dialog-title'>
                {'Choose your products list'}
                <IconButton
                    aria-label='Close'
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    justifyContent='center'
                    alignItems='center'
                    display='flex'
                    style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        overflowY: 'auto',
                        padding: 10,
                        minWidth: 400,
                    }}
                >
                    <List className={classes.list}>
                        {productsLists.map((list, i) => (
                            <Box key={list.dbID}>
                                <ListItem
                                    onClick={() => {
                                        return onSelectList(list);
                                    }}
                                    button
                                    style={{ justifyContent: 'space-between' }}
                                >
                                    <Typography variant='h6'>
                                        {getCurrDate(list.date)}
                                    </Typography>
                                    <Box
                                        justifyContent='flex-start'
                                        marginLeft={15}
                                    >
                                        <Typography variant='subtitle2'>
                                            Quantity: {list.quantity}
                                        </Typography>
                                        <Typography variant='subtitle2'>
                                            Products: {list.numOfProducts}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                {i < productsLists.length - 1 ? (
                                    <Divider
                                        variant='fullWidth'
                                        component='li'
                                    />
                                ) : null}
                            </Box>
                        ))}
                    </List>
                </Box>
            </DialogContent>
            {loading ? <LinearProgress className={classes.progress} /> : null}
        </Dialog>
    );
}

SelectProductsList.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    productsLists: PropTypes.array,
    onClose: PropTypes.func,
    onSelectList: PropTypes.func,
    loading: PropTypes.bool,
};
