import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signoutSuccess } from '../../store/actions/authActions'
import firebase from 'firebase';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from '@material-ui/core/Divider';


const AdminLinks = (props) => {
    let { onItemClick } = props;
    var signOut = () => {
        onItemClick("Project")()
        firebase.auth().signOut().then((resp) => { props.dispatch(signoutSuccess(resp)) })
    }

    return (
        <List>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/"
                onClick={onItemClick("Home")}
            >
                <ListItemText color="#000">Home</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/import"
                onClick={onItemClick("Import")}
            >
                <ListItemText>Import</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/upc"
                onClick={onItemClick("Product")}
            >
                <ListItemText>Product</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/sourcing"
                onClick={onItemClick("Sourcing")}
            >
                <ListItemText>Sourcing</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/sierradb"
                onClick={onItemClick("SierraDB")}
            >
                <ListItemText>SierraDB</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/orders"
                onClick={onItemClick("Orders")}
            >
                <ListItemText>Orders</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/scheduler"
                onClick={onItemClick("Scheduler")}
            >
                <ListItemText>Scheduler</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/repricer"
                onClick={onItemClick("Repricer")}
            >
                <ListItemText>Repricer</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/notifs"
                onClick={onItemClick("Notifs")}
            >
                <ListItemText>Notifs</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/shipments"
                onClick={onItemClick("Shipments")}
            >
                <ListItemText>Shipments</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/box"
                onClick={onItemClick("Boxes")}
            >
                <ListItemText>Boxes</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/returns"
                onClick={onItemClick("Returns")}
            >
                <ListItemText>Returns</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/box_shipment"
                onClick={onItemClick("Box Shipment")}
            >
                <ListItemText>Shipping</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/buylist"
                onClick={onItemClick("Buylist Viewer")}
            >
                <ListItemText>Buylist Viewer</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/boxes_notifs"
                onClick={onItemClick("Boxes Notifs")}
            >
                <ListItemText>Boxes Notifs</ListItemText>
            </ListItem>
            <Divider />
            <ListItem
                button
                component={Link}
                to="/settings"
                onClick={onItemClick("Settings")}
            >
                <ListItemText>Settings</ListItemText>
            </ListItem>
            <ListItem
                button
                onClick={signOut}
            >
                <ListItemText>Log out</ListItemText>
            </ListItem>
        </List>
    )
}

export default connect()(AdminLinks)