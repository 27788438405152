import { FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getToken } from '../../utils/auth';

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

const niceScrollTheme = createMuiTheme({
    overrides: {
        MUIDataTable: {
            responsiveScroll: {
                maxHeight: '720px'
            }
        }
    }
});

class SchedulerBrowser extends Component {
    state = {
        productData: [],
        errorMsg: '',
        snackbarOpened: false,
        isLoading: false,
        loadingMsg: '',
    }

    constructor(props) {
        super(props)

        this.textInput = React.createRef();
        this.focusTextInput = this.focusTextInput.bind(this);
    }

    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;

        const columns = [
            {
                name: "change",
                label: "Change",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Change includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "changeCount",
                label: "Count",
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Count: ${v['min']}, Max Count: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Count: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Count: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Count</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                }
            },
            {
                name: "brand",
                label: "Brand",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'dropdown',
                    customFilterListRender: v => {
                        if (v) {
                            return `isClearance: ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "name",
                label: "Name",
                options: {
                    filter: true,
                    sort: true,
                    customFilterListRender: v => {
                        if (v) {
                            return `Name includes ${v}`
                        }
                        return false;
                    },
                }
            },
            {
                name: "price",
                label: "Price",
                options: {
                    filter: true,
                    display: true,
                    filterType: 'custom',
                    customFilterListRender: v => {
                        if (v['min'] && v['max']) {
                            return `Min Price: ${v['min']}, Max Price: ${v['max']}`;
                        } else if (v['min']) {
                            return `Min Price: ${v['min']}`;
                        } else if (v['max']) {
                            return `Max Price: ${v['max']}`;
                        }
                        return false;
                    },
                    filterOptions: {
                        names: [],
                        logic(age, filters) {
                            if (filters['min'] && filters['max']) {
                                return age < filters['min'] || age > filters['max'];
                            } else if (filters['min']) {
                                return age < filters['min'];
                            } else if (filters['max']) {
                                return age > filters['max'];
                            }
                            return false;
                        },
                        display: (filterList, onChange, index, column) => (
                            <div>
                                <FormLabel>Price</FormLabel>
                                <FormGroup row>
                                    <TextField
                                        label="min"
                                        value={filterList[index]['min'] || ''}
                                        onChange={event => {
                                            filterList[index]['min'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%', marginRight: '5%' }}
                                    />
                                    <TextField
                                        label="max"
                                        value={filterList[index]['max'] || ''}
                                        onChange={event => {
                                            filterList[index]['max'] = event.target.value;
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '45%' }}
                                    />
                                </FormGroup>
                            </div>
                        ),
                    },
                },
            },
            {
                name: "date",
                label: "SortDate",
                options: {
                    sort: true,
                    display: true,
                    sortDirection: 'desc',
                    customBodyRender: (value, _, __) => {
                        return <p>{value.replace("T", " ").replace("Z", " ").split(".")[0]}</p>
                    }
                }
            },
            {
                name: "newDate",
                label: "Date",
                options: {
                    sort: true,
                    display: true,
                    sortDirection: 'desc',
                    customBodyRender: (value, _, __) => {
                        return <p>{value.replace("T", " ").replace("Z", " ").split(".")[0]}</p>
                    }
                }
            },
            {
                name: "url",
                label: "URL",
                options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, _, __) => {
                        return <a target="_blank" rel="noopener noreferrer" href={value}>Link</a>
                    }
                }
            },

        ]

        const options = {
            filterType: 'textField',
            selectableRows: 'none',
            responsive: 'scroll',
            print: false,
        };

        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container display="flex" maxWidth={false} height="100%">
                <Container width='100%' m={3} >
                    <Box display="flex" flexDirection="row" className={classes.container} justifyContent="center" alignItems="center">
                        <Button onClick={() => this.getData(this.state.keyword)}>
                            Download
                        </Button>
                    </Box>
                </Container>


                <Container width='100%' maxWidth="xl" height="100%" className={classes.container}>
                    <MuiThemeProvider theme={niceScrollTheme}>
                        <MUIDataTable
                            title={"Scheduler Data"}
                            data={this.state.productData}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>


                </Container>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.snackbarOpened}
                    autoHideDuration={6000}
                >
                    <SnackbarContent
                        className={clsx(classes.error, classes.margin)}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                                {this.state.errorMsg}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="Close" color="inherit" onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                this.setState({ snackbarOpened: false })
                            }} >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </Container>
        )
    }

    componentDidMount() {
        // this.getCollections()
    }

    focusTextInput() {
        this.textInput.current.focus();
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    changePage = (page) => {
        this.setState({
            isLoading: true,
        });
    };

    getData = async (keyword) => {
        // this.setState({ isLoading: true });
        let token = await getToken();
        fetch(`https://api.projectsuite.io/repricer?db=scheduler&collection=test&firebase_token=${token}`).then(response => {
            return response.json();
        }).then(jsonResponse => {
            console.log(jsonResponse)
            let prods = jsonResponse["result"]
            if (prods != null) {
                // Product changes to match what we want to see 
                prods = prods.map(prod => {
                    prod.price = parseFloat(prod.price)
                    if (prod.change.includes("sizes") || prod.change.includes("colors")) {
                        prod.changeCount = prod.change.split('(')[1].split(')')[0].split(',').length
                    } else {
                        prod.changeCount = -1
                    }
                    prod.newDate = new Date(prod.date).toLocaleString("en-US", { timeZone: "America/Winnipeg" })
                    return prod
                })

                // Product filtering
                prods = prods.filter(prod => !prod.name.includes("Factory Seconds"))


                this.setState({ productData: prods })
            } else {
                this.setState({ snackbarOpened: true, errorMsg: 'No results found', isLoading: false })
            }
        })
    }
}

SchedulerBrowser.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(SchedulerBrowser))