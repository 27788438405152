import {
    ConfirmTransportResponse,
    CreateInboundShipmentPlanRequest,
    CreateInboundShipmentPlanResult,
    EstimateTransportResponse,
    FbaInboundApiConfirmTransportRequest,
    FbaInboundApiCreateInboundShipmentRequest,
    FbaInboundApiEstimateTransportRequest,
    FbaInboundApiGetLabelsRequest,
    FbaInboundApiGetShipmentItemsByShipmentIdRequest,
    FbaInboundApiGetShipmentItemsRequest,
    FbaInboundApiGetShipmentsRequest,
    FbaInboundApiGetTransportDetailsRequest,
    FbaInboundApiPutTransportDetailsRequest,
    FbaInboundApiUpdateInboundShipmentRequest,
    FbaInboundApiVoidTransportRequest,
    GetLabelsResponse,
    GetShipmentItemsResponse,
    GetShipmentsResponse,
    GetTransportDetailsResult,
    InboundShipmentResponse,
    LabelPrepPreference,
    PutTransportDetailsResponse,
    ShipmentStatus,
    VoidTransportResponse,
} from '@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model';
import { MARKETPLACE_ID } from '..';
import { DOMAIN, sleep } from '../../utils';
import { RequestExtraParams } from '../types';
import { extractTransportContent, makeCall } from '../utils';

const URL_BASE = `${DOMAIN}/spApi/fulfillmentInbound`;

export async function createInboundShipmentPlan(
    request: CreateInboundShipmentPlanRequest & RequestExtraParams
): Promise<CreateInboundShipmentPlanResult> {
    const res = await makeCall<CreateInboundShipmentPlanResult>(
        'post',
        `${URL_BASE}/createInboundShipmentPlan`,
        { devMode: request.devMode },
        request
    );

    return res;
}

export async function createInboundShipmentPlanEasy(
    products: any[],
    address: any,
    labelPrepPreference: LabelPrepPreference,
    devMode?: boolean
) {
    const {
        name,
        address1,
        address2,
        city,
        stateCode,
        postalCode,
        countryCode,
    } = address;

    let request: CreateInboundShipmentPlanRequest = {
        InboundShipmentPlanRequestItems: products.map(product => ({
            ASIN: product.ASIN,
            Condition: 'NewItem',
            Quantity: product.Quantity,
            SellerSKU: product.SKU,
        })),
        LabelPrepPreference: labelPrepPreference,
        ShipFromAddress: {
            AddressLine1: address1,
            City: city,
            CountryCode: countryCode,
            Name: name,
            PostalCode: postalCode,
            StateOrProvinceCode: stateCode,
        },
    };

    if (address2.trim() !== '') {
        request.ShipFromAddress.AddressLine2 = address2;
    }

    return createInboundShipmentPlan({ ...request, ...{ devMode } });
}

export async function updateInboundShipment(
    request: FbaInboundApiUpdateInboundShipmentRequest & RequestExtraParams
): Promise<InboundShipmentResponse> {
    const { body, ...queryParams } = request;
    const res = await makeCall<InboundShipmentResponse>(
        'put',
        `${URL_BASE}/updateInboundShipment`,
        queryParams,
        body
    );

    return res;
}

export async function createInboundShipment(
    request: FbaInboundApiCreateInboundShipmentRequest & RequestExtraParams
): Promise<InboundShipmentResponse> {
    const { body, ...queryParams } = request;
    const res = await makeCall<InboundShipmentResponse>(
        'post',
        `${URL_BASE}/createInboundShipment`,
        queryParams,
        body
    );

    return res;
}

export function createInboundShipmentEasy(
    box: any,
    address: any,
    labelPrepPreference: LabelPrepPreference,
    shipmentStatus: ShipmentStatus,
    devMode?: boolean
): Promise<InboundShipmentResponse> {
    const {
        name,
        address1,
        address2,
        city,
        stateCode,
        postalCode,
        countryCode,
    } = address;

    let request: FbaInboundApiCreateInboundShipmentRequest = {
        shipmentId: box.shipmentID,
        body: {
            InboundShipmentHeader: {
                DestinationFulfillmentCenterId: box.warehouse,
                LabelPrepPreference: labelPrepPreference,
                ShipmentName: box.shipmentName,
                ShipmentStatus: shipmentStatus,
                ShipFromAddress: {
                    AddressLine1: address1,
                    City: city,
                    CountryCode: countryCode,
                    Name: name,
                    PostalCode: postalCode,
                    StateOrProvinceCode: stateCode,
                },
                IntendedBoxContentsSource: 'FEED',
            },
            MarketplaceId: MARKETPLACE_ID,
            InboundShipmentItems: box.products.map((product: any) => ({
                SellerSKU: product.SKU,
                QuantityShipped: product.Quantity,
            })),
        },
    };

    // let sandboxRequest: FbaInboundApiCreateInboundShipmentRequest = {
    //     shipmentId: 'ShipmentId',
    //     body: {
    //         InboundShipmentHeader: {
    //             ShipmentName: '43545345',
    //             ShipFromAddress: {
    //                 Name: '35435345',
    //                 AddressLine1: '123 any st',
    //                 DistrictOrCounty: 'Washtenaw',
    //                 City: 'Ann Arbor',
    //                 StateOrProvinceCode: 'Test',
    //                 CountryCode: 'US',
    //                 PostalCode: '48103',
    //             },
    //             DestinationFulfillmentCenterId: 'AEB2',
    //             AreCasesRequired: true,
    //             ShipmentStatus: 'WORKING',
    //             LabelPrepPreference: 'SELLER_LABEL',
    //             IntendedBoxContentsSource: 'NONE',
    //         },
    //         InboundShipmentItems: [
    //             {
    //                 ShipmentId: '345453',
    //                 SellerSKU: '34534545',
    //                 FulfillmentNetworkSKU: '435435435',
    //                 QuantityShipped: 0,
    //                 QuantityReceived: 0,
    //                 QuantityInCase: 0,
    //                 ReleaseDate: '2020-04-23',
    //                 PrepDetailsList: [
    //                     {
    //                         PrepInstruction: 'Polybagging',
    //                         PrepOwner: 'AMAZON',
    //                     },
    //                 ],
    //             },
    //         ],
    //         MarketplaceId: 'MarketplaceId',
    //     },
    // };

    if (address2.trim() !== '') {
        request.body.InboundShipmentHeader.ShipFromAddress.AddressLine2 =
            address2;
    }

    return createInboundShipment({ ...request, ...{ devMode } });
}

export async function getTransportDetails(
    request: FbaInboundApiGetTransportDetailsRequest & RequestExtraParams
): Promise<GetTransportDetailsResult> {
    const res = await makeCall<GetTransportDetailsResult>(
        'get',
        `${URL_BASE}/getTransportDetails`,
        request
    );

    return res;
}

export async function putTransportDetails(
    request: FbaInboundApiPutTransportDetailsRequest & RequestExtraParams
): Promise<PutTransportDetailsResponse> {
    const { body, ...queryParams } = request;
    const res = await makeCall<PutTransportDetailsResponse>(
        'put',
        `${URL_BASE}/putTransportDetails`,
        queryParams,
        body
    );

    return res;
}

export async function putTransportDetailsPartneredSmallParcelData(
    shipmentId: string,
    boxes: any[],
    devMode?: boolean
): Promise<PutTransportDetailsResponse> {
    let request: FbaInboundApiPutTransportDetailsRequest = {
        shipmentId,
        body: {
            IsPartnered: true,
            ShipmentType: 'SP',
            TransportDetails: {
                PartneredSmallParcelData: {
                    PackageList: boxes.map(box => ({
                        Dimensions: {
                            Height: box.height.toFixed(2),
                            Length: box.length.toFixed(2),
                            Width: box.width.toFixed(2),
                            Unit: 'inches',
                        },
                        Weight: {
                            Unit: 'pounds',
                            Value: Math.round(box.weight),
                        },
                    })),
                },
            },
        },
    };

    return putTransportDetails({ ...request, devMode });
}

export async function voidTransport(
    request: FbaInboundApiVoidTransportRequest & RequestExtraParams
): Promise<VoidTransportResponse> {
    const res = await makeCall<VoidTransportResponse>(
        'post',
        `${URL_BASE}/voidTransport`,
        request
    );

    return res;
}

export async function estimateTransport(
    request: FbaInboundApiEstimateTransportRequest & RequestExtraParams
): Promise<EstimateTransportResponse> {
    const res = await makeCall<EstimateTransportResponse>(
        'post',
        `${URL_BASE}/estimateTransport`,
        request
    );

    return res;
}

export async function confirmTransport(
    request: FbaInboundApiConfirmTransportRequest & RequestExtraParams
): Promise<ConfirmTransportResponse> {
    const res = await makeCall<ConfirmTransportResponse>(
        'post',
        `${URL_BASE}/confirmTransport`,
        request
    );

    return res;
}

export async function getLabels(
    request: FbaInboundApiGetLabelsRequest & RequestExtraParams
): Promise<GetLabelsResponse> {
    const res = await makeCall<GetLabelsResponse>(
        'get',
        `${URL_BASE}/getLabels`,
        request
    );

    return res;
}

export async function getShipments(
    request: FbaInboundApiGetShipmentsRequest & RequestExtraParams
): Promise<GetShipmentsResponse> {
    const res = await makeCall<GetShipmentsResponse>(
        'get',
        `${URL_BASE}/getShipments`,
        request
    );

    return res;
}

export async function getShipmentItemsByShipmentId(
    request: FbaInboundApiGetShipmentItemsByShipmentIdRequest &
        RequestExtraParams
): Promise<GetShipmentItemsResponse> {
    const res = await makeCall<GetShipmentItemsResponse>(
        'get',
        `${URL_BASE}/getShipmentItemsByShipmentId`,
        request
    );

    return res;
}

export async function getShipmentItems(
    request: FbaInboundApiGetShipmentItemsRequest & RequestExtraParams
): Promise<GetShipmentItemsResponse> {
    const res = await makeCall<GetShipmentItemsResponse>(
        'get',
        `${URL_BASE}/getShipmentItems`,
        request
    );

    return res;
}

export async function confirmAndGetDeadline(shipmentId: string) {
    const devMode = false;
    await confirmTransport({
        shipmentId,
        devMode,
    });
    let transportContent = extractTransportContent(
        await getTransportDetails({
            shipmentId,
            devMode,
        })
    );
    while (
        !(
            transportContent.Estimate && transportContent.Estimate.VoidDeadline
        ) &&
        transportContent.Status !== 'ERROR_ON_CONFIRMING'
    ) {
        await sleep(1000);
        transportContent = extractTransportContent(
            await getTransportDetails({
                shipmentId,
                devMode,
            })
        );
    }
    return transportContent;
}

export async function voidAndGetStatus(shipmentId: string) {
    const devMode = false;
    await voidTransport({
        shipmentId,
        devMode,
    });
    let transportContent = extractTransportContent(
        await getTransportDetails({
            shipmentId,
            devMode,
        })
    );
    while (
        transportContent.Status !== 'VOIDED' &&
        transportContent.Status !== 'WORKING' &&
        transportContent.Status !== 'ERROR_IN_VOIDING'
    ) {
        await sleep(1000);
        transportContent = extractTransportContent(
            await getTransportDetails({
                shipmentId,
                devMode,
            })
        );
    }
    return transportContent;
}
