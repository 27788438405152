import MaterialTable from 'material-table';
import React from 'react';

/**
 * List of columns extracted from data passed to the MaterialTable component.
 */
const columns = [
    {
        title: 'Brand',
        field: 'brand',
        editable: 'onAdd',
    },
];

/**
 * Renders an editable table of banned brands.
 * 
 * @param {Object} props - Component props.
 * @param {boolean} props.loading - Indicates if the table should be in the loading state.
 * @param {Object[]} props.brands - List of brand objects with the 'brand' property.
 * @param {function} props.addMessage - Callback function executed when a new brand should be added.
 * @param {function} props.deleteMessage - Callback function executed when a brand should be deleted.
 */
export default function BrandsTable({
    loading,
    brands,
    addBrand,
    deleteBrand,
}) {
    return (
        <MaterialTable
            isLoading={loading}
            title=''
            data={brands}
            columns={columns}
            style={{ width: '100%', marginTop: '1em', padding: '0.2em' }}
            editable={{
                onRowAdd: newData => addBrand(newData.brand.trim()),
                onRowDelete: oldData => deleteBrand(oldData.brand),
            }}
        />
    );
}
