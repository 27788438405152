import { put, select, all } from 'redux-saga/effects';
import * as shippingActions from '../../actions/shippingActions';
import * as notifications from '../../actions/notificationsActions';
import * as Sentry from '@sentry/react';
import { inboundShipments } from '../../../utils/project_api';

const downloadPdf = function (blobUrl, shipmentName) {
    const link = document.createElement('a');
    link.download = `${shipmentName}.pdf`;
    link.href = blobUrl;
    link.click();
};

/**
 * Saga function responsible for downloading package labels for the given
 * shipment.
 * 
 * @param {Object} actionData 
 */
export function* getUniqueLabels(actionData) {
    let { shipmentId } = actionData;
    let shipments = yield select(state => state.inboundShipments.shipments);

    let shipment = shipments.find(s => s.ShipmentId === shipmentId);
    yield put(
        shippingActions.updateStatus({
            error: null,
            isFetchingLabels: true,
        })
    );
    try {
        // Pass the shipment to the server
        let response = yield inboundShipments.post(
            'getPackageLabels',
            {},
            { data: { shipment } }
        );
        if (response.error) {
            throw new Error(response.error);
        }

        let blob = new Blob([Uint8Array.from(response.result.data).buffer], {
            type: 'application/pdf',
        });
        let blobURL = URL.createObjectURL(blob);
        downloadPdf(blobURL, shipment.ShipmentName);
        yield all([
            put(
                shippingActions.updateStatus({
                    isFetchingLabels: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: 'Labels downloaded',
                    options: { variant: 'success' },
                })
            ),
        ]);
    } catch (e) {
        Sentry.withScope(function (scope) {
            Sentry.setContext('printLabels', {
                shipmentId,
            });
            Sentry.captureException(e);
        });

        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                    isFetchingLabels: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}
