const initState = {
    userData: null,
    address: null,
    salesData: null,
    purchasedData: null,
}

const dbReducer = (state = initState, action) => {
    switch(action.type) {
        case 'PASS_CREDENTIALS':
            return {
                ...state,
                userData: action.credentials,
            }
        case 'PASS_ADDRESS':
            return {
                ...state,
                address: action.address
            }
        case 'PASS_SALESDATA':
            return {
                ...state,
                salesData: action.salesData
            }
        case 'PASS_PURCHASEDDATA':
            return {
                ...state,
                purchasedData: action.purchasedData
            }
        default:
            return state
    }
}

export default dbReducer