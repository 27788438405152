import * as utils from '../../components/shipping_boxes/shipments/utils';

const _ = require('lodash');

const initState = {
    /** List of all downloaded shipments. */
    shipments: [],
    /** List of identifiers of shipments containing the scanned item. */
    currentShipmentsIds: [],
    /** 
     * List of objects containing a shipment ID and a message. Represents
     * shipments which are being processed by some request.
     */
    loadingShipments: [],
    /** Scanned item data. */
    scannedItem: null,
    /** Identifier of a shipment selected by the user. */
    selectedShipmentId: null,
    /** Setting it to true opens a ProductDialog. */
    productDialogOpened: false,
    /** 
     * Setting it to true opens a confirmation dialog with information about 
     * a box the user wants to delete.
     */
    boxDeletionConfirmation: false,
    /** Identifier of a box the user wants to delete. */
    boxToDeleteId: null,
    /** 
     * Stores the shipment ID and the SKU code of an item the user wants
     * to remove from a shipment.
     */
    deletedItem: null,
    /** 
     * Setting it to true opens a confirmation dialog with information about 
     * an item the user wants to remove from a shipment.
     */
    shipmentItemDeletionConfirmation: false,
    status: {
        error: null,
        /** Indicates if shipments' data is being fetched. */
        isFetchingShipments: false,
        /** Indicates if product's data is being fetched. */
        isFetchingProduct: false,
        /** Indicates if product labels are being fetched. */
        isFetchingLabels: false,
        /** Indicates if shipments' data is being saved in the database. */
        isSaving: false,
        /** Indicates if a shipment is being submitted to Seller Central. */
        isSubmitting: false,
        /** Indicates if a shipment is being confirmed in Seller Central. */
        isConfirming: false,
        /** Indicates if a shipment is being marked as completed in Seller Central. */
        isCompleting: false,
        /** Indicates if a shipment is being voided. */
        isVoiding: false,
        lastUpdated: 0,
    },
};

export const inboundShipmentsReducer = (state = initState, action) => {
    let stateCopy = _.cloneDeep(state);
    let box, shipment, newItem, item, index;

    switch (action.type) {
        // Creates an object representing a shipment
        case 'CREATE_SHIPMENT':
            // If the shipment with this ID exists, skip
            if (
                stateCopy.shipments.find(
                    s => s.ShipmentId === action.shipment.ShipmentId
                )
            ) {
                return stateCopy;
            }
            var newShipment = {
                ...action.shipment,
            };
            // Make sure that at least 1 box exists
            if (!action.shipment.Boxes || action.shipment.Boxes.length === 0) {
                box = utils.newBox(1, action.shipment.ShipmentId);
                newShipment.Boxes = [box];
                newShipment.CurrentBox = box.id;
            }
            stateCopy.shipments.push(newShipment);
            return stateCopy;

        // Updates a shipment of the given ID with the given data
        case 'UPDATE_SHIPMENT':
            index = stateCopy.shipments.findIndex(
                s => s.ShipmentId === action.shipmentId
            );
            let resetData = {};
            if (action.shouldResetStatus) {
                resetData = {
                    TransportStatus: null,
                    PackageList: null,
                    Estimate: null,
                    LatestFeedInfo: null,
                };
            }
            if (index > -1) {
                stateCopy.shipments[index] = {
                    ...stateCopy.shipments[index],
                    ...action.shipmentData,
                    ...resetData,
                };
            }
            return stateCopy;

        // Creates an empty box in the given shipment
        case 'CREATE_BOX':
            shipment = stateCopy.shipments.find(
                s => s.ShipmentId === action.shipmentId
            );
            if (shipment) {
                box = utils.newBox(
                    shipment.Boxes.length + 1,
                    shipment.ShipmentId
                );
                shipment.Boxes.push(box);
                // Mark the new box as the current box. This way
                // new items will automatically be added to it
                shipment.CurrentBox = box.id;
            }

            return stateCopy;

        // Adds the given item to the given box. Its quantity is automatically set to 0.
        case 'ADD_ITEM':
            // First find the wanted box
            [box, shipment] = utils.findBox(stateCopy.shipments, action.boxId);
            if (box) {
                newItem = action.item;
                // Try to find the given item in the found box
                var foundItem = box.items.find(
                    i =>
                        i.FulfillmentNetworkSKU ===
                        newItem.FulfillmentNetworkSKU
                );

                // If the item is already added, only mark it as the current item
                if (foundItem) {
                    foundItem.QuantityLeft = newItem.QuantityShipped;
                    box.currentItem = foundItem.FulfillmentNetworkSKU;
                } 
                // If the item is not present in the found box, make sure that
                // its quantity in the whole shipment doesn't exceed the limit
                else if (
                    utils.getItemQuantity(
                        shipment,
                        newItem.FulfillmentNetworkSKU
                    ) < newItem.QuantityShipped
                ) {
                    newItem.QuantityLeft = newItem.QuantityShipped;
                    newItem.BoxQuantity = 0;
                    box.items.push(newItem);
                    box.currentItem = newItem.FulfillmentNetworkSKU;
                }
            }
            return stateCopy;

        // Adds an item with the given quantity to the box. If the new quantity exceeds
        // the limit specified by the QuantityShipped property, only the difference is added.
        case 'ADD_QUANTITY':
            [box, shipment] = utils.findBox(stateCopy.shipments, action.boxId);
            if (!box) return stateCopy;
            item = utils.findItem(box, box.currentItem);
            if (item) {
                item.BoxQuantity += utils.parseQuantity(
                    shipment,
                    item,
                    action.quantity
                );
            }
            // Make sure that only items with the quantity greater than zero remain
            box.items = box.items.filter(item => item.BoxQuantity > 0);
            return stateCopy;

        case 'SET_CURRENT_SHIPMENT_IDS':
            stateCopy.currentShipmentsIds = action.shipmentIds;
            return stateCopy;

        case 'SET_SHIPMENTS':
            // Reset currentShipmentsIds, because these shipments may no longer
            // exist in the new list 
            return {
                shipments: action.shipments,
                currentShipmentsIds: [],
            };

        case 'ADD_BOX_DIMENSIONS':
            var { weight, length, width, height, shipmentId, boxId } = action;
            shipment = stateCopy.shipments.find(
                s => s.ShipmentId === shipmentId
            );
            if (shipment) {
                var currentBox;
                // If the box ID is not set, take the marked box specified by the
                // CurrentBox property of the shipment
                if (boxId) {
                    currentBox = shipment.Boxes.find(b => b.id === boxId);
                } else {
                    currentBox = shipment.Boxes.find(
                        b => b.id === shipment.CurrentBox
                    );
                }
                if (currentBox) {
                    currentBox.weight = weight;
                    currentBox.length = length;
                    currentBox.width = width;
                    currentBox.height = height;
                }
            }
            return stateCopy;

        // Updates items of the given box
        case 'UPDATE_BOX':
            [box, shipment] = utils.findBox(stateCopy.shipments, action.boxId);
            box.items = action.items;
            if (box.items.length === 0) {
                utils.deleteBox(shipment, action.boxId);
            }
            return stateCopy;

        case 'DELETE_BOX':
            [box, shipment] = utils.findBox(stateCopy.shipments, action.boxId);
            utils.deleteBox(shipment, action.boxId);
            return stateCopy;

        case 'UPDATE_ITEM':
            [box, shipment] = utils.findBox(stateCopy.shipments, action.boxId);
            // Try to find the item with the same FNSKU as the given item
            item = box.items.find(
                i =>
                    i.FulfillmentNetworkSKU ===
                    action.item.FulfillmentNetworkSKU
            );
            // If the item exists in the given box, handle the given quantity
            if (item) {
                // If the quantity is given, update the found item
                if (action.quantity) {
                    // Count the summed quantity of this item in the shipment
                    let itemQuantity = utils.getItemQuantity(
                        shipment,
                        item.FulfillmentNetworkSKU
                    );
                    // Check if adding the new quantity stays within the limit
                    if (
                        itemQuantity + action.quantity <=
                        item.QuantityShipped
                    ) {
                        item.BoxQuantity += action.quantity;
                    }
                } 
                // If the quantity isn't given, overwrite the quantity of the found item
                // with the quantity of the given item
                else {
                    item.BoxQuantity = action.item.BoxQuantity;
                }
            } 
            // If the item is not found in the box, add it
            else {
                box.items.push(action.item);
                box.currentItem = action.item.FulfillmentNetworkSKU;
            }

            // Make sure that only items with the quantity greater than zero remain
            box.items = box.items.filter(item => item.BoxQuantity > 0);

            if (box.items.length === 0) {
                utils.deleteBox(shipment, box.id);
            }

            return stateCopy;

        case 'ADD_LOADING_SHIPMENT':
            stateCopy.loadingShipments.push({
                shipmentId: action.shipmentId,
                message: action.message,
            });
            return stateCopy;

        case 'DELETE_LOADING_SHIPMENT':
            stateCopy.loadingShipments = stateCopy.loadingShipments.filter(
                s => s.shipmentId !== action.shipmentId
            );
            return stateCopy;

        case 'CLEAR_LOADING_SHIPMENT':
            stateCopy.loadingShipments = [];
            return stateCopy;

        case 'UPDATE_STATUS':
            stateCopy.status = {
                ...stateCopy.status,
                ...action.status,
                lastUpdated: new Date().getTime(),
            };
            return stateCopy;

        case 'SET_SELECTED_SHIPMENT_ID':
            stateCopy.selectedShipmentId = action.shipmentId;
            return stateCopy;

        case 'SET_SCANNED_ITEM':
            stateCopy.scannedItem = action.scannedItem;
            return stateCopy;

        case 'SET_PRODUCT_DIALOG_OPENED':
            stateCopy.productDialogOpened = action.opened;
            return stateCopy;

        case 'CONFIRM_BOX_DELETION':
            stateCopy.boxDeletionConfirmation = true;
            stateCopy.boxToDeleteId = action.boxId;
            return stateCopy;

        case 'SET_DELETE_CONFIRMATION_OPENED':
            stateCopy.boxDeletionConfirmation = action.opened;
            return stateCopy;

        case 'SET_DELETED_SHIPMENT_ITEM':
            if (!action.shipmentId || !action.sku) {
                stateCopy.deletedItem = null;
            } else {
                stateCopy.deletedItem = {
                    shipmentId: action.shipmentId,
                    sku: action.sku,
                };
            }
            return stateCopy;

        case 'SET_DELETE_SHIPMENT_ITEM_CONFIRMATION':
            stateCopy.shipmentItemDeletionConfirmation = action.open;
            return stateCopy;

        case 'SET_EXPECTED_ITEMS':
            index = stateCopy.shipments.findIndex(
                shipment => shipment.ShipmentId === action.shipmentId
            );
            if (index > -1) {
                stateCopy.shipments[index].ExpectedItems = action.items;
            }
            return stateCopy;

        case 'DELETE_ITEM_FROM_SHIPMENT':
            shipment = stateCopy.shipments.find(
                s => s.ShipmentId === action.shipmentId
            );
            if (shipment) {
                // Filters out items with the given sku for every box in the found
                // shipment
                shipment.Boxes.forEach(box => {
                    box.items = box.items.filter(
                        item => item.SellerSKU !== action.sku
                    );
                });

                // Remove empty boxes
                shipment.Boxes = shipment.Boxes.filter(
                    box => box.items.length > 0
                );

                // If there are any boxes, make sure that they are numbered
                // properly
                if (shipment.Boxes.length > 0) {
                    shipment.Boxes = utils.reorderBoxes(shipment.Boxes);
                } 
                // If there are no boxes, create one
                else {
                    shipment.Boxes.push(utils.newBox(1, action.shipmentId));
                }

                // Mark the last box in the list as the current box
                shipment.CurrentBox =
                    shipment.Boxes[shipment.Boxes.length - 1].id;
            }

            return stateCopy;
        default:
            return state;
    }
};
