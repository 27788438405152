import React from 'react';
import { Button } from '@material-ui/core';
import { utils as shipmentUtils } from './shipments';
import { useDispatch, batch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/shippingActions';
import ProductInfoBanner from './ProductInfoBanner';

/**
 * Displays a single row with the newest box of the given shipment.
 * Allows to add scanned item to it and edit its dimensions.
 * @param {Object} props - Component props. 
 * @param {Object} props.shipment - Shipment's data.
 * @param {function} props.createBox - Callback function executed when
 * the user wants to finish current box by adding its dimensions. It results in
 * creating a completely new box.
 */
function ShipmentRow({ shipment, createBox, focusInput }) {
    const [quantity, setQuantity] = React.useState(0);
    const dispatch = useDispatch();
    const product = useSelector(state => state.inboundShipments.scannedItem);

    let currentBox = shipment.Boxes.find(b => b.id === shipment.CurrentBox);
    if (!currentBox) return null;

    let itemQuantity = 0;
    let quantityLeft = -1;
    let currentItem = shipment.ExpectedItems.find(
        i => i.FulfillmentNetworkSKU === product?.FulfillmentNetworkSKU
    );

    if (currentItem) {
        itemQuantity = shipmentUtils.getItemQuantity(
            shipment,
            currentItem.FulfillmentNetworkSKU
        );
        quantityLeft = currentItem.QuantityShipped - itemQuantity;
    }

    const increment = () => {
        if (quantity + 1 <= quantityLeft) {
            setQuantity(quantity + 1);
        }
        focusInput();
    };

    const decrement = () => {
        if (quantity - 1 >= 0) {
            setQuantity(quantity - 1);
        }
        focusInput();
    };

    return (
        <div className='list-row' key={shipment.ShipmentId}>
            <div className='fulfillment-center-container shipment-info-container box-border-round'>
                <div className='name-container'>
                    {shipment.DestinationFulfillmentCenterId}
                </div>
                <div className='quantity-left-container'>
                    <p className='label'>Qty left</p>
                    <p className='number'>{quantityLeft}</p>
                </div>
            </div>
            <div className='box-container shipment-info-container box-border-round'>
                <div className='title'>
                    <p>BOX {currentBox.number}</p>
                </div>
                <div className='button-container'>
                    <Button
                        style={{
                            margin: 0,
                            borderRadius: '1em',
                        }}
                        onClick={() => {
                            if (!currentItem) return;
                            let boxItem = shipmentUtils.findItem(
                                currentBox,
                                currentItem.FulfillmentNetworkSKU
                            );

                            if (boxItem && boxItem.BoxQuantity > 0) {
                                createBox(shipment, {
                                    ...boxItem,
                                });
                            } else if (quantityLeft > 0) {
                                // TO-DO: error handling
                            } else {
                                // TO-DO: error handling
                            }

                            focusInput();
                        }}
                        variant='outlined'
                    >
                        Finish box
                    </Button>
                </div>
            </div>
            <div className='quantity-container shipment-info-container box-border-round'>
                <div className='plus-minus-buttons'>
                    <Button
                        onClick={increment}
                        style={{
                            margin: '0 0 2px 0',
                            width: '50px',
                            height: '50px',
                            minWidth: 0,
                            fontSize: '1.2em',
                            borderRadius: '1em',
                        }}
                        variant='outlined'
                    >
                        +
                    </Button>
                    <Button
                        onClick={decrement}
                        style={{
                            margin: '2px 0 0 0',
                            width: '50px',
                            height: '50px',
                            minWidth: 0,
                            fontSize: '1.2em',
                            borderRadius: '1em',
                        }}
                        variant='outlined'
                    >
                        -
                    </Button>
                </div>
                <div className='quantity'>
                    <p>{quantity}</p>
                </div>
                <div className='button-container'>
                    <Button
                        style={{
                            margin: 0,
                            borderRadius: '1em',
                        }}
                        onClick={() => {
                            if (quantityLeft - quantity >= 0) {
                                batch(() => {
                                    if (
                                        !shipmentUtils.findItem(
                                            currentBox,
                                            currentItem.FulfillmentNetworkSKU
                                        )
                                    ) {
                                        dispatch(
                                            actions.addItem(
                                                currentBox.id,
                                                currentItem
                                            )
                                        );
                                    }
                                    dispatch(
                                        actions.addQuantity(
                                            currentBox.id,
                                            quantity
                                        )
                                    );
                                    dispatch(
                                        actions.updateShipment(
                                            shipment.ShipmentId,
                                            {
                                                LastUpdated: new Date().getTime(),
                                            },
                                            true
                                        )
                                    );
                                    dispatch(
                                        actions.saveShipments([
                                            shipment.ShipmentId,
                                        ])
                                    );
                                });
                                setQuantity(0);
                            }
                            focusInput();
                        }}
                        variant='outlined'
                    >
                        ADD
                    </Button>
                </div>
            </div>
        </div>
    );
}

/**
 * Displays a list of given shipments and information about the scanned item
 * above it.
 * @param {Object} props - Component props. 
 * @param {Object[]} props.shipments - List of shipments.
 * @param {function} props.createBox - Callback function executed when
 * the user wants to finish current box by adding its dimensions. It results in
 * creating a completely new box.
 */
export default function CurrentShipmentsList({ shipments, createBox, focusInput }) {
    const product = useSelector(state => state.inboundShipments.scannedItem);
    return (
        <>
            <ProductInfoBanner product={product} />
            <div className='list-container white-container soft-corners'>
                <div className='shipment-list'>
                    {shipments.map(shipment => (
                        <ShipmentRow
                            shipment={shipment}
                            createBox={createBox}
                            key={shipment.id}
                            focusInput={focusInput}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}
