import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import Dropzone from '../upc/elements/Dropzone';
import './UploadPhotos.scss';
import { returns } from '../../utils/project_api';

export default function UploadPhotos(props) {
    const [uploading, setUploading] = React.useState(false);
    const [photos, setPhotos] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const { onClose, opened, finish, urlParams } = props;

    useEffect(() => {
        if (opened) {
            setUploadProgress(0);
            setUploading(false);
            setError(null);
            setPhotos([]);
        }
    }, [opened]);

    const acceptedTypes = ['image/png', 'image/jpg', 'image/jpeg'];

    const isValidFileType = fileType => {
        return acceptedTypes.includes(fileType);
    };

    const renderActions = () => {
        return (
            <>
                <Button
                    disabled={photos.length <= 0 || uploading}
                    onClick={() => {
                        uploadPhotos();
                    }}
                    style={{ margin: '0.2em' }}
                    color='primary'
                    variant='contained'
                >
                    Upload
                </Button>
                <Button
                    onClick={() => {
                        finish();
                    }}
                    style={{ margin: '0.2em' }}
                    color='primary'
                    variant='contained'
                >
                    Continue
                </Button>
            </>
        );
    };

    const sendFiles = async filesToUpload => {
        console.log(filesToUpload);
        let bodyFormData = new FormData();
        filesToUpload.forEach((f, i) => {
            bodyFormData.append(`image${i}`, f);
        });
        console.log(urlParams);
        await returns.post('uploadPhotos', urlParams, {
            data: bodyFormData,
            onUploadProgress: ev => {
                const progress = (ev.loaded / ev.total) * 100;
                setUploadProgress(Math.round(progress));
            },
        });
        // await axios({
        //     method: 'post',
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     data: bodyFormData,
        //     url,
        //     onUploadProgress: (ev) => {
        //         const progress = ev.loaded / ev.total * 100;
        //         setUploadProgress(Math.round(progress));
        //     },
        // })
    };

    const uploadPhotos = async () => {
        setUploadProgress(0);
        setUploading(true);
        setError(null);
        try {
            console.log('Sending files!');
            await sendFiles(photos);

            setUploading(false);
            finish();
        } catch (e) {
            setUploading(false);
            setError('Could not send files: ' + e.message);
            console.log(e);
        }
    };

    return (
        <Dialog
            open={opened}
            onClose={onClose}
            aria-labelledby='form-dialog-title'
        >
            <DialogTitle id='form-dialog-title'>
                {'Upload photos'}
                <IconButton
                    aria-label='Close'
                    className='close-button'
                    style={{
                        position: 'absolute',
                        right: '0.4em',
                        top: '0.4em',
                    }}
                    onClick={() => {
                        setUploading(false);
                        onClose();
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className='main-container'>
                    <div className='upload-container'>
                        <Dropzone
                            onFilesAdded={files => {
                                if (files.some(f => !isValidFileType(f.type))) {
                                    setError(
                                        'Only images are allowed (png or jpg)'
                                    );
                                } else {
                                    setError(null);
                                    setPhotos([...photos, ...files]);
                                }
                            }}
                            disabled={uploading}
                        />
                        {error && <p className='error-msg'>{error}</p>}
                    </div>
                    <div className='photos-list-container'>
                        {photos.length > 0 && (
                            <div className='photos-list'>
                                {photos.map(file => {
                                    return (
                                        <div
                                            key={file.name}
                                            className='photo-row'
                                        >
                                            <img
                                                alt='FILE'
                                                src={URL.createObjectURL(file)}
                                            />
                                            <p className='filename'>
                                                {file.name}
                                            </p>
                                            <IconButton
                                                aria-label='Close'
                                                className='delete-button'
                                                onClick={() =>
                                                    setPhotos(
                                                        photos.filter(
                                                            p =>
                                                                p.name !==
                                                                file.name
                                                        )
                                                    )
                                                }
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className='actions'>{renderActions()}</div>
                    {uploading && (
                        <LinearProgress
                            className='upload-progress-indicator'
                            variant='determinate'
                            value={uploadProgress}
                        />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}

UploadPhotos.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    files: PropTypes.array,
    onClose: PropTypes.func,
    onEntered: PropTypes.func,
    onProceed: PropTypes.func,
};
