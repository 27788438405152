import React, { useEffect } from 'react';
import LoadingDialog from '../common/LoadingDialog';
import {
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/shippingActions';

/**
 * Dialog which displays shipment's charges and allows to print labels and
 * confirm, void and complete the shipment.
 * @param {Object} props - Component props.
 * @param {boolean} props.opened - Setting it to true displays a dialog.
 * @param {function} props.onClose - Callback function executed when a dialog
 * is being closed.
 * @param {boolean} props.loading - Tells if a dialog should display a loading indicator.
 * @param {Object} props.shipment - Shipment data.
 */
export default function ShippingChargesDialog({
    opened,
    onClose,
    shipment,
    loading,
}) {
    const [rows, setRows] = React.useState([]);
    const [cost, setCost] = React.useState(0);
    const status = useSelector(state => state.inboundShipments.status);
    const dispatch = useDispatch();

    useEffect(() => {
        if (shipment.PackageList) {
            let carriers = {};
            for (const element of shipment.PackageList) {
                if (carriers[element.CarrierName]) {
                    carriers[element.CarrierName].weight +=
                        element.Weight.Value;
                    carriers[element.CarrierName].numOfBoxes++;
                } else {
                    carriers[element.CarrierName] = {
                        shippingCarrier: element.CarrierName,
                        weight: element.Weight.Value,
                        numOfBoxes: 1,
                    };
                }
            }
            setRows(Object.values(carriers));
            let newCost = 0;
            if (shipment.Estimate && shipment.Estimate.Amount) {
                newCost = shipment.Estimate.Amount.Value;
            }
            setCost(newCost);
        } else {
            setRows([]);
        }
    }, [shipment]);

    return (
        <LoadingDialog
            onClose={onClose}
            opened={opened}
            loading={
                loading ||
                status.isVoiding ||
                status.isConfirming ||
                status.isFetchingLabels ||
                status.isCompleting
            }
            title='Shipping charges'
            classes={{
                paper: 'shipping-page-background-color',
            }}
            maxWidth={false}
            disableBackdropClick
            actions={
                <>
                    <Button onClick={onClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        onClick={() =>
                            dispatch(
                                actions.getUniqueLabels(shipment.ShipmentId)
                            )
                        }
                    >
                        Print
                    </Button>
                    <Button
                        color='primary'
                        onClick={() =>
                            dispatch(actions.voidShipment(shipment.ShipmentId))
                        }
                    >
                        Void
                    </Button>
                    {shipment.TransportStatus === 'CONFIRMED' ? (
                        <Button
                            color='primary'
                            onClick={() =>
                                dispatch(
                                    actions.completeShipment(
                                        shipment.ShipmentId
                                    )
                                )
                            }
                        >
                            COMPLETE
                        </Button>
                    ) : (
                        <Button
                            color='primary'
                            onClick={() =>
                                dispatch(
                                    actions.confirmShipment(shipment.ShipmentId)
                                )
                            }
                        >
                            Confirm
                        </Button>
                    )}
                </>
            }
        >
            <Table style={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Shipping Carrier</TableCell>
                        <TableCell align='right'># of Boxes</TableCell>
                        <TableCell align='right'>Shipment Weight</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => {
                        return (
                            <TableRow key={row.shippingCarrier}>
                                <TableCell>{row.shippingCarrier}</TableCell>
                                <TableCell align='right'>
                                    {row.numOfBoxes}
                                </TableCell>
                                <TableCell align='right'>
                                    {row.weight}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <p style={{ float: 'right', fontWeight: 600 }}>
                Estimated Cost:{' '}
                <span style={{ fontWeight: 'initial' }}>${cost}</span>
            </p>
        </LoadingDialog>
    );
}
