import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import Image from 'material-ui-image';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectStaffMemberDialog from '../upc/elements/SelectStaffMemberDialog'
import SelectProductsListDialog from '../upc/elements/SelectProductsListDialog'
import CustomSnackbar from '../layout/CustomSnackbar';

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    selectContainer: {
        alignItems: 'space-between'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});


class ShipmentsViewer extends Component {
    state = {
        products: [],
        errorMsg: '',
        isLoading: false,
        loadingMsg: '',
        userID: null,
        collections: [],
        shopperMap: [],
        userShipments: [],
        shopperShipments: [],
        shipmentsToView: [],
        selectStaffMemberDialogOpened: false,
        selectProductsListDialogOpened: false,
        popupLoading: false,
        snackbarVariant: "error",
        snackbarOpened: false,
        snackbarMsg: null,
    }

    constructor(props) {
        super(props)
        this.shipmentOwner = ""
    }

    render() {
        const { classes, } = this.props;

        const columns = [
            { title: 'Nr', field: 'Number', editable: 'never' },
            { title: 'Image', field: 'ImgURL', editable: 'never', render: rowData => <Image src={rowData.ImgURL} /> },
            { title: 'UPC', field: 'UPC', editable: 'never' },
            { title: 'ASIN', field: 'ASIN', editable: 'never' },
            { title: 'MSKU', field: 'SKU', editable: 'never', hidden: true },
            { title: 'Title', field: 'Name', editable: 'never' },
            {
                title: 'SizeTier', field: 'SizeTier', editable: 'never', render: rowData => {
                    return <span style={rowData.SizeTier.toLowerCase().includes("oversize") ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>{rowData.SizeTier.toLowerCase().includes("oversize") ? "OS" : "SS"}</span>
                }
            },
            { title: 'Quantity', field: 'Quantity', editable: 'never' }
        ]

        return (
            <Container>
                <Box display="flex" flexDirection="row" width='100%' justifyContent='space-evenly' className={classes.container}>
                    <Button
                        color='primary' variant="contained"
                        onClick={() => {
                            this.shipmentOwner = "YOU"
                            this.setState({ selectProductsListDialogOpened: true, shipmentsToView: this.state.userShipments, userID: this.props.userData.uid })
                        }}
                    >Your shipments</Button>
                    <Button color='primary' variant="contained" onClick={() => this.setState({ selectStaffMemberDialogOpened: true })}>Staff shipments</Button>
                </Box>

                <Box display="flex" flexDirection="column" width='100%' minHeight={500} className={classes.container} marginBottom={30}>
                    <Box flexDirection='row' display='flex' style={{ justifyContent: 'center' }}>
                        <Box style={{ justifyContent: 'center', alignItems: 'center', margin: 5 }} flexDirection="column" display='flex'>
                            <Typography variant='h5'>User: {this.state.shipmentOwner}</Typography>
                            <Typography variant='subtitle1'>Items added: {this.state.products.reduce((sum, prod) => { return sum + prod.Quantity }, 0)}</Typography>
                        </Box>
                    </Box>
                    <MaterialTable
                        title=''
                        columns={columns}
                        data={this.state.products}
                        options={{
                            columnsButton: true
                        }}
                    />
                </Box>

                <SelectStaffMemberDialog
                    opened={this.state.selectStaffMemberDialogOpened}
                    shopperList={this.state.shopperMap}
                    onSelectShopper={this.getShopperShipments}
                    onClose={() => { this.setState({ selectStaffMemberDialogOpened: false }) }}
                    loading={this.state.popupLoading}
                />
                <SelectProductsListDialog opened={this.state.selectProductsListDialogOpened} productsLists={this.state.shipmentsToView}
                    onClose={() => { this.setState({ selectProductsListDialogOpened: false }) }} onSelectList={this.onSelectProductsList}
                    loading={this.state.popupLoading} />
                <CustomSnackbar variant={this.state.snackbarVariant} duration={6000} onClose={this.onSnackbarClose} className={classes.margin} opened={this.state.snackbarOpened} message={this.state.snackbarMsg} />
            </Container>
        )
    }

    componentDidMount() {
        this.getShipmentsData()
    }

    onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpened: false, snackbarMsg: null })
    }

    onSelectProductsList = (list) => {
        this.setState({ selectProductsListDialogOpened: false, popupLoading: true })
        firebase.firestore().collection(`/shipmentProducts/${this.state.userID}/current/`).doc(list.dbID).get()
            .then(result => {
                var s = result.data()
                console.log(s)
                this.setState({ products: s.products, shipmentOwner: this.shipmentOwner, popupLoading: false })
            })
            .catch(e => this.setState({ snackbarOpened: true, snackbarMsg: e.message, snackbarVariant: 'error', popupLoading: false }))
    }

    getShipmentsData = async () => {
        this.setState({ isLoading: true })
        let shopperMap = []
        let promises = []

        this.props.userData.shoppers.forEach(shopperID => {
            promises.push(firebase.firestore().collection('users').doc(shopperID).get().then((snapshot) => {
                if (!snapshot.empty) {
                    let shopperData = {
                        'email': snapshot.data().email,
                        'id': shopperID,
                    }
                    shopperMap.push(shopperData)
                }
            }))
        })
        Promise.all(promises).then(() => {
            console.log(shopperMap)
            this.setState({ isLoading: false, shopperMap: shopperMap })
        })

        var getShipmentProducts = firebase.functions().httpsCallable('getShipmentProducts')
        getShipmentProducts(this.props.userData.uid).then(result => {
            console.log(result.data)
            this.setState({ userShipments: result.data, selectProductsListDialogOpened: false })
        }).catch(e => {
            console.log('Could not load shipments: ' + e.message)
            this.setState({ snackbarOpened: true, snackbarMsg: e.message, snackbarVariant: 'error', popupLoading: false })
        })
    }


    getShopperShipments = async (shopper) => {
        this.shipmentOwner = shopper.email
        this.setState({ userID: shopper.id, popupLoading: true })
        var getShipmentProducts = firebase.functions().httpsCallable('getShipmentProducts')
        getShipmentProducts(shopper.id).then(result => {
            console.log(result.data)
            this.setState({ shopperShipments: result.data, shipmentsToView: result.data, selectStaffMemberDialogOpened: false, selectProductsListDialogOpened: true, popupLoading: false })
        }).catch(e => {
            console.log('Could not load shipments: ' + e.message)
            this.setState({ snackbarOpened: true, snackbarMsg: e.message, snackbarVariant: 'error', popupLoading: false })
        })
    }
}

ShipmentsViewer.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ShipmentsViewer))