import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: 40
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        marginTop: theme.spacing(4),
    }
});

class ShopperManagement extends Component {
    state = {
        email: '',
        password: '',
        passwordConfirm: '',
        errorMsg: '',
        snackbarOpened: false,
        isLoading: false,
        loadingMsg: '',
    }

    constructor(props) {
        super(props)

        this.textInput = React.createRef();
    }

    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;
        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Create a new shopper
                </Typography>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Confirm password"
                            type="password"
                            id="passwordConfirm"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                        >
                            Create
                              </Button>
                    </form>
                    <Typography className={classes.error} color='error'>
                        {this.state.errorMsg}
                    </Typography>
                </div>
            </Container>
        )
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.password !== this.state.passwordConfirm) {
            this.setState({ errorMsg: 'Passwords not identical' })
        } else if (this.state.password.trim() === '') {
            this.setState({ errorMsg: 'Password is empty' })
        } else {
            firebase.auth().createUserWithEmailAndPassword(
                this.state.email,
                this.state.password
            ).then(resp => {
                firebase.firestore().collection('users').doc(resp.user.uid).set({
                    email: this.state.email,
                    id: resp.user.uid,
                    subscription: "ACTIVE",
                    subscription_start: firebase.firestore.Timestamp.fromDate(new Date()),
                    subscription_end: firebase.firestore.Timestamp.fromDate(new Date()),
                    role: 'SHOPPER',
                    manager: this.props.userData.uid,
                    auth_token: this.props.userData.credentials.token,
                    keepa_key: this.props.userData.credentials.keepaKey,
                    seller_id: this.props.userData.credentials.sellerID,
                }).then(resp => {
                    console.log('Shopper user created successfully')
                });
                return resp.user.uid
            }).then((userID) => {
                firebase.firestore().collection('users').doc(this.props.userData.uid).update({
                    shoppers: firebase.firestore.FieldValue.arrayUnion(userID)
                }).then(resp => {
                    console.log('Manager user updated successfully')
                });
            }).catch((err) => {
                this.setState({ errorMsg: err.message })
            });
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            errorMsg: null
        })
    }
}

ShopperManagement.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ShopperManagement))