import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import AddressPanel from './AddressPanel'
import firebase from 'firebase';
import { passAddress, passSalesData, passPurchasedData } from '../../store/actions/dbActions'
import CustomSnackbar from '../layout/CustomSnackbar'
import { Typography, TextField, Button } from '@material-ui/core';
import { getSessionID } from '../../ebay/eBayCall';

const csv = require('fast-csv');

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 10
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

class SettingsPage extends Component {
    state = {
        name: '',
        address1: '',
        address2: '',
        city: '',
        stateCode: '',
        postalCode: '',
        countryCode: '',
        quantity: 1,
        subscription: 'NOT ACTIVE',
        labelSrc: 'data:image/png;base64,',
        credentials: {
            token: ' ',
            sellerID: ' ',
            keepaKey: ' '
        },
        nameError: false,
        addressError: false,
        cityError: false,
        scError: false,
        pcError: false,
        ccError: false,
        quantityError: false,
        errorMsg: '',
        snackbarOpened: false,
        isLoading: false,
        loadingMsg: '',
        printMethod: 'ZPL',
        expanded: 'address',
        role: '',
        weightAdj: '',
        prepAdj: '',
    }

    render() {
        const { classes } = this.props;
        const { auth } = this.props;

        if (!auth) return <Redirect to='/signin' />

        return (
            <Container>
                <Box display="flex" flexDirection="row" width='100%' className={classes.container}>
                    <AddressPanel expanded={this.state.expanded} onPanelChange={this.onPanelChange} onAddressChange={this.handleChange} onSave={this.onSave}
                        name={this.state.name} address1={this.state.address1} address2={this.state.address2} city={this.state.city} stateCode={this.state.stateCode} postalCode={this.state.postalCode}
                        countryCode={this.state.countryCode} handleError={this.handleError} />
                </Box>
                {/* <div>
                    <button onClick={this.eBaySignIn}>Get session</button>
                </div> */}
                {this.state.role === 'ADMIN' && 
                <Box display="flex" flexDirection="column" width='100%' className={classes.container}>
                    <Typography>x/lb to Adjusted Price [for example, 0.4 or 3]</Typography>
                    <TextField
                            multiline
                            id="weightAdj"
                            label="Your Weight Adjustment"
                            type="text"
                            className={classes.textField}
                            value={this.weightAdj}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                    />
                    <Button size="small" color="primary" onClick={() => this.saveSetting("weight")}>
                        Save
                    </Button>
                </Box>
                }
                {this.state.role === 'ADMIN' && 
                <Box display="flex" flexDirection="column" width='100%' className={classes.container}>
                <Typography>Prep costs for Adjusted Price [for example, 1 or 12.15]</Typography>
                <TextField
                        multiline
                        id="prepAdj"
                        label="Your Prep Adjustment"
                        type="text"
                        className={classes.textField}
                        value={this.prepAdj}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                />
                <Button size="small" color="primary" onClick={() => this.saveSetting("prep")}>
                    Save
                </Button>
                </Box>
                }
                <CustomSnackbar variant='error' duration={6000} onClose={this.onSnackbarClose} className={classes.margin} opened={this.state.snackbarOpened} message={this.state.errorMsg} />
            </Container>

        )
    }

    onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpened: false })
    }

    componentDidMount() {
        if (this.props.address) {
            const { name, address1, address2, city, stateCode, postalCode, countryCode } = this.props.address
            this.setState({ name, address1, address2, city, stateCode, postalCode, countryCode })
        }
        this.setState({role: this.props.userData.role})
    }

    eBaySignIn = async () => {
        let result = await getSessionID();
        console.log(result);
    }

    saveSetting = (setting) => {
        let value = 0;
        if (setting === "weight") {
            value = parseFloat(this.state.weightAdj)
        } else if (setting === "prep") {
            value = parseFloat(this.state.prepAdj)
        }

        let updateData = {
            'key': setting,
            'value': value
        }

        let postBody = {
            'products': [updateData],
            'key': 'maximumsecurity',
            'dbname': 'browser',
            'collection': 'settings',
            'reset': true,
        };
        let body = JSON.stringify(postBody);
    
        fetch(`https://api.projectsuite.io/repricer?key=maximumsecurity&collection=settings&db=browser&searchKey=key&searchValue=${setting}`, {
            method: 'PUT',
            body: body,
            headers: {'Content-Type': 'application/json'},
        }).then((res) => {
            let z = res.text();
            return z;
        }).then((z) => {
            console.log(z);
        });
    }

    saveSales = () => {
        if (this.state.salesData !== '') {
            let prods = []

            csv.parseString(this.state.salesData, { headers: true })
            .on('error', error => console.error(error))
            .on('data', row => prods.push(row))
            .on('end', rowCount => {
                console.log('Parsed ' + rowCount + ' prods')
                let salesData = prods.map(prod => {
                    let finalProd = {
                        'ASIN': prod.ASIN,
                        'Orders': prod.Orders,
                        'OnHand': prod['On Hand']
                    }
                    return finalProd
                })
                firebase.firestore().collection('users').doc(this.props.userData.uid).update({
                    salesData 
                }).then(() => {
                    console.log('Successfully saved')
                    this.props.dispatch(passSalesData(salesData))
                }).catch(e => { this.setState({ snackbarOpened: true, errorMsg: e.message }) })
            });
        }
    }

    savePurchased = () => {
        if (this.state.purchasedData !== '') {
            let purchasedDataRows = this.state.purchasedData.split('\n')

            let filterAsins = purchasedDataRows.filter(row => {
                if (row.trim().length === 10) {
                    return true
                } else {
                    return false
                }
            })

            let purchasedData = filterAsins.map(asin => {
                return asin.trim()
            })

            firebase.firestore().collection('users').doc(this.props.userData.uid).update({
                purchasedData 
                }).then(() => {
                    console.log('Successfully saved')
                    this.props.dispatch(passPurchasedData(purchasedData))
                }).catch(e => { this.setState({ snackbarOpened: true, errorMsg: e.message }) })
        }
    }

    onSave = () => {
        console.log(this.state)
        const { name, address1, address2, city, stateCode, postalCode, countryCode } = this.state
        if (name.trim() === '' || address1.trim() === '' || city.trim() === '' || stateCode.trim() === '' || postalCode.trim() === '' || countryCode.trim() === '') {
            this.setState({ snackbarOpened: true, errorMsg: 'Required fields must not be empty' })
            return
        }

        var address = {
            name: name.trim(), 
            address1: address1.trim(), 
            address2: address2.trim(), 
            city: city.trim(), 
            stateCode: stateCode.trim(),
            postalCode: postalCode.trim(),
            countryCode: countryCode.trim(),
        }

        firebase.firestore().collection('users').doc(this.props.userData.uid).update({
            address 
        }).then(() => {
            console.log('Successfully saved')
            this.props.dispatch(passAddress(address))
        }).catch(e => { this.setState({ snackbarOpened: true, errorMsg: e.message }) })
    }

    onPanelChange = panel => (event, isExpanded) => {
        this.setState({ expanded: isExpanded ? panel : false })
    }

    handleChange = e => {
        this.setState({ snackbarOpened: false, errorMsg: '' })
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    handleError = (errorMsg) => {
        this.setState({ snackbarOpened: true, errorMsg: errorMsg })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.address !== this.props.address) {
            const { name, address1, address2, city, stateCode, postalCode, countryCode } = this.props.address
            this.setState({ name, address1, address2, city, stateCode, postalCode, countryCode })
        }
    }

    static getDerivedStateFromProps(props, state) {
        return null
    }

}

SettingsPage.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        address: state.db.address
    }
}


export default connect(mapStateToProps)(withStyles(styles)(SettingsPage))