import React from 'react';
import {
    CircularProgress,
    Button,
    LinearProgress,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ArrowUpward } from '@material-ui/icons';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import { returns } from '../../utils/project_api';
import UploadPhotos from './UploadPhotos';

export default function ViewProductsPage(props) {
    const [loading, setLoading] = React.useState(false);
    const [uploadPhotosDialogOpened, setUploadPhotosDialogOpened] =
        React.useState(false);
    const [urlParams, setUrlParams] = React.useState({});
    const [actionFilter, setActionFilter] = React.useState([]);
    const {
        showError,
        db,
        products,
        setProducts,
        photosLoadingProgress,
        setPhotosLoadingProgress,
    } = props;

    const columns = [
        { title: 'Title', field: 'product-name', editable: 'never' },
        { title: 'FNSKU', field: 'fnsku', editable: 'never' },
        { title: 'LPN', field: 'license-plate-number', editable: 'never' },
        {
            title: 'Removal ID',
            field: 'order-id',
            editable: 'never',
            hidden: true,
        },
        {
            title: 'Order ID',
            field: 'customer-order-id',
            editable: 'never',
            filterCellStyle: { minWidth: 300 },
        },
        {
            title: 'Info',
            field: '',
            editable: 'never',
            render: rowData => {
                return (
                    <div>
                        <p>
                            {rowData['product-name']}
                            <br />
                            <br />
                            <b>LPN:</b> {rowData['license-plate-number']}
                            <br />
                            <b>FNSKU:</b> {rowData.fnsku}
                            <br />
                            <b>Removal ID:</b> {rowData['order-id']}
                        </p>
                    </div>
                );
            },
            hidden: true,
        },
        {
            title: 'Image',
            field: 'ImgURL',
            editable: 'never',
            filtering: false,
            render: rowData => {
                return (
                    <div className='table-image-cell'>
                        <img
                            alt='product'
                            src={rowData.ImgURL}
                            style={{ width: '100px', height: 'auto' }}
                        />
                    </div>
                );
            },
        },
        { title: 'ASIN', field: 'asin', editable: 'never', hidden: true },
        { title: 'Reason', field: 'reason', editable: 'never', hidden: true },
        {
            title: 'Action',
            field: 'action',
            editable: 'onUpdate',
            hidden: false,
            defaultFilter: actionFilter,
            lookup: {
                relist: 'Relist on Amazon',
                'mf-used': 'MF - USED',
                'other-sell-elsewhere': 'Sell elsewhere',
                'damage-discard': 'Damaged - Donate',
                'fraud-discard': 'Fraud - Donate',
                'fraud-keep': 'Fraud - Sell in store',
            },
            customFilterAndSearch: (term, rowData) => {
                setActionFilter(term);
                return (
                    actionFilter.length === 0 || term.includes(rowData.action)
                );
            },
        },
        {
            title: 'Tracking Number',
            field: 'tracking-number',
            editable: 'never',
            hidden: true,
        },
        { title: 'MSKU', field: 'sku', editable: 'never', hidden: true },
        {
            title: 'Case Created',
            field: 'caseCreated',
            editable: 'never',
            // lookup: { true: 'Yes', false: 'No' },
            type: 'boolean',
            render: rowData => {
                return (
                    <div style={{ display: 'flex' }}>
                        <>
                                <IconButton
                                    className='download-photos-button'
                                    aria-label='Delete'
                                    onClick={() => {
                                        updateProduct(
                                            {
                                                ...rowData,
                                                caseCreated: !rowData.caseCreated,
                                            },
                                            rowData.tableData.id
                                        );
                                    }}
                                >
                                    {rowData.caseCreated ? (
                                        <CheckIcon
                                            style={{ color: '#2cb10b' }}
                                        />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </IconButton>
                        </>
                    </div>
                );
            },
        },
        {
            title: 'Reimbursed',
            field: 'reimbursed',
            editable: 'never',
            type: 'boolean',
            render: rowData => {
                return (
                    <div style={{ display: 'flex' }}>
                        <>
                                <IconButton
                                    className='download-photos-button'
                                    aria-label='Delete'
                                    onClick={() => {
                                        updateProduct(
                                            {
                                                ...rowData,
                                                reimbursed: !rowData.reimbursed,
                                            },
                                            rowData.tableData.id
                                        );
                                    }}
                                >
                                    {rowData.reimbursed ? (
                                        <CheckIcon
                                            style={{ color: '#2cb10b' }}
                                        />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </IconButton>
                        </>
                    </div>
                );
            },
        },
        {
            title: 'Submitted',
            field: 'submitted',
            editable: 'never',
            type: 'boolean',
            render: rowData => {
                return (
                    <div style={{ display: 'flex' }}>
                        <>
                                <IconButton
                                    className='download-photos-button'
                                    aria-label='Delete'
                                    onClick={() => {
                                        updateProduct(
                                            {
                                                ...rowData,
                                                submitted: !rowData.submitted,
                                            },
                                            rowData.tableData.id
                                        );
                                    }}
                                >
                                    {rowData.submitted ? (
                                        <CheckIcon
                                            style={{ color: '#2cb10b' }}
                                        />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </IconButton>
                        </>
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            field: '',
            editable: 'never',
            render: rowData => {
                if (photosLoadingProgress[rowData['license-plate-number']])
                    return (
                        <LinearProgress
                            className='single-product-photos-loading-indicator'
                            variant='determinate'
                            value={
                                photosLoadingProgress[
                                    rowData['license-plate-number']
                                ]
                            }
                        />
                    );

                return (
                    <div style={{ display: 'flex' }}>
                        {rowData.photosTaken && (
                            <>
                                <Tooltip
                                    title='Download photos'
                                    placement='top'
                                >
                                    <IconButton
                                        className='download-photos-button'
                                        aria-label='Delete'
                                        onClick={() => {
                                            return downloadPhotos(rowData);
                                        }}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        <Tooltip title={'Upload photos'} placement='top'>
                            <IconButton
                                className='download-photos-button'
                                aria-label='Upload'
                                onClick={() => {
                                    setUrlParams({
                                        lpn: rowData['license-plate-number'],
                                        trackingID: rowData['tracking-number'],
                                        db,
                                    });
                                    setUploadPhotosDialogOpened(true);
                                }}
                            >
                                <ArrowUpward />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const getProducts = async () => {
        setLoading(true);
        try {
            let data = await returns.get('getProducts', { db, limit: 5000 });
            if (!data.result.length) {
                throw Error('Something went wrong');
            } else {
                setProducts(data.result);
            }
        } catch (error) {
            showError(error.message);
        }
        setLoading(false);
    };

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    const downloadPhotos = async product => {
        console.log(product);
        try {
            let lpn = product['license-plate-number'];
            let trackingID = product['tracking-number'];
            let data = await returns.get(
                'getPhotos',
                { db, lpn, trackingID },
                {
                    onDownloadProgress: ev => {
                        const progress = (ev.loaded / ev.total) * 100;
                        photosLoadingProgress[lpn] = Math.round(progress);
                        setPhotosLoadingProgress(photosLoadingProgress);
                    },
                }
            );
            photosLoadingProgress[lpn] = false;
            setPhotosLoadingProgress(photosLoadingProgress);

            if (!data.result.length) {
                throw Error('Something went wrong');
            } else {
                console.log(data.result);
                let images = data.result;
                images.forEach((img, index) => {
                    let blob = b64toBlob(img.data, img.mimetype || '');
                    let blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.download = `${img['license-plate-number']}_${
                        index + 1
                    }`;
                    link.href = blobUrl;
                    link.click();
                });
            }
        } catch (error) {
            showError(error.message);
        }
    };

    const updateProduct = async (rowData, index) => {
        let productToSend = JSON.parse(JSON.stringify(rowData));
        delete productToSend.tableData;
        try {
            await returns.post(
                'addProducts',
                { db },
                { data: { products: [productToSend] } }
            );
            const dataUpdate = [...products];
            dataUpdate[index] = rowData;
            setProducts([...dataUpdate]);
        } catch (e) {
            showError(e.message);
        }
    };

    return (
        <div className='view-products-container'>
            <Button
                onClick={getProducts}
                style={{ margin: '0.2em' }}
                color='primary'
                variant='contained'
            >
                {loading ? (
                    <CircularProgress
                        color='#ffffff'
                        style={{ width: '20px', height: '20px' }}
                    />
                ) : (
                    'Get products'
                )}
            </Button>
            <MaterialTable
                title=''
                columns={columns}
                data={products}
                options={{
                    columnsButton: true,
                    search: false,
                    filtering: true,
                }}
                className='view-products-table'
                style={{ width: '100%' }}
                editable={{
                    isEditable: rowData => true,
                    onRowUpdate: async (newData, oldData) => {
                        await updateProduct(newData, oldData.tableData.id);
                    },
                }}
            />
            <UploadPhotos
                opened={uploadPhotosDialogOpened}
                onClose={() => setUploadPhotosDialogOpened(false)}
                urlParams={urlParams}
                finish={() => {
                    setUploadPhotosDialogOpened(false);
                    const product = products.find(
                        p =>
                            p['license-plate-number'] === urlParams.lpn &&
                            p['tracking-number'] === urlParams.trackingID
                    );
                    if (product) {
                        updateProduct(
                            { ...product, photosTaken: true },
                            products.indexOf(product)
                        );
                    }
                }}
            />
        </div>
    );
}
