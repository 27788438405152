import firebase from 'firebase';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signoutSuccess } from '../../store/actions/authActions';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from '@material-ui/core/Divider';


const SignedInLinks = (props) => {
    let { onItemClick } = props;
    var signOut = () => {
        onItemClick("Project")()
        firebase.auth().signOut().then((resp) => { props.dispatch(signoutSuccess(resp)) })
    }

    return (
        <List>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/"
                onClick={onItemClick("Home")}
            >
                <ListItemText color="#000">Home</ListItemText>
            </ListItem>
            <ListItem
                color="#000"
                button
                component={Link}
                to="/box"
                onClick={onItemClick("Boxes")}
            >
                <ListItemText>Boxes</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/buylist"
                onClick={onItemClick("Buylist Viewer")}
            >
                <ListItemText>Buylist Viewer</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/returns"
                onClick={onItemClick("Returns")}
            >
                <ListItemText>Returns</ListItemText>
            </ListItem>
            <ListItem
                button
                component={Link}
                to="/box_shipment"
                onClick={onItemClick("Box Shipment")}
            >
                <ListItemText>Shipping</ListItemText>
            </ListItem>
            <Divider />
            <ListItem
                button
                component={Link}
                to="/settings"
                onClick={onItemClick("Settings")}
            >
                <ListItemText>Settings</ListItemText>
            </ListItem>
            <ListItem
                button
                onClick={signOut}
            >
                <ListItemText>Log out</ListItemText>
            </ListItem>
        </List>
    )
}

export default connect()(SignedInLinks)