import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'material-ui-image';
import PropTypes from 'prop-types';
import React from 'react';
import placeholder from '../../../assets/placeholder.png';

const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    card: {
        width: 500,
        margin: 10,
        background: '#eceff1',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 5,
    },
    list: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function PrintProductDialog(props) {
    const classes = useStyles();
    const {
        onPrint,
        opened,
        product,
        onQuantityChange,
        onEntered,
        onClose,
        printMethod,
        onSelectChange,
        quantity,
    } = props;

    if (!product) return null;

    return (
        <Dialog
            open={opened}
            onClose={onClose}
            aria-labelledby='form-dialog-title'
            maxWidth='md'
            onEntered={onEntered}
        >
            <DialogTitle id='alert-dialog-title'>
                {'Print'}
                <IconButton
                    aria-label='Close'
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant='h6'>{product.Name}</Typography>
                        <Box
                            flexDirection='row'
                            flex={2}
                            justifyContent='start'
                            alignItems='start'
                            display='flex'
                            marginTop={1}
                        >
                            <Box
                                width='20%'
                                height='50%'
                                flex={1}
                                borderRadius={5}
                                justifyContent='center'
                                alignItems='center'
                            >
                                <Image
                                    src={
                                        product.ImgURL
                                            ? product.ImgURL
                                            : placeholder
                                    }
                                />
                            </Box>
                            <Box
                                flex={2}
                                marginLeft={2}
                                flexDirection='column'
                                display='flex'
                                style={{
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <TextField
                                    id='quantity'
                                    label='Quantity'
                                    type='number'
                                    value={quantity}
                                    onChange={e => {
                                        return onQuantityChange(e);
                                    }}
                                    margin='normal'
                                    variant='outlined'
                                    onSubmit={onPrint}
                                    style={{ width: '50%' }}
                                    inputProps={{ min: '1' }}
                                    inputRef={props.inputFieldRef}
                                    onFocus={event => {
                                        event.preventDefault();
                                        event.target.select();
                                    }}
                                    onKeyPress={ev => {
                                        console.log(
                                            `Pressed keyCode ${ev.key}`
                                        );
                                        if (ev.key === 'Enter') {
                                            // Do code here
                                            onPrint();
                                        }
                                    }}
                                />
                                <Select
                                    style={{ height: 50, width: '50%' }}
                                    value={printMethod}
                                    onChange={onSelectChange}
                                    inputProps={{
                                        name: 'dialogPrintMethod',
                                        id: 'print-simple',
                                    }}
                                >
                                    <MenuItem value={'Image'}>Image</MenuItem>
                                    <MenuItem value={'ZPL'}>ZPL</MenuItem>
                                </Select>
                                <Button
                                    onClick={() => onPrint()}
                                    style={{
                                        marginTop: 5,
                                        alignSelf: 'flex-end',
                                    }}
                                >
                                    Print
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
}

PrintProductDialog.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    onPrint: PropTypes.func,
    loading: PropTypes.bool,
    product: PropTypes.object,
    onQuantityChange: PropTypes.func,
    onEntered: PropTypes.func,
    printMethod: PropTypes.string,
    quantity: PropTypes.number,
};
