import { GetTransportDetailsResult } from '@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model';
import axios from 'axios';
import { getToken } from '../../auth';
import { stringify } from 'qs';

export async function makeCall<R>(
    method: 'get' | 'post' | 'put',
    route: string,
    queryParams?: { [k: string]: any },
    body?: any
): Promise<R> {
    let url: string = route;
    queryParams = queryParams || {};
    queryParams.firebase_token = await getToken();
    if (
        (queryParams.devMode === undefined &&
            process.env.NODE_ENV === 'development') ||
        queryParams.devMode
    ) {
        queryParams.devMode = 1;
    } else {
        delete queryParams.devMode;
    }

    const res = await axios({
        method,
        params: queryParams,
        url,
        data: body,
        paramsSerializer: {
            serialize: (params: any) => stringify(params),
        },
    });

    return res.data.result as R;
}

export function extractTransportContent(response: GetTransportDetailsResult) {
    if (!response.TransportContent)
        throw new Error('GetTransportDetailsResponse: no transport content');

    const transportContent = response.TransportContent;
    var transportDetails = transportContent.TransportDetails;
    var status = transportContent.TransportResult.TransportStatus;
    var estimate = transportDetails.PartneredSmallParcelData?.PartneredEstimate;
    var packageList = transportDetails.PartneredSmallParcelData?.PackageList;

    return {
        Status: status,
        Estimate: estimate,
        PackageList: packageList,
    };
}
