export interface RequestExtraParams {
    devMode?: boolean;
}

export const sizeTiers = {
    SMALL: 'Small',
    LARGE: 'Large',
    SMALL_OVERSIZE: 'Small oversize',
    MEDIUM_OVERSIZE: 'Medium oversize',
    LARGE_OVERSIZE: 'Large oversize',
    SPECIAL_OVERSIZE: 'Special oversize',
    UNSPECIFIED: 'Unspecified',
};

export type SizeTier =
    | typeof sizeTiers.SMALL
    | typeof sizeTiers.LARGE
    | typeof sizeTiers.SMALL_OVERSIZE
    | typeof sizeTiers.MEDIUM_OVERSIZE
    | typeof sizeTiers.LARGE_OVERSIZE
    | typeof sizeTiers.SPECIAL_OVERSIZE
    | typeof sizeTiers.UNSPECIFIED;

export interface Product {
    ID?: string;
    UPC?: string;
    ASIN?: string;
    Brand?: string;
    Name?: string;
    MSRP?: number;
    ImgURL?: string;
    Color?: string;
    Size?: string;
    SalesRank?: number;
    SizeTier?: string;
    FNSKU?: string;
    SKU?: string;
    InvQuantity?: number;
    Quantity?: number;
    StorageType?: string;
    TempQuantity?: number;
    AddedQuantity?: number;
}
