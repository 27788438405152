import { FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import KeepaGraph from './KeepaGraph';
import LoadingOverlay from 'react-loading-overlay';

const rankSalesCorrelation = [
  {
    'Rank': 100,
    'Sales': 14730,
  },
  {
    'Rank': 500,
    'Sales': 7770,
  },
  {
    'Rank': 1000,
    'Sales': 5520,
  },
  {
    'Rank': 2000,
    'Sales': 3750,
  },
  {
    'Rank': 3000,
    'Sales': 2940,
  },
  {
    'Rank': 5000,
    'Sales': 2070,
  },
  {
    'Rank': 7500,
    'Sales': 1560,
  },
  {
    'Rank': 10000,
    'Sales': 1230,
  },
  {
    'Rank': 15000,
    'Sales': 900,
  },
  {
    'Rank': 20000,
    'Sales': 690,
  },
  {
    'Rank': 30000,
    'Sales': 480,
  },
  {
    'Rank': 40000,
    'Sales': 360,
  },
  {
    'Rank': 50000,
    'Sales': 270,
  },
  {
    'Rank': 75000,
    'Sales': 180,
  },
  {
    'Rank': 100000,
    'Sales': 120,
  },
  {
    'Rank': 125000,
    'Sales': 90,
  },
  {
    'Rank': 150000,
    'Sales': 75,
  },
  {
    'Rank': 175000,
    'Sales': 60,
  },
  {
    'Rank': 200000,
    'Sales': 50,
  },
  {
    'Rank': 250000,
    'Sales': 30,
  },
  {
    'Rank': 300000,
    'Sales': 20,
  },
  {
    'Rank': 500000,
    'Sales': 6,
  },
  {
    'Rank': 1000000,
    'Sales': 3,
  },
]

const styles = theme => ({
  container: {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    marginTop: theme.spacing(4),
    padding: 5,
    margin: 5,
  },
  innerContainer: {

  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const niceScrollTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: '720px'
      }
    }
  }
});

class SourceBrowser extends Component {
  state = {
    product: {
      Name: '',
      ASIN: '',
      UPC: 'Unknown',
      MSRP: -1,
      Price: -1,
      Offers: 0,
      OfferInfo: [],
      SalesRank: -1,
      ImgURL: '../assets/placeholder.png',
      ParentASIN: '',
      ActiveVars: -1,
      AvgVarPrice: -1,
      Vars: [],
      Color: '',
      Size: '',
      Dimensions: {
        Width: -1,
        Length: -1,
        Heigth: -1,
        Weight: -1,
      },
      SizeTier: '',
      Fee: 0,
      Restricted: 'Unknown',
      RestrictedStatus: 'Unchecked',
      Store: '',
      ID: 0,
      Quantity: 1,
      Cost: 0,
      TaxCode: '',
      SKU: '',
      FNSKU: '',
      multiProdUPC: false,
      feeError: false,
      allConsidered: false,
    },
    resultProduct: {
      graphURL: 'https://graph.keepa.com/pricehistory.png?domain=com&salesrank=1&asin='
    },
    code: '',
    cost: '',
    coupon: '',
    subscription: 'NOT ACTIVE',
    credentials: {
      token: ' ',
      sellerID: ' ',
      keepaKey: ' '
    },
    productData: [],
    errorMsg: '',
    snackbarOpened: false,
    isLoading: false,
    loadingMsg: '',
    currColl: '',
    oldColl: '',
    collections: [],
    purchasedList: '',
    salesData: [],
  }

  constructor(props) {
    super(props)

    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  render() {
    const { classes } = this.props;
    const { auth, userData } = this.props;
    const copy = require('clipboard-copy')

    const columns = [
      {
        name: "name",
        label: "Name",
        options: {
          filter: true,
          sort: true,
          customFilterListRender: v => {
            if (v) {
              return `Name includes ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "UPC",
        label: "UPC",
        options: {
          filter: false,
          sort: true,
          display: false,
        }
      },
      {
        name: "ASIN",
        label: "ASIN",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "SKU",
        label: "SKU",
        options: {
          filter: false,
          sort: true,
          display: true,
          customBodyRender: (value, _, __) => {
            if (value.includes('http')) {
              return <a target="_blank" rel="noopener noreferrer" href={value}>LINK</a>
            } else if (value.includes('-')) {
              return <a target="_blank" rel="noopener noreferrer" href={'https://www.sierra.com/product/bounce/' + value}>{value}</a>
            } else {
              return <a target="_blank" rel="noopener noreferrer" href={'https://www.eastbay.com/product/~/' + value + '.html'}>{value}</a>
            }
          }
        }
      },
      {
        name: "brand",
        label: "Brand",
        options: {
          filter: true,
          sort: true,
          display: false,
          customFilterListRender: v => {
            if (v) {
              return `Brand excludes ${v}`
            }
            return false;
          },
          filterOptions: {
            logic(brand, filterVal) {
              return !(brand !== filterVal)
            }
          }
        }
      },
      {
        name: "isClearance",
        label: "Clearance",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isClearance: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isNewClearance",
        label: "NewClearance",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isNewClearance: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isLowerPrice",
        label: "LowerPrice",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isLowerPrice: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isNew",
        label: "isNew",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isNew: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "isPurchased",
        label: "Purchased",
        options: {
          filter: true,
          display: false,
          filterType: 'dropdown',
          customFilterListRender: v => {
            if (v) {
              return `isPurchased: ${v}`
            }
            return false;
          },
        }
      },
      {
        name: "shopPrice",
        label: "Shop",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Shop: ${v['min']}, Max Shop: ${v['max']}`;
            } else if (v['min']) {
              return `Min Shop: ${v['min']}`;
            } else if (v['max']) {
              return `Max Shop: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Shop</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "adjustPrice",
        label: "Adj",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Adj: ${v['min']}, Max Adj: ${v['max']}`;
            } else if (v['min']) {
              return `Min Adj: ${v['min']}`;
            } else if (v['max']) {
              return `Max Adj: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Adj</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "salePrice",
        label: "SalePrice",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min SalePrice: ${v['min']}, Max SalePrice: ${v['max']}`;
            } else if (v['min']) {
              return `Min SalePrice: ${v['min']}`;
            } else if (v['max']) {
              return `Max SalePrice: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>SalePrice</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "lowestPrice",
        label: "Low",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Low: ${v['min']}, Max Low: ${v['max']}`;
            } else if (v['min']) {
              return `Min Low: ${v['min']}`;
            } else if (v['max']) {
              return `Max Low: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Low</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: 'currentAmazonPrice',
        label: "AZ",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min AZ: ${v['min']}, Max AZ: ${v['max']}`;
            } else if (v['min']) {
              return `Min AZ: ${v['min']}`;
            } else if (v['max']) {
              return `Max AZ: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>AZ</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "currentNewPrice",
        label: "New",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min New: ${v['min']}, Max New: ${v['max']}`;
            } else if (v['min']) {
              return `Min New: ${v['min']}`;
            } else if (v['max']) {
              return `Max New: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>New</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "lowestNewPrice",
        label: "Low New",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Low New: ${v['min']}, Max Low New: ${v['max']}`;
            } else if (v['min']) {
              return `Min Low New: ${v['min']}`;
            } else if (v['max']) {
              return `Max Low New: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Low New</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "avg30BB",
        label: "AVG 30 BB",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min AVG 30 BB: ${v['min']}, Max AVG 30 BB: ${v['max']}`;
            } else if (v['min']) {
              return `Min AVG 30 BB: ${v['min']}`;
            } else if (v['max']) {
              return `Max AVG 30 BB: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>AVG 30 BB</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "avg90BB",
        label: "AVG 90 BB",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min AVG 90 BB: ${v['min']}, Max AVG 90 BB: ${v['max']}`;
            } else if (v['min']) {
              return `Min AVG 90 BB: ${v['min']}`;
            } else if (v['max']) {
              return `Max AVG 90 BB: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>AVG 90 BB</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "avg90AZ",
        label: "AVG 90 AZ",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min AVG 90 AZ: ${v['min']}, Max AVG 90 AZ: ${v['max']}`;
            } else if (v['min']) {
              return `Min AVG 90 AZ: ${v['min']}`;
            } else if (v['max']) {
              return `Max AVG 90 AZ: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>AVG 90 AZ</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "currentListPrice",
        label: "List",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min List: ${v['min']}, Max List: ${v['max']}`;
            } else if (v['min']) {
              return `Min List: ${v['min']}`;
            } else if (v['max']) {
              return `Max List: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>List</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "currentBuyBoxPrice",
        label: "BuyBox",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min BuyBox: ${v['min']}, Max BuyBox: ${v['max']}`;
            } else if (v['min']) {
              return `Min BuyBox: ${v['min']}`;
            } else if (v['max']) {
              return `Max BuyBox: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>BuyBox</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "lowestBuyBoxPrice",
        label: "Low BuyBox",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Low BuyBox: ${v['min']}, Max Low BuyBox: ${v['max']}`;
            } else if (v['min']) {
              return `Min Low BuyBox: ${v['min']}`;
            } else if (v['max']) {
              return `Max Low BuyBox: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Low BuyBox</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "currentSalesRank",
        label: "Rank",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Rank: ${v['min']}, Max Rank: ${v['max']}`;
            } else if (v['min']) {
              return `Min Rank: ${v['min']}`;
            } else if (v['max']) {
              return `Max Rank: ${v['max']}`;
            }
            return false;
          },
          customBodyRender: (value, _, __) => {
            return parseInt(value)
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Rank</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "avg30SalesRank",
        label: "Avg 30 Rank",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min 30 Rank: ${v['min']}, Max 30 Rank: ${v['max']}`;
            } else if (v['min']) {
              return `Min 30 Rank: ${v['min']}`;
            } else if (v['max']) {
              return `Max 30 Rank: ${v['max']}`;
            }
            return false;
          },
          customBodyRender: (value, _, __) => {
            return parseInt(value)
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Avg Rank</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "offersCount",
        label: "Offers",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Offers: ${v['min']}, Max Offers: ${v['max']}`;
            } else if (v['min']) {
              return `Min Offers: ${v['min']}`;
            } else if (v['max']) {
              return `Max Offers: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Offers</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "OnHand",
        label: "OnHand",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min On Hand: ${v['min']}, Max On Hand: ${v['max']}`;
            } else if (v['min']) {
              return `Min On Hand: ${v['min']}`;
            } else if (v['max']) {
              return `Max On Hand: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>On Hand</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },

      {
        name: "Orders",
        label: "Orders",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Orders: ${v['min']}, Max Orders: ${v['max']}`;
            } else if (v['min']) {
              return `Min Orders: ${v['min']}`;
            } else if (v['max']) {
              return `Max Orders: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Orders</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "rootCategory",
        label: "Category",
        options: {
          filter: false,
          sort: true,
          display: false,
        }
      },
      {
        name: "Fees",
        label: "Fees",
        options: {
          filter: true,
          sort: true,
          display: false,
        }
      },
      {
        name: "variationsASIN",
        label: "Vars",
        options: {
          filter: true,
          sort: true,
          display: false,
          customFilterListRender: v => {
            if (v) {
              return `Min VarCount: ${v}`
            }
            return false;
          },
          customBodyRender: (value, _, __) => {
            let vars = value.split(',')
            return vars.length
          },
          filterOptions: {
            logic(val, filterVal) {
              let vars = val.split(',').length
              return !(vars <= parseFloat(filterVal))
            }
          }
        }
      },
      {
        name: "ROI",
        label: "ROI",
        options: {
          filter: true,
          sort: true,
          display: true,
          customFilterListRender: v => {
            if (v) {
              return `Min ROI: ${v}%`
            }
            return false;
          },
          customBodyRender: (value, _, __) => (
            <span>{parseFloat(value).toFixed(0)}%</span>
          ),
          filterOptions: {
            logic(ROI, filterVal) {
              return !(ROI >= parseFloat(filterVal))
            }
          }
        }
      },
      {
        name: "EstimatedSales",
        label: "EstSales",
        options: {
          filter: true,
          display: false,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min EstSales: ${v['min']}, Max EstSales: ${v['max']}`;
            } else if (v['min']) {
              return `Min EstSales: ${v['min']}`;
            } else if (v['max']) {
              return `Max EstSales: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>EstSales</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "OurSales",
        label: "OurSales",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min OurSales: ${v['min']}, Max OurSales: ${v['max']}`;
            } else if (v['min']) {
              return `Min OurSales: ${v['min']}`;
            } else if (v['max']) {
              return `Max OurSales: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>OurSales</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "velocityScore",
        label: "Velocity",
        options: {
          filter: true,
          display: true,
          filterType: 'custom',
          customFilterListRender: v => {
            if (v['min'] && v['max']) {
              return `Min Velocity Score: ${v['min']}, Max Velocity Score: ${v['max']}`;
            } else if (v['min']) {
              return `Min Velocity Score: ${v['min']}`;
            } else if (v['max']) {
              return `Max Velocity Score: ${v['max']}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters['min'] && filters['max']) {
                return age < filters['min'] || age > filters['max'];
              } else if (filters['min']) {
                return age < filters['min'];
              } else if (filters['max']) {
                return age > filters['max'];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Velocity Score</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index]['min'] || ''}
                    onChange={event => {
                      filterList[index]['min'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index]['max'] || ''}
                    onChange={event => {
                      filterList[index]['max'] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: "azLink",
        label: "AZ Link",
        options: {
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, _, __) => (
            <a target="_blank" rel="noopener noreferrer" href={value}>AZ</a>
          )
        }
      },
      {
        name: "graphURL",
        label: "Graph",
        options: {
          filter: false,
          sort: false,
          display: true,
          customBodyRender: (value, _, __) => (
            <KeepaGraph graphURL={value} />
          )
        }
      },
    ]

    const options = {
      filterType: 'textField',
      selectableRows: 'none',
      responsive: 'scroll',
      print: false,
      // onRowClick: (rowData, rowMeta) => {
      //   copy(`"${rowData[0]}",${rowData[2]}`)
      // }
      onCellClick: (colData, celMeta) => {
        copy(`${colData}`)
      }
    };

    if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text={this.state.loadingMsg}>
        <Container display="flex" maxWidth={false} height="100%">
        <Container width='100%' m={3} >
          <Box display="flex" flexDirection="row" className={classes.container} justifyContent="center" alignItems="center">
            <Select
              value={this.state.currColl}
              onChange={this.handleChange}
              inputProps={{
                name: 'currColl',
                id: 'choose-curr-coll',
              }}
            >
              {this.state.collections.map((name) => {
                return <MenuItem value={name}>{name}</MenuItem>
              })}
            </Select>
            <Select
              value={this.state.oldColl}
              onChange={this.handleChange}
              inputProps={{
                name: 'oldColl',
                id: 'choose-old-coll',
              }}
            >
              {this.state.collections.map((name) => {
                return <MenuItem value={name}>{name}</MenuItem>
              })}
            </Select>
            <TextField
              id="coupon"
              label="Coupon"
              type="text"
              className={classes.textField}
              value={this.state.coupon}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              fullWidth
              onSubmit={this.handleSubmit}
              inputRef={this.textInput}
            />
            <Button onClick={() => this.getData(this.state.currColl, this.state.oldColl)}>
              Generate
                        </Button>
            <Button onClick={() => this.deleteColl(this.state.currColl)}>
              Delete
                        </Button>
          </Box>
        </Container>


        <Container width='100%' maxWidth="xl" height="100%" className={classes.container}>
          <MuiThemeProvider theme={niceScrollTheme}>
            <MUIDataTable
              title={"Product Data"}
              data={this.state.productData}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>


        </Container>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.snackbarOpened}
          autoHideDuration={6000}
        >
          <SnackbarContent
            className={clsx(classes.error, classes.margin)}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                {this.state.errorMsg}
              </span>
            }
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={(event, reason) => {
                if (reason === 'clickaway') {
                  return;
                }
                this.setState({ snackbarOpened: false })
              }} >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </Container>

      </LoadingOverlay>
    )
  }

  componentDidMount() {
    this.getCollections()
  }

  focusTextInput() {
    this.textInput.current.focus();
  }

  getCollections = () => {
    fetch('https://api.projectsuite.io/collections?list').then(response => {
      return response.json();
    }).then(jsonResponse => {
      let collections = jsonResponse["collections"]
      this.setState({
        collections: collections.map((coll) => {
          return coll["name"]
        })
      })
    })
  }

  deleteColl = (coll) => {
    fetch('https://api.projectsuite.io/delete?coll=' + coll).then(response => {
      return response.json();
    }).then(jsonResponse => {
      if (jsonResponse.error !== null) {
        this.setState({ snackbarOpened: true, errorMsg: jsonResponse.error })
      } else {
        this.getCollections()
      }
    })
  }

  handleChange = e => {
    if (e.target.id !== undefined) {
      this.setState({
        [e.target.id]: e.target.value
      })
    } else if (e.target.name !== undefined) {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  handleSubmit = (e) => {
    console.log('Submitted wtf lol')
  }

  changePage = (page) => {
    this.setState({
      isLoading: true,
    });
  };

  getData = (currColl, oldColl) => {
    this.setState({ isLoading: true });
    fetch('https://api.projectsuite.io/products?collection=' + currColl + '&trim=none').then(response => {
      return response.json();
    }).then(jsonResponse => {
      let prods = jsonResponse["products"]
      fetch('https://api.projectsuite.io/products?collection=' + oldColl + '&trim=asin').then(response_old => {
        return response_old.json();
      }).then(jsonResponse_old => {
        // Get previous products
        let old_prods = jsonResponse_old["products"]
        let asins = []
        let prices = {}
        for (var i = 0; i < old_prods.length; i++) {
          asins.push(old_prods[i]["ASIN"])
          prices[asins[i]] = old_prods[i]["shopPrice"]
        }

        prods = prods.map((prod) => {
          let nums = [];
          let adjustPrice = parseFloat((prod.shopPrice * (1 - parseFloat(this.state.coupon))).toFixed(2))
          prod.currentSalesRank = parseInt(prod.currentSalesRank)
          prod.avg30SalesRank = parseInt(prod.avg30SalesRank)
          prod.adjustPrice = adjustPrice

          // Check if existed previously
          if (asins.includes(prod.ASIN)) {
            prod.isNew = 'False'
          } else {
            prod.isNew = 'True'
          }

          if ((prod.shopPrice % 1) === 0 && (prices[prod.ASIN] % 1) !== 0) {
            prod.isNewClearance = 'True'
          } else {
            prod.isNewClearance = 'False'
          }

          if (prod.shopPrice < prices[prod.ASIN]) {
            prod.isLowerPrice = 'True'
          } else {
            prod.isLowerPrice = 'False'
          }

          let foundSalesData
          if (this.props.salesData !== undefined && (foundSalesData = this.props.salesData.find(el => el.ASIN === prod.ASIN)) !== undefined) {
            prod.Orders = parseInt(foundSalesData.Orders)
            prod.OnHand = parseInt(foundSalesData.OnHand)
          } else {
            prod.Orders = -1
            prod.OnHand = -1
          }

          // Check if on purchased list
          if (this.props.purchasedData === undefined) {
            prod.isPurchased = 'Unknown'
          } else {
            if (this.props.purchasedData.includes(prod.ASIN)) {
              prod.isPurchased = 'True'
            } else {
              prod.isPurchased = 'False'
            }
          }

          // Check for clearance
          if ((prod.shopPrice % 1) === 0) {
            prod.isClearance = 'True'
          } else {
            prod.isClearance = 'False'
          }

          // Pick low price
          prod.currentNewPrice = parseFloat(prod.currentNewPrice)
          prod.currentAmazonPrice = parseFloat(prod.currentAmazonPrice)
          prod.currentListPrice = parseFloat(prod.currentListPrice)
          prod.currentBuyBoxPrice = parseFloat(prod.currentBuyBoxPrice)
          prod.lowestNewPrice = parseFloat(prod.lowestNewPrice)
          prod.lowestBuyBoxPrice = parseFloat(prod.lowestBuyBoxPrice)
          prod.avg90AZ = parseFloat(prod.avg90AZ)
          prod.avg90BB = parseFloat(prod.avg90BB)
          prod.avg30BB = parseFloat(prod.avg30BB)
          prod.offersCount = parseInt(prod.offersCount)

          if (prod.currentNewPrice !== -1) {
            nums.push(prod.currentNewPrice)
          }
          if (prod.currentAmazonPrice !== -1) {
            nums.push(prod.currentAmazonPrice)
          }
          if (nums.length >= 1) {
            let lowestPrice = Math.min.apply(Math, nums)
            prod.lowestPrice = lowestPrice
          } else {
            prod.lowestPrice = -1
          }

          if (prod.lowestPrice === -1) {
            let listPriceDiv = 1
            let listPriceVal = 0
            if (prod.currentListPrice !== -1) {
              listPriceDiv = 2
              listPriceVal = prod.currentListPrice
            }

            if (prod.avg30BB !== -1) {
              prod.lowestPrice = (prod.avg30BB + listPriceVal) / listPriceDiv
            } else if (prod.avg90BB !== -1) {
              prod.lowestPrice = (prod.avg90BB + listPriceVal) / listPriceDiv
            } else if (prod.lowestBuyBoxPrice !== -1) {
              prod.lowestPrice = (prod.lowestBuyBoxPrice + listPriceVal) / listPriceDiv
            } else if (prod.lowestNewPrice !== -1) {
              prod.lowestPrice = (prod.lowestNewPrice + listPriceVal) / listPriceDiv
            }
          }

          prod.salePrice = -1
          if (prod.lowestPrice !== -1) {
            if (prod.currentBuyBoxPrice !== -1) {
              prod.salePrice = prod.currentBuyBoxPrice
            } else if (prod.currentNewPrice !== -1) {
              if (prod.avg30BB !== -1) {
                prod.salePrice = (prod.currentNewPrice + prod.avg30BB) / 2
              } else {
                prod.salePrice = prod.currentNewPrice
              }
            }
          }

          if (prod.currentSalesRank !== -1 && prod.avg30SalesRank !== -1) {
            prod.velocityScore = (prod.currentSalesRank * 3 + prod.avg30SalesRank) / 4
          } else {
            prod.velocityScore = -1
          }

          if (prod.currentSalesRank !== -1 && prod.variationsASIN !== undefined && prod.velocityScore > 0) {
            prod.EstimatedSales = rankSalesCorrelation.find(rank => !(prod.velocityScore > rank.Rank)) !== undefined ? parseFloat((rankSalesCorrelation.find(rank => !(prod.velocityScore > rank.Rank)).Sales / prod.variationsASIN.split(',').length).toFixed(2)) : -1
            if (prod.rootCategory !== "Clothing, Shoes & Jewerly") {
              if (prod.EstimatedSales !== -1) {
                prod.EstimatedSales /= 2
              }
            }
          } else {
            prod.EstimatedSales = -1
          }

          if (prod.EstimatedSales >= 0) {
            if (prod.offersCount > 0) {
              prod.OurSales = parseFloat((prod.EstimatedSales / prod.offersCount * 2).toFixed(2))
            } else {
              prod.OurSales = parseFloat((prod.EstimatedSales * 2 ).toFixed(2))
            }
          }

          prod.graphURL = prod.graphURL + '&range=365'

          // Calculate ROI
          prod.Fees = parseFloat(prod.Fees)
          let calcPrice = prod.salePrice !== -1 ? prod.salePrice : (prod.lowestPrice !== -1 ? prod.lowestPrice : -1)
          if (prod.Fees !== -1 && calcPrice !== -1) {
            prod.ROI = parseFloat(((calcPrice * 0.85 - adjustPrice - prod.Fees) / adjustPrice * 100).toFixed(0))
            prod.salePrice = parseFloat(calcPrice.toFixed(2))
            return prod
          } else if (calcPrice !== -1) {
            prod.ROI = parseFloat(((calcPrice * 0.85 - adjustPrice - 5) / adjustPrice * 100).toFixed(0))
            prod.salePrice = parseFloat(calcPrice.toFixed(2))
            return prod
          } else {
            prod.ROI = -2
            return prod
          }
        })

        // Remove duplicates
        prods = this.uniq(prods)
        // console.log('prods after: ' + JSON.stringify(prods))
        this.setState({
          productData: prods.filter((prod) => {
            return prod.currentSalesRank !== -1 && prod.ROI > 50 && prod.OurSales > 1 && prod.currentAmazonPrice <= 0
          }), isLoading: false
        });
      })
    })
  }

  uniq = (a) => {
    var seen = {};
    return a.filter(function (item) {
      return seen.hasOwnProperty(item.ASIN) ? false : (seen[item.ASIN] = true);
    });
  }

  _resetProduct = () => {
    let newProduct = {
      Name: '',
      ASIN: '',
      UPC: 'Unknown',
      MSRP: -1,
      Price: -1,
      Offers: 0,
      OfferInfo: [],
      SalesRank: -1,
      ImgURL: '../assets/placeholder.png',
      ParentASIN: 'none',
      ActiveVars: -1,
      AvgVarPrice: -1,
      Vars: [],
      Color: '',
      Size: '',
      Dimensions: {
        Width: -1,
        Length: -1,
        Heigth: -1,
        Weight: -1,
      },
      SizeTier: '',
      Fee: 0,
      Restricted: 'Unknown',
      RestrictedStatus: 'Unchecked',
      Store: this.state.product.Store,
      Quantity: 1,
      Cost: 0,
      TaxCode: '',
      SKU: '',
      FNSKU: '',
      multiProdUPC: false,
      feeError: false,
      ID: 0,
      allConsidered: false,
    };
    this.setState({ product: newProduct, labelSrc: 'data:image/png;base64,', code: '' })
  }
}

SourceBrowser.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.info,
    userData: state.db.userData,
    salesData: state.db.salesData,
    purchasedData: state.db.purchasedData,
  }
}


export default connect(mapStateToProps)(withStyles(styles)(SourceBrowser))