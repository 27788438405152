import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import Image from 'material-ui-image';
import PropTypes from 'prop-types';
import React from 'react';
import placeholder from '../../assets/placeholder.png';
import './ProductDialog.scss';

export default function ProductDialog(props) {
    const [selectedOption, setSelectedOption] = React.useState("relist");
    const { onClose, opened, products, onEntered, onProceed } = props;
    if (!products.length) return null;
    let product = products[0];
    let properties = ["UPC", "sku", "asin", "quantity", "detailed-disposition", "reason", "status", "license-plate-number", "customer-comments"];
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    }

    return (
        <Dialog open={opened} onClose={onClose} aria-labelledby="form-dialog-title" onEntered={onEntered}>
            <DialogTitle id="form-dialog-title">
                {"Confirm product"}
                <IconButton aria-label="Close" className="close-button" style={{ position: 'absolute', right: '0.4em', top: '0.4em' }} onClick={onClose} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className="product-view-container">
                    <div className="product-left-side-container">
                        <Image src={product.ImgURL || placeholder} imageStyle={{ maxWidth: '150px', height: 'auto', maxHeight: '200px', width: 'auto' }} />

                        <div className="action-container">
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Choose action</FormLabel>
                                <RadioGroup aria-label="gender" name="product-action" value={selectedOption} onChange={handleChange}>
                                    <FormControlLabel value="relist" control={<Radio />} label="Relist on Amazon" />
                                    <FormControlLabel value="mf-used" control={<Radio />} label="MF - USED" />
                                    <FormControlLabel value="other-sell-elsewhere" control={<Radio />} label="Sell elsewhere" />
                                    <FormControlLabel value="damage-discard" control={<Radio />} label="Damaged - Donate" />
                                    <FormControlLabel value="fraud-discard" control={<Radio />} label="Fraud - Donate" />
                                    <FormControlLabel value="fraud-keep" control={<Radio />} label="Fraud - Sell in store" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="product-details-container">
                        <div className="product-name-container">
                            <p className="product-name">
                                {product.Name}
                            </p>
                        </div>
                        <div className="product-details">
                            {
                                properties.map(p => (
                                    <div key={p} className="product-details-row">
                                        <p className="detail-title">{p}</p>
                                        <p className="detail">{product[p] === undefined || product[p] === "" ? "N/A" : product[p]}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onProceed(selectedOption) }} color="primary">
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ProductDialog.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    products: PropTypes.array,
    onClose: PropTypes.func,
    onEntered: PropTypes.func,
    onProceed: PropTypes.func,
};