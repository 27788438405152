import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({
    panel: {
        width: '100%'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
});

class AddressPanel extends Component {

    render() {
        const { classes } = this.props;

        return (
            <ExpansionPanel expanded={this.props.expanded === 'address'} className={classes.panel}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>Address</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Box display="flex" flexDirection="column" width='100%' className={classes.container}>
                        <TextField
                            id="name"
                            label="The name or business name"
                            type="text"
                            className={classes.textField}
                            value={this.props.name}
                            onChange={this.props.onAddressChange}
                            margin="normal"
                            variant="outlined"
                            onSubmit={this.props.onSave}
                            required
                           // error={this.props.nameError}
                            // helperText={this.props.nameError ? 'Empty field' : ''}
                        />
                        <TextField
                            id="address1"
                            label="Address line 1"
                            type="text"
                            className={classes.textField}
                            value={this.props.address1}
                            onChange={this.props.onAddressChange}
                            margin="normal"
                            variant="outlined"
                            onSubmit={this.props.onSave}
                            required
                        />
                        <TextField
                            id="address2"
                            label="Address line 2"
                            type="text"
                            className={classes.textField}
                            value={this.props.address2}
                            onChange={this.props.onAddressChange}
                            margin="normal"
                            variant="outlined"
                            onSubmit={this.props.onSave}
                        />
                        <Box flexDirection='row' display='flex' width='100%'>
                            <TextField
                                id="city"
                                label="City"
                                type="text"
                                className={classes.textField}
                                value={this.props.city}
                                onChange={this.props.onAddressChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                onSubmit={this.props.onSave}
                                required
                            />
                            <TextField
                                id="stateCode"
                                label="State code"
                                type="text"
                                className={classes.textField}
                                value={this.props.stateCode}
                                onChange={this.props.onAddressChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                onSubmit={this.props.onSave}
                                required
                            />
                        </Box>
                        <Box flexDirection='row' display='flex' width='100%'>
                            <TextField
                                id="postalCode"
                                label="Postal code"
                                type="text"
                                className={classes.textField}
                                value={this.props.postalCode}
                                onChange={this.props.onAddressChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                onSubmit={this.props.onSave}
                                required
                            />
                            <TextField
                                id="countryCode"
                                label="Country code"
                                type="text"
                                className={classes.textField}
                                value={this.props.countryCode}
                                onChange={this.props.onAddressChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                onSubmit={this.props.onSave}
                                required
                            />
                        </Box>
                    </Box>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small" color="primary" onClick={this.props.onSave}>
                        Save
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        )
    }

    componentDidMount() {

    }
}

AddressPanel.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AddressPanel)