import React, { useEffect } from 'react';
import { Button, Checkbox } from '@material-ui/core';
import { utils as shipmentUtils } from './shipments';
import LoadingDialog from '../common/LoadingDialog';

const _ = require('lodash');

function ShipmentRow({ shipment, onChange }) {
    let unsaved = shipment.Boxes
        .filter(box =>
            shipmentUtils.calcBoxSize(box) > 0 &&
            (box.weight === -1 || box.length === -1 || box.width === -1 || box.height === -1)
        ).length;

    let notEmpty = shipmentUtils.calcNonemptyBoxes(shipment.Boxes);

    return (
        <div className='list-row shipment-dialog-row soft-corners' key={shipment.ShipmentId} style={{
            backgroundColor: unsaved > 0 && '#f7d088'
        }}>
            <div className='checkbox-container'>
                <Checkbox
                    checked={shipment.checked}
                    onChange={event => {
                        onChange(shipment.ShipmentId, event.target.checked);
                    }}
                />
            </div>
            <div className='center-container label-info-container'>
                <p className='label'>
                    Center
                </p>
                <p className='info'>
                    {shipment.DestinationFulfillmentCenterId}
                </p>
            </div>
            <div className='shipment-name-container label-info-container'>
                <p className='label'>
                    Name
                </p>
                <p className='info'>
                    {shipment.ShipmentName}
                </p>
            </div>
            <div className='boxes-container label-info-container'>
                <p className='label'>
                    Boxes
                </p>
                <p className='info'>
                    {notEmpty}
                </p>
            </div>
            <div className='shipment-size-container label-info-container'>
                <p className='label'>
                    Size
                </p>
                <p className='info' >
                    {shipmentUtils.calcShipmentSize(shipment)}
                </p>
            </div>
        </div>
    )
}

function ShipmentsList({ shipments, onChange }) {
    return (
        <div className='shipment-list dialog-shipment-list'>
            {
                shipments.map(shipment => <ShipmentRow shipment={shipment} onChange={onChange} />)
            }
        </div>
    );
}


export default function SubmitShipmentsDialog({ opened, onClose, onEntered, onSubmit, shipments, loading }) {
    const [message, setMessage] = React.useState(null);
    const [shipmentsState, setShipments] = React.useState([]);

    useEffect(() => {
        setShipments(_.cloneDeep(shipments));
    }, [shipments, opened]);

    const onChangeShipment = (id, checked) => {
        let shipmentsCopy = _.cloneDeep(shipmentsState);
        let shipment = shipmentsCopy.find(s => s.ShipmentId === id);
        shipment.checked = checked;

        let emptyBoxes = 0;
        shipmentsCopy.forEach(shipment => {
            if (shipment.checked) {
                shipment.Boxes.forEach(box => {
                    if (shipmentUtils.calcBoxSize(box) > 0 &&
                        (box.weight === -1 || box.length === -1 || box.width === -1 || box.height === -1)) {
                        emptyBoxes++;
                    }
                });
            }

        });

        if (emptyBoxes > 0) {
            setMessage("Unsaved boxes will get removed before proceeding");
        } else {
            setMessage(null);
        }
        setShipments(shipmentsCopy);
    }

    return (
        <LoadingDialog
            onClose={onClose}
            opened={opened}
            onEntered={onEntered}
            loading={loading}
            classes={{
                paper: "shipping-page-background-color",
            }}
            title='Submit shipments'
            actions={
                <React.Fragment>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            let shipmentsCopy = _.cloneDeep(shipmentsState);
                            let filtered = shipmentsCopy.filter(s => s.checked);
                            if (filtered.length > 0) {
                                onSubmit(filtered);
                            }
                        }}
                        color="primary"
                    >
                        Submit
                    </Button>
                </React.Fragment>
            }
        >
            <div className="shipment-dialog-container">
                <p className="shipment-dialog-message">{message}</p>
                <ShipmentsList
                    shipments={shipmentsState}
                    onChange={onChangeShipment}
                />
            </div>
        </LoadingDialog>
    )
}