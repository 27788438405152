import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

/**
 * Renders the given component under the specific route only if the user is authorized
 * to view it. Otherwise, the component redirects to the login or the forbidden page.
 * @param {Object} props - Component props.
 */
export default function PrivateRoute({ closed, children, render, ...rest }) {
    const auth = useSelector(state => state.auth.info);

    const shouldRedirect = !auth || closed;

    // If the 'closed' prop is set to true, the component should redirect to 
    // the forbidden page
    let redirect = closed ? (
        <Redirect to='/forbidden' />
    ) : (
        <Redirect to='/signin' />
    );

    return (
        <Route
            {...rest}
            render={props => {
                return shouldRedirect ? redirect : render(props);
            }}
        >
            {shouldRedirect ? redirect : children}
        </Route>
    );
}
