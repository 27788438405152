import { put, all, select } from 'redux-saga/effects';
import * as shippingActions from '../../actions/shippingActions';
import * as notifications from '../../actions/notificationsActions';
import { utils } from '../../../components/shipping_boxes/shipments';
import * as Sentry from '@sentry/react';

/**
 * Saga function responsible for updating only the shipment selected by the user.
 * It takes an item and the desired quantity and adds it to the newest box in the shipment.
 * 
 * @param {Object} actionData 
 */
export function* updateSelectedShipment(actionData) {
    let { item, quantity } = actionData;

    // Extract the shipment data from the redux state
    let state = yield select(state => state.inboundShipments);
    try {
        if (!item) {
            throw new Error('Item not found');
        }
        let selectedShipment = state.shipments.find(
            s => s.ShipmentId === state.selectedShipmentId
        );

        if (selectedShipment.TransportStatus === 'CONFIRMED') {
            throw new Error('This shipment is already confirmed');
        }

        console.log(selectedShipment, actionData)
        // Make sure that the given item belongs to the selected shipment
        if (state.selectedShipmentId === item.ShipmentId) {
            let quantityToAdd =  Math.max(0, quantity);
            // First add the item to the newest box
            yield put(
                shippingActions.addItem(selectedShipment.CurrentBox, item)
            );
            // Add the given quantity to this item. It automatically updates
            // the marked item in the given box (in this case the marked item is
            // newly added item)
            yield put(
                shippingActions.addQuantity(
                    selectedShipment.CurrentBox,
                    quantityToAdd
                )
            );
            // Update the shipment locally
            yield put(
                shippingActions.updateShipment(
                    selectedShipment.ShipmentId,
                    {
                        LastUpdated: new Date().getTime(),
                    },
                    true
                )
            );
            yield all([
                put(
                    shippingActions.updateStatus({
                        isFetchingProduct: false,
                    })
                ),
                put(shippingActions.saveShipments([state.selectedShipmentId])),
            ]);

            // Count the quantity of the given item in the selected shipment
            let currentQuantity = utils.getItemQuantity(
                selectedShipment,
                item.FulfillmentNetworkSKU
            );
            // If the current quantity plus the given quantity exceeds the limit, display
            // a warning
            if (quantityToAdd + (currentQuantity || 0) > item.QuantityShipped) {
                yield put(
                    notifications.enqueueSnackbar({
                        message:
                            'Maximum product quantity for this shipment reached',
                        options: { variant: 'warning' },
                    })
                );
            } else {
                yield put(
                    notifications.enqueueSnackbar({
                        message: `Product added to Box ${selectedShipment.Boxes.find((box) => box.id === selectedShipment.CurrentBox)?.number || 'Unknown'}`,
                        options: { 
                            variant: 'success',
                            style: {
                                marginRight: '10px',
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            }
                        },
                    })
                );
            }
        } else {
            throw new Error('Item not found in this shipment');
        }
    } catch (e) {
        Sentry.withScope(function (scope) {
            if (e.message === 'Item not found') {
                scope.setLevel(Sentry.Severity.Warning);
            }
            scope.setContext('updateSelectedShipment', {
                item,
                selectedShipmentId: state.selectedShipmentId,
            });
            Sentry.captureException(e);
        });
        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}

/**
 * Saga function responsible for updating all shipments to which the given items belong.
 * It takes a list of items and searches for their shipments. Found shipments are represented as
 * a list of their IDs, which is then saved in the state.
 * 
 * @param {Object} actionData 
 */
export function* updateCurrentShipments(actionData) {
    let { items } = actionData;

    // Extract the shipment data from the redux state
    let state = yield select(state => state.inboundShipments);
    try {
        let currentShipments = [];
        let areAnyConfirmed = false;
        for (let item of items) {
            let filteredShipment = state.shipments.find(
                shipment => shipment.ShipmentId === item.ShipmentId
            );

            if (!filteredShipment) continue;

            if (filteredShipment.TransportStatus === 'CONFIRMED') {
                areAnyConfirmed = true;
            } else {
                // Update this shipment and reset its status
                yield put(
                    shippingActions.updateShipment(
                        filteredShipment.ShipmentId,
                        {
                            LastUpdated: new Date().getTime(),
                        },
                        true
                    )
                );
                currentShipments.push(filteredShipment.ShipmentId);
            }
        }

        // Already confirmed and completed shipments are not deleted from the list right away
        // so warn the user if one of the given items belong to such a shipment
        if (areAnyConfirmed) {
            yield put(
                notifications.enqueueSnackbar({
                    message: 'Some found shipments are already confirmed',
                    options: { variant: 'warning' },
                })
            );
        }

        if (currentShipments.length > 0) {
            yield all([
                put(
                    shippingActions.updateStatus({
                        isFetchingProduct: false,
                    })
                ),
                put(
                    notifications.enqueueSnackbar({
                        message: 'Product added',
                        options: { variant: 'success' },
                    })
                ),
                put(shippingActions.setCurrentShipmentIds(currentShipments)),
                put(shippingActions.saveShipments(currentShipments)),
            ]);
        } else {
            throw new Error('Item not found');
        }
    } catch (e) {
        Sentry.withScope(function (scope) {
            if (e.message === 'Item not found') {
                scope.setLevel(Sentry.Severity.Warning);
            }
            scope.setContext('updateCurrentShipments', {
                items: items.map(i => i.FulfillmentNetworkSKU),
            });
            Sentry.captureException(e);
        });
        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                    isFetchingProduct: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}
