import React, { useRef } from 'react';
import { Button, TextField } from '@material-ui/core';

export default function QuantityField({
    onChange,
    buttonStyle,
    textFieldStyle,
    className,
    style,
    classes,
    value,
}) {
    const textField = useRef(null);
    let containerClass = classes && classes.container ? classes.container : '';
    let buttonsClass = classes && classes.buttons ? classes.buttons : '';
    let textFieldClass = classes && classes.textField ? classes.textField : '';
    return (
        <div
            className={`quantity-field-container ${className} ${containerClass}`}
            style={style}
            onClick={e => e.stopPropagation()}
        >
            <Button
                className={`quantity-field-button ${buttonsClass}`}
                style={{
                    borderRadius: '1em 0 0 1em',
                    ...buttonStyle,
                }}
                onClick={() => {
                    let q = parseInt(textField.current.value);
                    if (q - 1 >= 0) onChange(q - 1);
                }}
                variant='outlined'
            >
                -
            </Button>
            <TextField
                className={`quantity-textfield full-height-textfield ${textFieldClass}`}
                size='small'
                value={value}
                style={{
                    width: '64px',
                    height: '100%',
                    ...textFieldStyle,
                }}
                inputProps={{
                    style: {
                        height: '100%',
                    },
                }}
                onChange={e => {
                    let q = parseInt(e.target.value) || 0;
                    if (q >= 0) onChange(q);
                }}
                inputRef={textField}
                variant='outlined'
            />
            <Button
                className={`quantity-field-button ${buttonsClass}`}
                style={{
                    borderRadius: '0 1em 1em 0',
                    ...buttonStyle,
                }}
                onClick={() => {
                    onChange(parseInt(textField.current.value) + 1);
                }}
                variant='outlined'
            >
                +
            </Button>
        </div>
    );
}
