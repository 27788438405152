import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';


const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 400,
        height: '100%',
        margin: 5,
        background: '#eceff1'
    },
    cardFixed: {
        width: 400,
        height: '100%',
        margin: 5,
        background: '#eceff1'
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        overflow: 'auto',
        height: 290
    },
    head: {
        background: '#000',
        position: "sticky",
        top: 0,
        textEmphasisColor: '#fff',
        fontSize: 10
    },
    cell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: 50,
        fontSize: 10
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
        fontSize: 20,
    },
    button: {
        margin: theme.spacing(1),
        alignSelf: 'right'
    },
}));

export default function BoxCard(props) {
    const classes = useStyles();
    const { data, onEditPressed, onCopyPressed, onDeletePressed, buttonFree, fullWidth, onSelect } = props
    
    return (
        <Card className={fullWidth ? classes.cardFixed : classes.card}>
            <CardContent>
                <Box flexDirection='row' display='flex' alignItems="center" justifyItems="space-between" justifyContent="space-between">
                    <Typography variant='h4' style={{ width: 5 }}>
                        #{data.number}
                    </Typography>
                    <Typography variant='h6'>
                        Warehouse: {data.warehouse}
                    </Typography>
                    {buttonFree ? null :
                        onSelect ?
                        <Button size="small" color="primary" onClick={onSelect} variant='outlined'>
                            Select
                        </Button>
                        :
                        <Button size="small" color="primary" onClick={onCopyPressed} variant='outlined' className={classes.button}>
                            Copy
                            <Icon className={classes.rightIcon}>file_copy</Icon>
                        </Button>
                    }
                </Box>
                <Paper className={classes.paper}>
                    <Table className={classes.table} size='small'>
                        <TableHead >
                            <TableRow>
                                <TableCell className={classes.head} style={{ color: 'white' }}>Nr</TableCell>
                                <TableCell align="left" className={classes.head} style={{ color: 'white' }}>UPC</TableCell>
                                <TableCell align="left" className={classes.head} style={{ color: 'white' }}>Title</TableCell>
                                <TableCell align="left" className={classes.head} style={{ color: 'white' }}>Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.products.map(row => (
                                <TableRow key={row.ID}>
                                    <TableCell component="th" scope="row" className={classes.cell}>
                                        {row.Number}
                                    </TableCell>
                                    <TableCell align="right" className={classes.cell}>{row.UPC}</TableCell>
                                    <TableCell align="right" className={classes.cell}>{row.Name}</TableCell>
                                    <TableCell align="right" className={classes.cell}>{row.Quantity}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </CardContent>
            {buttonFree || onSelect ? null :
                <CardActions>
                    <Button size="small" color="secondary" onClick={onDeletePressed}>
                        Delete
                    </Button>
                    <Button size="small" color="primary" onClick={onEditPressed}>
                        Edit
                    </Button>
                </CardActions>
            }
        </Card>

    );
}

BoxCard.propTypes = {
    data: PropTypes.object,
    onEditPressed: PropTypes.func,
    onCopyPressed: PropTypes.func,
    onDeletePressed: PropTypes.func,
    buttonFree: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onSelect: PropTypes.func,
};