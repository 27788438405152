import {
    CatalogApiGetCatalogItemRequest,
    CatalogApiSearchCatalogItemsRequest,
    Item,
    ItemSearchResults,
} from '@scaleleap/selling-partner-api-sdk/lib/api-models/catalog-items-api-model-v20220401';
import { DOMAIN } from '../../utils';
import { RequestExtraParams } from '../types';
import { makeCall } from '../utils';

const URL_BASE = `${DOMAIN}/spApi/catalogItems`;

export async function getCatalogItem(
    request: CatalogApiGetCatalogItemRequest & RequestExtraParams
): Promise<Item> {
    const res = await makeCall<Item>(
        'get',
        `${URL_BASE}/getCatalogItem`,
        request
    );

    return res;
}

export async function searchCatalogItems(
    request: CatalogApiSearchCatalogItemsRequest & RequestExtraParams
): Promise<Item[]> {
    const res = await makeCall<Item[]>(
        'get',
        `${URL_BASE}/searchCatalogItems`,
        request
    );

    return res || [];
}
