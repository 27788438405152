import {
  Feed,
  FeedsApiGetFeedDocumentRequest,
  FeedsApiGetFeedRequest,
} from '@scaleleap/selling-partner-api-sdk/lib/api-models/feeds-api-model';
import { DOMAIN } from '../../utils';
import { RequestExtraParams } from '../types';
import { makeCall } from '../utils';

const URL_BASE = `${DOMAIN}/spApi/feeds`;

export async function downloadReport(
  request: FeedsApiGetFeedDocumentRequest & RequestExtraParams
): Promise<any> {
  const res = await makeCall<any>(
      'get',
      `${URL_BASE}/downloadReport`,
      request
  );

  return res;
}

export async function getFeed(
  request: FeedsApiGetFeedRequest & RequestExtraParams
): Promise<Feed> {
  const res = await makeCall<Feed>('get', `${URL_BASE}/getFeed`, request);

  return res;
}
