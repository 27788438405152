import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signupSuccess } from '../../store/actions/authActions'
import firebase from 'firebase';
import 'firebase/firestore';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    padding: 40
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    marginTop: theme.spacing(4),
  }
});

class SignUp extends Component {
  state = {
    email: '',
    password: '',
    passwordConfirm: '',
    errorMsg: null
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      errorMsg: null
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ errorMsg: 'Passwords not identical' })
    } else if (this.state.password.trim() === '') {
      this.setState({ errorMsg: 'Password is empty' })
    } else {
      firebase.auth().createUserWithEmailAndPassword(
        this.state.email,
        this.state.password
      ).then(resp => {
        return firebase.firestore().collection('users').doc(resp.user.uid).set({
          email: this.state.email,
          id: resp.user.uid,
          subscription: "NOT ACTIVE",
          subscription_start: firebase.firestore.Timestamp.fromDate(new Date()),
          subscription_end: firebase.firestore.Timestamp.fromDate(new Date()),
          role: 'MANAGER',
          auth_token: '',
          keepa_key: '',
          seller_id: '',
        });
      }).then((resp) => {
        this.props.dispatch(signupSuccess(resp));
      }).catch((err) => {
        this.setState({ errorMsg: err.message })
      });
    }
  }
  render() {
    const { auth, classes, updateTitle } = this.props;
    const link = React.forwardRef((props, ref) => <div ref={ref}><NavLink {...props} /></div>)
    if (auth) {
      updateTitle('Home')
      return <Redirect to='/' />
    }
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={this.handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirm password"
              type="password"
              id="passwordConfirm"
              autoComplete="current-password"
              onChange={this.handleChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
            >
              Sign Up
                        </Button>
            <Grid container>
              <Grid item>
                <Link href="#" variant="body2" component={link} to='/signin'>
                  {"Already have an account? Login"}
                </Link>
              </Grid>
            </Grid>
          </form>
          <Typography className={classes.error} color='error'>
            {this.state.errorMsg}
          </Typography>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.info,
  }
}


export default connect(mapStateToProps)(withStyles(styles)(SignUp))
