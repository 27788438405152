import React, { useEffect } from 'react';
import {
    Button,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Box,
} from '@material-ui/core';
import LoadingDialog from '../common/LoadingDialog';
import QuantityField from '../common/QuantityField';

/**
 * Dialog which allows to select a box the user wants to move
 * an item to. Additionally he can input the desired quantity.
 * @param {Object} props - Component props. 
 * @param {boolean} props.opened - Setting it to true displays a dialog.
 * @param {function} props.onClose - Callback function executed when a dialog
 * is being closed.
 * @param {boolean} props.loading - Tells if a dialog should display a loading indicator.
 * @param {Object} props.item - Item to move.
 * @param {Object[]} props.boxes - List of available boxes.
 * @param {function} props.onMove - Callback function executed when the move button is clicked.
 */
export default function MoveItemDialog({
    opened,
    onClose,
    onMove,
    loading,
    item,
    boxes,
}) {
    const [selectedBox, setSelectedBox] = React.useState(null);
    const [quantity, setQuantity] = React.useState(0);

    useEffect(() => {
        setSelectedBox(boxes[0] ? boxes[0].id : null);
        setQuantity(0);
    }, [boxes, opened]);

    return (
        <LoadingDialog
            onClose={onClose}
            opened={opened}
            loading={loading}
            title='Move product'
            classes={{
                paper: 'shipping-page-background-color',
            }}
            maxWidth={false}
            actions={
                <>
                    <Button onClick={onClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (selectedBox && quantity > 0) {
                                onMove(selectedBox, item, quantity);
                            }
                        }}
                        color='primary'
                    >
                        Move
                    </Button>
                </>
            }
        >
            <Box
                className='edit-box-dialog-container'
                display='flex'
                flexDirection='row'
                minHeight='60px'
            >
                <QuantityField
                    value={quantity}
                    onChange={q => {
                        if (q <= item.BoxQuantity) {
                            setQuantity(q);
                        }
                    }}
                />
                <FormControl
                    variant='outlined'
                    style={{
                        margin: '0 1em',
                        minWidth: '120px',
                        borderRadius: '1em',
                    }}
                >
                    <InputLabel htmlFor='select-box-input'>Box</InputLabel>
                    <Select
                        value={selectedBox}
                        defaultValue={selectedBox}
                        label='Box'
                        inputProps={{
                            id: 'select-box-input',
                        }}
                        style={{
                            minWidth: '120px',
                            borderRadius: '1em',
                            backgroundColor: '#ffffff',
                        }}
                        disabled={boxes.length === 0}
                        onChange={event => setSelectedBox(event.target.value)}
                    >
                        {boxes.map(box => {
                            return (
                                <MenuItem value={box.id} key={box.id}>
                                    Box {box.number}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
        </LoadingDialog>
    );
}
