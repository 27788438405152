import React, { useState, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import './Appbar.scss';
import { connect, useSelector } from 'react-redux';

import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import AdminLinks from './AdminLinks';

import BoxPageV2 from '../../components/upc/BoxPageV2';
import SettingsPage from '../../components/settings/SettingsPage';
import Dashboard from '../../components/dashboard/Dashboard';
import BuylistViewer from '../../components/scan/BuylistViewer';
import SierraBrowser from '../../components/upc/SierraBrowser';
import SierraDB from '../../components/upc/SierraDB';
import CombinedBrowser from '../../components/upc/CombinedBrowser';
import RepricerBrowser from '../../components/upc/RepricerBrowser';
import ShopperManagement from '../../components/scan/ShopperManagement';
import SchedulerBrowser from '../../components/upc/SchedulerBrowser';
import ShipmentsViewer from '../../components/upc/ShipmentsViewer';
import SourceBrowser from '../../components/upc/SourceBowser';
import ReturnItemsPage from '../../components/return/ReturnItemsPage';
import StatsPage from '../../components/return/StatsPage';
import OrdersBrowser from '../../components/upc/OrdersBrowser';
import ProductBrowser from '../../components/upc/ProductBrowser';
import ProductImport from '../../components/upc/ProductImport';
import SignIn from '../../components/auth/SignIn';
import SignUp from '../../components/auth/SignUp';
import BoxShipmentPage from '../../components/shipping_boxes/BoxShipmentPage';
import SourcingBrowser from '../upc/SourcingBrowser';
import NotifBrowser from '../upc/NotifBrowser';
import BoxesNotifsPage from '../upc_notifs/BoxesNotifsPage';
import PrivateRoute from './PrivateRoute';
import ForbiddenPage from '../auth/ForbiddenPage';
import NotifLinks from './NotifLinks';

const CustomToolbar = ({ title, onMenuClick, username }) => (
    <Fragment>
        <AppBar position='static'>
            <Toolbar className='toolbar'>
                <div className='title-container'>
                    <IconButton
                        color='inherit'
                        aria-label='Menu'
                        onClick={onMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' color='inherit'>
                        {title}
                    </Typography>
                </div>
                {username && (
                    <div className='title-container'>
                        <Typography variant='caption' color='inherit'>
                            {username}
                        </Typography>
                        <div className='user-avatar'>
                            <p>{username.toUpperCase()[0]}</p>
                        </div>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    </Fragment>
);

const CustomDrawer = ({ variant, open, onClose, links, updateTitle }) => {
    const userData = useSelector(state => state.db.userData);

    const renderComponent = Component => props => (
        <Component {...props} updateTitle={updateTitle} />
    );

    const routeClosed = !(userData?.role?.includes('MANAGER') || userData?.role === 'ADMIN');

    return (
        <BrowserRouter>
            <Drawer
                variant={variant}
                open={open}
                onClose={onClose}
                classes={{
                    paper: 'drawer-paper',
                }}
            >
                <div className='drawer-bar-container'>
                    <p className='drawer-bar-title'>Project: Suite</p>
                </div>
                {links}
            </Drawer>
            {userData?.role?.includes('NOTIFS') ? 
            <Switch>
                <PrivateRoute
                    path='/'
                    render={renderComponent(BoxesNotifsPage)}
                />
                
                <Route path='/signin' render={renderComponent(SignIn)} />
                <Route path='/signup' render={renderComponent(SignUp)} /> 
            </Switch>
            :
            <Switch>
                <PrivateRoute
                    exact
                    path='/'
                    render={renderComponent(Dashboard)}
                />
                <Route path='/signin' render={renderComponent(SignIn)} />
                <Route path='/signup' render={renderComponent(SignUp)} />
                <PrivateRoute
                    path='/upc'
                    render={renderComponent(ProductBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/import'
                    render={renderComponent(ProductImport)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/settings'
                    render={renderComponent(SettingsPage)}
                />
                <PrivateRoute path='/box' render={renderComponent(BoxPageV2)} />
                <PrivateRoute
                    path='/buylist'
                    render={renderComponent(BuylistViewer)}
                />
                <PrivateRoute
                    path='/shipments'
                    render={renderComponent(ShipmentsViewer)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/sierra'
                    render={renderComponent(SierraBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/sierradb'
                    render={renderComponent(SierraDB)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/combined'
                    render={renderComponent(CombinedBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/repricer'
                    render={renderComponent(RepricerBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/notifs'
                    render={renderComponent(NotifBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/shoppers'
                    render={renderComponent(ShopperManagement)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/scheduler'
                    render={renderComponent(SchedulerBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/sourcing'
                    render={renderComponent(SourcingBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/source'
                    render={renderComponent(SourceBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/returns/stats'
                    render={renderComponent(StatsPage)}
                />
                <PrivateRoute
                    path='/returns'
                    render={renderComponent(ReturnItemsPage)}
                />
                <PrivateRoute
                    path='/orders'
                    render={renderComponent(OrdersBrowser)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/box_shipment'
                    render={renderComponent(BoxShipmentPage)}
                />
                <PrivateRoute
                    path='/boxes_notifs'
                    render={renderComponent(BoxesNotifsPage)}
                    closed={routeClosed}
                />
                <PrivateRoute
                    path='/forbidden'
                >
                    <ForbiddenPage />
                </PrivateRoute>
            </Switch>
            }
        </BrowserRouter>
    );
};

function AppBarInteraction({ variant, auth, userData }) {
    const [drawer, setDrawer] = useState(false);
    const [title, setTitle] = useState('Project: Suite');

    const toggleDrawer = () => {
        setDrawer(!drawer);
    };

    const updateTitle = title => {
        setTitle(title);
    };

    const onItemClick = title => () => {
        setTitle(title);
        setDrawer(!drawer);
    };

    var links = auth ? (
        <SignedInLinks onItemClick={onItemClick} />
    ) : (
        <SignedOutLinks onItemClick={onItemClick} />
    );
    if (auth !== null && userData !== null && userData.role === 'ADMIN') {
        links = (
            <AdminLinks onItemClick={onItemClick} toggleDrawer={toggleDrawer} />
        );
    } else if (auth !== null && userData !== null && userData.role?.includes('NOTIFS')) {
        links = (
            <NotifLinks onItemClick={onItemClick} toggleDrawer={toggleDrawer} />
        );
    }

    let username;
    if (auth) {
        username = auth.email.split('@')[0];
    }
    return (
        <div className='root'>
            <CustomToolbar
                title={title}
                onMenuClick={toggleDrawer}
                username={username}
            />
            <CustomDrawer
                open={drawer}
                onClose={toggleDrawer}
                updateTitle={updateTitle}
                variant={variant}
                links={links}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
    };
};

export default connect(mapStateToProps)(AppBarInteraction);
