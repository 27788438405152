import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
// import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import CustomSnackbar from '../layout/CustomSnackbar';
import CustomDatatable from './elements/CustomDatatable';
import { FormLabel, FormGroup, TextField } from '@material-ui/core';
import KeepaGraph from './KeepaGraph';

const styles = theme => ({
    container: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        marginTop: theme.spacing(4),
        padding: 5,
        margin: 5,
    },
    innerContainer: {

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});


const academyColumns = [
    {
        name: "Name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
            customFilterListRender: v => {
                if (v) {
                    return `Name includes ${v}`
                }
                return false;
            },
        }
    },
    {
        name: "Link",
        label: "Link",
        options: {
            filter: false,
            sort: true,
            display: true,
            customBodyRender: (value, _, __) => {
                return <a target="_blank" rel="noopener noreferrer" href={value}>Link</a>
            }
        }
    },
    {
        name: "ListPrice",
        label: "ListPrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min ListPrice: ${v['min']}, Max ListPrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min ListPrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max ListPrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>ListPrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "SalePrice",
        label: "SalePrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min SalePrice: ${v['min']}, Max SalePrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min SalePrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max SalePrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>SalePrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "Savings",
        label: "Savings",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min Savings: ${v['min']}, Max Savings: ${v['max']}`;
                } else if (v['min']) {
                    return `Min Savings: ${v['min']}`;
                } else if (v['max']) {
                    return `Max Savings: ${v['max']}`;
                }
                return false;
            },

            customBodyRender: (value, _, __) => (
                <span>{value}%</span>
            ),
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>Savings</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "Image",
        label: "Image",
        options: {
            filter: false,
            sort: false,
            display: true,
            customBodyRender: (value, _, __) => (
                <KeepaGraph graphURL={'https://' + value} width={150} height={150} />
            )
        }
    },
]


const dicksColumns = [
    {
        name: "Name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
            customFilterListRender: v => {
                if (v) {
                    return `Name includes ${v}`
                }
                return false;
            },
        }
    },
    {
        name: "Link",
        label: "Link",
        options: {
            filter: false,
            sort: true,
            display: true,
            customBodyRender: (value, _, __) => {
                return <a target="_blank" rel="noopener noreferrer" href={value}>Link</a>
            }
        }
    },
    {
        name: "OriginalPrice",
        label: "ListPrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min ListPrice: ${v['min']}, Max ListPrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min ListPrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max ListPrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>ListPrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "OldPrice",
        label: "OldPrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min OldPrice: ${v['min']}, Max OldPrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min OldPrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max OldPrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>OldPrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "SalePrice",
        label: "SalePrice",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min SalePrice: ${v['min']}, Max SalePrice: ${v['max']}`;
                } else if (v['min']) {
                    return `Min SalePrice: ${v['min']}`;
                } else if (v['max']) {
                    return `Max SalePrice: ${v['max']}`;
                }
                return false;
            },
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>SalePrice</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "Savings",
        label: "Savings",
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            customFilterListRender: v => {
                if (v['min'] && v['max']) {
                    return `Min Savings: ${v['min']}, Max Savings: ${v['max']}`;
                } else if (v['min']) {
                    return `Min Savings: ${v['min']}`;
                } else if (v['max']) {
                    return `Max Savings: ${v['max']}`;
                }
                return false;
            },
            customBodyRender: (value, _, __) => (
                <span>{value}%</span>
            ),
            filterOptions: {
                names: [],
                logic(age, filters) {
                    if (filters['min'] && filters['max']) {
                        return age < filters['min'] || age > filters['max'];
                    } else if (filters['min']) {
                        return age < filters['min'];
                    } else if (filters['max']) {
                        return age > filters['max'];
                    }
                    return false;
                },
                display: (filterList, onChange, index, column) => (
                    <div>
                        <FormLabel>Savings</FormLabel>
                        <FormGroup row>
                            <TextField
                                label="min"
                                value={filterList[index]['min'] || ''}
                                onChange={event => {
                                    filterList[index]['min'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%', marginRight: '5%' }}
                            />
                            <TextField
                                label="max"
                                value={filterList[index]['max'] || ''}
                                onChange={event => {
                                    filterList[index]['max'] = event.target.value;
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: '45%' }}
                            />
                        </FormGroup>
                    </div>
                ),
            },
        },
    },
    {
        name: "LowStock",
        label: "LowStock",
        options: {
            filter: true,
            display: true,
            filterType: 'dropdown',
            customFilterListRender: v => {
                if (v) {
                    return `LowStock: ${v}`
                }
                return false;
            },
        }
    },
    {
        name: "SortDate",
        label: "SortDate",
        options: {
            sort: true,
            display: false,
            sortDirection: 'desc',
        }
    },
    {
        name: "FirstSeen",
        label: "FirstSeen",
        options: {
            display: true,
            customBodyRender: (value, _, __) => {
                return <p>{value.replace("T", " ").replace("Z", " ").split(".")[0]}</p>
            }
        }
    },
]



class SourcingBrowser extends Component {
    state = {
        code: '',
        cost: '',
        coupon: '',
        subscription: 'NOT ACTIVE',
        credentials: {
            token: ' ',
            sellerID: ' ',
            keepaKey: ' '
        },
        productData: [],
        errorMsg: '',
        snackbarMsg: '',
        snackbarOpened: false,
        snackbarVariant: 'error',
        isLoading: false,
        loadingMsg: '',
        currColl: '',
        oldColl: '',
        collections: ['academy', 'dicks_app', 'dicks_foot', 'dicks_north'],
        purchasedList: '',
        salesData: [],
        siteColumns: []
    }

    constructor(props) {
        super(props)
        this.textInput = React.createRef();
    }


    render() {
        const { classes } = this.props;
        const { auth, userData } = this.props;
        const copy = require('clipboard-copy')



        const options = {
            filterType: 'textField',
            selectableRows: 'none',
            print: false,
            onCellClick: (colData, celMeta) => {
                copy(`${colData}`)
            },
            downloadOptions: {
                filename: 'sourcingBrowserExport.csv',
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true,
                }
            },
            responsive: 'standard',
            fixedHeader: true,
        };

        if (!auth || (userData !== undefined && userData.role !== 'ADMIN')) return <Redirect to='/signin' />

        return (
            <Container display="flex" maxWidth={false} height="80%">
                <Container width='100%' m={3} >
                    <Box display="flex" flexDirection="row" className={classes.container} justifyContent="center" alignItems="center">
                        <Select
                            value={this.state.currColl}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'currColl',
                                id: 'choose-curr-coll',
                            }}
                        >
                            {this.state.collections.map((name) => {
                                return <MenuItem value={name}>{name}</MenuItem>
                            })}
                        </Select>
                        <Button onClick={() => this.newGetData(this.state.currColl)}>
                            Generate
            </Button>
                    </Box>
                </Container>

                <Container width='100%' maxWidth="xl" className={classes.container}>
                    {/* <MuiThemeProvider theme={niceScrollTheme}> */}
                    <CustomDatatable
                        title={"Website data"}
                        data={this.state.productData}
                        columns={this.state.siteColumns}
                        options={options}
                    />
                    {/* </MuiThemeProvider> */}


                </Container>

                <CustomSnackbar variant={this.state.snackbarVariant} duration={2000} onClose={this.onSnackbarClose} className={classes.margin} opened={this.state.snackbarOpened} message={this.state.snackbarMsg} />
            </Container>
        )
    }

    focusTextInput() {
        this.textInput.current.focus();
    }

    handleChange = e => {
        if (e.target.id !== undefined) {
            this.setState({
                [e.target.id]: e.target.value
            })
        } else if (e.target.name !== undefined) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    handleSubmit = (e) => {
        console.log('Submitted wtf lol')
    }


    getProjSuiteData = async (token, db, coll) => {
        const dropletData = await fetch(`https://api.projectsuite.io/repricer?firebase_token=${token}&db=${db}&collection=${coll}`).then((resp) => resp.json());
        return dropletData.result;
    }

    newGetData = async (currColl) => {
        console.log(currColl);
        const token = await getToken();
        this.getProjSuiteData(token, 'scheduler', currColl).then(data => {
            console.log(data);
            if (currColl === 'academy') {
                const excludedBrandList = [`BCG`, `Zephyr`, `WinCraft`, `The Northwest Company`, `State Love`, `New World Graphics`, `NFL`, `Majestic`, `New Era`, `New World Graphics`, `Magellan Outdoors`, `Forever Collectibles`, `Kansas City Chiefs`, `Dickies`, `Colosseum Athletics`, `BCG`, `'47`];
                const tableData = data.map(prod => {
                    const newProd = { ...prod }
                    newProd.Savings = parseFloat((newProd.Savings * 100).toFixed(2));
                    return newProd
                }).filter(prod => !excludedBrandList.includes(prod.Manufacturer) && (prod.Savings > 20 || prod.Savings === 0))
                this.setState({ productData: tableData, siteColumns: academyColumns })
            } else {
                const tableData = data.map(prod => {
                    const newProd = { ...prod }
                    newProd.OriginalPrice = parseFloat(newProd.OriginalPrice);
                    newProd.SalePrice = parseFloat(newProd.SalePrice);
                    newProd.LowStock = prod.LowStock ? 'True' : 'False';
                    newProd.Savings = parseFloat(((1 - newProd.SalePrice / newProd.OriginalPrice) * 100).toFixed(2));
                    newProd.SortDate = (new Date(prod.FirstSeen)).getTime()
                    return newProd
                })
                this.setState({ productData: tableData, siteColumns: dicksColumns })
            }
        })
    }

    uniq = (a) => {
        var seen = {};
        return a.filter(function (item) {
            return seen.hasOwnProperty(item.ASIN) ? false : (seen[item.ASIN] = true);
        });
    }

    onSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbarOpened: false, snackbarMsg: null })
    }
}

SourcingBrowser.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.info,
        userData: state.db.userData,
        salesData: state.db.salesData,
        purchasedData: state.db.purchasedData,
    }
}


export default connect(mapStateToProps)(withStyles(styles)(SourcingBrowser))