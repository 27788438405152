import { randID } from '../../../utils/random';

const _ = require('lodash');

/**
 * Returns the given quantity of the item if it doesn't exceed the limit for the shipment or
 * the maximum quantity that can be added to the shipment otherwise. 
 * @param {Object} shipment 
 * @param {Object} item 
 * @param {number} quantity 
 */
const parseQuantity = (shipment, item, quantity) => {
    let itemQuantity = getItemQuantity(shipment, item.FulfillmentNetworkSKU);
    let diff = itemQuantity + quantity - item.QuantityShipped;
    diff = Math.max(0, diff);
    return quantity - diff;
};

/**
 * Sums the quantity of the item across all boxes in the given shipment.
 * @param {Object} shipment 
 * @param {string} fnsku FNSKU code of the item
 * @param {string} boxToExcludeId ID of the box which shouldn't be taken into
 * consideration
 */
const getItemQuantity = (shipment, fnsku, boxToExcludeId) => {
    let boxes = shipment.Boxes;
    if (boxToExcludeId) boxes = boxes.filter(b => b.id !== boxToExcludeId);
    let items = boxes.reduce((prev, curr) => prev.concat(curr.items), []);
    items = items.filter(i => i.FulfillmentNetworkSKU === fnsku);
    return items.reduce((prev, curr) => prev + curr.BoxQuantity, 0);
};

/**
 * Counts unique items in the shipment.
 * @param {Object} shipment 
 */
const countSkus = shipment => {
    let shipmentCopy = _.cloneDeep(shipment);
    // Merge all items in boxes into one list
    let items = shipmentCopy.Boxes.reduce(
        (prev, curr) => prev.concat(curr.items),
        []
    );

    // Create a map in which every entry represents one unique item
    let map = new Map();
    items.forEach(item => {
        let key = item.FulfillmentNetworkSKU;
        let value = map.get(key);
        if (!value) {
            map.set(key, true);
        }
    });

    return map.size;
};

/**
 * Returns the number of boxes with any items and set dimensions
 * @param {Array} boxes 
 */
const calcNonemptyBoxes = boxes => {
    return boxes.filter(
        box =>
            calcBoxSize(box) > 0 &&
            box.weight > -1 &&
            box.length > -1 &&
            box.width > -1 &&
            box.height > -1
    ).length;
};

/**
 * Returns the summed quantity of items in the box.
 * @param {Object} box 
 */
const calcBoxSize = box => {
    let quantity = 0;
    box.items.forEach(item => {
        quantity += item.BoxQuantity;
    });
    return quantity;
};

/**
 * Returns the summed quantity of items across all boxes in the shipment.
 * @param {Object} shipment 
 */
const calcShipmentSize = shipment => {
    let quantity = 0;
    shipment.Boxes.forEach(box => {
        box.items.forEach(item => {
            quantity += item.BoxQuantity;
        });
    });
    return quantity;
};

const findItem = (box, fnsku) => {
    return box.items.find(i => i.FulfillmentNetworkSKU === fnsku);
};

/**
 * Returns a pair containing a box with the given ID and its shipment. Returns a pair of nulls
 * if no box is found.
 * @param {Array} shipments 
 * @param {string} boxId 
 */
const findBox = (shipments, boxId) => {
    for (let shipment of shipments) {
        let box = shipment.Boxes.find(b => b.id === boxId);
        if (box) {
            return [box, shipment];
        }
    }
    return [null, null];
};

const findShipment = (shipments, boxId) => {
    return shipments.find(shipment => shipment.Boxes.find(b => b.id === boxId));
};

const newBox = (number, shipmentId) => {
    let box = {
        number: number,
        weight: -1,
        length: -1,
        width: -1,
        height: -1,
        items: [],
        currentItem: null,
        id: randID(),
        shipmentId,
    };
    return box;
};

/**
 * Assigns numbers to boxes in the list.
 * @param {Array} boxes 
 */
const reorderBoxes = boxes => {
    return boxes.map((box, i) => {
        return {
            ...box,
            number: i + 1,
        };
    });
};

/**
 * Removes the box of the given ID from the shipment. If there is only 1 box,
 * it only resets its content.
 * @param {Object} shipment 
 * @param {string} boxId 
 */
const deleteBox = (shipment, boxId) => {
    if (shipment.Boxes.length > 1) {
        shipment.Boxes = reorderBoxes(
            shipment.Boxes.filter(b => b.id !== boxId)
        );
        shipment.CurrentBox = shipment.Boxes[shipment.Boxes.length - 1].id;
    } else {
        let box = shipment.Boxes[0] || {};
        box.items = [];
        box.width = -1;
        box.height = -1;
        box.weight = -1;
        box.length = -1;
        box.currentItem = null;
    }

    return shipment;
};

export {
    calcShipmentSize,
    calcBoxSize,
    findBox,
    newBox,
    calcNonemptyBoxes,
    reorderBoxes,
    deleteBox,
    countSkus,
    findItem,
    getItemQuantity,
    findShipment,
    parseQuantity,
};
