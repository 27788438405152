import React, { useEffect } from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import LoadingDialog from '../common/LoadingDialog';
import QuantityField from '../common/QuantityField';
import { utils } from './shipments';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../store/actions/notificationsActions';
import DeleteIcon from '@material-ui/icons/Delete';

const _ = require('lodash');

/**
 * Displays product information in a row.
 * @param {Object} props - Component props.
 * @param {Object} props.product - Product information.
 * @param {function} props.onSelect - Callback function executed when a single row is
 * clicked.
 * @param {function} props.onQuantityChange - Callback function executed when product's
 * quantity is changed by the user.
 * @param {function} props.onDelete - Callback function executed when a delete button is
 * clicked.
 */
function ProductRow({ product, onSelect, onQuantityChange, onDelete }) {
    return (
        <Grid
            className='list-row edit-box-dialog-row soft-corners'
            key={product.FulfillmentNetworkSKU}
            container={true}
            onClick={() => {
                if (product.BoxQuantity > 0) onSelect(product);
            }}
        >
            <Grid item={true} sm={1} className='centered-container'>
                <img
                    src={product.ImgURL}
                    alt=''
                    style={{ width: '60px', height: 'auto' }}
                />
            </Grid>
            <Grid
                item={true}
                sm={2}
                className='fnsku-container label-info-container'
            >
                <p className='label'>Title</p>
                <div className='info'>
                    <p>{product.Name}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={2}
                className='fnsku-container label-info-container'
            >
                <p className='label'>FNSKU</p>
                <div className='info'>
                    <p>{product.FulfillmentNetworkSKU}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={1}
                className='sku-container label-info-container'
            >
                <p className='label'>ASIN</p>
                <div className='info'>
                    <p>{product.ASIN}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={2}
                className='sku-container label-info-container'
            >
                <p className='label'>UPC</p>
                <div className='info'>
                    <p>{product.UPC}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={3}
                className='product-quantity-container label-info-container'
            >
                <p className='label'>Quantity</p>
                <div className='info'>
                    <QuantityField
                        style={{ backgroundColor: 'transparent' }}
                        onChange={quantity =>
                            onQuantityChange(
                                product.FulfillmentNetworkSKU,
                                quantity
                            )
                        }
                        value={product.BoxQuantity}
                    />
                </div>
            </Grid>
            <Grid
                item={true}
                sm={1}
                onClick={e => e.stopPropagation()}
                className='label-info-container'
            >
                <IconButton
                    aria-label='Close'
                    style={{ color: 'red' }}
                    onClick={() => onDelete(product)}
                >
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}

/**
 * Displays a list of products.
 * @param {Object} props - Component props.
 * @param {Object[]} props.products - List of products to display.
 * @param {function} props.onSelect - Callback function executed when a single product row is
 * clicked.
 * @param {function} props.onQuantityChange - Callback function executed when single product's
 * quantity is changed by the user.
 * @param {function} props.onDelete - Callback function executed when a delete button on a single
 * row is clicked.
 */
function ProductList({ products, onQuantityChange, onSelect, onDelete }) {
    return (
        <div className='dialog-product-list'>
            {products.map(product => (
                <ProductRow
                    product={product}
                    onQuantityChange={onQuantityChange}
                    onSelect={onSelect}
                    onDelete={onDelete}
                    key={product.FulfillmentNetworkSKU}
                />
            ))}
        </div>
    );
}

/**
 * Displays a dialog with an editable list of products
 * @param {Object} props - Component props.
 * @param {boolean} props.opened - Setting it to true displays a dialog.
 * @param {function} props.onClose - Callback function executed when a dialog
 * is being closed.
 * @param {function} props.onSave - Callback function executed when the user wants to
 * finish editing.
 * @param {Object} props.box - Box to edit data.
 * @param {boolean} props.loading - Tells if a dialog should display a loading indicator.
 * @param {Object} props.shipment - Data of the box's shipment.
 * @param {function} moveItem - Callback function executed when the user wants to move
 * an item to a different box.
 */
export default function EditBoxDialog({
    opened,
    onClose,
    onSave,
    box,
    loading,
    moveItem,
    shipment,
}) {
    const [productsState, setProducts] = React.useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setProducts(_.cloneDeep(box ? box.items : []));
    }, [box]);

    const onProductChange = (id, checked) => {
        let productsCopy = _.cloneDeep(productsState);
        let product = productsCopy.find(s => s.FulfillmentNetworkSKU === id);
        product.checked = checked;

        setProducts(productsCopy);
    };

    const showWarningSnackbar = msg => {
        dispatch(
            enqueueSnackbar({
                message: "This shipment is confirmed. You can't modify it.",
                options: { variant: 'warning' },
            })
        );
    };

    const onQuantityChange = (id, quantity) => {
        if (shipment.TransportStatus === 'CONFIRMED') {
            showWarningSnackbar();
            return;
        }

        let productsCopy = _.cloneDeep(productsState);
        let product = productsCopy.find(s => s.FulfillmentNetworkSKU === id);
        if (
            quantity + utils.getItemQuantity(shipment, id, box.id) <=
            product.QuantityShipped
        ) {
            product.BoxQuantity = quantity;
        }

        setProducts(productsCopy);
    };

    // let checkedProducts = productsState.filter((p) => p.checked);

    return (
        <LoadingDialog
            onClose={() => {
                if (shipment.TransportStatus !== 'CONFIRMED') {
                    onSave(productsState.map(({ checked, ...rest }) => rest));
                }
                onClose();
            }}
            opened={opened}
            loading={loading}
            title='Edit box'
            classes={{
                paper: 'edit-box-dialog shipping-page-background-color',
            }}
            maxWidth={false}
            actions={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                >
                    {/* {checkedProducts.length > 0 ? (
                        <Button
                            onClick={() => {
                                moveItems(checkedProducts);
                            }}
                            // variant="outlined"
                            style={{
                                margin: "0 0. !important",
                                padding: "6px 16px !important"
                            }}
                        >
                            Move items
                        </Button>
                    ) : null} */}
                    <div>
                        <Button
                            onClick={() => {
                                onClose();
                                setProducts(_.cloneDeep(box ? box.items : []));
                            }}
                            color='primary'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                if (shipment.TransportStatus === 'CONFIRMED') {
                                    showWarningSnackbar();
                                    return;
                                }
                                onSave(
                                    productsState.map(
                                        ({ checked, ...rest }) => rest
                                    )
                                );
                            }}
                            color='primary'
                        >
                            Save
                        </Button>
                    </div>
                </div>
            }
        >
            <div className='edit-box-dialog-container'>
                <ProductList
                    products={productsState}
                    onChange={onProductChange}
                    onQuantityChange={onQuantityChange}
                    onSelect={product => {
                        if (shipment.TransportStatus === 'CONFIRMED') {
                            showWarningSnackbar();
                        } else {
                            moveItem(product);
                        }
                    }}
                    onDelete={product => {
                        setProducts(
                            productsState.filter(
                                item =>
                                    item.FulfillmentNetworkSKU !==
                                    product.FulfillmentNetworkSKU
                            )
                        );
                    }}
                />
            </div>
        </LoadingDialog>
    );
}
