import React from 'react';
import { Button } from '@material-ui/core';
import LoadingDialog from './LoadingDialog';

export default function ConfirmationDialog({
    opened,
    onClose,
    message,
    loading,
    onConfirm,
}) {
    return (
        <LoadingDialog
            onClose={onClose}
            opened={opened}
            classes={{
                paper: 'shipping-page-background-color',
            }}
            loading={loading}
            actions={
                <>
                    <Button onClick={onClose} color='primary'>
                        No
                    </Button>
                    <Button color='secondary' onClick={onConfirm}>
                        Yes
                    </Button>
                </>
            }
        >
            <p className='dialog-message'>{message}</p>
        </LoadingDialog>
    );
}
