import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import LoadingDialog from '../common/LoadingDialog';
import Image from 'material-ui-image';
import { useSelector } from 'react-redux';
import placeholder from '../../assets/placeholder.png';
import QuantityField from '../common/QuantityField';
import { utils } from './shipments';

/**
 * Dialog with basic product information and an option to input its quantity.
 * @param {Object} props - Component props. 
 * @param {boolean} props.opened - Setting it to true displays a dialog.
 * @param {function} props.onClose - Callback function executed when a dialog
 * is being closed.
 * @param {function} props.onConfirm - Callback function executed when the user
 * confirms a product. 
 */
export default function ProductDialog({
    opened,
    onClose,
    onConfirm,
    shipment,
}) {
    const [quantity, setQuantity] = useState(0);

    const product = useSelector(state => state.inboundShipments.scannedItem);

    useEffect(() => {
        if (product && shipment) {
            let boxQuantity = utils.getItemQuantity(
                shipment,
                product.FulfillmentNetworkSKU
            );
            setQuantity(product.QuantityShipped - boxQuantity);
        }
    }, [product, shipment]);

    if (!product) return null;
    return (
        <LoadingDialog
            title='Product'
            opened={opened}
            onClose={onClose}
            classes={{
                paper: 'shipping-page-background-color product-dialog',
            }}
            actions={
                <>
                    <Button onClick={onClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onConfirm(product, quantity)}
                        color='primary'
                    >
                        OK
                    </Button>
                </>
            }
        >
            <Grid container={true}>
                <Grid item={true} sm={4}>
                    <Image
                        style={{ backgroundColor: 'initial' }}
                        imageStyle={{ height: 'auto' }}
                        src={product.ImgURL ? product.ImgURL : placeholder}
                    />
                </Grid>
                <Grid
                    item={true}
                    sm={8}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <p className='title-info'>{product.Name}</p>
                    <QuantityField
                        value={quantity}
                        onChange={quantity => {
                            if (
                                quantity +
                                    utils.getItemQuantity(
                                        shipment,
                                        product.FulfillmentNetworkSKU
                                    ) <=
                                product.QuantityShipped
                            ) {
                                setQuantity(quantity);
                            }
                        }}
                        style={{ margin: '1em' }}
                    />
                </Grid>
            </Grid>
        </LoadingDialog>
    );
}
