import { put, all } from 'redux-saga/effects';
import * as shippingActions from '../../actions/shippingActions';
import * as notifications from '../../actions/notificationsActions';
import { inboundShipments } from '../../../utils/project_api';
import * as Sentry from '@sentry/react';
import {
    fulfillmentInbound,
    MARKETPLACE_ID,
} from '../../../utils/project_api/selling_partner';

/**
 * Saga function responsible for downloading the list of the latest shipments
 * with the WORKING status (last 14 days are considered). It first downloads the list
 * from the SP API and then it passes their IDs to our server in order to fetch their items.
 *
 * @param {Object} actionData
 */
export function* getShipments(actionData) {
    yield put(
        shippingActions.updateStatus({
            error: null,
            isFetchingShipments: true,
        })
    );
    try {
        let updatedAfter = new Date();
        updatedAfter.setDate(updatedAfter.getDate() - 14);
        let updatedBefore = new Date();
        let response = yield fulfillmentInbound.getShipments({
            marketplaceId: MARKETPLACE_ID,
            queryType: 'DATE_RANGE',
            lastUpdatedAfter: updatedAfter.toISOString(),
            lastUpdatedBefore: updatedBefore.toISOString(),
            shipmentStatusList: ['WORKING'],
            devMode: false
        });
        let nextToken = response.NextToken;
        let shipments = response.ShipmentData || [];
        while (nextToken) {
            response = yield fulfillmentInbound.getShipments({
                marketplaceId: MARKETPLACE_ID,
                queryType: 'NEXT_TOKEN',
                nextToken: nextToken,
                devMode: false
            });
            nextToken = response.NextToken;
            shipments = shipments.concat(response.ShipmentData || []);
        }

        shipments = shipments.filter(s => /.*WISC RAPIDS/.test(s.ShipmentName));

        let ids = shipments.map(s => s.ShipmentId);
        let backendResponse = yield inboundShipments.get(
            'getShipments',
            {
                shipmentIds: JSON.stringify(ids),
            },
            {}
        );
        if (backendResponse.error) {
            throw new Error(backendResponse.error);
        }
        let returnedShipments = backendResponse.result;

        // Keep only shipments that have the same address as the user
        const address = actionData.address;
        returnedShipments = returnedShipments.filter(
            s =>
                s.ShipFromAddress.AddressLine1 === address.address1 &&
                s.ShipFromAddress.City === address.city &&
                s.ShipFromAddress.StateOrProvinceCode === address.stateCode &&
                s.ShipFromAddress.PostalCode === address.postalCode
        );

        // Create a local object in the state for every returned shipment
        yield all(
            returnedShipments.map(s => put(shippingActions.createShipment(s)))
        );
        yield put(
            notifications.enqueueSnackbar({
                message: 'Shipments downloaded!',
                options: { variant: 'success' },
            })
        );
        yield put(shippingActions.updateStatus({ isFetchingShipments: false }));
    } catch (e) {
        Sentry.captureException(e);
        yield all([
            put(
                shippingActions.updateStatus({
                    error: e.message,
                    isFetchingShipments: false,
                })
            ),
            put(
                notifications.enqueueSnackbar({
                    message: e.message,
                    options: { variant: 'error' },
                })
            ),
        ]);
    }
}
