import React, { useMemo } from 'react';
import { Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import LoadingDialog from '../common/LoadingDialog';
import { useSelector, batch, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/shippingActions';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from '../common/ConfirmationDialog';

/**
 * Renders product's data in a single row with an option to input
 * desired quantity in order to add it to the newest box and an option
 * to delete an item from the shipment.
 * @param {Object} props - Component props.
 * @param {Object} props.product - Product data.
 */
function ProductRow({ product }) {
    const dispatch = useDispatch();
    const shipmentsState = useSelector(state => state.inboundShipments);
    return (
        <Grid
            className='list-row edit-box-dialog-row soft-corners'
            key={product.FulfillmentNetworkSKU}
            container={true}
        >
            <Grid item={true} sm={1} className='centered-container'>
                <img
                    src={product.ImgURL}
                    alt=''
                    style={{ width: '60px', height: 'auto' }}
                />
            </Grid>
            <Grid
                item={true}
                sm={2}
                className='fnsku-container label-info-container'
            >
                <p className='label'>Title</p>
                <div className='info'>
                    <p>{product.Name}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={2}
                className='fnsku-container label-info-container'
            >
                <p className='label'>FNSKU</p>
                <div className='info'>
                    <p>{product.FulfillmentNetworkSKU}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={2}
                className='sku-container label-info-container'
            >
                <p className='label'>ASIN</p>
                <div className='info'>
                    <p>{product.ASIN}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={2}
                className='sku-container label-info-container'
            >
                <p className='label'>UPC</p>
                <div className='info'>
                    <p>{product.UPC}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={1}
                className='sku-container label-info-container'
            >
                <p className='label'>Qty Left</p>
                <div className='info'>
                    <p>{product.QuantityLeft}</p>
                </div>
            </Grid>
            <Grid
                item={true}
                sm={1}
                className='sku-container label-info-container'
            >
                <Button
                    style={{
                        margin: 0,
                        borderRadius: '1em',
                    }}
                    onClick={() => {
                        batch(() => {
                            let { QuantityLeft, ...other } = product;
                            dispatch(actions.setProductDialogOpened(true));
                            dispatch(actions.setScannedItem(other));
                        });
                    }}
                    variant='outlined'
                >
                    ADD
                </Button>
            </Grid>
            <Grid
                item={true}
                sm={1}
                onClick={e => e.stopPropagation()}
                className='label-info-container'
            >
                {shipmentsState.deletedItem?.sku === product.SellerSKU &&
                shipmentsState.deletedItem?.shipmentId ===
                    shipmentsState.selectedShipmentId &&
                !shipmentsState.shipmentItemDeletionConfirmation ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                ) : (
                    <IconButton
                        aria-label='Close'
                        style={{ color: 'red' }}
                        onClick={() => {
                            batch(() => {
                                dispatch(
                                    actions.setDeletedShipmentItem(
                                        shipmentsState.selectedShipmentId,
                                        product.SellerSKU
                                    )
                                );
                                dispatch(
                                    actions.setDeleteShipmentItemConfirmation(
                                        true
                                    )
                                );
                            });
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
}

/**
 * Displays a list of products.
 * @param {Object} props - Component props.
 * @param {Object[]} props.products - List of products. 
 */
function ProductList({ products }) {
    return (
        <div className='dialog-product-list'>
            {products.map(product => (
                <ProductRow
                    product={product}
                    key={product.FulfillmentNetworkSKU}
                />
            ))}
            {products.length === 0 && (
                <p className='title' style={{ textAlign: 'center' }}>
                    No items left
                </p>
            )}
        </div>
    );
}

/**
 * Displays unboxed items of the currently selected shipment. Also allows to 
 * delete an item from the shipment and add it to the newest box.
 * @param {Object} props - Component props.
 * @param {boolean} props.opened - Setting it to true displays a dialog.
 * @param {function} props.onClose - Callback function executed when a dialog
 * is being closed. 
 */
export default function UnboxedItemsDialog({ opened, onClose }) {
    const dispatch = useDispatch();
    const shipmentId = useSelector(
        state => state.inboundShipments.selectedShipmentId
    );
    const shipmentsState = useSelector(state => state.inboundShipments);

    const shipment = shipmentsState.shipments.find(
        s => s.ShipmentId === shipmentId
    );

    const unboxed = useMemo(() => {
        let boxed = shipment.Boxes.reduce((prev, curr) => {
            curr.items.forEach(item => {
                if (prev[item.FulfillmentNetworkSKU]) {
                    prev[item.FulfillmentNetworkSKU] += item.BoxQuantity;
                } else {
                    prev[item.FulfillmentNetworkSKU] = item.BoxQuantity;
                }
            });
            return prev;
        }, {});
        let unboxedItems = shipment.ExpectedItems.map(item => {
            return {
                ...item,
                QuantityLeft:
                    item.QuantityShipped -
                    (boxed[item.FulfillmentNetworkSKU] || 0),
            };
        }).filter(item => item.QuantityLeft > 0);
        return unboxedItems;
    }, [shipment]);

    return (
        <>
            <LoadingDialog
                onClose={onClose}
                opened={opened}
                title='Unboxed items'
                classes={{
                    paper: 'edit-box-dialog shipping-page-background-color',
                }}
                maxWidth={false}
            >
                <div className='edit-box-dialog-container'>
                    <ProductList products={unboxed} />
                </div>
            </LoadingDialog>
            <ConfirmationDialog
                opened={shipmentsState.shipmentItemDeletionConfirmation}
                message='Are you sure you want to delete this item?'
                onClose={() => {
                    batch(() => {
                        dispatch(
                            actions.setDeleteShipmentItemConfirmation(false)
                        );
                        dispatch(actions.setDeletedShipmentItem());
                    });
                }}
                onConfirm={() => {
                    // Extract information from the chosen item
                    let shipmentId = shipmentsState.deletedItem?.shipmentId;
                    let sku = shipmentsState.deletedItem?.sku;
                    batch(() => {
                        dispatch(
                            actions.setDeleteShipmentItemConfirmation(false)
                        );
                        dispatch(actions.deleteShipmentItem(shipmentId, sku));
                    });
                }}
            />
        </>
    );
}
