import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { List, ListItem, TextField, Button } from '@material-ui/core';
import './BoxesDialog.scss';

function getCurrDate(t) {
    return (new Date(t)).toLocaleString('en-US')
}

export default function BoxesDialog(props) {
    const [input, setInput] = React.useState("");
    const { onClose, opened, boxes, onEntered, loadBox } = props;
    // if (!boxes.length) return null;

    const handleSubmit = () => {
        loadBox(input);
    }

    const handleChange = (event) => {
        setInput(event.target.value);
    }

    return (
        <Dialog open={opened} onClose={onClose} aria-labelledby="form-dialog-title" onEntered={onEntered}>
            <DialogTitle id="form-dialog-title">
                {"Confirm product"}
                <IconButton aria-label="Close" className="close-button" style={{ position: 'absolute', right: '0.4em', top: '0.4em' }} onClick={onClose} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className="boxes-list-container">
                    <div className="input-container">
                        <TextField
                            className="code-input"
                            id="input"
                            label="Tracking ID"
                            type="text"
                            value={input}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            onKeyPress={(ev) => {
                                console.log(`Pressed keyCode ${ev.key}`);
                                if (ev.key === 'Enter') {
                                    handleSubmit(ev)
                                }
                            }}
                        />
                        <div className="btn-scan-container">
                            <Button onClick={handleSubmit} id="btn-scan" >Search</Button>
                        </div>
                    </div>
                    <List className="boxes-list">
                        {
                            boxes.map(b => (
                                <ListItem onClick={() => { loadBox(b.trackingID) }} button key={b.trackingID} >
                                    <div className="boxes-list-row">
                                        <div className="box-id-container">
                                            <p className="box-detail-label">Tracking ID</p>
                                            <p>{b.trackingID}</p>
                                        </div>
                                        <div className="box-date-container">
                                            <p className="box-detail-label">Date</p>
                                            <p>{getCurrDate(b.date)}</p>
                                        </div>
                                        <div className="box-expected-items-container">
                                            <p className="box-detail-label">Items</p>
                                            <p>{b.retrievedItems}</p>
                                        </div>
                                        <div className="box-finished-items-container">
                                            <p className="box-detail-label">Finished</p>
                                            <p>{b.finishedItems}</p>
                                        </div>
                                    </div>
                                </ListItem>
                            ))
                        }
                    </List>
                </div>
            </DialogContent>
        </Dialog>
    );
}

BoxesDialog.propTypes = {
    className: PropTypes.string,
    opened: PropTypes.bool,
    boxes: PropTypes.array,
    onClose: PropTypes.func,
    onEntered: PropTypes.func,
    onProceed: PropTypes.func,
};