import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from '../actions/notificationsActions';

const defaultState = {
    snackbars: [],
};

export const notificationsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                snackbars: [
                    ...state.snackbars,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                snackbars: state.snackbars.map(notification =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                snackbars: state.snackbars.filter(
                    notification => notification.key !== action.key
                ),
            };

        default:
            return state;
    }
};
